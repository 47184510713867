import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProductService } from '../../product/product.service';
@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.css']
})
export class SearchModalComponent implements OnInit {

  searchForm: FormGroup;
  submitted:boolean = false;
  formSumbit:boolean = false;
  errors:any = null;
  result:any = null;
  products:any = [];
  searchPost:string = "";
  constructor(
  	public activeModal: NgbActiveModal,
  	private formBuilder: FormBuilder,
    private ProductService: ProductService,
    public router: Router,
  ) { }

  ngOnInit(): void {

  	this.buildForm();
  }
  buildForm() {
    this.searchForm = this.formBuilder.group({
      searchname: ['']
    });
  }
  search(){
    if(this.router.url == '/product-search'){
      this.router.navigate(['/']);
    }
    this.ProductService.searchProduct(this.searchForm.value).subscribe(
      result => {
        this.result = result;
        this.products = this.result.data;
        this.searchPost   = this.result.search;
        this.ProductService.sharedProduct = this.result;
        this.activeModal.close('Modal Closed');
        this.router.navigateByUrl('/product-search');
      },
      error => {
        this.errors = error.error;
        this.formSumbit = false;
      },() => {
        this.formSumbit = false;
      }
    );
  }
}
