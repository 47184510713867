import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryService} from '../country.service';
@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {
  id;
  sub;
  store:any =[];
  storename:string = "";
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private CountryService: CountryService,
  ) { }

  ngOnInit(): void {
    localStorage.removeItem('cart_count');
    this.sub = this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.getStore();
      setTimeout(()=>this.router.navigate(['/order-details',this.id]),5000);
    });

     window.scroll({ 
           top: 0, 
           left: 0, 
           behavior: 'smooth' 
    });
  }
  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      result => {
        this.store = result;
        console.log(this.store);
        this.storename = this.store.seo_url
      },
      error => {
        
      },() => {
      }
    );
  }
}
