import { Component, OnInit } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CountryService} from 'src/app/country.service';
import { PrivacyPolicyComponent } from 'src/app/modals/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from 'src/app/modals/terms-conditions/terms-conditions.component';
import { ReturnPoliceComponent } from 'src/app/modals/return-police/return-police.component';
import { AuthStateService } from '../../shared/auth-state.service';
import { AuthService } from '../../shared/auth.service';
import { TokenService } from '../../shared/token.service';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  shopStatus:string = "CLOSED";
  shopStatusColor:string = "red";
  shopOpeningTiming:number = Date.now();
  shopCloseTiming:number = Date.now();
  now: number = Date.now();
  store:any =[];
  storename:string = "";
  address:string = "";
  pincode = "";
  storeTiming:string = "";
  html:any ="";
  isSignedIn: boolean;
  shopphone:string ="";
  shopOpClseStatus:boolean = false;
  mainSiteUrl = environment.MAIN_SITE_URL;
  constructor(
    private modalService: NgbModal,
    private CountryService: CountryService,
    private auth: AuthStateService,
    public authService: AuthService,
    public token: TokenService,
  ) { }

  async ngOnInit() {
    this.checkAuth();
    this.getStore();
    //setTimeout(() => { this.ngOnInit() }, 1000 * 10);
  }
  checkAuth(){
    this.authService.userAuthValideToken().subscribe(
      result => {
       //
        
      },error => {
        this.auth.setAuthState(false);
        this.token.removeToken();
      }
    );
  }
  getPrivacyPolicy(){
    const modalRef = this.modalService.open(PrivacyPolicyComponent,{ 
      centered: true, 
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'login_modal'
    });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
    console.log(result);
    }).catch((error) => {
    console.log(error);
    });
  }
  getterms(){
    const modalRef = this.modalService.open(TermsConditionsComponent,{ 
      centered: true, 
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'login_modal'
    });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
    console.log(result);
    }).catch((error) => {
    console.log(error);
    });
  }
  getReturnPolicy(){
    const modalRef = this.modalService.open(ReturnPoliceComponent,{ 
      centered: true, 
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'login_modal'
    });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
    console.log(result);
    }).catch((error) => {
    console.log(error);
    });
  }
  getStore(){
    this.auth.userAuthState.subscribe(val => {
      console.log(val);
      this.CountryService.getStoreDetails().subscribe(
        result => {
          this.store = result;
          this.storename = this.store.seo_url;
          if(this.store.address != "NULL" ){
            this.address = this.store.address;
          }
          this.pincode = this.store.pin;
          this.storeTiming = this.store.shop_timing;
          this.shopOpeningTiming = this.store.opening_time;
          this.shopCloseTiming = this.store.closing_time;
          this.shopphone = this.store.phone;
          this.shopStatus = this.store.shop_status;
          if(this.shopStatus =="OPEN"){
            this.shopStatusColor = "green";
          }else{
            this.shopStatusColor = "red";
          }
          if(this.store.opening_time != "00:00"){
            this.shopOpClseStatus =true;
          }
        },
        error => {
          
        },() => {
        });

    });
  }
}
