import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { ProductService } from '../../product/product.service';
 
@Component({
  selector: 'app-search-product-header',
  templateUrl: './search-product-header.component.html',
  styleUrls: ['./search-product-header.component.css']
})
export class SearchProductHeaderComponent implements OnInit {

  searchForm: FormGroup;
  submitted:boolean = false;
  formSumbit:boolean = false;
  errors:any = null;
  result:any = null;
  products:any = [];
  searchPost:string = "";
  constructor(
    private formBuilder: FormBuilder,
    private ProductService: ProductService,
    public router: Router,
  ) { }
  
  ngOnInit(): void {
    this.buildForm();
  }
  buildForm() {
    this.searchForm = this.formBuilder.group({
      searchname: ['']
    });
  }
  search(){
    this.ProductService.searchProduct(this.searchForm.value).subscribe(
      result => {
        this.result = result;
        this.products = this.result.data;
        this.searchPost   = this.result.search;
        this.ProductService.sharedProduct = this.result;
        this.router.navigateByUrl('/product-search');
      },
      error => {
        this.errors = error.error;
        this.formSumbit = false;
      },() => {
        this.formSumbit = false;
      }
    );
  }
}
