import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductService } from '../product/product.service';
import { CountryService} from '../country.service';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { CartService } from '../cart.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { NotificationsComponent } from '../modals/notifications/notifications.component';
import { ErrorNotificationsComponent} from '../modals/error-notifications/error-notifications.component';
import { Location } from '@angular/common'
import { LoginComponent } from 'src/app/modals/login/login.component';
import { AuthStateService } from '../shared/auth-state.service';
@Component({
  selector: 'app-search-product',
  templateUrl: './search-product.component.html',
  styleUrls: ['./search-product.component.css']
})
export class SearchProductComponent implements OnInit {
  searchForm: FormGroup;
  submitted:boolean = false;
  formSumbit:boolean = false;
  products:any = [];
  isLoading:boolean = false;
  storename:string = "";
  store:any = [];
  result:any = [];
  searchPost:string = "";
  errors:any = null;
  closeResult = '';
  cartR: any = [];
  session_id:string = "";
  count:any = 0;
  showNoProducts:boolean = false;
  showSearchDesc:boolean = false;
  isSignedIn: boolean;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ProductService: ProductService,
    private CountryService: CountryService,
    private formBuilder: FormBuilder,
    private cartService: CartService,
    private modalService: NgbModal,
    private confirmationDialogService: ConfirmationDialogService,
    private location: Location,
    private auth: AuthStateService,
  ) { }
  
  ngOnInit(): void {
    
    this.result = this.ProductService.sharedProduct;
    this.products = this.result.data;
    
    if(!this.products){
      this.router.navigate(['/']);
    }

    if(this.products.length == 0){
      this.showNoProducts = true; 
    }
    this.searchPost   = this.result.search;
    if(this.searchPost === null ){
      this.showSearchDesc = true;
    }
    this.buildForm();
    //this.search(this.searchPost);
    this.getStore();
    this.auth.userAuthState.subscribe(val => {
      this.isSignedIn = val;
    });
  }
  buildForm() {
    this.searchForm = this.formBuilder.group({
      searchname: [this.searchPost]
    });
  }
  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      data => {
        this.store = data;
        this.storename = this.store.seo_url
      }
    );
  }
  search(){
    this.ProductService.searchProduct(this.searchForm.value).subscribe(
      result => {
        this.result = result;
        this.products = this.result.data;
        if(this.products.length ===0){
          this.showNoProducts = true;
        }else{
          this.showNoProducts = false;
        }
        this.searchPost   = this.result.search;
        if(this.searchPost === null ){
          this.showSearchDesc = true;
        }
      },
      error => {
        this.errors = error.error;
      },() => {
      }
    );
  }
  buyNow( id, quantity): void {
    if(!this.isSignedIn ){ 
      this.openLoginModal('buynow', id, quantity); 
      return;
    }
    this.isLoading=true;
    let payload = {
      productId: id,
      quantity,
      session_id:sessionStorage.getItem("session_id")
    };
    this.cartService.addToCart(payload).subscribe(
      result => {
        this.cartR = result;
        sessionStorage.removeItem('session_id');
        sessionStorage.setItem('session_id', this.cartR.session_id);
        this.isLoading=false;
        this.getCartCount();
        this.router.navigate(['/cart']);
      },
      error => {
        this.errors = error.error;
      },() => {
        
      }
    );
  }

  
  
  addItemToCart( id, quantity): void {
    if(!this.isSignedIn ){ 
      this.openLoginModal('addtocart', id, quantity); 
      return;
    }
    sessionStorage.removeItem('message');
    this.isLoading=true;
    let payload = {
      productId: id,
      quantity,
      session_id:sessionStorage.getItem("session_id")
    };
    this.cartService.addToCart(payload).subscribe(
      result => {
        this.cartR = result;
        sessionStorage.removeItem('session_id');
        sessionStorage.setItem('session_id', this.cartR.session_id);
        this.isLoading=false;
        this.getCartCount();
        sessionStorage.setItem('message', "message"); 
        this.modalService.open(NotificationsComponent, {
            centered: true,
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
            windowClass: 'cartadd_modal',
            animation: true,
            ariaDescribedBy:'test',
          }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
      },
      error => {
		
		this.errors = error.error;
        this.isLoading=false;
        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                            size: 'xl',
                            backdrop: 'static',
                            keyboard: false,
                            windowClass: 'cartadd_modal',
                            animation: true,
                            ariaDescribedBy:'test',
                          });
        modalRef.componentInstance.message = this.errors.error;
		
		
      },() => {
        
      }
    );
  }
  getCartCount(){
    this.cartService.getCartItemCount().subscribe(
      data => {
        this.count = data;
        localStorage.setItem("cart_count", JSON.stringify(this.count));
      },
      error =>{
        this.errors = error.error;
        this.count = 0;
        localStorage.setItem("cart_count", JSON.stringify(this.count));
      }
    ); 
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  goBack(){
    this.location.back();
  }
  goToShopBrand(){
    this.router.navigate(['/brands']);
  }
  openLoginModal(type, id, quantity) {
    //history.pushState(null, null, '');
    const modalRef = this.modalService.open(LoginComponent,{ 
                                            centered: true, 
                                            size: 'xl',
                                            backdrop: 'static',
                                            keyboard: false,
                                            windowClass: 'login_modal'
                                          });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
      this.auth.userAuthState.subscribe(val => {
        this.isSignedIn = val;
      });
      if(type == "buynow"){
        this.buyNow(id, quantity);
      }else{
        this.addItemToCart(id, quantity)
      }
    }).catch((error) => {
      console.log(error);
    });
  }
}
