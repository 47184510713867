import { Component, OnInit, Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef, HostListener, Inject} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  closeResult: string;
  resetPasswordForm: FormGroup;
  submitted:boolean = false;
  errors = null;
  result = null;
  token  :string = '';
  constructor(
  	public router: Router,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
  	this.activatedRoute.queryParams.subscribe(params => {
        this.token = params['token'];
        if(this.token){
        	this.resetPasswordForm = this.formBuilder.group({
      			email:[''],
            phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
	   			  password: ['',[Validators.required,Validators.minLength(6),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&].{5,}')] ],
        		password_confirmation: ['', Validators.required],
        		token: [this.token]
    		});
        }
    });
  }
  get f() { return this.resetPasswordForm.controls; }
  	isFieldValid(field: string) {
    return !this.resetPasswordForm.get(field).valid && this.resetPasswordForm.get(field).touched;
  }
  resetPassword(){
    this.errors ="";
    this.submitted = true;
    if(this.resetPasswordForm.invalid){
      this.scrollToError();
      return;
    }

    this.authService.resetPassword(this.resetPasswordForm.value).subscribe(
      result => {
        this.result = result;
        
        setTimeout(()=>{                           // <<<---using ()=> syntax
            this.activeModal.close('Modal Closed');
            this.router.navigate(['/'])
        }, 3000);
      },
      error => {
        this.submitted = false;
        this.errors = error.error;
        console.error(this.errors);
      },() => {
        //this.activeModal.close('Modal Closed');
      }
    );
  }
  trim(myString) {
    console.log(myString);
    this.resetPasswordForm.patchValue({
      phone: myString.replace(" ",''),
    });
    
  }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    console.log("aa:", firstElementWithError);

    this.scrollTo(firstElementWithError);
  }
}
