import { Component, OnInit , Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef} from '@angular/core';
import { NgbActiveModal , ModalDismissReasons , NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../../cart.service';
import { OrderService } from '../../order.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-gift-items',
  templateUrl: './order-gift-items.component.html',
  styleUrls: ['./order-gift-items.component.css']
})
export class OrderGiftItemsComponent implements OnInit {
  @Input() parent_id: number;
  items:any = [];
  giftbox:any =[];
  amount = 0;
  constructor(
  	public router: Router,
    public activeModal: NgbActiveModal,
    private cartService: CartService,
    private modalService: NgbModal,
    private OrderService: OrderService
  ) { }
  
  ngOnInit(): void {
  	this.getContent();
  }
  getContent(){
    this.OrderService.getOrderGiftBoxItem(this.parent_id).subscribe(
      data => {
        this.giftbox = data;
        this.items = this.giftbox.gift_items;
      }
    ); 
  	
  }
  locationsSum(){
  	if(this.items.length >0){
	    this.amount = this.items.map(tag => tag.amount*1).reduce((a, b) => a + b, 0);
	}
	return this.amount;
  }
}
