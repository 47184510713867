import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders  } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, tap, shareReplay } from 'rxjs/operators';
import { environment } from './../environments/environment';
export class User {
  email: String;
  phone:String;
  address:String;
  country_id:number;
  pin:number;
  state_id:number;
  town_id:number;
}
export class Shop {
  shop_type:any
}

export class Payment {
  payment_id: any; 
  id: any;
}

@Injectable({
  providedIn: 'root'
})


export class CountryService {
  appShopId:any;
  argShopId:any;

  constructor(private http: HttpClient) { }

 //apiBaseUrl = 'https://bolas.co.in/admin/api/';
 //apiBaseUrl = 'https://bsk.bispltest.in/admin/api/';
 apiBaseUrl = environment.APP_URL;
  getCountries() {
    return this.http.get(`${this.apiBaseUrl}country`).pipe(
      catchError(this.handleError)
    );
  }
  getStores() {
    return this.http.get(`${this.apiBaseUrl}get-stores`).pipe(
      catchError(this.handleError)
    );
  }
  getStates(countryId: number) {
    return this.http.get(`${this.apiBaseUrl}states/${countryId}`).pipe(
      catchError(this.handleError)
    );
  }

  getCities(stateId: number) {
    return this.http.get(`${this.apiBaseUrl}cities/${stateId}`).pipe(
      catchError(this.handleError)
    );
  }
  getAllStates(){
    return this.http.get(`${this.apiBaseUrl}all-states`).pipe(
      catchError(this.handleError)
    );
  }
  getAllTown(){
    return this.http.get(`${this.apiBaseUrl}all-towns`).pipe(
      catchError(this.handleError)
    );
  }
  getBrand() {


    //Handle without token
    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId   && this.appShopId!='undefined'){
        this.appShopId='0';
    }else{
        this.appShopId=localStorage.getItem('appShopId'); 
    } 

    return this.http.get(`${this.apiBaseUrl}get-brand/${this.appShopId}`).pipe(
      catchError(this.handleError)
    );
  }
  getCategory() {
    return this.http.get(`${this.apiBaseUrl}get-category`).pipe(
      catchError(this.handleError)
    );
  }
  
  getDeliveryAddress(){
    return this.http.get(`${this.apiBaseUrl}get-delivery-address`).pipe(
      catchError(this.handleError)
    );
  }
  getBillingAddress(){
    return this.http.get(`${this.apiBaseUrl}get-billing-address`).pipe(
      catchError(this.handleError)
    );
  }
  getPermittedPin(){
    return this.http.get(`${this.apiBaseUrl}get-premitted-pincode`).pipe(
      catchError(this.handleError)
    );
  }
  
  profileUpdate(user: User): Observable<any> {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.post(`${this.apiBaseUrl}update-profile`, user, {
      headers: headers
      }
    );
  }
  myAddress(){
    return this.http.get(`${this.apiBaseUrl}get-my-address`).pipe(
      catchError(this.handleError)
    );
  }
  myDefaultAddress(){
    return this.http.get(`${this.apiBaseUrl}get-my-defauld-address`).pipe(
      catchError(this.handleError)
    );
  }
  updateAddress(user: User): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}update-address`, user);
  }
  addAddress(user: User): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}add-address`, user);
  }
  getAddressbyID(id){
    return this.http.get(`${this.apiBaseUrl}get-address-id/${id}`);
  }
  deleteAddressbyID(id){
    return this.http.delete(`${this.apiBaseUrl}delete-address/${id}`);
  }
  changePassword(user: User): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}change-password`, user);
  }
  setPassword(user: User): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}set-password`, user);
  }
  payment(user: User): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}payment`, user);
  }
  getAllBrands(){
    return this.http.get(`${this.apiBaseUrl}get-all-brands`);
  }
  registerShop(user: User): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}register-shop`, user);
  }
  getUserStore() {
    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId && this.appShopId!='undefined'){
        return this.http.get(`${this.apiBaseUrl}get-user-store`);
    }else{
        this.appShopId=this.getAppShopId(); 
        return this.appShopId;
    }
  }
  userStoreLog(shop: Shop): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}user-store-log`, shop);
  }
  getStoresCount(){
    return this.http.get(`${this.apiBaseUrl}all-store-logs`);
  }
  getUserStoreDetails(){
    return this.http.get(`${this.apiBaseUrl}get-store-details`);
  }
  getStoreDetails(){

    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId && this.appShopId!='undefined'){
      return this.http.get(`${this.apiBaseUrl}get-store-details`).pipe(
          catchError(this.handleError)
      );
    }else{ 
        this.appShopId=this.getAppShopId(); 
        return this.http.get(`${this.apiBaseUrl}get-store-details-by-shopid/${this.appShopId}`).pipe(
          catchError(this.handleError)
        );
    } 

    /*return this.http.get(`${this.apiBaseUrl}get-store-details`).pipe(
      catchError(this.handleError)
    );*/
  }
  getStoresExceptCurrentOutlet(){
    return this.http.get(`${this.apiBaseUrl}get-stores-except-cshop`);
  }
  changeOutlet(user: User): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}change-outlet`, user);
  }
  payWithCOD(payment: Payment): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}payment-cod`, payment);
  }
  payWithRay(payment: Payment): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}payment-ray`, payment);
  }
  checkMaintenance(){
    return this.http.get(`${this.apiBaseUrl}check-maintenance`);
  } 

  getAppShopId(){
    return localStorage.getItem('appShopId');
  }

  getStoreDetailswithoutToken(){ 
    this.appShopId=this.getAppShopId(); 
    return this.http.get(`${this.apiBaseUrl}get-store-details-by-shopid/${this.appShopId}`).pipe(
      catchError(this.handleError)
    );
  }
  getCategorywithoutToken() {
    this.appShopId=this.getAppShopId();
    return this.http.get(`${this.apiBaseUrl}get-category-by-shopid/${this.appShopId}`).pipe(
      catchError(this.handleError)
    );
  }

  getStoreDetailswithId(shopid){ 
    this.argShopId=shopid; 
    return this.http.get(`${this.apiBaseUrl}get-store-details-by-shopid/${this.argShopId}`).pipe(
      catchError(this.handleError)
    );
  } 

  changeUserOutletAfterLogin(payload): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}change-outlet`, payload);
  } 
  getUserShop(): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}get-user-shop`); 
  } 
  getOtherCategory(id) {
    return this.http.get(`${this.apiBaseUrl}get-other-category/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  getOtherCategorywithoutToken(id) {
    this.appShopId=this.getAppShopId();
    return this.http.get(`${this.apiBaseUrl}get-other-category-by-shopid/${this.appShopId}/${id}`).pipe(
      catchError(this.handleError)
    );
  } 

  getGooglemapNearestLocation(id) { 
    return this.http.get(`${this.apiBaseUrl}get-gmap-nearest-location/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  getGooglemapAllOutlets(id) { 
    return this.http.get(`${this.apiBaseUrl}get-gmap-all-outlets`).pipe(
      catchError(this.handleError)
    );
  }

  getlatlon(id) { 
    return this.http.get(`${this.apiBaseUrl}get-lat-lon/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }
}
