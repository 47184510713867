import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CountryService } from '../../country.service';
@Component({
  selector: 'app-billing-address',
  templateUrl: './billing-address.component.html',
  styleUrls: ['./billing-address.component.css']
})
export class BillingAddressComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  billingAddress:any =[];
  constructor(
    public router: Router,
    public activeModal: NgbActiveModal,
    private CountryService: CountryService,
  ) { }

  ngOnInit(): void {
    this.getBillingAddress();
  }
  addBillingAddressToStore(id){
    this.activeModal.close('Modal Closed');
    this.passEntry.emit(id);
  }
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
  getBillingAddress(){
    this.CountryService.getBillingAddress().subscribe(
      data => this.billingAddress = data
    );
  }

}
