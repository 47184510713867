<app-sub-header></app-sub-header>
 
<div class="containercart_outer">

    <div class="container_cart">

        <div class="row row_custom">

            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cart_topdummy">

            </div>

        </div>



        <div class="row row_custom">

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col accountheadbg addresshead">

                <h3 class="accountheadtext">NEW ADDRESS</h3>

            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

            </div>

        </div>



        <div class="row row_custom">

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 myacc_leftcol">

                <a [routerLink]="['/myaccount']">
                    <p class="myacc_return">RETURN TO ACCOUNT PAGE</p>
                </a>

                <div *ngIf="errors?.error" class="alert alert-danger mt-3">
                    {{ errors?.error }}
                </div>
				
				
		

                <form [formGroup]="newAddressFrom">

                    <label class="myacc_label" for="name">Full Name:</label>
                    <input type="hidden" formControlName="id" />
                    <input class="myacc_text" type="text" placeholder="Enter Name" name="name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }"  maxlength="80" >
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Name is required</div>
                    </div>
                    <div *ngIf="errors?.phone" class="backend_error">{{ errors?.name }}</div>
                    <label class="myacc_label" for="mobile">Mobile:</label>

                    <input class="myacc_text" type="text" placeholder="Enter Mobile Number" name="mobile" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">Mobile number is required</div>
                        <div *ngIf="f.phone.errors.pattern">Please enter a valid mobile number</div>
                    </div>
                    <div *ngIf="errors?.phone" class="backend_error">{{ errors?.phone }}</div>

                    <label class="myacc_label" for="address">Address:</label>

                    <input class="myacc_text" type="text" placeholder="Enter House No./Building/Street" name="address" formControlName="address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                    <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                        <div *ngIf="f.address.errors.required">Address is required</div>
                    </div>
                    <div *ngIf="errors?.phone" class="backend_error">{{ errors?.address }}</div>


                    <label class="myacc_label" for="pin">Pincode:</label>

                    <input class="myacc_text" type="text" placeholder="Enter Pincode" name="pin" formControlName="pin" [ngClass]="{ 'is-invalid': submitted && f.pin.errors }">
                    <div *ngIf="submitted && f.pin.errors" class="invalid-feedback">
                        <div *ngIf="f.pin.errors.required">Pincode is required</div>
                        <div *ngIf="f.pin.errors.minlength">Minimum 6 characters required</div>
                        <div *ngIf="f.pin.errors.pattern">Please enter a valid pincode</div>
                    </div>
                    <div *ngIf="errors?.pin" class="backend_error">{{ errors?.pin }}</div>

                    <label class="myacc_label" for="country">Country:</label>

                    <select class="myacc_text" id="country" name="country" (change)="onChangeCountry($event.target.value)" formControlName="country_id">
  
          <option value="">Select Country...</option>
          <option *ngFor="let country of countries" [value]="country.id" >{{country.name}}</option>
    
          </select>

                    <label class="myacc_label" for="state1">State:</label>

                    <select class="myacc_text" id="state1" name="state1" formControlName="state_id" (change)="onChangeState($event.target.value)">
    
            <option value="">Select State...</option>
            <option *ngFor="let state of states" [value]="state.id" >{{state.state}}</option>
    
          </select>

                    <label class="myacc_label" for="city">City:</label>

                    <select class="myacc_text" id="city" formControlName="town_id" name="town"  (change)="addOtherCityBilling($event)" [(ngModel)]="billingCity" >
            <option value="">Select Town /City...</option>
            <option *ngFor="let city of cities" [value]="city.id" >{{city.town}}</option>
          </select>

                    <!-- <input class="chk_defaultaddress" type="checkbox" id="addressdefault" name="addressdefault" value="1" formControlName="default">


                    <label class="lbldefaultaddress" for="defaultadd">&nbsp;Set as default address?</label> -->

                    <label class="myacc_label" for="address"  *ngIf="this.billingAddCity">Add City:</label>

                    <input class="myacc_text" type="text"  placeholder="Add City" name="web_city" formControlName="web_city" maxlength="50" (keypress)="keyPressAlpha($event)" (paste)="onPaste($event)"  *ngIf="this.billingAddCity"   (change)="checkExistingCity()">


                    <label class="myacc_label" for="country">Address Type</label>

                    <div class="myacc_buttonsectop">
                        <input type="hidden" formControlName="address_type" value="1" />
                        <button class="myacc_buttong"  [ngClass]="{myacc_buttony: isActive(1)}"  (click)="setAddressType(1)">WORK</button>

                        <button class="myacc_buttong"  [ngClass]="{myacc_buttony: isActive(2)}"  (click)="setAddressType(2)">HOME</button>

                        <button class="myacc_buttong"  [ngClass]="{myacc_buttony: isActive(3)}"  (click)="setAddressType(3)">OTHERS</button>

                    </div>



                    <div class="myacc_buttonsec">

                        <button type="submit" class="common_updatebutton" (click)="onSubmit()">SAVE</button>

                        <button class="common_cancelbutton" (click)="onReset()">CANCEL</button>

                    </div>

					<div *ngIf="success" class="alert alert-success mt-3">
                     New address saved successfully
                    </div>

                </form>

            </div>

        </div>



    </div>

</div>
<app-footer></app-footer>