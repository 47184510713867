<div class="modal-body">
    <div class="row row_custom">
        
        <div class="col-lg-10 col-md-10 col-sm-10 col-xs-10 process_col warningright">
            <p class="cartadded_text cartaddtext2">{{ message }}</p>

        </div>
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 process_col warningleft">
            <span><img class="mx-auto d-block cartwarning" src="/assets/images/close_popup.png" (click)="activeModal.dismiss('Close clicked')"></span>
        </div> 
    </div>
</div>