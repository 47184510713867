<script> gtag('event', 'conversion', {'send_to': 'AW-10991120270/dzoqCIya9uEDEI7f_Pgo'}); </script>
<app-sub-header></app-sub-header>
 
  
  
  
  <div class="containercart_outer"> 
  
  <div class="container_cart"> 
  
   <div class="row row_custom">
  
       <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cart_topdummy">
  
       </div>
  
   </div>
  
   
  
    <div class="row row_custom">
  
       <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">
  
       </div>
  
       <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col accountheadbg addresshead">
  
        <h3 class="accountheadtext">THANK YOU</h3>
  
        </div>
  
         <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">
  
       </div>
  
      </div>
  
      
  
      <div class="row row_custom">
  
      
  
       <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col thankyou_col">
  
        <p class="thankyou_text">Thank you for shopping with us.</p>
  
        </div>
  
       
  
      </div>
  
  
  
      
  
  </div>
  
  </div>
<app-footer></app-footer>