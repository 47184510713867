import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-error-notifications',
  templateUrl: './error-notifications.component.html',
  styleUrls: ['./error-notifications.component.css']
})
export class ErrorNotificationsComponent implements OnInit {
  @Input() message: string;
  @Input() timeout_val: number =0; 
  defaultTimeout =0;
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.defaultTimeout=3000;
    if(this.timeout_val>0){
        this.defaultTimeout=this.timeout_val; 
    }
    setTimeout(()=>this.activeModal.dismiss('Close clicked'),this.defaultTimeout);
  }

}
