<app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>
<app-sub-header></app-sub-header>
<div class="containeritem_outer bg-grey"> 
	<div class="containeritem"> 
		<div class="process_desk">
		  	<div class="row row_custom ">
				<div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">
					<a [routerLink]="['/gift']">
						<p class="previous_button">
							<span class="giftspan"  >
								<img src="/assets/images/prev.png" class="" alt="image">&nbsp;&nbsp;PREVIOUS
							</span>
						</p>
					</a>
				</div>
				<div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col gifthead">
					<img src="/assets/images/giftbox.png" class="mx-auto d-block" alt="icon">
					<h2 class="accountheadtext">CHOOSE YOUR ITEMS</h2>
					<p class="gifttop_text">We’ve hand-selected the best products in one place. Select from the items below and fill up your box!</p>
				</div>
				<div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">
				</div>
		    </div>
		

			<div class="row row_custom gitem_toprow">
				<div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 gitem_topcol">
					<div class="row row_custom " *ngIf="suggestions.length >0 ">
						<div class="col-md-8 col-lg-8 col-sm-12 col-xs-12 px-0" >
							<p class="gitem_top" *ngIf="totalCartQuanity() == giftData.gift_quanity">
								<span class="gitem_span">
								<img src="/assets/images/itemtop.png" class="" alt="icon">
								</span>Your box is ready to purchase.
							</p>
						</div>
						<div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 px-0">
							<p class="gitem_topgrey text-right">FILLING&nbsp;&nbsp;{{totalCartQuanity()}}/{{giftData.gift_quanity}}</p>
						</div>
					</div>
					<div class="row row_custom gitem_closerow bg-grey" *ngIf="suggestions.length >0 ">
						<div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 gitem_topcol1" *ngFor="let product of suggestions">
							<div class="gitemtop_outer">
								<img src="{{product.product_images}}" class="img-thumbnail0 mx-auto d-block img_selecteditems" alt="giftbox">
								<h4 class="gitem_topimgtext">{{product.quantity}}</h4>
								<a *ngIf="!multiGiftBox" (click)="deleteCartItem(product.id)" class="gitem_cross"><img src="/assets/images/gitemcross.png" class="img-thumbnail0" alt="icon"></a>
							</div>
						</div>

						<span class="gitem_ignore" *ngIf="suggestions.length >0 && !multiGiftBox"><img src="/assets/images/gitem_ignore.png" class="img-thumbnail0" alt="icon">&nbsp;I wish to customize giftbox. <a *ngIf="!multiGiftBox" (click)= emptyGift(giftBox.id) >Ignore suggestions</a></span>
					</div>
					<div class="row row_custom gitem_leftrow2 bg-grey ">
						<div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 gitem_lb">
							<div class="gitemtop_outer">
								<img src="{{giftBox.product_images}}" class="img-thumbnail0 mx-auto d-block" alt="giftbox">
							</div>
						</div>
						<div class="col-md-9 col-lg-9 col-sm-12 col-xs-12 gitem_lb1">
						 	<h3 class="gitem_subhead">{{giftBox.product_name}}</h3>
						  	<hr class="gifthr">
						   	<p class="gitem_leftbodytext">{{giftBox.description}}</p>
						   <p class="gitem_leftbodytext">You can fill upto {{giftData.gift_quanity}} fillings in this box</p>
						   <p class="gitem_leftbodytext"><a [routerLink]="['/gift']" >Change your gift box</a></p>
						   <p class="gitem_leftbodytext">Want to build your own box? <a (click)="chooseItem()">Choose your items</a></p>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 gitem_topcol0">
					<div class="row row_custom ">
						<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col">
							<h3 class="gitem_subhead text-center">BOX CONTENTS</h3>
							<hr class="gitem_rt">
						</div>
					</div>
					<div *ngIf="suggestions.length >0 ">
						<div class="row row_custom" *ngFor="let item of suggestions ; let i = index">
						    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12">
						    	<p class="cartbilltext">{{i+1}}. {{ item.product_name }}</p>
						    </div>
						    <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 px-0">
						    	<p class="cartbilltext " >
						    		<span class="" *ngIf="!multiGiftBox">
						    			<a *ngIf="item.quantity > 1"  [ngClass]="{inactiveButton: item.quantity == 1} " (click)="increamentQTY(item.id,-1)">-</a>
						    			<span *ngIf="item.quantity <= 1"  [ngClass]="{inactiveButton: item.quantity == 1} " >-</span>
						    		</span>&nbsp;&nbsp; <span class="gitem_tb">{{ item.quantity }}</span>&nbsp;&nbsp;<span *ngIf="!multiGiftBox">
						    		<a (click)="increamentQTY(item.id,1)" >+</a></span>
						    	</p>

						    </div>
						    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
						    	<p class="cartbilltext text-right">Rs. {{ item.product_price*item.quantity | number:'2.2-5' }}&nbsp;&nbsp;&nbsp;&nbsp;<span  *ngIf="!multiGiftBox"><a *ngIf="!multiGiftBox"  (click)="deleteCartItem(item.id)"><img src="/assets/images/remove_gi.png" class="img-thumbnail0" alt="icon"></a></span></p>
						    </div>
						</div>
					</div>
					<div class="bg-grey2" *ngIf="suggestions.length >0 ">
						<div class="row row_custom ">
							<div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
								<!--<p class="gitem_subtotal">Box  {{totalCartQuanity()}} Units</p> -->
							</div>
							<div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
								<p class="gitem_subtotal text-right pr-4">Items Subtotal: Rs.{{ locationsSum()  | number:'2.2-5'  }}</p>
							</div>
						</div>
					</div>
					<div class="row row_custom mt-2" *ngIf="suggestions.length >0 ">
						<div class="col-md-8 col-lg-8 col-sm-12 col-xs-12">
							<p class="cartbilltext">1. {{giftBox.product_name}}</p>
						</div>
						<div class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
							<p class="cartbilltext text-right pr-4">Rs. {{giftBox.product_price  | number:'2.2-5'}}<span></span></p>
						</div>
					</div>
					<div class="bg-grey2" *ngIf="suggestions.length >0 ">
						<div class="row row_custom ">
							<div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
								<div class="giftbox_Router">
									<div class="gitemtop_outer">
										<img src="{{giftBox.product_images}}" class="img-thumbnail0" alt="giftbox">
									</div>
									<div class="ml-2 mt-5">
										<p class="gitem_Rname">{{giftBox.product_name}}</p>
										<p class="gitem_Rdet">{{totalCartQuanity()}} Units</p>
										<p class="gitem_Rdet">Rs.{{ locationsSum() + giftBox.product_price*1   | number:'2.2-5' }}</p>
									</div>
								</div>
							</div>
							<div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 process_col mt-5" >
								<p class="cartbilltext" *ngIf="totalCartQuanity() == giftData.gift_quanity"><span class="" ><a *ngIf="giftBoxQuanity> 1" [ngClass]="{inactiveButton: giftBoxQuanity == 1} " (click)="increamentGiftBoxQTY(giftBox.id,-1)">-</a></span>&nbsp;&nbsp; <span class="gitem_tb">{{giftBoxQuanity}}</span>&nbsp;&nbsp;<span><a (click)="increamentGiftBoxQTY(giftBox.id,1)">+</a></span></p>
							</div>
							<div class="col-md-4 col-lg-4 col-sm-12 col-xs-12">
								<p class="gitem_subtotal mt-5 text-right pr-4">Total: Rs.{{ giftBoxQuanity*(locationsSum() + giftBox.product_price*1)   | number:'2.2-5' }}</p>
							</div>
						</div>
					</div>
					<div class="row row_custom " >
						<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 right_padd">
						<div class="input-outer">
						    <span class="icon ico_message"><img src="/assets/images/write.png"></span>
						   	<textarea [(ngModel)]="giftMsg" class="gitem_textarea" rows="2" name="comment" form="usrform" placeholder="Write your gift message here.."></textarea>
						</div>
						<h3 class="gitem_subhead text-right pt-0">Grand Total: Rs.{{ giftBoxQuanity*(locationsSum() + giftBox.product_price*1)   | number:'2.2-5' }}</h3>
						<button type="submit" class="gitem_button" (click)="submit(giftBox.id)"><span class="giftspan"><img src="/assets/images/gitem_check.png" class="img-thumbnail" alt="giftbox"></span>CONFIRM & PROCEED</button>

						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="process_device">
		<div class="fixed_div">
			<div class="row row_custom ">
				<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">
					<p class="gitem_topgrey text-center" *ngIf="suggestions.length >0 ">FILLING&nbsp;&nbsp;{{totalCartQuanity()}}/{{giftData.gift_quanity}}</p>
				</div>
			</div>
			<div class="row row_custom" *ngIf="suggestions.length >0 ">
				<div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 gitem_topcol1"  *ngFor="let product of suggestions">
					<div class="gitemtop_outer">
						<img src="{{product.product_images}}" class="img-thumbnail0 mx-auto d-block img_selecteditems" alt="giftbox">
						<h4 class="gitem_topimgtext">{{product.quantity}}</h4>
						<a *ngIf="!multiGiftBox" (click)="deleteCartItem(product.id)" class="gitem_cross"><img src="/assets/images/gitemcross.png" class="img-thumbnail0" alt="icon"></a>
					</div>
				</div>
			</div>
			<div class="row row_custom " >
				<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngIf="suggestions.length >0 ">
					<p class="gitem_top" *ngIf="totalCartQuanity() == giftData.gift_quanity">
						<span class="gitem_span" ><img src="/assets/images/itemtop.png" class="" alt="icon"></span>Your box is ready to purchase.&nbsp;<span><a class="btn_info" (click)="getGiftItemsAdded(giftBox.id)"><img src="/assets/images/information-button.png" class="" alt="icon"></a></span></p>
					<hr class="gitem_rt">
				</div>
			</div>
			<div class="bg-grey2">
				<div class="row row_custom ">
					<div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 col_half">
						<p class="gitem_Rname">{{giftBox.product_name}}</p>
						<p class="gitem_Rdet">{{giftData.gift_quanity}} Units </p>
						<div class="gitemtop_outer">
							<img src="{{giftBox.product_images}}" class="img-thumbnail0" alt="giftbox">
						</div>
					</div>
					<div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 col_half">
						<p class="gitem_subtotal text-right ">Total: Rs.{{ giftBoxQuanity*(locationsSum()  + giftBox.product_price*1)   | number:'2.2-5' }}</p>
						<p class="cartbilltext text-right" *ngIf="totalCartQuanity() == giftData.gift_quanity"><span class=""><a *ngIf="giftBoxQuanity> 1" [ngClass]="{inactiveButton: giftBoxQuanity == 1} " (click)="increamentGiftBoxQTY(giftBox.id,-1)">-</a></span>&nbsp;&nbsp; <span class="gitem_tb">{{giftBoxQuanity}}</span>&nbsp;&nbsp;<span><a (click)="increamentGiftBoxQTY(giftBox.id,1)">+</a></span></p>
						<span class="gitem_ignore" *ngIf="suggestions.length >0 && !multiGiftBox"><img src="/assets/images/gitem_ignore.png" class="img-thumbnail0" alt="icon" >&nbsp;I wish to customize giftbox. <a  *ngIf="!multiGiftBox"  (click)= emptyGift(giftBox.id)>Ignore suggestions</a></span>
					</div>
				</div>
				<div class="row row_custom ">
					<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
						<div class="input-outer">
					    	<span class="icon ico_message"><img src="/assets/images/write.png"></span>
					   		<textarea [(ngModel)]="giftMsg" class="gitem_textarea" rows="2" name="comment" form="usrform" placeholder="Write your gift message here.."></textarea>
					  	</div>
					  	<h3 class="gitem_subhead text-right">Grand Total: Rs.{{ giftBoxQuanity*(locationsSum() + giftBox.product_price*1)   | number:'2.2-5' }}</h3>
						<button type="submit" class="gitem_button" (click)="submit(giftBox.id)" >
							<span class="giftspan"><img src="/assets/images/gitem_check.png" class="img-thumbnail" alt="giftbox"></span>CONFIRM & PROCEED
						</button>
					</div>
				</div>
			</div>
		</div>
			 
			<div  [ngClass]="suggestions.length >0 ? 'bottom_div':'bottom_div_less'">
			<div class="row row_custom ">
				<div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">
				
				</div>
			    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col gifthead">
				 	<img src="/assets/images/giftbox.png" class="mx-auto d-block" alt="icon">
			      	<h2 class="accountheadtext">CHOOSE YOUR ITEMS</h2>
				  	<p class="gifttop_text">We’ve hand-selected the best products in one place. Select from the items below and fill up your box!</p>
			    </div>
				<div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">
			    </div>
		    </div>

          </div>
		</div>
		<div class="row row_custom ">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 gitem_search">
				<hr class="gitem_rt">
				<form role="form" class="gitem_searchform">
				  <div class="input-outer">
				    <span class="icon ico_name"><img src="/assets/images/gitem_search.png"></span>
				    <input class="input-field" type="text" [(ngModel)]="searchText" placeholder="Search item" name="search" id="search">
				  </div>
				</form>
			</div>
		</div>
		<div class="row row_custom gitem_row">
			
			<div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 gitem_col" *ngFor="let giftItem of giftItems | search : searchText">
				
				<a *ngIf="giftItem.is_default_suggested ==1" (click)="deleteGiftItem(giftItem.id, giftItem.giftbox_id)" class="gitem_sugg_cross"><img src="/assets/images/close6.png" class="img-thumbnail0" alt="icon"></a>

				<span *ngIf="giftItem.is_default_suggested ==1" class="gitem_tick"><span class="add_text">ADDED</span><img src="/assets/images/gitem_tick.png" class="img-thumbnail0" alt="icon"></span>
				<div class="gitemcol_inner " [ngClass]="{'pink_border':giftItem.stock <= 0, 'yellow_border':giftItem.is_default_suggested ==1 }" >
				<div class="giftitem_imgouter">
				<div class="giftitem_imgspace">
					<img src="{{giftItem.product_images}}" class="img-thumbnail mx-auto d-block mb-2 giftitem_img" alt="giftbox">
					</div>
				    <p class="shopprod_top text-left">{{giftItem.product_name}}</p>
					<p class="shopprod_bot text-left">Rs.{{giftItem.product_price}}</p>
					</div>
					<div *ngIf="!multiGiftBox" >
						<button type="submit" *ngIf="giftItem.stock > 0" class="gitem_button1" (click)="addItemIntoGiftBox(giftBox.id, giftItem.id)"><span class="giftspan"><img src="/assets/images/gitem_add.png" class="img-thumbnail" alt="giftbox"></span>ADD TO BOX</button>
						<button class="gift_oos" *ngIf="giftItem.stock <= 0">OUT OF STOCK</button>
					</div>
				</div>
				<span *ngIf="giftItem.is_default_suggested ==1" class="gitem_tick"><img src="/assets/images/gitem_tick.png" class="img-thumbnail0" alt="icon"></span>
          
		    </div>

		</div>
	</div>
</div>

<app-footer></app-footer>