import * as $ from 'jquery';
import { Component, OnInit,Input, Output, EventEmitter  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductService } from '../product.service';
import { CartService } from '../../cart.service';
import { NgbActiveModal, ModalDismissReasons ,NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { NotificationsComponent } from '../../modals/notifications/notifications.component';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { CountryService} from '../../country.service';
import { BehaviorSubject } from 'rxjs';
import { Lightbox } from 'ngx-lightbox';
import {Location} from '@angular/common';
import { ErrorNotificationsComponent} from '../../modals/error-notifications/error-notifications.component';
import { LoginComponent } from 'src/app/modals/login/login.component';
import { AuthStateService } from '../../shared/auth-state.service';
@Component({
  selector: 'app-category-product',
  templateUrl: './category-product.component.html',
  styleUrls: ['./category-product.component.css']
})
export class CategoryProductComponent implements OnInit {
  isLoading=false;
  id;
  errors:any = "";
  closeResult = '';
  quantity =1;
  price:any ="";
  sub;
  stock:any = [];
  isShowInStock:boolean= false;
  product: any =[];
  @Output() productAdded = new EventEmitter();
  imagePath:string = "";
  count:any = 0;
  photos: any = [];
  inStock:boolean = true;
  shock:any =0;
  isProductActive:boolean = true;
  iqnty:any = 1;
  albums:any = [];
  album:any = [];
  store:any =[];
  storename:string = "";
  setIndex:any =0;
  imageObject;
  isSignedIn: boolean;
  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public activeModal: NgbActiveModal,
    private ProductService: ProductService,
    private cartService: CartService,
    private confirmationDialogService: ConfirmationDialogService,
    private modalService: NgbModal,
    private _lightbox: Lightbox,
    private CountryService: CountryService,
    private _location: Location,
    private auth: AuthStateService,
  ) { }
  addProductToCart(product) {
    this.productAdded.emit(product);
  }
  ngOnInit(): void {
    window.scroll({ 
           top: 0, 
           left: 0, 
           behavior: 'smooth' 
    });
    this.sub = this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('slug');
      this.getStore();
      this.ProductService.getProductById(this.id).subscribe(
        data => {
          this.product = data;
          this.photos = this.product.photos;
          this.shock  = this.product.stock;
          console.log(this.shock);
          this.imageObject = this.product.photos;
          this.setImageObject();
          if(this.shock <= 0){
            this.inStock = false;
          }
          this.isLoading=false;
          this.price = this.product.product_price;
          this.imagePath = this.product.product_images;
        }
      );
      this.getStock(this.id);
    });
    this.auth.userAuthState.subscribe(val => {
      this.isSignedIn = val;
    });
  }
  getStock(id){
    this.ProductService.getProductStock(id).subscribe(
      data => {
        this.stock = data;
        if(this.stock > 0){
          this.isShowInStock = true;
        }
      }
    );
  }
  calculateAmount(value){
    this.quantity = value;
    this.price = this.product.product_price;
    this.price = this.product.product_price*this.quantity;
  }

  public dismiss() {
    this.activeModal.dismiss();
  }

  buyNow( ): void {
    if(!this.isSignedIn ){ 
      this.openLoginModal('buynow'); 
      return;
    }
    this.isLoading=true;
    let payload = {
      productId: this.product.id,
      quantity:this.iqnty,
      session_id:sessionStorage.getItem("session_id")
    };
    this.cartService.addToCart(payload).subscribe(
      result => {
        this.isLoading=false;
        this.getCartCount();
        this.router.navigate(['/cart']);
        this.activeModal.close('Modal Closed');
      },
      error => {
        this.errors = error.error;
        this.isLoading=false;
        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                            size: 'xl',
                            backdrop: 'static',
                            keyboard: false,
                            windowClass: 'cartadd_modal',
                            animation: true,
                            ariaDescribedBy:'test',
                          });
        modalRef.componentInstance.message = this.errors.error;
        if(this.errors != "Out of Stock"){
          //this.emptyCartBuyNow(this.id, this.quantity);
        }else{

        }
      },() => {
        
      }
    );
  }
  emptyCartBuyNow(id, quantity){
    this.confirmationDialogService.confirm('', 'Out of stock')
    .then((confirmed) => {
      if(confirmed === true){
        this.cartService.emptyCart().subscribe(() => {
          let payload = {
            productId: id,
            quantity,
          };
          this.cartService.addNewCart(payload).subscribe(
          result => {
            this.activeModal.close('Modal Closed');
            this.isLoading=false;
            this.getCartCount();
            this.router.navigate(['/cart']);
          },
          error => {
            this.errors = error.error;
          },() => {
              
            }
          );
        })
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
 
  }
  emptyCart(id, quantity){
    this.confirmationDialogService.confirm('', 'Adding this item to cart will replace the existing cart items. Are you sure you want to proceed?')
    .then((confirmed) => {
      if(confirmed === true){
      this.cartService.emptyCart().subscribe(() => {
        let payload = {
          productId: id,
          quantity,
        };
        this.cartService.addNewCart(payload).subscribe(
          result => {
            this.isLoading=false;
            localStorage.setItem('message', "message");
            this.getCartCount();
            this.modalService.open(NotificationsComponent, {
              centered: true,
              size: 'sm',
              backdrop: 'static',
              keyboard: false,
              windowClass: 'cartadd_modal',
              animation: true,
              ariaDescribedBy:'test',
            }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          },
          error => {
            this.errors = error.error;
          },() => {
            
          }
        );
      })
    }
    }
  )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    
  }
  addItemToCart(): void {
    if(!this.isSignedIn ){ 
      this.openLoginModal('addtocart'); 
      return;
    }
    localStorage.removeItem('message');
    this.isLoading=true;
    let payload = {
      productId: this.product.id,
      quantity:this.iqnty,
      session_id:sessionStorage.getItem("session_id")
    };
    this.cartService.addToCart(payload).subscribe(
      result => {
        this.activeModal.close('Modal Closed');
        this.isLoading=false;
        this.getCartCount();
        localStorage.setItem('message', "message");  
        this.modalService.open(NotificationsComponent, {
          centered: true,
          size: 'sm',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'cartadd_modal',
          animation: true,
          ariaDescribedBy:'test',
        }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      },
      error => {
        this.errors = error.error;
        this.isLoading=false;
        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                            size: 'xl',
                            backdrop: 'static',
                            keyboard: false,
                            windowClass: 'cartadd_modal',
                            animation: true,
                            ariaDescribedBy:'test',
                          });
        modalRef.componentInstance.message = this.errors.error;
        if(this.errors != "Out of Stock"){
          //this.emptyCart(this.id, this.quantity);
        }
      },() => {
        
      }
    );
  }
  increamentQTY(val){
    this.iqnty = this.iqnty+val;
    this.price = this.product.product_price*this.iqnty;
  }
  
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  setImageObject() {
    for (let img of this.imageObject) {
        const src = img.product_image;
        const caption = this.product.product_name;
        const thumb = img.product_image;
        this.album = {
            src: src,
            caption: caption,
            thumb: thumb
          };
          this.albums.push(this.album);
    }
    console.log(this.albums);
  }
  updateMainImage(e, ky){
    this.setIndex=ky;
    console.log(e.target.src)
    this.imagePath = e.target.src;
  }
  open(index: number): void {
    index=this.setIndex; 

    this._lightbox.open(this.albums, index);
  }
    
  close(): void {
    
    this._lightbox.close();
  }
  getCartCount(){
    this.cartService.getCartItemCount().subscribe(
      data => {
        this.count = data;
        localStorage.setItem("cart_count", JSON.stringify(this.count));
      }
    ); 
  }
  goToShopBrand(){
    this.router.navigate(['/brands']);
  }

  goToShopCategory(){
    this.router.navigate(['/']);
  }
  goBack(){
    this._location.back();
  }

  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      result => {
        this.store = result;
        console.log(this.store);
        this.storename = this.store.seo_url
      },
      error => {
        
      },() => {
      }
    );
  }
  openLoginModal(type) {
    //history.pushState(null, null, '');
    const modalRef = this.modalService.open(LoginComponent,{ 
                                            centered: true, 
                                            size: 'xl',
                                            backdrop: 'static',
                                            keyboard: false,
                                            windowClass: 'login_modal'
                                          });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
      this.auth.userAuthState.subscribe(val => {
        this.isSignedIn = val;
      });
      if(type == "baynow"){
        this.buyNow();
      }else{
        this.addItemToCart()
      }
    }).catch((error) => {
      console.log(error);
    });
  }
}
