import * as $ from 'jquery';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { CountryService} from '../country.service';
import { AuthStateService } from '../shared/auth-state.service';
import { AuthService } from '../shared/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { CartService } from '../cart.service';
import { ShopRegisterComponent } from '../modals/shop-register/shop-register.component';
import { CartItem } from '../cart.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { NotificationsComponent } from '../modals/notifications/notifications.component';
import { ErrorNotificationsComponent} from '../modals/error-notifications/error-notifications.component';
import { LoginComponent } from 'src/app/modals/login/login.component';
import { SearchoutletComponent } from 'src/app/modals/searchoutlet/searchoutlet.component';
@Component({
  selector: 'app-shop-product',
  templateUrl: './shop-product.component.html',
  styleUrls: ['./shop-product.component.css']
})
export class ShopProductComponent implements OnInit {

  images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);
  categories: any = [];
  brands: any = [];
  user:any =[];
  isSignedIn: boolean;
  isShopType:boolean = false;
  store_image:any = "/assets/images/store.png";
  eshop_image:any = "/assets/images/eshop.png";
  
  store_image_device:any = "/assets/images/shopinstore.png";
  eshop_image_device:any = "/assets/images/eeshop.png";
  store:any =[];
  storename:string = "";
  address:string = "";
  pincode = "";
  storeTiming:string = "";

  closeResult = '';
  isLoading:boolean = false;
  shopId:any = "";
  cartCount: number = 0;
  cartItem: Array<CartItem> = [];
  count:any =0;
  errors:any;

  nfPro =4;
  actualPCount = 0;

  shopStatus:string = "CLOSED";
  shopStatusColor:string = "red";
  shopOpeningTiming:number = 0;
  shopCloseTiming:number = 0;
  now: number = Date.now();
  shopphone:string ="";
  brandsNotFound:string ="";
  showShopSelectMsg:boolean = false;
  myShopId:any = [];
  constructor(
    config: NgbCarouselConfig,
    private CountryService: CountryService,
    public router: Router,
    private auth: AuthStateService,
    public authService: AuthService,
    private modalService: NgbModal,
    private cartService: CartService,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
    private confirmationDialogService: ConfirmationDialogService
  ) { 
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  async ngOnInit() {

    $(window).bind("load", function() {
      if($(window).width() == 800){
      $('html, body').animate({scrollTop : 120},700);
     }
     else if($(window).width() == 1280){
      $('html, body').animate({scrollTop : 250},700);
     }
     else if($(window).width() == 768){
      $('html, body').animate({scrollTop : 200},700);
     }
     else if($(window).width() == 1024){
      $('html, body').animate({scrollTop : 200},700);
     }
     else if($(window).width() >= 1300){
         $('html, body').animate({scrollTop : 280},700);
       }
      });
      
      
      $(window).on('resize', function(){
       if($(window).width() == 800){
      $('html, body').animate({scrollTop : 120},700);
     }
     else if($(window).width() == 1280){
      $('html, body').animate({scrollTop : 250},700);
     }
     else if($(window).width() == 768){
      $('html, body').animate({scrollTop : 200},700);
     }
     else if($(window).width() == 1024){
      $('html, body').animate({scrollTop : 200},700);
     }
     else if($(window).width() >= 1300){
        $('html, body').animate({scrollTop : 280},700);
       }
      });
      this.getBand();
    this.auth.userAuthState.subscribe(val => {
      this.isSignedIn = val;
      this.setCartCount();
      this.getStore();
      this.CountryService.getUserStore().subscribe(
        data => {
          this.shopId = data;
          this.getBand();
          localStorage.setItem('purchase_type', "shop");
          
        },
        error => {
          this.errors = error.error;
          console.log(this.errors);
        },() => {
        }
      );
    });
    this.cartItem = await this.cartService.getCart();
    this.cartCount =this.cartItem.length;
    this.cartService.prodCountCountChange.subscribe(
      newProdCount =>{
        console.log(newProdCount);
        this.cartCount = newProdCount
      }
    );
  }
  setCartCount(){
    this.cartService.getCartItemCount().subscribe(
      data => {
        this.count = data;
        localStorage.setItem("cart_count", JSON.stringify(this.count));
      }
    ); 
  }
  ngAfterViewInit(){

    if(!this.isSignedIn ){
         this.myShopId=localStorage.getItem('appShopId');  
         if(this.myShopId === null){ 
             this.showShopSelectMsg=true;
          }else{ 
              this.showShopSelectMsg=false;
          }
    }else{
      this.showShopSelectMsg=false;
    }

  this.CountryService.getUserStore().subscribe(
      data => {
        this.shopId = data;
       
        this.store_image="/assets/images/store.png";
        this.eshop_image = "/assets/images/eshop.png";

        this.store_image_device = "/assets/images/shopinstore.png";
        this.eshop_image_device = "/assets/images/eeshop.png";

        localStorage.setItem('purchase_type', "shop");
      
      }
    );
  }
  changeShopType(type){
    const payload = {
      shop_type: type
    };
    this.CountryService.userStoreLog(payload).subscribe(
      data => {
        this.reloadComponent();
      },
      error => {
        this.errors = error.error;
        this.showShopRegisterModel();
      },() => {
        
      }
    );
    this.authService.profileUser().subscribe(
      data => {
        this.user = data;
        if(this.user.shop_id === 4){
          this.showShopRegisterModel();
        }
      }
    );
    localStorage.setItem('purchase_type', type);
  }
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['']);
    }
  showShopRegisterModel(){
    this.modalService.open(ShopRegisterComponent, {
      centered: true, 
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'login_modal'
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      result => {
        this.store = result;
        console.log(this.store);
        this.storename = this.store.seo_url;
        this.address = this.store.address;
        this.pincode = this.store.pin;
        this.storeTiming = this.store.shop_timing;
        this.shopOpeningTiming = this.store.opening_time;
        this.shopCloseTiming = this.store.closing_time;
        this.shopphone = this.store.phone;
        console.log(this.now);
        console.log(this.store.openingstrtotime*1000);
        console.log(this.store.closingstrtotime*1000);
        if((this.store.openingstrtotime*1000 >= this.now) || (this.now <= this.store.closingstrtotime*1000)){
          this.shopStatus = "OPEN";
          this.shopStatusColor = "green";
        }
        
      },
      error => {
        
      },() => {
      }
    );
  }
  getAllBrand(){
    this.isLoading=true;
    this.CountryService.getAllBrands().subscribe(
      data => {
        this.brands = data;
        this.isLoading=false;
      }
    );
  }
  getBand(){
    this.isLoading=true;
    this.CountryService.getBrand().subscribe(
      data => {
        this.brands = data;
        this.isLoading=false; 
        if(this.brands.length==0){ 
          if(this.showShopSelectMsg==false){ 
              this.brandsNotFound="notfound";
          }
        }else{
          this.brandsNotFound=""; 
        }
      }
    );
  }
  buyNow( id, quantity): void {
    if(!this.isSignedIn ){ 
      this.openLoginModal('baynow',id,quantity); 
      return;
    }
    this.isLoading=true;
    let payload = {
      productId: id,
      quantity,
    };
    this.cartService.addToCart(payload).subscribe(
      result => {
        this.getBand();
        localStorage.setItem('purchase_type', "shop");
        
        this.isLoading=false;
        this.getCartCount();
        this.router.navigate(['/cart']);
      },
       error => {
        this.errors = error.error;
        this.isLoading=false;
        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                            size: 'xl',
                            backdrop: 'static',
                            keyboard: false,
                            windowClass: 'cartadd_modal',
                            animation: true,
                            ariaDescribedBy:'test',
                          });
        modalRef.componentInstance.message = this.errors.error;
      },() => {
        
      }
    );
  }
  emptyCartBuyNow(id, quantity){
    this.confirmationDialogService.confirm('', 'Adding this item to cart will replace the existing cart items. Are you sure you want to proceed?')
    .then((confirmed) => {
      if(confirmed === true){
        this.cartService.emptyCart().subscribe(() => {
        let payload = {
          productId: id,
          quantity,
        };
        this.cartService.addNewCart(payload).subscribe(
          result => {
            this.getBand();
            localStorage.setItem('purchase_type', "shop");
            
            this.isLoading=false;
            this.getCartCount();
            this.router.navigate(['/cart']);
          },
          error => {
            this.errors = error.error;
          },() => {
            
          }
        );
      })
    }
  })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
 
  }
  emptyCart(id, quantity){
    this.confirmationDialogService.confirm('', 'Adding this item to cart will replace the existing cart items. Are you sure you want to proceed?')
    .then((confirmed) => {
      if(confirmed === true){
        this.cartService.emptyCart().subscribe(() => {
        let payload = {
          productId: id,
          quantity,
        };
        this.cartService.addNewCart(payload).subscribe(
          result => {
            this.getBand();
            localStorage.setItem('purchase_type', "shop");
            
            this.isLoading=false;
            this.getCartCount();
            localStorage.setItem('message', "message");
            this.modalService.open(NotificationsComponent, {
              centered: true,
              size: 'sm',
              backdrop: 'static',
              keyboard: false,
              windowClass: 'cartadd_modal',
              animation: true,
              ariaDescribedBy:'test',
            }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          },
          error => {
            this.errors = error.error;
          },() => {
            
          }
        );
      })
      }}
    )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    
  }
  addItemToCart( id, quantity): void {
    if(!this.isSignedIn ){ 
      this.openLoginModal('addtocart',id,quantity); 
      return;
    }
    localStorage.removeItem('message');
    this.isLoading=true;
    let payload = {
      productId: id,
      quantity,
    };
    this.cartService.addToCart(payload).subscribe(
      result => {
          this.getBand();
          localStorage.setItem('purchase_type', "shop");
        
        this.isLoading=false;
        this.getCartCount();
        localStorage.setItem('message', "message");
        this.modalService.open(NotificationsComponent, {
          centered: true,
          size: 'sm',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'cartadd_modal',
          animation: true,
          ariaDescribedBy:'test',
        }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      },
      error => {
        this.errors = error.error;
        this.isLoading=false;
        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                            size: 'xl',
                            backdrop: 'static',
                            keyboard: false,
                            windowClass: 'cartadd_modal',
                            animation: true,
                            ariaDescribedBy:'test',
                          });
        modalRef.componentInstance.message = this.errors.error;
      },() => {
        
      }
    );
  }
  getCartCount(){
    this.cartService.getCartItemCount().subscribe(
      data => {
        this.count = data;
        localStorage.setItem("cart_count", JSON.stringify(this.count));
      },
      error =>{
        this.errors = error.error;
        this.count = 0;
        localStorage.setItem("cart_count", JSON.stringify(this.count));
      }
    ); 
  }
  counter(i: number) {
    this.actualPCount = this.nfPro - i;
    return new Array(this.actualPCount);
  }
  openLoginModal(type,id,quantity) {
    //history.pushState(null, null, '');
    const modalRef = this.modalService.open(LoginComponent,{ 
                                            centered: true, 
                                            size: 'xl',
                                            backdrop: 'static',
                                            keyboard: false,
                                            windowClass: 'login_modal'
                                          });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
      this.auth.userAuthState.subscribe(val => {
        this.isSignedIn = val;
      });
      if(type == "buynow"){
        this.buyNow(id,quantity);
      }else{
        this.addItemToCart(id,quantity)
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  openSearcOutletModal(is_close=false) {
    console.log('clicked');
    //history.pushState(null, null, '');
    const modalRef = this.modalService.open(SearchoutletComponent,{ 
                                            centered: true, 
                                            size: 'sm',
                                            backdrop: 'static',
                                            keyboard: false,
                                            windowClass: 'search_outlet_modal'
                                          });
    modalRef.componentInstance.id = 1; // should be the id
    modalRef.componentInstance.is_close = is_close; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
}
