import * as $ from 'jquery';
import {  Component, OnInit, Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef, HostListener, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {RegistraionComponent} from 'src/app/modals/registraion/registraion.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import {ChooseOutletsComponent} from 'src/app/modals/choose-outlets/choose-outlets.component';
import { Router } from '@angular/router';
import { AuthService } from './../../shared/auth.service';
import { TokenService } from '../../shared/token.service';
import { AuthStateService } from '../../shared/auth-state.service';
import { CountryService } from '../../country.service';
@Component({
  selector: 'app-searchoutlet',
  templateUrl: './searchoutlet.component.html',
  styleUrls: ['./searchoutlet.component.css']
})
export class SearchoutletComponent implements OnInit {
  public showContainer: boolean;

  @Input()id: number;
  isShowOutlets:boolean = false;
  closeResult: string;
  searchOuletFrm: FormGroup; 
  submitted:boolean = false;
  formSumbit:boolean = false;
  errors = null;
  shops:any = [];
  other_outlets:any = [];
  myShopId:any = [];
  allOutlets:any;
  outletDetails:any;
  distric_name:any;
  showoutletDetails:boolean = false;
  shopsLength:boolean = false;
  outletdetls:any;
  @Input()is_close: boolean = false;
  isSignedIn: boolean;
  gmapPincode:any;
  showLocate:boolean = true;
  srchPinOutlets:  boolean = false; 
  constructor(
    public router: Router,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private token: TokenService,
    private authState: AuthStateService,
    private CountryService: CountryService,
    private auth: AuthStateService,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    $(".is-invalid").focus(); 
    this.getoutlets();  
    this.auth.userAuthState.subscribe(val => {
      this.isSignedIn = val; 
    });
  } 
  buildForm() {
    this.searchOuletFrm = this.formBuilder.group({
      pinsearch_text: ['', [Validators.required, Validators.pattern("^[1-9][0-9]{5}$")]], 
    });
    this.myShopId=this.CountryService.getAppShopId();
    console.log(this.myShopId);
    if(this.myShopId>0){ 
      this.isShowOutlets=true;
      this.router.navigate(['/']);
    }
  } 
  trim(myString) { 
    console.log(myString);
    this.searchOuletFrm.patchValue({
      pinsearch_text: myString.replace(" ",''),
    });

   if(myString.length>=5){
       this.showLocate=true;
    }
    return myString.replace(" ",'-');  
    return myString;
    
  }
  get f() { return this.searchOuletFrm.controls; }
  searchOutlets(){
        this.errors = '';
        this.submitted = true;
        this.formSumbit = true;
        if(this.searchOuletFrm.invalid){
          this.formSumbit = false;
          return;
        }   
        this.searchShopPincode(); 
        this.getOtherOutlets(); 
        this.isShowOutlets=true;
  }
  searchShopPincode(){ 

   // this.srchPinOutlets=true; 
    //this.showoutletDetails=false; 
    let payload = {
      pincode: this.searchOuletFrm.get('pinsearch_text').value,
      otheroutlets: 'no',
    };
    this.authService.searchPincode(payload).subscribe(
    //this.authService.getOutletsByPincode(this.searchOuletFrm.value).subscribe(
      data => { 
          this.shops = data;
          if(this.shops.length==0){
              this.shopsLength=true;
          } else{ 
              document.querySelector(".listOutlets").scrollIntoView({ behavior: 'smooth', block: 'center' });
              this.shopsLength=false;  

              /*if(this.shops[0].id != null){  
                  this.setData(this.shops[0].id); 
                  this.activeModal.close('Modal Closed');
                  this.modalService.dismissAll();
                  //alert(this.outletdetls.shop_id);
                  this.changeUserOutletAfterLogin(this.shops[0].id);
                  this.reloadComponent(); 
              }*/
          }


          console.log('test'+this.shops.length);
      },
      error => {

      },() => {

      });
  } 


  getOtherOutlets(){ 
    let payload = {
      pincode: this.searchOuletFrm.get('pinsearch_text').value,
      otheroutlets: 'yes',
    };
    this.authService.searchPincode(payload).subscribe(
    //this.authService.getOutletsByPincode(this.searchOuletFrm.value).subscribe(
      data => { 
          this.other_outlets = data;
          //console.log('m here');
          //console.log(this.other_outlets);  
      },
      error => {

      },() => {

      });
  } 
  selectOutlets(val){ 
      this.setData(val); 
      console.log('single'+val)
      this.changeUserOutletAfterLogin(val);
      //alert('single1'+val)
      this.router.navigate(['/']);
  } 
  selectChangeHandler (event: any) {  
      this.setData(event.target.value);  
      this.router.navigate(['/']);
  } 
  setData(val){ 
    if(val>0){
      console.log(val);
      localStorage.setItem('appShopId', val);
      sessionStorage.setItem("appShopId", val); 
      localStorage.setItem('appPincode', this.searchOuletFrm.get('pinsearch_text').value);
      localStorage.setItem('appPincode', this.searchOuletFrm.get('pinsearch_text').value); 
    }
  } 

  getoutlets(){  
    this.authService.getoutlets().subscribe(
    //this.authService.getOutletsByPincode(this.searchOuletFrm.value).subscribe(
      data => { 
          this.allOutlets = data;
         // console.log(this.allOutlets);  
      },
      error => {

      },() => {

      });
  }  

  getOutletDetails(id ,distrct){    
    this.srchPinOutlets=false; 
    this.distric_name=distrct;
    this.authService.getoutletDetails(id).subscribe(
    //this.authService.getOutletsByPincode(this.searchOuletFrm.value).subscribe(
      data => { 

          this.outletDetails = data;
          this.showoutletDetails=true; 
          this.outletdetls=this.outletDetails[0];
          if(this.outletDetails[0].count>1){
            this.showoutletDetails=true; 
            this.scrollToError();
          }
          //console.log(this.outletDetails[0].count);   
          if(this.outletDetails[0].count==1){
             this.showoutletDetails=false; 
            // console.log(this.outletDetails[0].shop_id);
            this.setData(this.outletDetails[0].shop_id);  
            this.outletDetails='';
            //this.reloadCurrentRoute();

            if(this.outletdetls.shop_id != null){ 
                this.activeModal.close('Modal Closed');
                this.modalService.dismissAll();
                //alert(this.outletdetls.shop_id);
                this.changeUserOutletAfterLogin(this.outletdetls.shop_id);
                this.reloadComponent(); 
            }
          
          } 
      },
      error => {

      },() => {

      }); 
    //document.querySelector(".search_outlet_modal ").scrollTo({top:0,behavior:'smooth'});
   //this.scrollToError();
  }  

   scrollToError(): void {
      const firstElementWithError = document.querySelector('.all_outlts');
      console.log("aa:", firstElementWithError); 
      this.scrollTo(firstElementWithError);
   } 

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/brands', {skipLocationChange: true}).then(() => {  
        this.router.navigate([currentUrl]);
    });

         setTimeout(()=>{ 
          this.activeModal.close('Modal Closed');
          this.modalService.dismissAll()
          this.reloadComponent();
        },5000);
  }


  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }

    changeUserOutletAfterLogin(shopId:string){

        if(this.isSignedIn){  
          console.log('changeUserOutletAfterLogin');
          console.log(shopId);

              let payload = {
                    outlet: shopId.toString(), 
              };

              this.CountryService.changeUserOutletAfterLogin(payload).subscribe(
              result => {
                console.log(result)  
              },
              error => {
                this.errors = error.error;
              },
              () => { 
              }
            );

        }
   }

   loadGoogleMap(){
    // alert(this.router.url);
 

     this.gmapPincode = this.searchOuletFrm.get('pinsearch_text').value;  
     if(this.gmapPincode==''){
         this.gmapPincode = 'all';  
     }

    this.errors = '';
    this.submitted = true;
    this.formSumbit = true;
    if(this.searchOuletFrm.invalid){
      if(this.gmapPincode!='all'){
        this.formSumbit = false;
        return; 
      }
    }  
     //this.gmapPincode = 'all';  
     if(this.gmapPincode.length==0){
       return false;
     }
     localStorage.setItem('gmapPincode', this.gmapPincode);
                this.activeModal.close('Modal Closed');
                this.modalService.dismissAll();
    // this.router.navigate(['/viewmap',this.gmapPincode]);
         // this.reloadComponent();

     this.router.navigateByUrl('/viewmap', { skipLocationChange: true }).then(() => this.router.navigate(['/viewmap/' + this.gmapPincode ]));   


   }

     keyPressAllowNumbers(evt){  
       
        var inp = String.fromCharCode(evt.keyCode); 

        // Only ASCII character in that range allowed

        var ASCIICode = (evt.which) ? evt.which : evt.keyCode;
         console.log(ASCIICode);
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
            return false;
        return true;

        if (/[0-9]/.test(inp)) {
          return true;
        } else {
          evt.preventDefault();
          return false;
        } 
     }
  


  
}
