<div class="modal-body">

    <div class="row row_custom">

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 process_col">

            <!--<img class="close closedevice" aria-label="Close" (click)="dismiss()" src="/assets/images/close.png"> -->

        </div>



        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">

            <!--<img class="close closedesk" aria-label="Close" (click)="dismiss()" src="/assets/images/close.png"> -->

        </div>

    </div>

    <div class="row row_custom">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 process_col">
            <div class="row row_custom">
                <h3 class="modal_title shoplogin_head">{{ title }}</h3>
            </div>
            <p class="cartstay_text">
                {{ message }}
            </p>



            <div class="myacc_buttonsec cartstay_buttonsec">

                <button type="submit" class="common_updatebutton cartstayyes" (click)="accept()">{{ btnOkText }}</button>

                <button class="common_cancelbutton cartstaycancel" (click)="decline()">{{ btnCancelText }}</button>

            </div>

        </div>

    </div>

</div>