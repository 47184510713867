import { Component, OnInit,Inject, Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CountryService} from '../../country.service';
import { AuthStateService } from '../../shared/auth-state.service';
import { MustMatch } from '../../_helpers/must-match.validator';
import { DOCUMENT } from '@angular/common';
import * as $ from 'jquery';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  errors:any;
  submitted = false;
  changePasswordFrom: FormGroup;
  result:any ="";
  fieldTextType:string = "text";
  store:any =[];
  storename:string = "";



  shopStatus:string = "CLOSED";
  shopStatusColor:string = "red";
  shopOpeningTiming:number = Date.now();
  shopCloseTiming:number = Date.now();
  now: number = Date.now();  
  address:string = "";
  pincode = "";
  storeTiming:string = "";
  html:any ="";
  isSignedIn: boolean;
  shopphone:string ="";
  shopOpClseStatus:boolean = false;
  
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private CountryService: CountryService,
    private auth: AuthStateService,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngOnInit(): void {
    this.createForm();
    //this.getStore();
    $(document).ready(function(){
      $("body").on('click', '#togglePasswordold', function() {
        $(this).toggleClass("fa-eye fa-eye-slash");
        var input = $("#old_password");
        if (input.attr("type") === "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      });
      $("body").on('click', '#togglePassword1', function() {
        $(this).toggleClass("fa-eye fa-eye-slash");
        var input = $("#new_password");
        if (input.attr("type") === "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      });
      $("body").on('click', '#togglePassword2', function() {
        $(this).toggleClass("fa-eye fa-eye-slash");
        var input = $("#cnew_password");
        if (input.attr("type") === "password") {
          input.attr("type", "text");
        } else {
          input.attr("type", "password");
        }
      });

    });
  }
  createForm(){
    this.changePasswordFrom = this.formBuilder.group({
      old_password:  ['',[Validators.required,Validators.minLength(6),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&].{5,}')] ],
      password: ['',[Validators.required,Validators.minLength(6),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&].{5,}')] ],
      password_confirmation: ['', Validators.required],
    },{
      validator: MustMatch('password', 'password_confirmation')
    });
  }
  get f() { return this.changePasswordFrom.controls; }
  isFieldValid(field: string) {
    return !this.changePasswordFrom.get(field).valid && this.changePasswordFrom.get(field).touched;
  }
  onSubmit() {
    this.errors = '';
    this.submitted = true;
    if(this.changePasswordFrom.invalid){
      this.scrollToError();
      return;
    }
    this.CountryService.changePassword(this.changePasswordFrom.value).subscribe(
      result => {
        this.result = result;
        
      },
      error => {
        this.errors = error.error;
      },
      () => {
        setTimeout(()=>this.reloadComponent(),5000);
        //this.changePasswordFrom.reset();
      }
    );
  }
  
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/myaccount']);
  }

  getStore(){
    this.auth.userAuthState.subscribe(val => {
      console.log(val);
      this.CountryService.getStoreDetails().subscribe(
        result => {
          this.store = result;
          this.storename = this.store.seo_url;
          if(this.store.address != "NULL" ){
            this.address = this.store.address;
          }
          this.pincode = this.store.pin;
          this.storeTiming = this.store.shop_timing;
          this.shopOpeningTiming = this.store.opening_time;
          this.shopCloseTiming = this.store.closing_time;
          this.shopphone = this.store.phone;
          this.shopStatus = this.store.shop_status;
          if(this.shopStatus =="OPEN"){
            this.shopStatusColor = "green";
          }else{
            this.shopStatusColor = "red";
          }
          if(this.store.opening_time != "00:00"){
            this.shopOpClseStatus =true;
          }
        },
        error => {
          
        },() => {
        });

    });
  }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    console.log("aa:", firstElementWithError);

    this.scrollTo(firstElementWithError);
  }
}
