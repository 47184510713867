<div class="modal-body">
    <div class="row row_custom">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 shoplogin_side">
            <img class="close closedevice" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 shoplogin_main">
            <h3 class="shoplogin_head">CHOOSE AN OUTLET</h3>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 shoplogin_side">
            <img class="close closedesk" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">
        </div>
    </div>
    <div class="register_contents">
        <form id="contact_form" [formGroup]="registerShopForm">
            <div class="row row_custom">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 shopreg_outlet">
                    <label class="shoplogin_label"></label>
                    <div class="outlet_resp" *ngFor="let store of stores">
                        <div class="outlet_gallery">
                            <a href="#" (click)="ToggleCourse(store)">
                                <img class="outlet_img" [ngClass]="{image_active: isActive(store)}" src="/assets/images/surathkal.png" alt="outlet">
                                <div class="outlet_center">{{store.shop_name | uppercase}}</div>
                            </a>
                        </div>
                        <input type="hidden" value="0" formControlName="outlet" [ngClass]="{ 'is-invalid': submitted && f.outlet.errors }" />
                        <div *ngIf="submitted && f.outlet.errors" class="invalid-feedback">
                            <div *ngIf="f.outlet.errors.required">Outlet is required</div>
                        </div>
                        <div *ngIf="errors?.outlet" class="backend_error">{{ errors?.outlet }}</div>
                    </div>
                </div>
            </div>
            <div class="row row_custom shopreg_botrow">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <button class="shopreg_button" type="submit" (click)="onSubmit()">SUBMIT</button>
                </div>
            </div>
        </form>
    </div>

</div>