<form class="search_form" [formGroup]="searchForm">
    <div class="input-group">

        <input type="text" class="form-control sale_search" placeholder="Search Products" id="search_text" name="searchname" formControlName="searchname">

        <div class="input-group-append">

		<button class="btn btn-sale" type="submit" (keydown)="search()" (click)="search()"><span class="icon ico_search"><img src="/assets/images/shopsearch.png"/></span></button>

        </div>

    </div>
</form> 