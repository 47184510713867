<!--<app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>-->
<div id="pause" class="d-flex align-items-center justify-content-center" *ngIf="isLoading">
    <img src="/assets/images/loader-shop.gif" /> 
</div>
<app-sub-header></app-sub-header>
<div class="container_shop process_desk">

    <!--<a href="#">-->
        <!-- <img [src]="store_image" class="mx-auto d-block img_shopstore" alt="product" data-src="/assets/images/store.png" data-hover="/assets/images/store_ho.png">-->
        <!-- <img [src]="eshop_image" id="" class="mx-auto d-block img_shopstore2" alt="product" data-src="/assets/images/eshop.png" data-hover="/assets/images/eshop_ho.png"> -->
    <!--</a>-->
  




    <div class="store_section">

        <ul class="nav nav-tabs" id="shop-tabs" role="tablist">
            <li class="nav-item" id="prodtab">

                <a class="nav-link active" data-toggle="tab" href="#product"><img class="noshop_logo" src="/assets/images/products_hover0.png" alt="logo"><img class="acshop_logo" src="/assets/images/products0.png" alt="logo">&nbsp;Products&nbsp;</a>

            </li>
            <li class="nav-item" id="brandtab">

                <a class="nav-link"  data-toggle="tab"   [routerLink]="['/brands']"><img class="acshop_logo" src="/assets/images/brands0.png" alt="logo"><img class="noshop_logo" src="/assets/images/brands_hover0.png" alt="logo">&nbsp;Brands&nbsp;</a>

            </li>
		   
            

            <li class="nav-item" id="searchtab">

                <app-search-product-header></app-search-product-header>
  
            </li>

        </ul>

 

        <!-- Tab panes -->

        <div class="sale_contentinner">

            <div class="tab-content sale_content">



                <div id="brand" class="tab-pane fade">
                    <!-- <app-brand></app-brand> -->
                </div>



                <div id="product" class="tab-pane active ">
                    <div class="prod_layer">

                        <div class="row row_shop2 shop_items">

                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 shop_procol"  *ngIf="store.banner_image_web"> 
                                  <div class="bhatkal_outer">
                                    <img class="img-thumbnail bhatkal_image" src="{{store.banner_image_web}}" alt="Bhatkal Banner">
                                      <!--<div class="row row_custom ">
                                          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 px-0">
                                                 <img class="img-thumbnail bhatkal_image" src="{{store.banner_image_web}}" alt="Bhatkal Banner">
                                           </div> 
                                      </div>-->
                                  </div> 
                            </div>

                            <!--<div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 shop_procol " >

                                <div class="estorebg">
                                    <h3 class="offer1"> Exciting direct factory prices on every product. </h3>
                                    <h3 class="offer2">Avail upto 35% off.</h3>
                                    <h3 class="offer3">Click on the products to discover the prices </h3>

                                </div>
                            </div> -->
                            <!--bhatkal 
                             <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 shop_procol"  *ngIf="store.id ==33"> 
                                  <div class="bhatkal_outer">
                                      <div class="row row_custom ">
                                          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 px-0">
                                                 <img class="img-thumbnail bhatkal_image" src="/assets/images/Bhatkal_web.jpg" alt="Bhatkal Banner">
                                           </div> 
                                      </div>
                                  </div> 
                            </div>

                             bhatkal end -->

                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 shop_procol"  *ngIf="this.giftbox.length">
                    
                                <div class="shop_prod">
                    
                                    <a class="proditem_link" [routerLink]="['/gift']">
                                        <img src="/assets/images/gift.jpg" class="img-fluid img-thumbnail category-image" alt="product">

                    
                                        <h3 class="shopprod_head">Gift</h3>
                                    </a>
                    
                                </div>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 shop_procol" *ngFor="let category of categories">
                    
                                <div class="shop_prod">
                    
                                    <a class="proditem_link" [routerLink]="['/product/category',category.seo_url]">
                                        <img src="{{category.category_image}}" class="img-fluid img-thumbnail category-image" alt="product">
                                        
                    
                                        <h3 class="shopprod_head">{{category.category_name}}</h3>
                                        <!-- <p class="shopprod_det">Lorem ipsum dolor sit amet, etur adipiscing</p> -->
                                    </a>
                    
                                </div>
                            </div>
                    
                        </div>

                        <div class="noproductFound" *ngIf="showShopSelectMsg" >
                            <div class="shopProdOuter">     
                                <div class="shopProdInner">     
                                    <a (click)="openSearcOutletModal(true)" title="Choose an outlet to start shopping">                            <img class="outlet-img" src="/assets/images/outlet-icon.png" alt="outlet-icon"> 
                                        <span>Choose an outlet to start shopping </span>
                                    <img class="arrow-img" src="/assets/images/arrow.png" alt="image"></a> 
                                </div>  
                            </div>   
                        </div>
                    
                    </div>
                </div>

            </div>

        </div>

    </div>

</div>








<div class="container_shop process_device">
    
    <div class="store_section">
        <ul class="nav nav-tabs" id="shop-tabs2" role="tablist">
		
			<li class="nav-item" id="prodtab1">
                <a class="nav-link active" data-toggle="tab" href="#product1"><img class="acshop_logo" src="/assets/images/products_hover0.png" alt="logo"><img class="noshop_logo" src="/assets/images/products.png" alt="logo">&nbsp;Products</a>
            </li>
			
            <li class="nav-item" id="brandtab1">
                <a class="nav-link" data-toggle="tab" [routerLink]="['/brands']"><img class="acshop_logo" src="/assets/images/brands0.png" alt="logo"><img class="noshop_logo" src="/assets/images/brands_hover0.png" alt="logo">&nbsp;Brands</a>
            </li>
           
        </ul>

        
        <!-- Tab panes -->
        <div class="tab-content sale_content">
            <div id="brand1" class="tab-pane fade">
                <!-- <app-device-brand></app-device-brand> -->

                <div class="shopitem_dummyrow">
                </div>

            </div>
            <div id="product1" class="tab-pane active product_device"><br>
                <div class="row row_shop2 ">

                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 "   *ngIf="store.banner_image_mobile">
                          <div class="bhatkal_outer">
                              <div class="row row_custom ">
                                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 px-0">
                                        <img class="img-thumbnail" src="{{store.banner_image_mobile}}" alt="Bhatkal Banner">
                                   </div> 
                              </div>
                          </div>
                    </div>

                    <!--<div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 shop_procol ">

                        <div class="estorebg">
                            <h3 class="offer1"> Exciting direct factory prices on every product. </h3>
                            <h3 class="offer2">Avail upto 35% off.</h3>
                            <h3 class="offer3">Click on the products to discover the prices </h3>

                        </div>
                    </div>-->


                    <!--bhatkal  

                     <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 "   *ngIf="store.id ==33">
                          <div class="bhatkal_outer">
                              <div class="row row_custom ">
                                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 px-0">
                                        <img class="img-thumbnail" src="/assets/images/Bhatkal_device.jpg" alt="Bhatkal Banner">
                                   </div> 
                              </div>
                          </div>
                    </div>

                     bhatkal end -->

                </div>
                <div class="row row_shop2 shop_items">
                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 shop_itemscol"  *ngIf="this.giftbox.length">
                        <div class="shop_prod">
                            <a [routerLink]="['/gift']">
                                <img src="/assets/images/gift.jpg" class="img-fluid img-thumbnail category-image" alt="product">
                                <h3 class="shopprod_head">Gift</h3>
                                
                            </a>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 shop_itemscol" *ngFor="let category of categories">
                        <div class="shop_prod">
                            <a [routerLink]="['/product/category',category.seo_url]">
                                <img src="{{category.category_image}}" class="img-fluid img-thumbnail category-image" alt="product">
                                <h3 class="shopprod_head">{{category.category_name}}</h3>
                                <!-- <p class="shopprod_det">Lorem ipsum dolor sit amet, etur adipiscing</p> -->
                            </a>
                        </div>
                    </div>
                </div>

                <div class="noproductFound" *ngIf="showShopSelectMsg" >
                    <div class="shopProdOuter">     
                        <div class="shopProdInner">     
                            <a (click)="openSearcOutletModal(true)" title="Choose an outlet to start shopping">                            <img class="outlet-img" src="/assets/images/outlet-icon.png" alt="outlet-icon"> 
                                <span>Choose an outlet to start shopping </span>
                            <img class="arrow-img" src="/assets/images/arrow.png" alt="image"></a> 
                        </div>  
                    </div>  
                </div>

                <div class="shopitem_dummyrow">
                </div>
            </div>

        </div>

    </div>



    <div class="row row_custom">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 whatsapp_col">
            <a href="#"><img src="/assets/images/whatsapp2.png" alt="whatsapp" class="img-thumbnail"></a>
        </div>
    </div>
</div>

<app-footer></app-footer>