import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CountryService } from '../../country.service';
@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.css']
})
export class DeliveryAddressComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  deliverryAddress:any =[];
  constructor(
    public router: Router,
    public activeModal: NgbActiveModal,
    private CountryService: CountryService,
  ) { }
  addDeliveryAddressToLocal(id){
    this.activeModal.close('Modal Closed');
    this.passEntry.emit(id);
  }
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
  ngOnInit(): void {
    this.getDelivaryAddress();
  }
  getDelivaryAddress(){
    this.CountryService.getDeliveryAddress().subscribe(
      data => this.deliverryAddress = data
    );
  }

}
