<app-sub-header></app-sub-header>

<div class="container_shop process_desk">
    <a href="#">
        <!--<img [src]="store_image" class="mx-auto d-block img_shopstore" alt="product" data-src="/assets/images/store.png" data-hover="/assets/images/store_ho.png">-->
        <!-- <img [src]="eshop_image" id="" class="mx-auto d-block img_shopstore2" alt="product" data-src="/assets/images/eshop.png" data-hover="/assets/images/eshop_ho.png"> -->
    </a>
  




    <div class="store_section">

        <ul class="nav nav-tabs" id="shop-tabs" role="tablist">

           
		   <li class="nav-item" id="prodtab">

                <a class="nav-link " data-toggle="tab" [routerLink]="['']"><img class="noshop_logo" src="/assets/images/products_hover0.png" alt="logo"><img class="acshop_logo" src="/assets/images/products0.png" alt="logo">&nbsp;Products&nbsp;</a>

            </li>
            <li class="nav-item" id="brandtab">

                <a class="nav-link active" data-toggle="tab" href="#brand"><img class="acshop_logo" src="/assets/images/brands0.png" alt="logo"><img class="noshop_logo" src="/assets/images/brands_hover0.png" alt="logo">&nbsp;Brands&nbsp;</a>

            </li>

            <li class="nav-item" id="searchtab">

                <app-search-product-header></app-search-product-header>
  
            </li>

        </ul>

 

        <!-- Tab panes -->

        <div class="sale_contentinner">

            <div class="tab-content sale_content">



                <div id="brand" class="tab-pane active ">
                    <div *ngFor="let brand of brands">
                        <div class="row row_custom">
                    
                            <div class="col-md-2 col-lg-3 col-sm-3 col-xs-3 process_col saleheadside">
                    
                            </div>
                    
                            <div class="col-md-8 col-lg-6 col-sm-6 col-xs-6 process_col shopheadbg saleheadmiddle">
                    
                                <h3 class="sale_conttext">{{brand.brand_name}}</h3>
                    
                            </div>
                    
                        </div>
                    
                        <div class="row row_shop1 shop_items">
                    
                            <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 shop_itemscol" *ngFor="let product of brand.product">
                    
                                <a [routerLink]="['/product-details', product.seo_url]">
                                    <img src="{{product.product_images}}" class="mx-auto d-block img-fluid img-thumbnail" alt="product">
                    
                                    <p class="shopprod_top">{{product.product_name}}</p>
                    
                                    <p class="shopprod_bot">Rs. {{product.product_price}}</p>
                                    <p class="mrp-price"><del>Rs. {{ product.cskd_mrp}}</del></p>
                                </a>
                                <div class="action_outer">
                    
                                    <button type="submit" class="shopbuy" (click)="buyNow(product.id, 1)">BUY NOW</button>
                    
                                    <button type="submit" class="shopadd" (click)="addItemToCart(product.id, 1)">ADD TO
                                                        CART</button>
                                </div>
                            </div>
                    
                            <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 shop_itemscol" *ngFor='let in of counter(brand.product.length) ;let i = index'>
                    
                            </div>
                    
                            <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 shop_itemscol">
                    
                                <a [routerLink]="['/product/brand',brand.seo_url]"><img src="/assets/images/shopmore.png" class="mx-auto d-block shop_more" alt="product"></a>
                    
                    
                    
                            </div>
                    
                        </div>
                    </div>

                    <div>
                        <div class="row row_custom" *ngIf="brandsNotFound == 'notfound' ">
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 listitem_col">
                                <div class="listitem_inner product_text text-center">
                                   Brands not found
                                </div>
                            </div>
                        </div>
                    </div>

                    



                    <div class="noproductFound" *ngIf="showShopSelectMsg" >
                        <div class="shopProdOuter">     
                            <div class="shopProdInner">     
                                <a (click)="openSearcOutletModal(true)" title="Choose an outlet to start shopping">                            <img class="outlet-img" src="/assets/images/outlet-icon.png" alt="outlet-icon"> 
                                    <span>Choose an outlet to start shopping </span>
                                <img class="arrow-img" src="/assets/images/arrow.png" alt="image"></a> 
                            </div>  
                        </div>   
                    </div>

                    

                   
                    </div>



                <div id="product" class="tab-pane  fade">
                    <!-- <app-category></app-category> -->
                </div>

            </div>

        </div>

    </div>

</div>


<div class="container_shop process_device">
    <!-- <div class="row row_shopcontact">

        <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 shop_contactcol">
            <p class="center_text ">
                <a href="tel:+919449868000"><img src="/assets/images/shopmcall.png" alt="phone" class="img-thumbnail"><br> +91-9449868000
                </a>
            </p>
        </div>

        <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 shop_contactcol">
            <p class="center_text">
                <a href="tel:+918258281944"><img src="/assets/images/shopmland.png" alt="phone" class="img-thumbnail"><br> 91-8258 281944</a>
            </p>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 shop_contactcol">
            <p class="center_text">
                <a href="mailto:info@bolas.co.in?Subject=Contact Inquiries">
                    <img src="/assets/images/shopmemail.png" alt="phone" class="img-thumbnail"><br> info@bolas.co.in
                </a>
            </p>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 shop_contactcol">
            <p class="center_text"><img src="/assets/images/shopmlocation.png" alt="phone" class="img-thumbnail"><br> Karkala, Mangalore
            </p>

        </div>


    </div> -->



    <!-- <ul class="nav nav-tabs" id="shop-tabs" role="tablist">
        <li class="nav-item" id="searchtab1">
            <app-search-product-header></app-search-product-header>
        </li>
        <li class="nav-item meshoptab" id="mobtab1" (click)="changeShopType('shop')">

            <img class="img-fluid mx-auto d-block imgm_shopstore" [src]="store_image_device" alt="logo" data-src="/assets/images/shopinstore.png" data-hover="/assets/images/shopinstore_ho.png">

        </li>
        <li class="nav-item meshoptab" id="mobtab2">

            <img class="img-fluid mx-auto d-block imgm_shopstore2" src="/assets/images/eeshop.png" alt="logo" data-src="/assets/images/eeshop.png" data-hover="/assets/images/eeshop_ho.png">

        </li> 
    </ul>-->
    <div class="store_section">
        <ul class="nav nav-tabs" id="shop-tabs2" role="tablist">
		
			<li class="nav-item" id="prodtab1">
                <a class="nav-link " data-toggle="tab" [routerLink]="['/']"><img class="acshop_logo" src="/assets/images/products0.png" alt="logo"><img class="noshop_logo" src="/assets/images/products_hover0.png" alt="logo">&nbsp;Products</a>
            </li>
			
            <li class="nav-item" id="brandtab1">
                <a class="nav-link active" data-toggle="tab" href="#brand1"><img class="acshop_logo" src="/assets/images/brands0.png" alt="logo"><img class="noshop_logo" src="/assets/images/brands_hover0.png" alt="logo">&nbsp;Brands</a>
            </li>
           
        </ul>


        <!-- Tab panes -->
        <div class="tab-content sale_content">
            <div id="brand1" class="tab-pane active ">
                <div *ngFor="let brand of brands; let j=index">
                    <div class="row row_custom">
                        <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2 process_col">
                        </div>
                        <div class="col-md-8 col-lg-8 col-sm-8 col-xs-8 process_col shopheadbg">
                            <h3 class="sale_conttext">{{brand.brand_name}}</h3>
                        </div>
                        <div class="col-md-2 col-lg-2 col-sm-2 col-xs-2 process_col">
                        </div>
                    </div>
                
                    <div id="carousel{{j}}" class="carousel slide" data-ride="carousel">
                
                        <div class="carousel-inner">
                            <div class="carousel-item" *ngFor="let product of brand.product; let i=index" [ngClass]="(i === 0) ? 'active' : ''">
                
                                <div class="row row_shop1 shop_items">
                
                                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 shop_itemscol">
                
                                        <a [routerLink]="['/product-details', product.seo_url]"> <img src="{{product.product_images}}" class="img-fluid mx-auto d-block" alt="product">
                
                                            <p class="shopprod_top">{{product.product_name}}</p>
                
                                            <p class="shopprod_bot">Rs. {{product.product_price}}
                                                <!-- <s>Rs. 450.00</s> -->
                                            </p>
                                            <p class="mrp-price"><del>Rs. {{ product.cskd_mrp}}</del></p>
                                        </a>
                
                                    </div>
                
                                </div>
                
                            </div>
                
                
                
                            <a class="carousel-control-prev" href="#carousel{{j}}" role="button" data-slide="prev">
                
                                <img src="/assets/images/shopmobileleft.png" alt="whatsapp" class="img-thumbnail">
                
                            </a>
                
                            <a class="carousel-control-next" href="#carousel{{j}}" role="button" data-slide="next">
                
                                <img src="/assets/images/shopmobileright.png" alt="whatsapp" class="img-thumbnail">
                
                            </a>
                
                        </div>
                        <div class="row row_custom">
                
                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col ">
                
                                <a [routerLink]="['/product/brand',brand.seo_url]">
                                    <p class="shopmore_button">VIEW ALL</p>
                                </a>
                
                            </div>
                
                        </div>
                    </div>
                </div>

                <div>
                    <div class="row row_custom" *ngIf="brandsNotFound == 'notfound' ">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 listitem_col">
                            <div class="listitem_inner product_text text-center">
                                Brands not found 
                            </div>
                        </div>
                    </div>
                </div>

                <div class="noproductFound" *ngIf="showShopSelectMsg" >
                    <div class="shopProdOuter">     
                        <div class="shopProdInner">     
                            <a (click)="openSearcOutletModal(true)" title="Choose an outlet to start shopping">                            <img class="outlet-img" src="/assets/images/outlet-icon.png" alt="outlet-icon"> 
                                <span>Choose an outlet to start shopping </span>
                            <img class="arrow-img" src="/assets/images/arrow.png" alt="image"></a> 
                        </div>  
                    </div>   
                </div>

                <div class="shopitem_dummyrow">
                </div>

            </div>
            <div id="product1" class="tab-pane fade "><br>
                <!-- <app-device-category></app-device-category> -->

                <div class="shopitem_dummyrow">
                </div>
            </div>

        </div>

    </div>



    <div class="row row_custom">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 whatsapp_col">
            <a href="#"><img src="/assets/images/whatsapp2.png" alt="whatsapp" class="img-thumbnail"></a>
        </div>
    </div>
</div>
<app-footer></app-footer>



<app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>