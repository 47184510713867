import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {
  @Input() fromParent;
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    setTimeout(()=>this.activeModal.dismiss('Close clicked'),5000);
  }
}
