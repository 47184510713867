<div class="modal-body">

    <div class="row row_custom">

        <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12 shoplogin_side">
            <img class="close closedevice" (click)="activeModal.dismiss('Close clicked')" data-dismiss="modal" aria-label="Close" src="/assets/images/close.png">
        </div>

        <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 policy_body">

            <h3 class="policy_head">Return Policy</h3>

          <p>
           We have a 3-day return policy, which means you have 3 days after receiving your item to request a return.
		      </p>
  		  <p>
            To be eligible for a return, your item must be in the same condition that you received it, unworn or unused, with tags, and in its original packaging. You’ll also need the receipt or proof of purchase.<br>
            To start a return or lodge a concern please email us at <a href="mailto:mail@bolas.co.in">mail@bolas.co.in</a> or contact us at <a href="tel:+919449868000">+91 9449868000</a>

  		  </p>

        
        </div>
        <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12 shoplogin_side">

            <img class="close closedesk" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">

        </div>
    </div>
</div>
