<app-sub-header></app-sub-header>
 

<div class="containercart_outer">

    <div class="container_cart">

        <div class="row row_custom">

            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cart_topdummy">

            </div>

        </div>

        <div class="row row_custom">

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col shopheadbg carthead">

                <h3 class="sale_conttext">DELIVERY & PAYMENT</h3>

            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

            </div>

        </div>



        <div class="row row_custom cart_row">

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">

                <ol class="breadcrumb cart_breadcrumb">
                    <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>

                    <li class="breadcrumb-item"><a [routerLink]="['/cart']">Cart</a></li>

                    <li class="breadcrumb-item active">Delivery & Payment</li>

                </ol>

            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">

            </div>

        </div>


        <div class="row row_custom cart_row"  [formGroup]="paymentForm">

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col web_col1">

                <div class="row row_custom">

                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">

                        <h3 class="cart_coninfohead">Contact Information</h3>

                    </div>
                </div>

                <input class="cartdel_email" type="email" id="email" name="email" placeholder="Email" formControlName="email"  [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Please enter a valid email</div>
                </div>
                <!--<input class="chk_cart" type="checkbox" id="uptodate" name="uptodate" value="uptodate" formControlName="uptodate">

                <label class="txt_cartchk" for="uptodate"> Keep me up to date on news and exclusive offers</label>-->



                <div class="cart_detsection">
                    <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
                        <ngb-panel id="custom-panel-1">
                            <ng-template ngbPanelTitle let-opened="opened">
                                <div class="accordion accor_cart">
                                    <span class="m-0 uppercase">Choose delivery</span>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div *ngIf="displayHomeDelivery">
                                    <input type="radio" id="homedel" name="purchare_type" value="1" formControlName="purchare_type" (change)="changeType($event)" [ngClass]="{ 'is-invalid': submitted && f.purchare_type.errors }">
                                    
                                    <label class="txt_radio" for="homedel"> Home delivery</label> &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                <div *ngIf="displayPickup">
                                    <input type="radio" id="pickup" name="purchare_type" value="2" formControlName="purchare_type" (change)="changeType($event)" [ngClass]="{ 'is-invalid': submitted && f.purchare_type.errors }">

                                    <label class="txt_radio" for="homedel"> Pick up from store</label>
                                    &nbsp;
                                    <span>
                                        <a class="btn_info" (click)="showStoreInfo(store)">
                                            <img src="/assets/images/information-button.png" class="" alt="icon">
                                        </a>
                                    </span>

                                </div>
                                <div *ngIf="submitted && f.purchare_type.errors" class="invalid-feedback1">
                                    <div *ngIf="f.purchare_type.errors.required">Purchase type is required</div>
                                </div>

                                <div *ngIf="displayDeliveryPincode" class="note-text"><!-- displayDeliveryFee -->
                                    <span *ngIf="pincode.permitted_postcodes != ''" class="note-text"><span style="color: #dc3545;">Note:</span> Please ensure your delivery address pincode fall under {{pincode.permitted_postcodes}}</span>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
                <div class="cart_detsection">
                    <ngb-accordion activeIds="custom-panel-2">
                        <ngb-panel id="custom-panel-2" [disabled]="isDisableFirst" [cardClass]="isDisableFirst ? 'isDisableFirst' : ''">
                            <ng-template ngbPanelTitle>
                                <div class="accordion accor_cart">
                                    <span class="m-0 uppercase">Billing & Delivery</span>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>

                                <div class="row row_custom">

                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">

                                        <h3 class="cart_coninfohead">Billing Address</h3>

                                    </div>

                                    <div  class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">

                                        <a  *ngIf="displayChooseAddress" (click)="addressModal('billing')">
                                            <img src="/assets/images/Group1.png" class="img_cartaddress" alt="cart"> 
                                        </a>&nbsp;

                                        <a  *ngIf="displayChooseAddress" (click)="addAddress('billing')">
                                            <img src="/assets/images/Group2.png" class="img_cartaddress" alt="cart">
                                        </a>

                                    </div>
                                     

                                </div>



                                <div class="row row_custom cartaddrow">

                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col">

                                        <input class="cartdel_email"  [attr.readonly]="defaultPhoneNumber"  type="text" name="fname" placeholder="Full Name" formControlName="billing_name" [ngClass]="{ 'is-invalid': submitted && f.billing_name.errors }" (keyup)="enableThirdEnable($event)" maxlength="80" >
                                        
                                        <div *ngIf="submitted && f.billing_name.errors" class="invalid-feedback">
                                            <div *ngIf="f.billing_name.errors.required">Name is required</div>
                                            <div *ngIf="f.billing_name.errors.pattern">letters only, no numbers, punctuation or special characters</div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row row_custom cartaddrow">

                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col">

                                        <input class="cartdel_email" type="text"  [attr.readonly]="defaultPhoneNumber" name="billing_phone" placeholder="Phone" formControlName="billing_phone" (keyup)="trim($event.target.value)"  [ngClass]="{ 'is-invalid': submitted && f.billing_phone.errors }">
                                        
                                        <div *ngIf="submitted && f.billing_phone.errors" class="invalid-feedback">
                                            <div *ngIf="f.billing_phone.errors.required">Phone number is required</div>
                                            <div *ngIf="f.billing_phone.errors.pattern">Please enter a valid phone number</div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row row_custom cartaddrow">

                                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col">
                                        <input class="cartdel_email" type="text"  [attr.readonly]="billingHaveContactID" name="billing_address" placeholder="Address" formControlName="billing_address" [ngClass]="{ 'is-invalid': submitted && f.billing_address.errors }">
                                       
                                        <div *ngIf="submitted && f.billing_address.errors" class="invalid-feedback">
                                            <div *ngIf="f.billing_address.errors.required">Billing Address is required</div>
                                            <div *ngIf="f.billing_address.errors.minlength">Please enter a valid address</div>
                                        </div>
                                    </div>

                                </div>


                                <div class="row row_custom cartaddrow">
                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 cart_leftcol">

                                        <select formControlName="country_id_billing" id="country"  [attr.disabled]="billingHaveContactID" placeholder="Country" name="country" class="cartdel_email" id="country" (change)="onChangeCountry($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.country_id_billing.errors }">
                                            <option value="" >Select country...</option>
                                            <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>
                                        </select>
                                         <div *ngIf="submitted && f.country_id_billing.errors" class="invalid-feedback">
                                            <div *ngIf="f.country_id_billing.errors.required">Country is required</div>
                                        </div>
                                    </div>

                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 cart_rightcol">

                                        <select formControlName="state_id_billing" class="cartdel_email" [attr.disabled]="billingHaveContactID" placeholder="State" (change)="onChangeState($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.state_id_billing.errors }">
                                            <option value="" >Select state...</option>
                                            <option *ngFor="let state of states" [value]="state.id">{{state.state}}</option>
                                        </select>
                                        <div *ngIf="submitted && f.state_id_billing.errors" class="invalid-feedback">
                                            <div *ngIf="f.state_id_billing.errors.required">State is required</div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row row_custom cartaddrow">

                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 cart_leftcol">
                                        <select formControlName="town_id_billing" class="mdb-select cartdel_email" [attr.disabled]="billingHaveContactID" searchable="Search here.." placeholder="Town" [ngClass]="{ 'is-invalid': submitted && f.town_id_billing.errors }" (change)="addOtherCityBilling($event)" [(ngModel)]="billingCity"  >
                                            <option value=null >Select Town /City...</option>
                                            <option *ngFor="let city of cities" [value]="city.id">{{city.town}}</option>
                                        </select>

                                        <!--<input list="brow">
                                        <datalist id="brow">
                                          <option *ngFor="let city of cities" [value]="city.town">{{city.town}}</option>
                                        </datalist> -->
                                        

                                        <div *ngIf="submitted && f.town_id_billing.errors" class="invalid-feedback">
                                            <div *ngIf="f.town_id_billing.errors.required">City/town is required</div>
                                        </div>
                                    </div> 

                                    <!-- add new city -->

                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 cart_rightcol" *ngIf="this.billingAddCity">   

                                            <input class="cartdel_email" type="text" placeholder="Add New Town/City" [attr.readonly]="billingHaveContactID" formControlName="billing_web_city"  (keypress)="keyPressAlpha($event,'billing')" (paste)="onPaste($event)"  (change)="checkExistingCity('billing')">  
                                    </div>
                                    <!-- add new city end --> 

                                </div>


                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 addCity">

                                        <input class="cartdel_email" type="text" placeholder="Pincode" [attr.readonly]="billingHaveContactID" formControlName="billing_pin" [ngClass]="{ 'is-invalid': submitted && f.billing_pin.errors }">
                                        <div *ngIf="submitted && f.billing_pin.errors" class="invalid-feedback">
                                            <div *ngIf="f.billing_pin.errors.required">Pincode is required</div>
                                            <div *ngIf="f.billing_pin.errors.minlength">Minimum 6 characters required</div>
                                            <div *ngIf="f.billing_pin.errors.pattern">Please enter a valid pincode</div>
                                        </div>
                                    </div>


                                <div *ngIf="isDisableDiveliveryAddress">
                                    <input class="chk_cart" type="checkbox" id="sameaddress" name="sameaddress"  [(ngModel)]="isSameAddress"  value="sameaddress" [checked]="accepted" (change)="sameDeliveryAddress($event)" formControlName="sameaddress" [attr.disabled]="in_process"> 

                                    <label class="txt_cartchk" for="uptodate">Check if your delivery address is same as billing address</label>

                                    <div class="row row_custom cart_topspace" *ngIf="isDisableDiveliveryAddress">

                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">

                                            <h3 class="cart_coninfohead">Delivery Address</h3>

                                        </div>

                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col" *ngIf="!isSameAddress">

                                            <a  (click)="addressModal('delivery')">
                                                <img src="/assets/images/Group1.png" class="img_cartaddress" alt="cart" >
                                            </a>

                                        </div>
                                    </div>
                                    <div class="row row_custom cartaddrow">

                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col">

                                            <input class="cartdel_email" type="text" (keyup)="onKeyUp($event)" [attr.readonly]="defaultDeliveryPhoneNumber" name="fname" placeholder="Name" formControlName="delivery_name" [ngClass]="{ 'is-invalid': submitted && f.delivery_name.errors }"  maxlength="80" >
                                            <div *ngIf="submitted && f.delivery_name.errors" class="invalid-feedback">
                                                <div *ngIf="f.delivery_name.errors.required">Name is required</div>
                                                <div *ngIf="f.delivery_name.errors.pattern">Only Alphabets with space between 2 to 20 characters</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row row_custom cartaddrow">

                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col">

                                            <input class="cartdel_email" type="text" (keyup)="onKeyUp($event)" [attr.readonly]="defaultDeliveryPhoneNumber"  name="delivery_phone" placeholder="Phone" formControlName="delivery_phone" (keyup)="trimDeliveryPhone($event.target.value)"  [ngClass]="{ 'is-invalid': submitted && f.delivery_phone.errors }">
                                            <div *ngIf="submitted && f.delivery_phone.errors" class="invalid-feedback">
                                                <div *ngIf="f.delivery_phone.errors.required">Phone is required</div>
                                                <div *ngIf="f.delivery_phone.errors.pattern">Please enter a valid phone number</div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row row_custom cartaddrow">

                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col">

                                            <input class="cartdel_email" type="text" (keyup)="onKeyUp($event)" [attr.readonly]="deliveryHaveContactID" placeholder="Address" formControlName="delivery_address" [ngClass]="{ 'is-invalid': submitted && f.delivery_address.errors }">
                                            <div *ngIf="submitted && f.delivery_address.errors" class="invalid-feedback">
                                                <div *ngIf="f.delivery_address.errors.required">Delivery address is required</div>
                                            </div>
                                        </div>

                                    </div>


                                    <div class="row row_custom cartaddrow">



                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 cart_leftcol ">
                                            <select [attr.disabled]="deliveryHaveContactID"  formControlName="country_id_delivery" id="country" placeholder="Country" name="country" class="cartdel_email" id="country" (change)="onChangeDeliveryCountry($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.country_id_delivery.errors }">
                                                <option value="" >Select country...</option>
                                                <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>
                                            </select>
                                            <div *ngIf="submitted && f.country_id_delivery.errors" class="invalid-feedback">
                                                <div *ngIf="f.country_id_delivery.errors.required">Country is required</div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 cart_rightcol">
                                            <select [attr.disabled]="deliveryHaveContactID" formControlName="state_id_delivery" class="cartdel_email" placeholder="State" (change)="onChangeDeliveryState($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.state_id_delivery.errors }">
                                                <option value="" >Select state...</option>
                                                <option *ngFor="let state of deliveryState" [value]="state.id">{{state.state}}</option>
                                            </select>
                                            <div *ngIf="submitted && f.state_id_delivery.errors" class="invalid-feedback">
                                                <div *ngIf="f.state_id_delivery.errors.required">State is required</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row row_custom cartaddrow">

                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 cart_leftcol">
                                            <select [attr.disabled]="deliveryHaveContactID" formControlName="town_id_delivery" class="cartdel_email" (change)="onKeyUp($event)" [ngClass]="{ 'is-invalid': submitted && f.town_id_delivery.errors }" (change)="addOtherCityDelivery($event)"><!-- [(ngModel)]="deliveryCity" -->
                                                <option value=null >Select Town /City...</option>
                                                <option *ngFor="let city of deliveryCities" [value]="city.id">{{city.town}}</option>
                                            </select>
                                            <div *ngIf="submitted && f.town_id_delivery.errors" class="invalid-feedback">
                                                <div *ngIf="f.town_id_delivery.errors.required">City/town is required</div>
                                            </div>
                                        </div> 

                                        <!-- add deviery city --> 

                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 cart_rightcol" *ngIf="deliveryAddCity"> 

                                            <div class="col-sm-12 col-xs-12 addCity"> <!-- cart_rightcol --> 
                                                <input [attr.readonly]="deliveryHaveContactID" class="cartdel_email" type="text" placeholder="Other City" formControlName="delivery_web_city" (keypress)="keyPressAlpha($event,'delivery')" (paste)="onPaste($event)" (change)="checkExistingCity('delivery')">  
                                            </div>

                                        </div>
                                        <!-- add deviery city -->

                                    </div>  

                                    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 addCity">

                                        <input [attr.readonly]="deliveryHaveContactID" class="cartdel_email" type="text" (keyup)="onKeypressUpDeliveryPin($event)" placeholder="Pincode"  (change)="checkDeliveryFee()"    formControlName="delivery_pin" [ngClass]="{ 'is-invalid': submitted && f.delivery_pin.errors }"   >
                                         <div *ngIf="submitted && f.delivery_pin.errors" class="invalid-feedback">
                                            <div *ngIf="f.delivery_pin.errors.required">Delivery pincode is required</div>
                                            <div *ngIf="f.delivery_pin.errors.minlength">Minimum 6 characters required</div>
                                            <div *ngIf="f.delivery_pin.errors.pattern">Please enter a valid pincode</div>
                                        </div>

                                        <div><p class="del_fee" *ngIf="displayDeliveryFee">Delivery Fee Rs. {{ deliveryCharge | number:'2.2-5'}}</p></div>
                                    </div>

                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>



                <div class="cart_detsection">
                    <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3">
                        <ngb-panel id="custom-panel-3" [disabled]="isDisableSecond" [cardClass]="isDisableSecond ? 'isDisableSecond' : ''">
                            <ng-template ngbPanelTitle>
                                <div class="accordion accor_cart">
                                    <span class="m-0 uppercase">Payment options</span>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div *ngIf="displayPayOnline">
                                    <input type="radio" name="payment_type" value="1" formControlName="payment_type"    (click)="resetSubmit()" >

                                    <label class="txt_radio" for="radio">Pay Online</label> &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>  
                                <div *ngIf="displayCOD">
                                    <input type="radio" name="payment_type" value="2" formControlName="payment_type"    (click)="resetSubmit()" >

                                    <label class="txt_radio" for="radio"> Cash on delivery</label>
                                </div>
                                <div *ngIf="displayStorePick">
                                    <input type="radio" name="payment_type" value="3" formControlName="payment_type"    (click)="resetSubmit()" >

                                    <label class="txt_radio" for="radio"> Pay at Store</label> &nbsp;&nbsp;&nbsp;&nbsp;
                                </div>
                                <div *ngIf="submitted && f.payment_type.errors" class="invalid-feedback1">
                                    <div *ngIf="f.payment_type.errors.required">Payment type is required</div>
                                </div>

                                <div *ngIf="payment_type_error" class="invalid-feedback1">
                                    <div *ngIf="payment_type_error">Payment type is required</div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>

                </div>

            </div>









            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">

                <div class="cart_itemsection">

                    <div class="row row_custom cart_row4" *ngFor="let item of carts">

                        <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12 process_col">

                            <div class="itemthumb_outer">

                                <div class="item_thumbnail">

                                    <img src="{{ item.product_images }}" class="img_cartthumb img-fluid img-thumbnail" alt="cart">

                                    <span class="checkout_qty">{{ item.quantity }}</span>

                                </div>

                                <p class="itemthumbdet">{{ item.product_name }} </p>

                            </div>

                        </div>

                        <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 process_col">

                            <p *ngIf="item.gift_box != 1" class="itemthumbdet">Rs. {{ item.product_price * item.quantity | number:'2.2-5'}}</p>

                            <p *ngIf="item.gift_box == 1" class="itemthumbdet">Rs. {{ item.product_price | number:'2.2-5'}}</p>

                        </div>

                    </div>



                    <div class="row row_custom cart_row4">

                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col itemthumb_outer">

                            <input class="txt_discount" type="text" id="discount" name="discount"
                                placeholder="Discount code" value="" (change)="applyDiscountclass($event)" (paste)="applyDiscountclass($event)" (keydown)="applyDiscountclass($event)" formControlName="discount" >

                            <button class="button btndiscount" (click)="applyDiscount()" [ngClass]="{'bg-black':displayButtonBlack}">Apply</button>

                        </div>

                    </div>

                    <div class="row row_custom cart_row4">

                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col">

                            <hr class="cart_tot_hr">

                        </div>

                    </div>

                    <div class="row row_custom cart_row4">

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col carttotal_left">

                            <p class="cartbilltext">Subtotal</p>

                        </div>

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col carttotal_right">

                            <p class="cartbilltext">Rs. {{ locationsSum() | number:'2.2-5' }}</p>

                        </div>

                    </div>

                    <div class="row row_custom cart_row4" *ngIf="showDiscount">

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col carttotal_left">

                            <p class="cartbilltext">Discount({{discountAmount.discount_value}}%)</p>

                        </div>

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col carttotal_right">

                            <p class="cartbilltext">-Rs. {{ discountAmount.discount_amount | number:'2.2-5'}}</p>

                        </div>

                    </div>


                    <div class="row row_custom cart_row4" *ngIf="displayDeliveryFee">

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col carttotal_left">

                            <p class="cartbilltext">Delivery Fee</p>

                        </div>

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col carttotal_right">

                            <p class="cartbilltext">Rs. {{ deliveryCharge | number:'2.2-5'}}</p>

                        </div>

                    </div>

                    

                    <div class="row row_custom cart_row4">

                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col">

                            <hr class="cart_tot_hr">

                        </div>

                    </div>

                    <div class="row row_custom cart_row4">

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col carttotal_left">

                            <p class="cartbilltext">Total</p>

                        </div>

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col carttotal_right">

                            <p *ngIf="grandTotal == 0" class="cartbilltextgrand">Rs. {{ locationsSum() + deliveryCharge - discountGrandtotal | number:'2.2-5' }}</p>
                            <p *ngIf="grandTotal != 0" class="cartbilltextgrand">Rs. {{ grandTotal | number:'2.2-5' }}</p>
                        </div>

                    </div>

                    <!-- <div class="row row_custom cart_row4">

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col carttotal_left">

                             <p class="cartbilltext">Including Rs. 104.29 in taxes</p> 

                        </div>

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col carttotal_right">

                            <p class="cartbilltextgrand">Rs. {{ locationsSum() | number:'2.2-5' }}</p>

                        </div>

                    </div> -->

					<div *ngIf="errors" class="row row_custom cart_row4">

                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col">

                           <div class="alert alert-danger mt-3" *ngIf="errors?.pincode">
							{{ errors?.pincode }}
							</div>
                            <div class="alert alert-danger mt-3" *ngIf="errors?.error">
                            {{ errors?.error }}
                            </div>
                        </div>

                    </div>

                </div>


                <div class="delppay_submit">
                    
                    <p class="cart_return2">
                        <a class="returnlink" [routerLink]="['/cart']">
                            < &nbsp; Return to cart</a> &nbsp;&nbsp; <a id="submitlink2" class="submitlink2" [ngClass]="{'buttonDisable':formSumbit}"  (click)="onSubmit()">PLACE YOUR ORDER</a></p>

                </div>




            </div>

        </div>


    </div>



</div>
<app-footer></app-footer>

<app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>