<app-sub-header></app-sub-header>
 
<div class="containercart_outer">

    <div class="container_cart">

        <div class="row row_custom">

            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cart_topdummy">

            </div>

        </div>
        <div class="row row_custom">

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col accountheadbg addresshead">

                <h3 class="accountheadtext">SET PASSWORD</h3>

            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

            </div>

        </div>
        <div class="row row_custom change_passrow">

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 change_passwordcol">

                <form id="change_password" [formGroup]="changePasswordFrom">

                    <label class="myacc_label" for="new_password">New Password:</label>

                    <div class="pass_textarea">

                        <input class="myacc_text" type="password" placeholder="Enter New Password" id="new_password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" onkeypress="return event.charCode != 32">

                        <i class="far fa-eye-slash" id="togglePassword1"></i>

                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">New Password is required</div>
                            <div *ngIf="f.password.errors.minlength">Minimum 6 characters required</div>
                            <div *ngIf="f.password.errors.pattern">Your new password must contain at least one uppercase, one lowercase, one special character and one number <br/>Note: Special characters allowed @$!%*#?& </div>
                            <div *ngIf="f.password.errors?.noWhiteSpace">Whitespace is not allowed</div>
                        </div>
                        <div *ngIf="errors?.password" class="backend_error">{{ errors?.password }}</div>

                    </div>

                    <label class="myacc_label" for="cnew_password">Confirm New Password:</label>

                    <div class="pass_textarea">

                        <input class="myacc_text" type="password" placeholder="Enter Confirm New Password" id="cnew_password" formControlName="password_confirmation" [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" onkeypress="return event.charCode != 32">

                        <i class="far fa-eye-slash" id="togglePassword2"></i>

                        <div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
                            <div *ngIf="f.password_confirmation.errors.required">Confirm Password is required</div>
                            <div *ngIf="f.password_confirmation.errors.mustMatch">Passwords must match</div>
                        </div>
                        <div *ngIf="errors?.password_confirmation" class="backend_error">{{ errors?.password_confirmation }}</div>
                    </div>

                    <div *ngIf="errors.error" class="alert alert-danger mt-3">
                        {{ errors.error }}
                    </div>
                    <div *ngIf="result.message" class="alert alert-success mt-3">
                        {{ result.message }}
                    </div>

                    <button type="submit" class="changepassword_submit" (click)="onSubmit()">SET PASSWORD</button>

                </form>

            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 change_passwordcol">



            </div>

        </div>
    </div>
</div>
<app-footer></app-footer>