import { Component, OnInit , Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef} from '@angular/core';
import { NgbActiveModal , ModalDismissReasons , NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { GiftService} from '../../gift/gift.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gift-items-mobile-view',
  templateUrl: './gift-items-mobile-view.component.html',
  styleUrls: ['./gift-items-mobile-view.component.css']
})
export class GiftItemsMobileViewComponent implements OnInit {
  @Input() product_id: number;
  giftBox:any   =[];
  itemAdded:any =[];
  amount = 0;
  constructor(
	  	public router: Router,
	    public activeModal: NgbActiveModal,
	    private GiftService: GiftService,
	    private modalService: NgbModal
	) { }

  ngOnInit(): void {
  	this.getGiftItemsAdded();
  }
  getGiftItemsAdded(){
	this.GiftService.getGiftCartAddedItems(this.product_id).subscribe(
      data => {
      	this.giftBox = data;
        this.itemAdded = this.giftBox.items
      	console.log(this.itemAdded);
      }
    ); 
  }
  locationsSum(){
  	if(this.itemAdded.length >0){
	    this.amount = this.itemAdded.map(tag => tag.product_price*tag.quantity).reduce((a, b) => a + b, 0);
	}
	return this.amount;
  }
}
