import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryService} from '../../country.service';
import { ProductService } from '../../product-details/product.service';
import { CartService } from '../../cart.service';
import { NotificationsComponent } from '../../modals/notifications/notifications.component';
import {Location} from '@angular/common';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { ErrorNotificationsComponent} from '../../modals/error-notifications/error-notifications.component';
import { LoginComponent } from 'src/app/modals/login/login.component';
import { AuthStateService } from '../../shared/auth-state.service';
@Component({
  selector: 'app-brand-list',
  templateUrl: './brand-list.component.html',
  styleUrls: ['./brand-list.component.css']
})
export class BrandListComponent implements OnInit {
  id;
  type;
  products:any = [];
  brands:any = [];
  sub;
  category_id: string = "";
  selected_count: number = 0;
  selectItems: string[];
  searchCategory:any =[];
  closeResult = '';
  errors:any ="";
  public isCollapsed=false;
  @Input() product: any;
  @Output() productAdded = new EventEmitter();
  isLoading:boolean = false;
  MESSAGE_DATA:any ="";
  purchase_type:any ="";
  disableStore:boolean =false;
  disableEStore:boolean =false;
  count:any = 0;
  store_image:any = "/assets/images/store.png";
  eshop_image:any  = "/assets/images/eshop.png";

  store_image_device:any = "/assets/images/shopinstore.png";
  eshop_image_device:any = "/assets/images/eeshop.png";

  shopId:any = "";
  store:any =[];
  storename:string = "";
  isSignedIn: boolean;
  showNoProducts:boolean = false;
  addProductToCart(product) {
    this.productAdded.emit(product);
  }
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private CountryService: CountryService,
    private ProductService:ProductService,
    private cartService: CartService,
    private modalService: NgbModal,
    private _location: Location,
    private confirmationDialogService: ConfirmationDialogService,
    private auth: AuthStateService,
  ) { }

  ngOnInit(): void {
    this.isLoading=true;
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.sub = this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('slug');
      this.type = params.get('type');
      //this.getStore();
      this.purchase_type = localStorage.getItem('purchase_type');
      this.CountryService.getUserStore().subscribe(
        data => {
          this.shopId = data;
          this.disableStore = false;
          this.disableEStore = true;
          this.store_image="/assets/images/store.png";
          this.eshop_image = "/assets/images/eshop.png";

          this.store_image_device = "/assets/images/shopinstore.png";
          this.eshop_image_device = "/assets/images/eeshop.png";
          
        }
      );
    });
    this.selectItems = new Array<string>();
    this.auth.userAuthState.subscribe(val => {
      this.isSignedIn = val;
    });
  }
  ngAfterViewInit(){
    this.isLoading=true;
    this.ProductService.getBrandProducts(this.id).subscribe(
      data => {
        this.products = data;
        this.isLoading=false;
      }
    );
  }

  getSelected(e:any,id:string) {
    if(e.target.checked){
      this.selectItems.push(id);
    } else{
      this.selectItems = this.selectItems.filter(m =>m!=id);
    }
    this.searchCategory =  this.selectItems;
  }
  clearSelect(){
    this.searchCategory = [];
     this.selectItems=[];
  }
  ngOnDestroy(){
    this.sub.unsubscribe();
  }
  onBack(): void{
    this.router.navigate(['/']);
  }
  getProducts(): void {
    this.isLoading=true;
    this.ProductService.getBrandProducts(this.id).subscribe(
      data => {
        this.products = data;
        this.isLoading=false;
      }
    );
    
  }
  onChangeFilters(selectedFilters: string) {
    this.ProductService.getProductsBrandByQuery(this.id,selectedFilters).subscribe(data => {
      this.products = data ? data : [];
      if(this.products.length == 0){
        this.showNoProducts = true;   
      }else{
        this.showNoProducts = false;  
      }  
        window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        }); 
    });
  }
  buyNow( id, quantity): void {
    if(!this.isSignedIn ){ 
      this.openLoginModal('buynow',id,quantity); 
      return;
    }
    this.isLoading=true;
    let payload = {
      productId: id,
      quantity,
    };
    this.cartService.addToCart(payload).subscribe(
      result => {
        this.getProducts();
        this.isLoading=false;
        this.getCartCount();
        this.router.navigate(['/cart']);
      },
      error => {
        this.errors = error.error;
        this.isLoading=false;
        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                            size: 'xl',
                            backdrop: 'static',
                            keyboard: false,
                            windowClass: 'cartadd_modal',
                            animation: true,
                            ariaDescribedBy:'test',
                          });
        modalRef.componentInstance.message = this.errors.error;
      },() => {
        
      }
    );
  }
  emptyCartBuyNow(id, quantity){
    this.confirmationDialogService.confirm('', 'Adding this item to cart will replace the existing cart items. Are you sure you want to proceed?')
    .then((confirmed) => {
      if(confirmed === true){
        this.cartService.emptyCart().subscribe(() => {
        let payload = {
          productId: id,
          quantity,
        };
        this.cartService.addNewCart(payload).subscribe(
          result => {
            this.getProducts();
            this.isLoading=false;
            this.getCartCount();
            this.router.navigate(['/cart']);
          },
          error => {
            this.errors = error.error;
          },() => {
            
          }
        );
      })
    }
  })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
 
  }
  emptyCart(id, quantity){
    this.confirmationDialogService.confirm('', 'Adding this item to cart will replace the existing cart items. Are you sure you want to proceed?')
    .then((confirmed) => {
      if(confirmed === true){
        this.cartService.emptyCart().subscribe(() => {
        let payload = {
          productId: id,
          quantity,
        };
        this.cartService.addNewCart(payload).subscribe(
          result => {
            this.getProducts();
            this.isLoading=false;
            this.getCartCount();
            localStorage.setItem('message', "message");
            this.modalService.open(NotificationsComponent, {
              centered: true,
              size: 'sm',
              backdrop: 'static',
              keyboard: false,
              windowClass: 'cartadd_modal',
              animation: true,
              ariaDescribedBy:'test',
            }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          },
          error => {
            this.errors = error.error;
          },() => {
            
          }
        );
      })
      }}
    )
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    
  }
  addItemToCart( id, quantity): void {
    if(!this.isSignedIn ){ 
      this.openLoginModal('addtocart',id,quantity); 
      return;
    }
    localStorage.removeItem('message');
    this.isLoading=true;
    let payload = {
      productId: id,
      quantity,
    };
    this.cartService.addToCart(payload).subscribe(
      result => {
        this.getProducts();
        this.isLoading=false;
        this.getCartCount();
        localStorage.setItem('message', "message");
        this.modalService.open(NotificationsComponent, {
          centered: true,
          size: 'sm',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'cartadd_modal',
          animation: true,
          ariaDescribedBy:'test',
        }).result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      },
      error => {
        this.errors = error.error;
        this.isLoading=false;
        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                            size: 'xl',
                            backdrop: 'static',
                            keyboard: false,
                            windowClass: 'cartadd_modal',
                            animation: true,
                            ariaDescribedBy:'test',
                          });
        modalRef.componentInstance.message = this.errors.error;
      },() => {
        
      }
    );
  }
  changeShopType(e){
    if(this.purchase_type ==="estore" && e === "shop"){
      this._location.back();
    }else if(this.purchase_type ==="shop" && e === "estore"){
      this._location.back();
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getCartCount(){
    this.cartService.getCartItemCount().subscribe(
      data => {
        this.count = data;
        localStorage.setItem("cart_count", JSON.stringify(this.count));
      }
    ); 
  }
  
   goBack(){
    this._location.back();
  }
  goToShopBrand(){
    this.router.navigate(['/brands']);
  }

  goToShopCategory(){
    this.router.navigate(['/']);
  }
  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      result => {
        this.store = result;
        console.log(this.store);
        this.storename = this.store.seo_url
      },
      error => {
        
      },() => {
      }
    );
  }
  openLoginModal(type,id,quantity) {
    //history.pushState(null, null, '');
    const modalRef = this.modalService.open(LoginComponent,{ 
                                            centered: true, 
                                            size: 'xl',
                                            backdrop: 'static',
                                            keyboard: false,
                                            windowClass: 'login_modal'
                                          });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
      this.auth.userAuthState.subscribe(val => {
        this.isSignedIn = val;
      });
      if(type == "buynow"){
        this.buyNow(id,quantity);
      }else{
        this.addItemToCart(id,quantity)
      }
    }).catch((error) => {
      console.log(error);
    });
  }
}
