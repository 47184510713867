import * as $ from 'jquery';
import { Component, EventEmitter, OnInit, Output  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { CountryService} from '../../../country.service';
import { ProductService } from '../../../product-details/product.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.css']
})
export class CategoryFilterComponent implements OnInit {
  clickWStatus:string ='';
  weightFilters:any = [];
  priceFilters:any =[];
  brands:any = [];
  class:string ="test";

  selectedWeight:any = [];
  selectedPrice:any =[];
  selectedBrand:any=[];  
  activeIds = ['panel-1', 'panel-2', 'panel-3']; 
  @Output() selectedFilters = new EventEmitter<string>();
  constructor( 
    private formBuilder: FormBuilder,
    private CountryService: CountryService,
    private ProductService:ProductService,
    private activatedRoute: ActivatedRoute,
    private render:Renderer2
  ) { }
  public brandsList: Map<string, boolean> = new Map<string, boolean>();;
  public WeightList: Map<string, boolean> = new Map<string, boolean>();
  public priceList: Map<string, boolean> = new Map<string, boolean>();

  ngOnInit(): void {
    $(document).ready(function(){
      var fil=$(".container_shop").height();    
      $(".side_filter").css('height',fil);  // $(".side_filter").height(fil);
      var mxhight=fil-80;
      $(".side_filter").css('max-height',mxhight);
     });
     
    $(window).on('resize', function(){
      var fil=$(".container_shop").height();
      var mxhight=fil-80;      
      $(".side_filter").css('height',fil); // $(".side_filter").height(fil);
       $(".side_filter").css('max-height',mxhight);
    });
    $(".awbp").click(function() { 
      $(this).closest('.filteritem').next().toggleClass('filterhover');
    });

    forkJoin( this.ProductService.getWeight(), this.ProductService.getPrice(), this.ProductService.getBrandFilter()).subscribe(
      ([weightFilters, priceFilters, brands]) => {
        // categories
        brands.forEach(brand => {
           this.brandsList.set(brand, false);
        });

        // prices
        priceFilters.forEach(pf => this.priceList.set(pf, false));

        weightFilters.forEach(pf => this.WeightList.set(pf, false));

        this.updateSelectedFilters();
      }
    );


  }


  updateSelectedFilters() {
    // categories

    const brandInSelection: string[] = this.setBrandFilterSelection(this.brandsList);
    this.selectedBrand = this.setBrandFilterSelection(this.brandsList);
    // weight

    const WeightInSelection: string[] = this.setweightFiltersSelection(this.WeightList);
    this.selectedWeight = this.setweightFiltersSelection(this.WeightList);
    // price
    const pricesInSelection:  string[]  = this.setPriceFilterSelection(this.priceList);
    this.selectedPrice = this.setPriceFilterSelection(this.priceList);

    // query
    let jsonObj = {};
    if (brandInSelection.length > 0) {
      jsonObj['brands'] = { $in: brandInSelection };
    }
    if (WeightInSelection.length > 0) {
      jsonObj['weight'] = { $in: WeightInSelection };
    }

    if (pricesInSelection.length > 0) {
      jsonObj['price'] = { $in: pricesInSelection };
    }

    const query = encodeURIComponent(JSON.stringify(jsonObj)); 
    this.selectedFilters.emit(query);
  }
  filterBrand(entry: { key: string; value: boolean }) {
    this.brandsList.set(entry.key, !entry.value);
    this.updateSelectedFilters();
  }

  filterWeight(entry: { key: string; value: boolean }) {
    this.WeightList.set(entry.key, !entry.value);
    this.updateSelectedFilters();
  }

  filterPrice(entry: { key: string; value: boolean }) {
    this.priceList.set(entry.key, !entry.value);
    this.updateSelectedFilters();
  }
  setBrandFilterSelection(collection: Map<string, boolean>): string[] {
    const inList = [];
    collection.forEach((value: boolean, key: string) => {
      if (value === true) {
        inList.push(key);
      }
    });
    return inList;
  }

  setweightFiltersSelection(collection: Map<string, boolean>): string[] {
    const inList = [];
    collection.forEach((value: boolean, key: string) => {
      if (value === true) {
        inList.push(key);
      }
    });
    return inList;
  }

  setPriceFilterSelection(collection: Map<string, boolean>): string[] {
    const inList = [];
    collection.forEach((value: boolean, key: string) => {
      if (value === true) {
        inList.push(key);
      }
    });
    return inList;
  }
}
