<div class="bd-example process_desk">

    <div id="carouselExampleCaptions" class="carousel slide carousel-fade" data-ride="carousel">

        <div class="carousel-inner">

            <div class="carousel-item active">

                <img src="/assets/images/banner.jpg" class="d-block w-100" alt="...">

            </div>
            <div class="shop_bannercontact">

                <div class="row row_custom">

                    <div class="col-md-10 col-lg-8 col-sm-8 col-xs-6 ">
                        <div class="row">
                            <div class="col-md-1"   *ngIf="pincode">
                                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 425.963 425.963" style="enable-background:new 0 0 425.963 425.963;" xml:space="preserve" fill="#fbb600">
                                    <g>
                                        <path d="M213.285,0h-0.608C139.114,0,79.268,59.826,79.268,133.361c0,48.202,21.952,111.817,65.246,189.081
                                            c32.098,57.281,64.646,101.152,64.972,101.588c0.906,1.217,2.334,1.934,3.847,1.934c0.043,0,0.087,0,0.13-0.002
                                            c1.561-0.043,3.002-0.842,3.868-2.143c0.321-0.486,32.637-49.287,64.517-108.976c43.03-80.563,64.848-141.624,64.848-181.482
                                            C346.693,59.825,286.846,0,213.285,0z M274.865,136.62c0,34.124-27.761,61.884-61.885,61.884
                                            c-34.123,0-61.884-27.761-61.884-61.884s27.761-61.884,61.884-61.884C247.104,74.736,274.865,102.497,274.865,136.62z"/>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg>
                            </div>

                            <div class="col-md-10 col-lg-6">
                                <p class="topaddress">
                                    <span *ngIf="shopOpClseStatus">
                                        <span class="store-open"  style="color: {{shopStatusColor}}" >{{shopStatus}}</span> <br/>
                                        <span class="store-address" >{{shopOpeningTiming}} - {{shopCloseTiming}}</span> <br/>
                                    </span>
                                    <span class="store-name"> {{storename}} </span> <br/>
                                    <span class="store-address" *ngIf="address">{{address}}</span>
                                    <span *ngIf="pincode">, {{pincode}}</span><br *ngIf="address"/>
                                    <a href="tel:+91{{shopphone}}" class="store-address text-white"  *ngIf="shopphone">+91 {{shopphone}} </a> 
                                </p>
                            </div>
                        </div> 
                    </div>
                </div> 
            </div>
            
            <button type="button" class="btn text-dark btn-banner btn_sale" id="button_sale"><a href="#"><img src="/assets/images/scroll-down.png" alt="Logo"></a></button>
        </div>
    </div>
</div>

<div class="bd-example process_device">



    <div id="carouselExampleCaptions2" class="carousel slide carousel-fade" data-ride="carousel">

        <div class="carousel-inner">

            <div class="carousel-item active">

                <img src="/assets/images/banner.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="shop_walkin">

                <p class="walkintext">{{storename}}<br/><a href="tel:+91{{shopphone}}" class="store-address text-white" style="color:#fbb600!important"    *ngIf="shopphone">+91 {{shopphone}} </a> </p>

            </div>
            <button type="button" class="btn text-dark btn-banner btn_sale" id="button_sale"><a href="#"><img src="/assets/images/scroll-down.png" alt="Logo"></a></button>
        </div>

    </div>

</div>
