
<app-sub-header></app-sub-header>
 

<div class="containercart_outer">

    <div class="container_cart">

        <div class="row row_custom">

            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cart_topdummy">

            </div>

        </div>

        <div class="row row_custom">

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col shopheadbg carthead">

                <h3 class="sale_conttext">SHOPPING CART</h3>

            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

            </div>

        </div>



        <div class="row row_custom cart_row">

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">

                <ol class="breadcrumb cart_breadcrumb">

                    <li class="breadcrumb-item"><a [routerLink]="['/']">Home</a></li>

                    <li class="breadcrumb-item active">Cart</li>

                </ol>

            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">

            </div>

        </div>



        <div class="row row_custom cart_row" *ngFor="let item of carts">

            <div class="col-md-1 col-lg-1 col-sm-12 col-xs-12 process_col">

                <a *ngIf="item.gift_box !=1"  [routerLink]="['/product-details', item.seo_url]"><img src="{{ item.product_images }}" class="mx-auto d-block img_cartitems img-fluid img-thumbnail" alt="cart"></a>
                <img *ngIf="item.gift_box ==1" src="{{ item.product_images }}" class="mx-auto d-block img_cartitems img-fluid img-thumbnail" alt="cart">

            </div>

            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 process_col">

                <p class="cart_itemtext"> {{ item.product_name }} <a *ngIf="item.gift_box==1" (click)="getGiftItemModel(item.id)" >&nbsp;<img src="/assets/images/information-button.png"></a>  <br>
                    <small class="text_red" *ngIf="inStock && item.stock <= 10">Only {{item.stock}} left</small>
                    <small class="text_red" *ngIf="!inStock">Out of Stock</small>
                </p>

            </div>
            <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 process_col">

                <p class="cart_itemtext">Rs.{{ item.product_price | number:'2.2-5'}} </p>

            </div>

            <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 process_col">

                <p class="cart_button"><span class="cartadd1" *ngIf="item.gift_box!=1"><a class="activebutton" *ngIf="item.quantity > 1" [ngClass]="{inactiveButton: item.quantity == 1} " (click)="increamentQTY(item.id,-1)">-</a></span>{{ item.quantity }}<span class="cartadd2" *ngIf="item.gift_box!=1"><a class="activebutton" (click)="increamentQTY(item.id,1)">+</a></span></p>

            </div>

            <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 process_col">

                <p class="cart_itemtext" *ngIf="item.gift_box != 1">Rs. {{ item.quantity * item.product_price | number:'2.2-5'}}&nbsp;&nbsp;&nbsp;&nbsp;
                    <a (click)="deleteCartItem(item.id)"><img src="/assets/images/delete-black.png" class="mx-auto" alt="delete"></a>
                </p>
                <p class="cart_itemtext" *ngIf="item.gift_box == 1">Rs. {{ item.product_price | number:'2.2-5'}}&nbsp;&nbsp;&nbsp;&nbsp;
                    <a (click)="deleteCartItem(item.id)"><img src="/assets/images/delete-black.png" class="mx-auto" alt="delete"></a>
                </p>
                
            </div>

        </div>

        <div class="row row_custom cartclear_row">

            <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12 process_col">

            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

                <a (click)="emptyCart()">
                    <p class="cart_itemtextblue"><img src="/assets/images/delete-blue.png" class="mx-auto" alt="delete">&nbsp;&nbsp;&nbsp;CLEAR SHOPPING CART</p>
                </a>

            </div>



        </div>









        <div class="row row_custom carttotal_row">

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">

            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">

                <div class="cart_totalbox">

                    <div class="row row_custom">

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 cart_totalleft">

                            <p class="cart_subtottext">

                                SUBTOTAL

                            </p>

                        </div>

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 cart_totalright">

                            <p class="cart_subtottext">

                                Rs. {{ locationsSum() | number:'2.2-5' }}

                            </p>

                        </div>

                    </div>



                    <div class="row row_custom">

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 cart_totalleft">

                            <p class="cart_subtottext">

                                GRAND TOTAL

                            </p>

                        </div>

                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 cart_totalright">

                            <p class="cart_grtottext">

                                Rs. {{ locationsSum() | number:'2.2-5' }}

                            </p>

                        </div>

                    </div>



                    <div class="row row_custom">

                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cart_totalright cart_returncol">

                            <p class="cart_return">
                                <a class="returnlink" [routerLink]="['/']"> Continue Shopping</a> &nbsp;&nbsp;
							    <a class="submitlink2 " [ngClass]="{'buttonDisable':isDisableButton}" (click)="processPay()">PROCEED TO BUY</a>
                        </div>

                    </div>



                </div>

            </div>

        </div>

    </div>



</div>
<app-footer></app-footer>
<app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>