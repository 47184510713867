<app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>
<app-sub-header></app-sub-header>
<div class="containercart_outer"> 
	<div class="container_giftbox"> 
		<div class="row row_custom">
			<div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">
	    	</div>
		    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col gifthead">
				<img src="/assets/images/giftbox.png" class="mx-auto d-block" alt="icon">
		      	<h2 class="accountheadtext">CHOOSE YOUR GIFT BOX</h2>
			  	<p class="gifttop_text">Welcome to the easiest way to send someone a custom gift, Choose your favourite gift box that is already filled with items. you can easily remove or add items</p>
		    </div>
		   	<div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">
	     	</div>
	    </div>
	    <div class="row row_custom gift_row">
	    	<div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 gift_col" *ngFor="let product of giftbox">
				<div class="giftcol_inner">
					<div class="giftbox_imgouter">
						<div class="giftbox_imgspace">
							<img src="{{product.product_images}}" class="img-thumbnail mx-auto d-block giftbox_img" alt="giftbox">
						</div>	
						<h3 class="gift_innerhead" (click)="addSuggestedItemsTocart(product.id, product.seo_url)">{{product.product_name}}</h3>
						<hr class="gifthr">
						<p class="giftinner_text tooltip">
							{{ (product.description.length>60)? (product.description | slice:0:60)+'...':(product.description) }}
							<span class="tooltiptext">{{product.description}}</span>
				    	</p>
					</div>
					<a *ngIf="product.stock >0" (click)="addSuggestedItemsTocart(product.id, product.seo_url)" class="gift_button">SELECT BOX</a>

					<button *ngIf="product.stock <= 0" class="gift_oos">OUT OF STOCK</button> 
				</div>
		    </div>
	    </div>
	    
	</div>
</div>
<app-footer></app-footer>