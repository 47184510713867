<ngb-accordion [activeIds]="activeIds">
    <!-- <ngb-panel id="panel-1">
        <ng-template ngbPanelTitle>
            <div class="accordion accor_pro">WEIGHT</div>
        </ng-template>
        <ng-template ngbPanelContent class="panel panel_pro">
            <div *ngFor='let weight of WeightList | keyvalue'>
                <a class="awbp" (click)="filterWeight(weight)">
                    <p class="filteritem" [ngClass]="{'filterhover': selectedWeight.includes(weight.key)}">
                        <img src="/assets/images/tick1.png" alt="select" class="img-thumbnail imgselect">
                        <img src="/assets/images/tick2.png" alt="select" class="img-thumbnail imghselect">{{weight.key.name}}
                    </p>
                </a>
            </div>
        </ng-template>
    </ngb-panel> -->    
    <ngb-panel id="panel-2">       
        <ng-template ngbPanelTitle>
            <div class="accordion accor_pro">PRICE</div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div *ngFor='let price of priceList | keyvalue'>
                <a (click)="filterPrice(price)">
                    <p class="filteritem" [ngClass]="{'filterhover': selectedPrice.includes(price.key)}">
                        <img src="/assets/images/tick1.png" alt="select" class="img-thumbnail imgselect">
                        <img src="/assets/images/tick2.png" alt="select" class="img-thumbnail imghselect">{{price.key.name}}
                    </p>
                </a>
            </div>
        </ng-template>
    </ngb-panel>
    <ngb-panel id="panel-3">
        <ng-template ngbPanelTitle>
            <div class="accordion accor_pro">BRAND</div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div class="category-filter filter-wrapper " *ngFor='let brand of brandsList | keyvalue'>
                <a (click)="filterBrand(brand)">
                    <p class="filteritem" [ngClass]="{'filterhover': selectedBrand.includes(brand.key)}">
                        <img src="/assets/images/tick1.png" alt="select" class="img-thumbnail imgselect">
                        <img src="/assets/images/tick2.png" alt="select" class="img-thumbnail imghselect">{{brand.key.brand_name}}
                    </p>
                </a>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>