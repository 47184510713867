
<div class="modal-body">
	<div class="row row_custom">
		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 process_col">
		 	<img class="close  closeinfo" (click)="activeModal.dismiss('Close clicked')" data-dismiss="modal" src="/assets/images/close5.png">
		</div>
	</div>
    <div class="infopopup_outer">
	    <div class="row row_custom">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 process_col">
				<h3 class="gitem_subhead text-center">BOX CONTENTS</h3>
	        	<hr class="gitem_rt2">
		   </div>
		</div>
		<div class="row row_custom" *ngFor="let item of items ; let i = index ">
		   	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 infopopup_left">
		   		<p class="cartbilltext">
		   			{{i+1}}. {{ item.product_name }} 
		   		</p>
		   	</div>
		    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 infopopup_right">
				<p class="cartbilltext">Rs. {{ item.product_price*item.quantity }}</p>
		   	</div>
		</div>
	
		<div class="bg-grey2 mt-3 mb-3">
	        <div class="row row_custom ">
		        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
		         	<p class="gitem_subtotal text-right ">Items Subtotal: Rs.{{locationsSum() | number:'2.2-5'}}</p>
		        </div>
	        </div>
	    </div>
	    <div class="row row_custom mb-4">
		   	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 infopopup_left">
		   		<p class="cartbilltext">{{cart.product_name}}</p>
		   	</div>
		    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 infopopup_right">
				<p class="cartbilltext">Rs. {{cart.product_price*cart.quantity }}</p>
		   </div>
		</div>
	</div>

	<div class="row row_custom ">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        	<p class="gitem_subtotal text-right ">Total: Rs.{{locationsSum()+cart.product_price*cart.quantity | number:'2.2-5'}}</p>
        </div>
    </div>

</div>