<!-- Modal content-->
<div class="modal-content">
      
	<div class="modal-body ">
	<div class="row row_custom">
	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
		<img *ngIf="is_close" class="close closepin" (click)="activeModal.dismiss('Close clicked')" data-dismiss="modal" src="/assets/images/close6.png">
	</div>
	</div>
   <form appForm [formGroup]="searchOuletFrm">
	   <div class="pin_bg">
		   <div class="row row_custom pinpopup_toprow">

				<!--<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
				<div class="pinhead_outer">
					<span class="popupheadspan"><img src="/assets/images/location-connection.png" class="img-thumbnail" alt="image"></span><h2 class="pinpopup_head">Enter the PIN code & <br class="process_desk">shop at your nearby store</h2>
				</div>

				</div>-->

				<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 br-right">				
				
					<div class="pinhead_outer">
						<span class="popupheadspan"><img src="/assets/images/location-connection.png" class="img-thumbnail" alt="image"></span><h2 class="pinpopup_head">Enter the PIN code & <br class="process_desk">shop at your nearby store</h2>
					</div>

					<div class="input-group pin_input">
						<input type="text" class="form-control pin_search" placeholder="Search by PIN" id="pinsearch_text" name="pinsearch_text" formControlName="pinsearch_text" (keyup)="keyPressAllowNumbers($event)" [ngClass]="{ 'is-invalid': submitted && f.pinsearch_text.errors }"   (keypress)="keyPressAllowNumbers($event)"  maxlength="6">


						<div class="input-group-append">
						<button class="btn btn-pin" type="submit" [ngClass]="{'buttonDisable':formSumbit}"  (click)="searchOutlets()">GO</button>
						</div>  

						<div *ngIf="submitted && shopsLength && !f.pinsearch_text.errors"  class="invalid-feedback" style="display:block;margin-bottom:10px">  
							<div>Searched results not found, enter a different pincode.<br/>You can also click on "Locate Shop Near Me" to check other nearby outlets OR go for "PAN India Delivery"</div> 
						</div>

						<div *ngIf="submitted && f.pinsearch_text.errors" class="invalid-feedback">
							<div *ngIf="f.pinsearch_text.errors.required">Pincode is required</div>
							<div *ngIf="f.pinsearch_text.errors.pattern">Please enter valid Pincode</div> 
						</div> 


						<!--<div *ngIf="submitted && shopsLength && !f.pinsearch_text.errors"  class="invalid-feedback" style="display:block;margin:botton:10px">  
							<div  class="pan-msg">For pan India delivery <a href="/estore"  (click)="selectOutlets(1)" class="btn btn-click-here">CLICK HERE</a></div> 
						</div>  -->
					</div> 	

				</div>

				<div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
					<div class="locate-pan-outer">			
						<div style="display:block;" *ngIf="showLocate">  
							<div class="locate-outer">
								<div class="locate-pin"></div>
								<div class="pan-msg">Locate	<span>shop near me </span></div>
								<div class="btn-router-link">
									<a [routerLink]="['/viewmap']"  (click)="loadGoogleMap()" class="btn btn-click-here">CLICK HERE</a>
								</div>

							</div>
							<!--<div  class="pan-msg">Locate shop near me 
								<a [routerLink]="['/viewmap']"  (click)="loadGoogleMap()" class="btn btn-click-here">CLICK HERE</a>
							</div> <br/>-->

						</div>   						
					
					</div>

					<div class="separator">OR</div>

					<div class="locate-pan-outer">	
						<div class="invalid-feedback" style="display:block;">  
							<div class="locate-outer">
								<div class="pan-pin"></div>
								<div class="pan-msg">PAN <span>India delivery</span></div>
								<div class="btn-router-link">
									<a href="/estore"  (click)="selectOutlets(1)" class="btn btn-click-here">CLICK HERE</a>
								</div>
							</div>
							<!--<div  class="pan-msg">PAN India delivery <a href="/estore"  (click)="selectOutlets(1)" class="btn btn-click-here">CLICK HERE</a></div>-->
						</div>  
					</div>

				</div>
			</div>
		</div>
	</form> 
	<span class="listOutlets"></span>
	<div class="row row_custom pinpopup_midrow "  >  
		<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pinpopup_left"  *ngFor="let outlets of shops;let indexOfelement=index;"  style="background-color: #FFEFC7;"  > 
			<div class="row row_custom" *ngIf="indexOfelement%2 == 0">
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pinlist_col">
					<div class="pinlistimg_outer">
					<img src="{{outlets.shop_image}}" class="img-thumbnail" alt="image">
					<p class="opendet" *ngIf="outlets.shop_status  == 'OPEN'">OPEN</p>
					<p class="closedet" *ngIf="outlets.shop_status  == 'CLOSED'">CLOSED</p>
					</div>
				</div>
				<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
					<a href="/estore"><h3 class="pinlist_head" (click)="selectOutlets(outlets.id)">{{outlets.seo_url}} </h3></a>
					<div class="pinhead_outer">
						<span class="pinlistspan"><img src="/assets/images/location6.png" class="" alt="image"></span>
						<p class="pinlist_det">{{outlets.address}}<br/>{{outlets.csm_state_name}} {{outlets.pin}}</p>
					</div>
				</div>
			</div>

			<div class="row row_custom" *ngIf="indexOfelement%2 == 1"  >
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pinlist_col">
					<div class="pinlistimg_outer">
						<img src="{{outlets.shop_image}}" class="img-thumbnail" alt="image">
						<p class="opendet">OPEN</p>
					</div>
				</div>
				<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
					<a href="/estore"><h3 class="pinlist_head"  (click)="selectOutlets(outlets.id)">{{outlets.seo_url}}</h3></a>
					<div class="pinhead_outer">
						<span class="pinlistspan"><img src="/assets/images/location6.png" class="" alt="image"></span>
						<p class="pinlist_det">{{outlets.address}}<br/>{{outlets.csm_state_name}}  {{outlets.pin}}</p>
					</div>
				</div>
			</div>

		</div>  
	</div>

	<!-- all outlets-->
	<div class="row row_custom pinpopup_midrow all_outlts"  *ngIf="allOutlets">Our Outlets</div>

	<div class="infoMsg process_desk"   *ngIf="allOutlets"> 
							<span >Choose an outlet to start Shopping</span>
				    </div>



	<!-- outlet details -->
	<div class="row row_custom pinpopup_midrow all_outlts_details" *ngIf="outletDetails">{{distric_name}} Outlets</div>
	<div class="row row_custom pinpopup_midrow set_border" style="background-color: #FFEFC7;"  *ngIf="outletDetails">
		<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pinpopup_left set_border"  *ngFor="let detail of outletDetails;let indexOfelement=index;"> 
			<div class="row row_custom" *ngIf="indexOfelement%2 == 0"> 


				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pinlist_col">
					<div class="pinlistimg_outer">
					<img src="{{detail.info.shop_image}}" class="img-thumbnail" alt="image">
					<p class="opendet" *ngIf="detail.info.shop_status  == 'OPEN'">OPEN</p>
					<p class="closedet" *ngIf="detail.info.shop_status  == 'CLOSED'">CLOSED</p>
					</div>
				</div>


				<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
					<a href="/estore"><h3 class="pinlist_head" (click)="selectOutlets(detail.shop_id)">{{detail.outlet_location}} </h3></a>
					<div class="pinhead_outer">
						<span class="pinlistspan"><img src="/assets/images/location6.png" class="" alt="image"></span>
						<p class="pinlist_det">{{detail.info.address}}<br/>{{detail.info.csm_state_name}} {{detail.info.pin}}</p>
					</div>
				</div>


				<!--<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
					<a href="/estore"><h3 class="pinlist_head" (click)="selectOutlets(detail.shop_id)">{{detail.outlet_location}} </h3></a> 
				</div>-->
			</div>

			<div class="row row_custom" *ngIf="indexOfelement%2 == 1"  > 
				<!--<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
					<a href="/estore"><h3 class="pinlist_head"  (click)="selectOutlets(detail.shop_id)">{{detail.outlet_location}}</h3></a> 
				</div>-->
				<div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 pinlist_col">
					<div class="pinlistimg_outer">
					<img src="{{detail.info.shop_image}}" class="img-thumbnail" alt="image">
					<p class="opendet" *ngIf="detail.info.shop_status  == 'OPEN'">OPEN</p>
					<p class="closedet" *ngIf="detail.info.shop_status  == 'CLOSED'">CLOSED</p>
					</div>
				</div>


				<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
					<a href="/estore"><h3 class="pinlist_head" (click)="selectOutlets(detail.shop_id)">{{detail.outlet_location}} </h3></a>
					<div class="pinhead_outer">
						<span class="pinlistspan"><img src="/assets/images/location6.png" class="" alt="image"></span>
						<p class="pinlist_det">{{detail.info.address}}<br/>{{detail.info.csm_state_name}} {{detail.info.pin}}</p>
					</div>
				</div>
			</div>

		</div>   
	</div>
	<div class="row row_custom pinpopup_midrow "  *ngIf="outletDetails">

	</div>


	<!-- Outlet Details --->


	<div class="row row_custom pinpopup_midrow " style="margin-top:10px" >
		<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pinpopup_left"  *ngFor="let alloutlet of allOutlets;let indexOfelement=index;"> 
			<div class="row row_custom" *ngIf="indexOfelement%2 == 0"> 
				<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
					 <h3 class="pinlist_head" (click)="getOutletDetails(alloutlet.id,alloutlet.outlet_city_name)">{{alloutlet.outlet_city_name}} </h3> 
					<!--<div class="pinhead_outer">
						<span class="pinlistspan"><img src="/assets/images/location6.png" class="" alt="image"></span>
						<p class="pinlist_det">{{alloutlet.address}}<br/>{{alloutlet.csm_state_name}} {{alloutlet.pin}}</p>
					</div>-->
				</div>
			</div>

			<div class="row row_custom" *ngIf="indexOfelement%2 == 1"  > 
				<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
					 <h3 class="pinlist_head"  (click)="getOutletDetails(alloutlet.id,alloutlet.outlet_city_name)">{{alloutlet.outlet_city_name}}</h3> 
					<!--<div class="pinhead_outer">
						<span class="pinlistspan"><img src="/assets/images/location6.png" class="" alt="image"></span>
						<p class="pinlist_det">{{alloutlet.address}}<br/>{{alloutlet.csm_state_name}}  {{alloutlet.pin}}</p>
					</div>-->
				</div>
			</div>

		</div>  
	</div>

	<!---- all outlets -->
	<div class="process_device" *ngIf="isShowOutlets"  style="background-color: #FFEFC7;"  >
		<div class="row row_custom pinpopup_midrow1 " >
			<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pinpopup_left"   *ngFor="let outlets of shops;let indexOfelement=index;">
				<a href="/estore"><h3 class="pinlist_head"  (click)="selectOutlets(outlets.id)">{{outlets.seo_url}}</h3></a>
			</div> 
		</div>  
	</div>


	


	<div class="process_device"  > 
		<div class="row row_custom pinpopup_midrow1 all_outlts" style="margin-bottom:10px" *ngIf="allOutlets">Our Outlets</div>
		<div class="infoMsg"   *ngIf="allOutlets"> 
							<span >Choose an outlet to start Shopping</span>
				    </div>



	 <div class="process_device"   *ngIf="outletDetails"> 
		<div class="row row_custom pinpopup_midrow1  all_outlts_details" *ngIf="outletDetails" style="border:0px!important"    >{{distric_name}} Outlets</div>
		<div class="row row_custom pinpopup_midrow1 set_border" style="background-color: #FFEFC7;"   >
			<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pinpopup_left set_border" *ngFor="let detail of outletDetails;let indexOfelement=index;">
				<a href="/estore"><h3 class="pinlist_head" (click)="selectOutlets(detail.shop_id)">{{detail.outlet_location}} </h3></a> 
			</div> 
		</div>

		<div class="row row_custom pinpopup_midrow1 extra_bot" *ngIf="outletDetails"> 
		</div> 
	</div>


		<div class="row row_custom pinpopup_midrow1 "  >
			<div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 pinpopup_left"   *ngFor="let alloutlet of allOutlets;let indexOfelement=index;">
				<h3 class="pinlist_head"   (click)="getOutletDetails(alloutlet.id,alloutlet.outlet_city_name)">{{alloutlet.outlet_city_name}}</h3>
			</div> 
		</div>
		
	</div>
	
  <!--<div class="pin_bottombg" *ngIf="isShowOutlets">
	  <div class="row row_custom">
		  <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
		  <h3 class="pinbottom_head text-center">Checkout Our Other Outlets</h3>
				<div class="input-group pinbot_input">
				   <select class="form-control outlet_select"  id="outletselect" name="outletselect" (change)="selectChangeHandler($event)">
						  <option value="">Select Outlet</option>
						<option *ngFor="let other_store of other_outlets" [value]="other_store.id">{{other_store.seo_url}}</option>
				   </select>
					<div class="input-group-append">
						 <button class="btn btn-pinbot" type="submit">OK</button>
					</div>
			  </div>
		  </div>
		   <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 process_col">
				   <p class="pinbottom_det">Not covered under any outlets?&nbsp; <a href="#"><span class="pinlistspan"><img src="/assets/images/cart6.png" class="" alt="image"></span>&nbsp;Go to Eshop</a> </p>
		  </div>
	  </div>
  </div>-->

</div>
</div>