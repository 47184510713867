import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryService } from '../country.service';

@Component({
  selector: 'app-my-agm',
  templateUrl: './my-agm.component.html',
  styleUrls: ['./my-agm.component.css']
})
export class MyAgmComponent implements OnInit {

  lat = 12.8842699;
  lng = 74.8487253;

  mapType = 'roadmap';
  postcode:any;
  sub;
  coordinates:any;
  zoom:number;
  pincode:any;
  mapFrm: FormGroup;  
  allOutlets:any;

  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private CountryService: CountryService

  ) { }

  ngOnInit(): void {
    this.zoom=8;
    this.pincode=='all';
     this.sub = this.activatedRoute.paramMap.subscribe(params => { 
          this.pincode = params.get('pincode');  
          console.log('pin'+this.pincode)
    }); 


    this.mapFrm = this.formBuilder.group({
      pinsearch_text: ['', [Validators.required, Validators.pattern("^[1-9][0-9]{5}$")]], 
    });
  }

  ngAfterViewInit():void{ 

   if(this.pincode=='all' || this.pincode==''){ 
       this.CountryService.getGooglemapAllOutlets(this.pincode).subscribe(
          data => {  
             this.coordinates=data;  
             this.allOutlets=data;  
             console.log(data);
          }); 
   }  


    /* this.CountryService.getGooglemapNearestLocation(this.pincode).subscribe(
      data => { 
        console.log(data); 
        this.coordinates=data; 
        this.lat=this.coordinates[0].center_latitude;
        this.lng=this.coordinates[0].center_longitude;
      }); */

  }
  markerClicked(e){

  }

  onMouseOver(infoWindow, $event: MouseEvent) {
        infoWindow.open();
    }

  onMouseOut(infoWindow, $event: MouseEvent) {
        infoWindow.close();
    }

  trim(myString) { 
    console.log(myString);
    this.mapFrm.patchValue({
      pinsearch_text: myString.replace(" ",''),
    }); 
    return myString.replace(" ",'-');  
    return myString; 
  }


   get f() { return this.mapFrm.controls; }

   loadGoogleMap(){

     this.pincode = this.mapFrm.get('pinsearch_text').value; 

     this.CountryService.getGooglemapNearestLocation(this.pincode).subscribe(
      data => { 
        console.log(data); 
        this.coordinates=data; 
        this.zoom=10; 
        this.lat=this.coordinates[0].center_latitude;
        this.lng=this.coordinates[0].center_longitude;
      });   

   }

   loadGoogleMapOnClickOfInfo(pin,marker){ 
    // alert(pin);
    //console.log(marker);
    this.coordinates=[];

        this.coordinates[0]=marker; 
        this.zoom=10; 
        console.log(this.coordinates);

    /* this.CountryService.getGooglemapNearestLocation(pin).subscribe(
      data => { 
        console.log(data); 
        this.coordinates=data; 
        this.zoom=10; 
        this.lat=this.coordinates[0].center_latitude;
        this.lng=this.coordinates[0].center_longitude;
      });   */

   }

}
