import { Component,  OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DirectLoginModalComponent } from '../direct-login/login/login.component';
import { AuthStateService } from '../shared/auth-state.service';
import { AuthService } from '../shared/auth.service';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { CartService } from '../cart.service';
import { TokenService } from '../shared/token.service';
import { CountryService} from '../country.service';

@Component({
  selector: 'app-direct-login',
  templateUrl: './direct-login.component.html',
  styleUrls: ['./direct-login.component.css']
})
export class DirectLoginComponent implements OnInit {
  shop:any ='';	
  userShop:any = '';
  user:any ='';
  store_image:any = "/assets/images/store.png";
  eshop_image:any = "/assets/images/eshop.png";
  error:any = '';
  store_image_device:any = "/assets/images/shopinstore.png";
  eshop_image_device:any = "/assets/images/eeshop.png";
  shopDetails:any = [];
  shopName:any ='';
  currentShopName:any='';
  shopCurrentDetails:any = [];
  isSignedIn: boolean;
  store:any =[];
  storename:string = "";
  address:string = "";
  pincode = "";
  storeTiming:string = "";
  shopStatus:string = "CLOSED";
  shopStatusColor:string = "red";
  shopOpeningTiming:number = Date.now();
  shopCloseTiming:number = Date.now();
  now: number = Date.now();
  categories: any = [];
  isLoading:boolean=false;
  shopphone:string ="";
  shopOpClseStatus:boolean = false;
  constructor( 
      private activatedRoute: ActivatedRoute,
      public authService: AuthService,
      private auth: AuthStateService,
      private confirmationDialogService: ConfirmationDialogService,
      private cartService: CartService,
      public router: Router,
      private token: TokenService,
      private CountryService: CountryService,
    ){ }

  ngOnInit(): void {
    this.isLoading = true;
    this.activatedRoute.paramMap.subscribe(params => {
      this.shop = params.get('shop');

      if(this.shop != ''){
        localStorage.setItem('estore', this.shop);
        this.getShopDetails();
      }else{
        localStorage.setItem('estore', '');
      }
    });
    this.getStore();
    this.auth.userAuthState.subscribe(
      data => {
        this.isSignedIn = data;
        this.cartService.checkUserCurrentShop().subscribe(
          data => {
            this.userShop = data;   
            this.getStore();
            this.getCategory();
            this.isLoading = false;
            //if( (this.shop != "") &&(this.shopName != "") && (this.userShop.toLowerCase() != this.shop.toLowerCase())){
            if( (this.isSignedIn) && (this.shopName != "") && (this.userShop != "") ){
              let payload = {
                shop: this.userShop
              };
              this.cartService.getShopDetailsSeoUrl(payload).subscribe(
                result => {
                  this.shopCurrentDetails = result;     
                  this.currentShopName = this.shopCurrentDetails.shop_name;
                  if(this.currentShopName  != this.shopName){
                      let payload = {
                        outlet: this.shop
                      };
                      this.authService.changeUserOutlet(payload).subscribe(
                      data => {
                      this.user = data;
                      this.token.handleData(localStorage.getItem('auth_token'));
                      this.auth.setAuthState(true);
                      this.authService.startRefreshTokenTimer();
                      localStorage.removeItem('cart_count');
                      this.router.navigate(['/']);
                    /*this.confirmationDialogService.confirm('', 'Are you sure you want to change your outlet from '+this.userShop+' to '+this.shop+' ?')
                    .then((confirmed) => {
                      if(confirmed === true){
                        let payload = {
                          outlet: this.shop
                        };
                        this.authService.changeUserOutlet(payload).subscribe(
                          data => {
                          this.user = data;
                          this.token.handleData(localStorage.getItem('auth_token'));
                          this.auth.setAuthState(true);
                          this.authService.startRefreshTokenTimer()
                          this.router.navigate(['/']);
                        })
                      }  
                      else
                      {
                        this.shop ="";
                        this.router.navigate(['/']);
                      }
                    })*/
                    });
                  }
                },
                error => {
                  this.error = error;
                }
              )


              
            }
            else{
              this.shop ="";
              this.router.navigate(['/']);
            }
          }
        );
            
      },
      error => {
        this.shop ="";
        //this.router.navigate(['/']);
      },() => {
        
      }
    );
   

  }
  getCategory(){
    
    this.CountryService.getCategory().subscribe(
      data => {
        this.categories = data;
        this.isLoading=false;
      }
    );
  }
  getShopDetails(){
    let payload = {
      shop: this.shop
    };
    this.cartService.getShopDetailsSeoUrl(payload).subscribe(
      result => {
        this.shopDetails = result;     
        this.shopName = this.shopDetails.shop_name;
      },
      error => {
        this.error = error;
      }
    )
  }
  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      result => {
        console.log(result);
        this.store = result;
        this.storename = this.store.seo_url;
        if(this.store.address != "NULL" ){
          this.address = this.store.address;
        }
        this.pincode = this.store.pin;
        this.storeTiming = this.store.shop_timing;
        this.shopOpeningTiming = this.store.opening_time;
        this.shopCloseTiming = this.store.closing_time;
        this.shopphone = this.store.phone;
        this.shopStatus = this.store.shop_status;
        if(this.shopStatus =="OPEN"){
          this.shopStatusColor = "green";
        }else{
          this.shopStatusColor = "red";
        }
        if(this.store.opening_time != "00:00"){
          this.shopOpClseStatus =true;
        }
      },
      error => {
        
      },() => {
      }
    );
  }
}
