<app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>
<div class="container-fluid" *ngIf="!isMaintenance">
    <app-header></app-header>
    <router-outlet></router-outlet>
    <!--<app-footer></app-footer>-->
</div>

<div class="container-fluid" *ngIf="isMaintenance" style="background-color: #000;height:775px;">
    <style>
        .d-none-RU {
            bottom: 0%;
        }

        body {
            background-color: #000;

        }

        .whatsapp_number {
            position: absolute;
            width: 11rem;
            top: 46%;
            left: 45%;
        }

        .whatsapp_icon {
            position: absolute;
            width: 2.6rem;
            top: 51%;
            left: 47.5%;
        }

        .cs_small {
            display: none;
        }

        @media (max-width: 1536px) {
            .whatsapp_number {
                width: 14rem;

            }

            .whatsapp_icon {
                left: 48%;
            }
        }


        @media (min-width:950px) {
            .navbar {
                padding: 0rem 1rem;
            }
        }

        @media (max-width:1280px) {
            .whatsapp_number {
                width: 10rem;
            }
        }

        @media (min-width:769px) and (max-width:896px) {
            .bd-example {
                padding-top: 65px;
            }

            .whatsapp_number {
                width: 10rem;
                top: 41%;
                left: 41%;
            }
        }


        @media (max-width:768px) {
            .bd-example {
                padding-top: 0px;
            }

            .whatsapp_number {
                width: 10rem;
                top: 41%;
                left: 41%;
            }

            #logo img {
                width: 20%;
            }
        }

        @media (max-width:415px) {
            .bd-example {
                padding-top: 30px;
            }

            .whatsapp_number {
                width: 10rem;
                top: 50%;
                left: 30%;
            }

            .cs_large {
                display: none;
            }

            .cs_small {
                display: block;
            }
        }
        .navbar-brand{

            background-image: url(/assets/images/topbar.png);
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            width: 100%;
        }
        .cs_large{
            display:none;
        }
        .cs_small{
            display:block;
        } 
        .containter{
            background-color: #000;
        }
    </style>
    <div class="containter">
        <div class="navbar-brand">
                <a class="logo" id="logo" href="https://bolas.co.in/">
                    <img src="https://bolas.co.in/resource/images/logo-glow-new.png" alt="Logo">&nbsp;
                </a>
        </div>

        <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner cs_large">
                <div class="carousel-item active">
                    <img src="https://bolas.co.in/resource/images/Coming_soon_maintenance.png" class="d-block w-100" alt="...">
                </div>
            </div>
            <div class="carousel-inner cs_small">
                <div class="carousel-item active">
                    <img src="https://bolas.co.in/resource/images/Coming_soon_maintenance.png" class="d-block w-100" alt="...">
                </div>
            </div> 
        </div>
    </div>
</div> 