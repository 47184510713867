import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { of , Observable, Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from './../environments/environment';
export interface CartItem {
  id: number
}

@Injectable({
  providedIn: 'root'
})
export class CartService {
  items = [];
  count = 0;
  simpleObservable = new Subject();
  simpleObservable$ = this.simpleObservable.asObservable();
  constructor(
    private http: HttpClient
  ) { }
  //apiBaseUrl = 'https://bolas.co.in/admin/api/';
  //apiBaseUrl = 'https://bsk.bispltest.in/admin/api/';
  apiBaseUrl = environment.APP_URL;
  getAllProducts() {
    return this.http.get(`${this.apiBaseUrl}product`);
  }
  addToCart(payload) {
    this.count+=1;
    this.simpleObservable.next(this.count);
    return this.http.post(`${this.apiBaseUrl}add-cart`, payload);
  }
  addNewCart(payload) {
    this.count+=1;
    this.simpleObservable.next(this.count);
    return this.http.post(`${this.apiBaseUrl}add-new-cart`, payload);
  }
  getCartItems() {
    return this.http.get(`${this.apiBaseUrl}cart-item`);
  }
  increaseQty(payload) {
    this.count+=1;
    this.simpleObservable.next(this.count)
    return this.http.post(`${this.apiBaseUrl}update-cart`,payload);
  }
  deleteCartItem(id){
    if (this.count > 0) { this.count-=1 };
    this.simpleObservable.next(this.count)
    return this.http.delete(`${this.apiBaseUrl}delete-cart-item/${id}`);
  }
  emptyCart() {
    this.count = 0;
    localStorage.setItem("cart_count", JSON.stringify(this.count));
    return this.http.delete(`${this.apiBaseUrl}empty-cart`);
  }
  getCount(){
    return this.simpleObservable$;
  }
  getCart(): Promise<CartItem[]> {
    return this.http.get(
      `${this.apiBaseUrl}cart-item`, 
      { observe :'response' }
    ).pipe(
      map((httpResponse: HttpResponse<any>) => {
        this.updateCount(httpResponse.body.length); // Don't know how your cardItem[] is formatted
        return [].concat(httpResponse.body);
      })
    ).toPromise();
  }
  getGIftItemStock(payload){
    return this.http.post(`${this.apiBaseUrl}get-cart-gift-item-stock`,payload);
  }
  getCartGiftBoxItem(id){
    return this.http.get(`${this.apiBaseUrl}get-cart-giftbox-items/${id}`);
  }
  addInvoiceAttachment(payload){
    return this.http.post(`${this.apiBaseUrl}add-invoice-attachment`,payload);
  }

  private prodCount: number = 0;
  public prodCountCountChange: Subject<number> = new Subject();
  updateCount(count: number = 0): void {
    this.prodCount = count;
    this.prodCountCountChange.next(this.prodCount);
  }
  getCartItemCount(){
    return this.http.get(`${this.apiBaseUrl}get-cart-count`);
  }
  checkUserCurrentShop() {
    return this.http.get(`${this.apiBaseUrl}check-user-current-shop`);
  }
  getOrderDetails(id: number){
    return this.http.get(`${this.apiBaseUrl}order-details/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  getPaymentKey(){
    return this.http.get(`${this.apiBaseUrl}get-payment-key`);
  }
  getDefaultAddress(){
    return this.http.get(`${this.apiBaseUrl}get-default-address`);
  }
  getDeliveryCharge(payload){
    return this.http.get(`${this.apiBaseUrl}get-delivery-charge/${payload}`,payload);
  }
  getShopDetailsSeoUrl(payload){
    return this.http.post<any>(`${this.apiBaseUrl}get-shop-details-seo`, payload);
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }
}
