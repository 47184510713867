<div class="modal-body xs-12">

	<div class="row row_custom">
		<div class="col-lg-1 col-md-1 col-sm-12 col-xs-12 shoplogin_side">
            <img class="close closedevice" (click)="activeModal.dismiss('Close clicked')" data-dismiss="modal" aria-label="Close" src="/assets/images/close.png">
        </div>
    </div>
    <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 policy_body">
    	<form class="search_form" [formGroup]="searchForm">
		    <div class="modal-body">
				<div class="input-group mb-3">
				 
					<input type="text" class="form-control" placeholder="Search" id="search_text" name="searchname"  formControlName="searchname">
					<div class="input-group-append">
					  <button class="btn btn-warning text-white" (keydown)="search()" (click)="search()" type="submit">Go</button>
					 </div>

				 
				</div>
		    </div>
	    </form>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12 shoplogin_side">

        <img class="close closedesk" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">

    </div>
</div>