import * as $ from 'jquery';
import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CountryService} from '../country.service';
import { AuthStateService } from '../shared/auth-state.service';
import { AuthService } from '../shared/auth.service';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot,ActivatedRoute  } from '@angular/router';
import { ShopRegisterComponent } from '../modals/shop-register/shop-register.component';
import {ChooseOutletsComponent} from 'src/app/modals/choose-outlets/choose-outlets.component';
import { SearchoutletComponent } from 'src/app/modals/searchoutlet/searchoutlet.component';
import { CartItem, CartService } from '../cart.service';
import { GiftService} from '../gift/gift.service';

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.css']
})
export class ShopComponent implements OnInit {
  images = [700, 800, 807].map((n) => `https://picsum.photos/id/${n}/900/500`);
  categories: any = [];
  brands: any = [];
  user:any =[];
  isSignedIn: boolean;
  isShopType:boolean = false;
  store_image:any = "/assets/images/store.png";
  eshop_image:any = "/assets/images/eshop.png";
  
  store_image_device:any = "/assets/images/shopinstore.png";
  eshop_image_device:any = "/assets/images/eeshop.png";
  store:any =[];
  storename:string = "";
  address:string = "";
  pincode = "";
  storeTiming:string = "";

  closeResult = '';
  isLoading:boolean = false;
  shopId:any = "";
  cartCount: number = 0;
  cartItem: Array<CartItem> = [];
  count:any =0;
  errors:any;
  sub;
  shopStatus:string = "CLOSED";
  shopStatusColor:string = "red";
  shopOpeningTiming:number = Date.now();
  shopCloseTiming:number = Date.now();
  now: number = Date.now();
  shopphone:string ="";
  shopOpClseStatus:boolean = false;
  appShopId:any;
  giftbox:any = [];
  myShopId:any = [];
  showShopSelectMsg:boolean = false;
  constructor(
    config: NgbCarouselConfig,
    private CountryService: CountryService,
    public router: Router,
    private auth: AuthStateService,
    public authService: AuthService,
    private modalService: NgbModal,
    private cartService: CartService,
    private activatedRoute: ActivatedRoute,
    private GiftService: GiftService,
  ) { 
    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;
  }

  async ngOnInit() { 
    //alert('ShopComponent');   
    this.getGiftBox();
    $(window).bind("load", function() {
      if($(window).width() == 800){
      $('html, body').animate({scrollTop : 120},700);
     }
     else if($(window).width() == 1280){
      $('html, body').animate({scrollTop : 250},700);
     }
     else if($(window).width() == 768){
      $('html, body').animate({scrollTop : 200},700);
     }
     else if($(window).width() == 1024){
      $('html, body').animate({scrollTop : 200},700);
     }
     else if($(window).width() >= 1300){
         $('html, body').animate({scrollTop : 280},700);
       }
      });
      
      
      $(window).on('resize', function(){
       if($(window).width() == 800){
      $('html, body').animate({scrollTop : 120},700);
     }
     else if($(window).width() == 1280){
      $('html, body').animate({scrollTop : 250},700);
     }
     else if($(window).width() == 768){
      $('html, body').animate({scrollTop : 200},700);
     }
     else if($(window).width() == 1024){
      $('html, body').animate({scrollTop : 200},700);
     }
     else if($(window).width() >= 1300){
        $('html, body').animate({scrollTop : 280},700);
       }
      });
    this.auth.userAuthState.subscribe(
      data => {
        this.isSignedIn = data;
        this.sub = this.activatedRoute.paramMap.subscribe(params => {
          console.log(params);
        }); 
        this.setCartCount();
        
        //this.getCategory();

        this.appShopId=localStorage.getItem('auth_token');
        if(this.appShopId  && this.appShopId!='undefined'){
            this.getCategory(); 
        }else{ 
            this.getCategoryWitoutTokenInfo();
        } 

      },
      error => {
        //
      },() => {
        
      }
    );
    this.getStore();
    this.cartItem = await this.cartService.getCart();
    this.cartCount =this.cartItem.length;
    this.cartService.prodCountCountChange.subscribe(
      newProdCount =>{
        console.log(newProdCount);
        this.cartCount = newProdCount
      }
    );

    
  }
  setCartCount(){
    
    this.cartService.getCartItemCount().subscribe(
      data => {
        this.count = data;
       
        localStorage.setItem("cart_count", JSON.stringify(this.count));
      }
    ); 
    
  }
  ngAfterViewInit(){

    if(!this.isSignedIn ){
         this.myShopId=localStorage.getItem('appShopId');  
         if(this.myShopId === null){ 
             this.showShopSelectMsg=true;
          }else{ 
              this.showShopSelectMsg=false;
          }
    }else{
      this.showShopSelectMsg=false;
    }
    this.isLoading=true;
    this.CountryService.getUserStore().subscribe(
      data => {
        this.shopId             = data;
        this.isLoading          = false;
        this.store_image        = "/assets/images/store.png";
        this.eshop_image        = "/assets/images/eshop.png";

        this.store_image_device = "/assets/images/shopinstore.png";
        this.eshop_image_device = "/assets/images/eeshop.png";

        localStorage.setItem('purchase_type', "shop");   
      }
    );
     this.authService.userAuthValideToken().subscribe(
      result => {
        this.getStore();
      },
      error => {

      }
      );
  }
  changeShopType(type){
    const payload = {
      shop_type: type
    };
    this.CountryService.userStoreLog(payload).subscribe(
      data => {
        this.reloadComponent();
      },
      error => {
        this.errors = error.error;
        this.showShopRegisterModel();
      },() => {
        
      }
    );
    this.authService.profileUser().subscribe(
      data => {
        this.user = data;
        if(this.user.shop_id === 4){
          this.showShopRegisterModel();
        }
      }
    );
    localStorage.setItem('purchase_type', type);
  }
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
  showShopRegisterModel(){
    this.modalService.open(ShopRegisterComponent, {
      centered: true, 
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'login_modal'
    }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      result => {
        console.log(result);
        this.store = result;
        this.storename = this.store.seo_url;
        if(this.store.address != "NULL" ){
          this.address = this.store.address;
        }
        this.pincode = this.store.pin;
        this.storeTiming = this.store.shop_timing;
        this.shopOpeningTiming = this.store.opening_time;
        this.shopCloseTiming = this.store.closing_time;
        this.shopphone = this.store.phone;
        this.shopStatus = this.store.shop_status;
        if(this.shopStatus =="OPEN"){
          this.shopStatusColor = "green";
        }else{
          this.shopStatusColor = "red";
        }
        if(this.store.opening_time != "00:00"){
          this.shopOpClseStatus =true;
        }
      },
      error => {
        
      },() => {
      }
    );
  }
  chooseOutlet(){

    const modalRef = this.modalService.open(ChooseOutletsComponent, { 
                                                centered: true, 
                                                size: 'xl',
                                                backdrop: 'static',
                                                keyboard: false,
                                                windowClass: 'register_modal' 
                                          });
    modalRef.componentInstance.id = 2; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
  getCategory(){
    
    this.CountryService.getCategory().subscribe(
      data => {
        this.categories = data;
        this.isLoading=false;
        if(this.showShopSelectMsg){ 
            this.showShopSelectMsg=false;   
            this.reloadPage();
        }
        this.getStore();
      }
    );

  }

  getCategoryWitoutTokenInfo(){
    
    this.CountryService.getCategorywithoutToken().subscribe(
      data => {
        this.categories = data;
        this.isLoading=false;
      }
    );
  }


  getGiftBox(){ 
    this.GiftService.getAllGiftBox().subscribe(
        data => {
          
          this.giftbox = data;
        }
      );
  }

  openSearcOutletModal(is_close=false) {
    //history.pushState(null, null, '');
    const modalRef = this.modalService.open(SearchoutletComponent,{ 
                                            centered: true, 
                                            size: 'sm',
                                            backdrop: 'static',
                                            keyboard: false,
                                            windowClass: 'search_outlet_modal'
                                          });
    modalRef.componentInstance.id = 1; // should be the id
    modalRef.componentInstance.is_close = is_close; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }


  reloadPage1(){  
     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/' ]));
  } 

  async reloadPage(): Promise<boolean> {
    await this.router.navigateByUrl('.', { skipLocationChange: true });
    return this.router.navigateByUrl('/');
  }

}
