import { Component, OnInit, Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CountryService} from '../country.service';
import { ChangeOutletComponent } from '../change-outlet/change-outlet.component';
import { OrderService } from '../order.service';
import { SearchoutletComponent } from 'src/app/modals/searchoutlet/searchoutlet.component';
@Component({
  selector: 'app-myaccount',
  templateUrl: './myaccount.component.html',
  styleUrls: ['./myaccount.component.css']
})
export class MyaccountComponent implements OnInit {
  user: any = [];
  stores: any = [];
  countries: any = [];
  states: any = [];
  cities: any=[];
  profileForm: FormGroup;
  submitted = false;
  errors = null;
  backErrors = null;
  showStoreDiv:boolean = false;
  storeCount;
  message = null;
  editNameMode:boolean = false;
  editImageMode:boolean = false;
  store:any =[];
  storename:string = "";
  imageSrc: any = "assets/images/myaccprofile.png";
  filedata : any;
  imgUploaded:boolean = false;
  fileToUpload: any;
  myaddress:any =[];
  orders:any = [];
  displayNewAddress:boolean = false;
  hasPassword:boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private modalService: NgbModal,
    private CountryService: CountryService,
    public authService: AuthService,
    private cd: ChangeDetectorRef,
    private OrderService: OrderService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getUser();
    this.getStore();
    this.getUserOrders();
    this.CountryService.getCountries().subscribe(
      data => this.countries = data
    );
    this.CountryService.getAllStates().subscribe(
      data => this.states = data
    );
    this.CountryService.getAllTown().subscribe(
      data => this.cities = data
    );
    this.CountryService.getStores().subscribe(
      data => this.stores = data
    );
    this.CountryService.getStoresCount().subscribe(
      data => {
        this.storeCount = data;
        if(this.storeCount === 0){
          this.showStoreDiv = false;
        }else{
          this.showStoreDiv = true;
        }
      }
    );
    
  }
  getUser(){
    this.authService.profileUser().subscribe(
      data => {
        this.user = data;
       // console.log(this.user);
        this.imageSrc = this.user.profile_photo_path;
        //console.log(this.user.password);
        if(this.user.userpsflag=="yes"){ /*password */
           this.hasPassword = true;
        }else{          
          this.hasPassword = false;
        }
        this.profileForm.patchValue({
            email: this.user.email,
            phone: this.user.phone,
            address:this.user.address,
            pin:this.user.pin,
            country_id:this.user.country_id,
            state_id:this.user.state_id,
            town_id:this.user.town_id
        });
      });
  }
  createForm(){
    this.profileForm = this.formBuilder.group({
      email: [''],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      file: ['']
    });
  }
  onChangeCountry(countryId: number) {
    if (countryId) {
      this.CountryService.getStates(countryId).subscribe(
        data => {
          this.states = data;
          this.cities = null;
        }
      );
    } else {
      this.states = null;
      this.cities = null;
    }
  }
  getMyAddressUser(){
    this.displayNewAddress = true;
    this.CountryService.myAddress().subscribe(
      data => {
        this.myaddress = data;
        console.log(this.myaddress.length);
        if(this.myaddress.length == 1){
          this.displayNewAddress = false;
        }else{
          this.displayNewAddress = true;
        }
      }
    );
  }
  getUserOrders(){
    this.OrderService.getMyOrder().subscribe(
      data => {
        this.orders = data;
        if(this.orders.length == 0){
          this.displayNewAddress = false;
        }else{
          this.displayNewAddress = true;
        }
        //localStorage.setItem("cart_count", JSON.stringify(0));
      }
    );
  }
  onChangeState(stateId: number) {
    if (stateId) {
      this.CountryService.getCities(stateId).subscribe(
        data => this.cities = data
      );
    } else {
      this.cities = null;
    }
  }

  get f() { return this.profileForm.controls; }
    isFieldValid(field: string) {
    return !this.profileForm.get(field).valid && this.profileForm.get(field).touched;
  }
  onSubmit() {
    this.submitted = true;
    if(this.profileForm.invalid){
      this.scrollToError();
      return;
    }
    this.CountryService.profileUpdate(this.profileForm.value).subscribe(
      result => {
        this.message = result.message;
      },
      error => {
        this.errors = error.error;
      },() => {
        setTimeout(()=>this.reloadComponent(),5000);
      }
    );

  }
  reloadComponent() {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
  onReset(){
    this.profileForm.reset();
  }
  changeOutletModal(){
    const modalRef = this.modalService.open(ChangeOutletComponent, { 
          centered: true, 
          size: 'xl',
          backdrop: 'static',
          keyboard: false,
          windowClass: 'register_modal' 
    });
    modalRef.componentInstance.id = 2; // should be the id

    modalRef.result.then((result) => {
    console.log(result);
    }).catch((error) => {
    console.log(error);
    });
  }
  
  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      result => {
        this.store = result;
        console.log(this.store);
        this.storename = this.store.seo_url
      },
      error => {
        
      },() => {
      }
    );
  }
  editImage(){
    this.editImageMode = true;
  }
  handleFileInput(event) {
    const reader = new FileReader();
 
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
  
      reader.onload = () => {
        this.profileForm.patchValue({
          file: reader.result
       });
        this.imageSrc = reader.result;
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    console.log("aa:", firstElementWithError);

    this.scrollTo(firstElementWithError);
  }

    openSearcOutletModal(is_close=false) {
    //history.pushState(null, null, '');
    const modalRef = this.modalService.open(SearchoutletComponent,{ 
                                            centered: true, 
                                            size: 'sm',
                                            backdrop: 'static',
                                            keyboard: false,
                                            windowClass: 'search_outlet_modal'
                                          });
    modalRef.componentInstance.id = 1; // should be the id
    modalRef.componentInstance.is_close = is_close; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
}
