<div class="modal-body">

    <div class="row row_custom">

        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 shoplogin_side">
            <!--<img class="close closedevice" (click)="activeModal.dismiss('Close clicked')" data-dismiss="modal" aria-label="Close" src="/assets/images/close.png">-->
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 shoplogin_main">

            <h3 class="shoplogin_head">Reset Password</h3>

            <form [formGroup]="resetPasswordForm">

                <div class="">
                    <div>
                        <label class="shoplogin_label" for="uname">Email:</label>

                        <input class="shoplogin_text" type="text" placeholder="Email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Please enter valid email</div>

                        </div>
                        <div *ngIf="errors?.email" class="backend_error">
                            {{ errors?.email }}
                        </div>
                    </div>
					<div>
                        <label class="shoplogin_label" for="uname">Mobile*:</label>

                        <input class="shoplogin_text" type="text" placeholder="Mobile" name="phone" formControlName="phone" (keyup)="trim($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                            <div *ngIf="f.phone.errors.required">Mobile is required</div>
                            <div *ngIf="f.phone.errors.pattern">Please enter valid number</div>

                        </div>
                        <div *ngIf="errors?.phone">
                            {{ errors?.phone }}
                        </div>
                    </div>
					<div>
                        <label class="shoplogin_label" for="uname">Password*:</label>

                        <input class="shoplogin_text" type="password" placeholder="Password" name="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" onkeypress="return event.charCode != 32">
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
	                        <div *ngIf="f.password.errors.required">Password is required</div>
	                        <div *ngIf="f.password.errors.minlength">Minimum 6 characters required</div>
	                        <div *ngIf="f.password.errors.pattern">Your password must contain at least one uppercase, one lowercase, one special character and one number <br/>Note: Special characters allowed @$!%*#?& </div>
                            <div *ngIf="f.password.errors?.noWhiteSpace">Whitespace is not allowed</div>
	                    </div>
                    	<div *ngIf="errors?.password" class="backend_error">{{ errors?.password }}</div>
                    </div>
					<div>
                        <label class="shoplogin_label" for="password_confirmation">Confirm password*</label>
	                    <input type="password" formControlName="password_confirmation" placeholder="Enter Confirm Password" class="shoplogin_text required" [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" onkeypress="return event.charCode != 32" />
	                    <div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
	                        <div *ngIf="f.password_confirmation.errors.required">Confirm Password is required</div>
	                        <div *ngIf="f.password_confirmation.errors.mustMatch">Passwords must match</div>
	                    </div>
	                    <div *ngIf="errors?.password_confirmation" class="backend_error">{{ errors?.password_confirmation }}</div>
                    </div>
                    <input type="hidden" formControlName="token" name="token"  value="{{token}}">
                    <button class="shoplogin_button" type="submit" (click)="resetPassword()">Reset</button>


                    <div *ngIf="errors?.error" class="alert alert-danger mt-3">
                        {{ errors?.error }}
                    </div>
                    <div *ngIf="result" class="alert alert-success mt-3">
                        {{ result }}
                    </div>

                </div>

            </form>

        </div>

        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 shoplogin_side">

            <!--<img class="close closedesk" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">-->

        </div>

    </div>

</div>
