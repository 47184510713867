<div class="modal-body">

    <div class="row row_custom">

        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 shoplogin_side">
            <!-- <img class="close closedevice" (click)="activeModal.dismiss('Close clicked')" data-dismiss="modal" aria-label="Close"   src="/assets/images/close.png"> -->
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 shoplogin_main">

            <h3 class="shoplogin_head">LOGIN</h3>

            <!-- <p class="shoplogin_det">Happy to see you again!</p> -->

            <form [formGroup]="loginForm">

                <div>
				    <div *ngIf="showMobileDiv">
                        <div>
                            <label appForm class="shoplogin_label" for="uname">Mobile:</label>

                            <input class="shoplogin_text" id="mobile" type="text" placeholder="Enter Mobile Number" name="mobile" formControlName="mobile" (keyup)="trim($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" (keyup)="getNameByMobile($event)">
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors.required">Mobile Number is required</div>
                                <div *ngIf="f.mobile.errors.pattern">Please enter valid mobile number</div>

                            </div>
                            <div *ngIf="errors?.mobile">
                                {{ errors?.mobile }}
                            </div>
                        </div>
                        <div>
                            <label class="shoplogin_label" for="uname">Name:</label>

                            <input class="shoplogin_text" id="mobile" type="text" placeholder="Enter Your Name" name="name" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Name is required</div>
                                <div *ngIf="f.name.errors.pattern">Please enter valid name</div>
                            </div>
                            <div *ngIf="errors?.name">
                                {{ errors?.name }}
                            </div>
                        </div>
                    </div>
                    <div class="send-whatsapp-sms-button" *ngIf="isShownSendOtp">
                        <button class="shoplogin_button sms-otp" [ngClass]="{'buttonDisable':formSumbit}" type="submit" (click)="sendOtp('whatsapp')" *ngIf="isShown"><span><img class="inline_logo" src="/assets/images/whatsapp.png" alt="logo"></span>&nbsp;&nbsp;Whatsapp OTP</button>

                        <button class="shoplogin_button  whatsapp-otp" [ngClass]="{'buttonDisable':formSumbit}" type="submit" (click)="sendOtp('sms')" *ngIf="isShown"><span><img class="inline_logo" src="/assets/images/msg.png" alt="logo"></span>&nbsp;&nbsp;SMS OTP</button>
                    </div>
                    <div style="text-align: center;" *ngIf="isShownOr"><span>OR</span></div>

                    <button class="shoplogin_button" type="submit" (click)="usePassword()" *ngIf="isShownPassword">Use Password</button>
                    
                    <div class="otp_validation" *ngIf="!isShown">
                        <label class="shoplogin_label" for="otp">OTP:</label>

                        <input class="shoplogin_text" id="otp" type="text" placeholder="Enter OTP" name="otp" formControlName="otp" [ngClass]="{ 'is-invalid': submitted && f.otp.errors }">
                        <div *ngIf="submitted && f.otp.errors" class="invalid-feedback">
                            <div *ngIf="f.otp.errors.required">OTP is required</div>
                            <div *ngIf="f.otp.errors.minlength">Minimum 6 characters required</div>
                            <div *ngIf="f.otp.errors.pattern">Please add a valid otp</div>
                        </div>
                        <div *ngIf="errors?.otp" class="backend_error">
                            {{ errors?.otp }}
                        </div>
                        <button class="shoplogin_button" [ngClass]="{'buttonDisable':formSumbit}" type="submit" (click)="loginWithShop()">VERIFY OTP</button>
                         <button class="shoplogin_button " type="button" *ngIf="showResendOtp" (click)="resendOtp()">RESEND OTP</button>         
                    </div>

                    <div class="otp_validation" *ngIf="isShownPasswordDiv">
                        <label class="shoplogin_label" for="email">Email/Mobile:</label>

                        <input class="shoplogin_text" id="email" type="text"  placeholder="Enter Email/Mobile Number" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email/mobile number is required</div>
                            <div *ngIf="f.email.errors.pattern">Please enter valid email number</div>
                        </div>
                        <div *ngIf="errors?.mobile">
                            {{ errors?.email }}
                        </div>

                        <label class="shoplogin_label" for="password">Password:</label>

                        <input class="shoplogin_text" type="password" placeholder="Enter Password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors}" onkeypress="return event.charCode != 32">
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors?.required">Password is required</div>
                            <div *ngIf="f.password.errors?.minlength">Minimum 6 characters required</div>
                            <div *ngIf="f.password.errors?.pattern">Your password must contain at least one uppercase, one lowercase, one special character and one number</div>
                            <div *ngIf="f.password.errors?.noWhiteSpace">Whitespace is not allowed</div>
                        </div>
                        <div *ngIf="errors?.mobile">
                            {{ errors?.password }}
                        </div>

                        <button class="shoplogin_button" [ngClass]="{'buttonDisable':formSumbit}" type="button" (click)="loginUsingPassword()">Login</button>
                    </div>

					<input type="hidden" id="outlet" name="outlet" formControlName="outlet" />
                    <div *ngIf="errors?.error" class="alert alert-danger mt-3">
                        {{ errors?.error }}
                    </div>
                    <div *ngIf="result?.message" class="alert alert-success mt-3">
                        {{ result?.message }}
                    </div>
                    <span *ngIf="isShownForgotPassword"><p class="shoplogin_forget"><a (click)="openForgetPassword()">Forgot password?</a></p></span>
                </div>

            </form>

        </div>

    </div>

</div>