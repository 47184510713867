<app-sub-header></app-sub-header>
 


<div class="container_search process_desk">


    <div class="store_section">

        <ul class="nav nav-tabs" id="shop-tabs" role="tablist">

            <li class="nav-item" id="backarrow">

                <a class="nav-link" (click)="goBack()"><img class="" src="/assets/images/arrow12.png" alt="logo"></a>

            </li>

            <!--<li class="nav-item" id="prodtab">

                <a class="nav-link" data-toggle="tab" href="#productlist">
                <img class="noshop_logo" src="/assets/images/products_hover0.png" alt="logo"><img class="acshop_logo" src="/assets/images/products0.png" alt="logo">&nbsp;&nbsp;
                </a> 
                
            </li>
			
            <li class="nav-item" id="listbrandtab">

                <a class="nav-link active" data-toggle="tab" href="#brandlist"><img class="acshop_logo" src="/assets/images/brands0.png" alt="logo"><img class="noshop_logo" src="/assets/images/brands_hover0.png" alt="logo">&nbsp;Brands&nbsp;</a>
            </li> -->

            <li class="nav-item" id="searchtab">
                <form class="search_form" [formGroup]="searchForm">
                    <div class="input-group">

                        <input type="text" class="form-control sale_search" placeholder="Search Products" (keydown)="search()" id="search_text" name="searchname" formControlName="searchname">

                        <div class="input-group-append">

                            <button class="btn btn-sale" type="submit"><span class="icon ico_search"  (click)="search()"><img src="/assets/images/shopsearch.png"/></span></button>

                        </div>

                    </div>
                </form>
  
            </li>



        </ul>



        <!-- Tab panes -->

        <div class="search_contentinner">

            <div class="tab-content list_content">



                <div id="productlist" class="tab-pane active">



                    <div class="row row_custom" *ngIf="!showSearchDesc">

                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">

                            <h3 class="searchresult_head">Search results for "{{searchPost}}"</h3> 

                        </div>

                    </div>



                    <div class="row row_custom searchlist_row" *ngIf="!showNoProducts">

                        <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 searchlist_col" *ngFor="let product of products">

                            <div class="listitem_inner">

                                <a [routerLink]="['/product-details', product.seo_url]"><img src="{{product.product_images}}" class="mx-auto d-block productlist_img" alt="product">

                                    <p class="listitem_desc">{{product.product_name}} <br> {{product.category_name}} </p>
                                </a>
                                <p class="listitem_cost">Rs. {{product.product_price}}</p>
                                <p class="mrp-price"><del>Rs. {{ product.cskd_mrp}}</del></p>

                                <button (click)="buyNow(product.id, 1)" type="submit" class="probuy">BUY NOW</button>
                                <button (click)="addItemToCart(product.id, 1)" type="submit" class="proadd">ADD TO CART</button>

                            </div>

                        </div>

                    </div>
                    <div class="row row_custom itemlist_row" *ngIf="showNoProducts">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 listitem_col">
                            <div class="listitem_inner product_text">
                                No results found
                            </div>
                        </div>
                    </div>
                </div>



                <div id="brandlist" class="tab-pane fade">

                    

                </div>

            </div>

        </div>

    </div>

</div>

<div class="container_shop process_device">

    <!--<ul class="nav nav-tabs" id="shop-tabs" role="tablist">-->

       <!--<li class="nav-item" id="searchtab">

        <form class="search_form" [formGroup]="searchForm">
            <div class="input-group">

                <input type="text" class="form-control sale_search1" placeholder="Search Products" (keyup)="search()" id="search_text1"  name="searchname" formControlName="searchname">

                <div class="input-group-append">

                    <button class="btn btn-sale" type="submit" (click)="search()"><i class="fa fa-search"></i></button>

                </div>

            </div>
        </form>
  
            </li> -->

        <!--<li class="nav-item meshoptab" id="mobtab1">



            <img class="img-fluid mx-auto d-block imgm_shopstore" src="/assets/images/shopinstore.png" alt="logo" data-src="/assets/images/shopinstore.png" data-hover="/assets/images/shopinstore_ho.png">



        </li> -->

        <!-- <li class="nav-item meshoptab" id="mobtab2">

            <img class="img-fluid mx-auto d-block imgm_shopstore2" src="/assets/images/eeshop.png" alt="logo" data-src="/assets/images/eeshop.png" data-hover="/assets/images/eeshop_ho.png">

        </li> -->

    <!-- </ul> -->

    <div class="store_section">

        <!--<ul class="nav nav-tabs" id="shop-tabs2" role="tablist">

            <li class="nav-item" id="brandtab1">

                <a class="nav-link active" data-toggle="tab" href="#brand1"><img class="acshop_logo" src="/assets/images/mbrands-hover.png" alt="logo"><img class="noshop_logo" src="/assets/images/mbrands.png" alt="logo">&nbsp;Brands</a>

            </li>

            <li class="nav-item" id="prodtab1">

                <a class="nav-link" data-toggle="tab" href="#product1"><img class="acshop_logo" src="/assets/images/mproducts.png" alt="logo"><img class="noshop_logo" src="/assets/images/mproducts-hover.png" alt="logo">&nbsp;Products</a>

            </li>

        </ul> -->





        <!-- Tab panes -->
        <!--<div class="device_category">
            <div id="carousel_catmenu" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="row row_shop1 device_categoryrow">
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 device_categorycol">
                                <a href="#"><p class="category_top rborder">Edible Oil</p></a>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 device_categorycol">
                                <a href="#"><p class="category_top rborder">Almond</p></a>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 device_categorycol">
                                <a href="#"><p class="category_top rborder">Raisins</p></a>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 device_categorycol">
                                <a href="#"><p class="category_top">Walnut</p></a>
                            </div>
                       </div>
                   </div>
                   <div class="carousel-item">
                        <div class="row row_shop1 device_categoryrow">
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 device_categorycol">
                                <a href="#"><p class="category_top rborder">Edible Oil</p></a>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 device_categorycol">
                                <a href="#"><p class="category_top rborder">Almond</p></a>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 device_categorycol">
                                <a href="#"><p class="category_top rborder">Raisins</p></a>
                            </div>
                            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 device_categorycol">
                                <a href="#"><p class="category_top">Walnut</p></a>
                            </div>
                       </div>
                   </div>
               
                    <a class="carousel-control-prev" href="#carousel_catmenu" role="button" data-slide="prev">
                        <img src="/assets/images/ico/left_arrownew.png" alt="whatsapp" class="img-thumbnail">
                    </a>
                    <a class="carousel-control-next" href="#carousel_catmenu" role="button" data-slide="next">
                        <img src="/assets/images/ico/right_arrownew.png" alt="whatsapp" class="img-thumbnail">
                    </a>
                </div>
            </div>
        </div> -->

        <div class="tab-content sale_content">

            <div id="brand1" class="tab-pane active">
                <div class="row row_custom" *ngIf="!showSearchDesc">

                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 ">

                        <h3 class="searchresult_head">Search results for "{{searchPost}}"</h3> 

                    </div>

                </div>

                <div class="row row_shop1 shop_items" *ngIf="!showNoProducts">

                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 shop_itemscol" *ngFor="let product of products">

                        <div class="listitem_inner">

                            <a [routerLink]="['/product-details', product.seo_url]"><img src="{{product.product_images}}" class="img-fluid  mx-auto d-block" alt="product">

                                <p class="listitem_desc">{{product.product_name}} <br> {{product.category_name}} </p>
                            </a>

                            <p class="listitem_cost">Rs. {{product.product_price}}</p>
                            <p class="mrp-price"><del>Rs. {{ product.cskd_mrp}}</del></p>
                            <button (click)="buyNow(product.id, 1)" type="submit" class="probuy">BUY NOW</button>
                            <button (click)="addItemToCart(product.id, 1)" type="submit" class="proadd">ADD TO CART</button>
                        </div>

                    </div>

                </div>
                <div class="row row_custom itemlist_row" *ngIf="showNoProducts">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 listitem_col">
                        <div class="listitem_inner product_text">
                            No results found
                        </div>
                    </div>
                </div>
            </div>

            <div id="product1" class="tab-pane fade product_device"><br>
                
            </div>



        </div>



    </div>


</div>

<app-footer></app-footer>