
<div class="modal-body">
	<div class="row row_custom">
		<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 process_col">
		 	<img class="close  closeinfo" (click)="activeModal.dismiss('Close clicked')" data-dismiss="modal" src="/assets/images/close5.png">
		</div>
	</div>
    <div class="infopopup_outer">
	    <div class="row row_custom">
			<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 process_col">
				<h3 class="gitem_subhead text-center" >STORE DETAILS</h3>
	        	<hr class="gitem_rt2">
		   </div>
		</div>
		<div class="row row_custom">
		   	<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 infopopup_left"> 

            <p class="cartbilltext text-center">
                <span *ngIf="shopOpClseStatus">
                    <span class="store-open"  style="color: {{shopStatusColor}}" >{{shopStatus}}</span> <br/>
                    <span class="store-address" >{{shopOpeningTiming}} - {{shopCloseTiming}}</span> <br/>
                </span>
                <span class="store-name" >  {{storename}}  </span> <br/>
                <span class="store-address" *ngIf="address">{{address}}</span>
                <span *ngIf="pincode">, {{pincode}}</span><br *ngIf="address"/>
                <a href="tel:+91{{shopphone}}" class="store-address text-white" style="color: #0056b3!important;">+91 {{shopphone}} </a> 
            </p>
		   	 
		   	</div> 
		</div>   
	</div> 
</div>