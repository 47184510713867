import { Component, OnInit,Input, EventEmitter,  AfterViewInit,ViewChild,ElementRef } from '@angular/core'; 
import { NgbActiveModal , ModalDismissReasons , NgbModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-store-details',
  templateUrl: './store-details.component.html',
  styleUrls: ['./store-details.component.css']
})
export class StoreDetailsComponent implements OnInit {
  @Input() store: any;
  storeDetails:any;

  storename:string = "";
  address:string = "";
  pincode = "";
  storeTiming:string = "";

  shopStatus:string = "CLOSED";
  shopStatusColor:string = "red";
  shopOpeningTiming:number = 0;
  shopCloseTiming:number = 0;
  shopphone:string ="";
  now: number = Date.now();
  shopOpClseStatus:boolean = false;

  constructor( 
    public activeModal: NgbActiveModal, 
    private modalService: NgbModal, 
  ) { }

  ngOnInit(): void {  
        this.storename = this.store.seo_url;
        if(this.store.address != "NULL" ){
          this.address = this.store.address;
        }
        this.pincode = this.store.pin;
        this.storeTiming = this.store.shop_timing;
        this.shopOpeningTiming = this.store.opening_time;
        this.shopCloseTiming = this.store.closing_time;
        this.shopphone = this.store.phone;
        this.shopStatus = this.store.shop_status;
        if(this.shopStatus =="OPEN"){
          this.shopStatusColor = "green";
        }else{
          this.shopStatusColor = "red";
        }
        if(this.store.opening_time != "00:00"){
          this.shopOpClseStatus =true;
        }
  }

}
