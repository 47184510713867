<div class="modal-body">
    <div class="row row_custom">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 shoplogin_side">
            <img class="close closedevice" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 shoplogin_main">
            <h3 class="shoplogin_head">REGiSTER</h3>
            <p class="shoplogin_det">Complete the details below to register</p>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 shoplogin_side">
            <img class="close closedesk" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">
        </div>
    </div>
    <form id="contact_form" appForm *ngIf="!showRegisterotp" [formGroup]="registerForm">
        <div class="register_contents">
            <div class="row row_custom shopreg_toprow">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 shoplogin_main">
                    <label class="shoplogin_label" for="name">Enter Full Name*</label>
                    <input class="shoplogin_text required" type="text" id="name" formControlName="name" placeholder="Enter Name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Full name is required</div>
                        <div *ngIf="f.name.errors.pattern">Only Alphabets with space between 2 to 20 characters</div>
                    </div>
                    <div *ngIf="errors?.name" class="backend_error">{{ errors?.name }}</div>
                </div>
            </div>
            <div class="row row_custom shopreg_toprow">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 shopreg_left">
                    <label class="shoplogin_label" for="phone">Enter Mobile Number*</label>
                    <input class="shoplogin_text required" type="tel" placeholder="Enter Mobile" maxlength="12" id="phone" formControlName="phone" (keyup)="trim($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">Mobile number is required</div>
                        <div *ngIf="f.phone.errors.pattern">Please enter a valid mobile number</div>
                    </div>
                    <div *ngIf="errors?.phone" class="backend_error">{{ errors?.phone }}</div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 shopreg_right">
                    <label class="shoplogin_label" for="email">Enter Email ID</label>
                    <input class="shoplogin_text required" type="email" placeholder="Enter Email" id="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.pattern">Please enter a valid email</div>
                    </div>
                    <div *ngIf="errors?.email" class="backend_error">{{ errors?.email }}</div>
                </div>
            </div>
            <div class="row row_custom shopreg_toprow">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 shopreg_left">
                    <label class="shoplogin_label" for="psw">Enter password</label>
                    <input class="shoplogin_text required" type="password" placeholder="Enter Password" id="password" name="password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" onkeypress="return event.charCode != 32">
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                        <div *ngIf="f.password.errors.minlength">Minimum 6 characters required</div>
                        <div *ngIf="f.password.errors.pattern">Your password must contain at least one uppercase, one lowercase, one special character and one number<br/>Note: Special characters allowed @$!%*#?& </div>
                        <div *ngIf="f.password.errors?.noWhiteSpace">Whitespace is not allowed</div>
                    </div>
                    <div *ngIf="errors?.password" class="backend_error">{{ errors?.password }}</div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 shopreg_right">
                    <label class="shoplogin_label" for="password_confirmation">Confirm password</label>
                    <input type="password" formControlName="password_confirmation" placeholder="Enter Confirm Password" class="shoplogin_text required" [ngClass]="{ 'is-invalid': submitted && f.password_confirmation.errors }" onkeypress="return event.charCode != 32"/>
                    <div *ngIf="submitted && f.password_confirmation.errors" class="invalid-feedback">
                        <div *ngIf="f.password_confirmation.errors.required">Confirm Password is required</div>
                        <div *ngIf="f.password_confirmation.errors.mustMatch">Passwords must match</div>
                    </div>
                    <div *ngIf="errors?.password_confirmation" class="backend_error">{{ errors?.password_confirmation }}</div>
                </div>
            </div>

            <div class="row row_custom shopreg_botrow">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 shopreg_left">

                    <label class="shopreg_label" for="Address1">Address (Optional)</label>

                    <input class="shopreg_text" type="text" placeholder="Flat / house No" name="address" formControlName="address">

                    <input class="shopreg_text" type="text" placeholder="Area / Street / Colony" name="address1" formControlName="address1">

                    <input class="shopreg_text" type="text" placeholder="Landmark (optional)" name="address2" formControlName="address2">

                </div>

                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 shopreg_right shop_addright">
                    <select formControlName="country_id" class="shopreg_text" id="country" (change)="onChangeCountry($event.target.value)">
                            <option value="">Select Country</option>
                            <option *ngFor="let country of countries" [value]="country.id">{{country.name}}</option>
                        </select>

                    <div class="reg_dropsec">
                        <select formControlName="state_id" class="shopreg_listleft" (change)="onChangeState($event.target.value)">
                                <option value="">Select State</option>
                                <option *ngFor="let state of states" [value]="state.id">{{state.state}}</option>
                            </select>

                        <select formControlName="town_id" class="shopreg_listright">
                                <option value="">Select Town /City</option>
                                <option *ngFor="let city of cities" [value]="city.id">{{city.town}}</option>
                            </select>
                    </div>

                    <input class="shopreg_text" type="text" placeholder="Pincode" name="pin" formControlName="pin" [ngClass]="{ 'is-invalid': submitted && f.pin.errors }">
                    <div *ngIf="submitted && f.pin.errors" class="invalid-feedback">
                        <div *ngIf="f.pin.errors.minlength">Minimum 6 characters required</div>
                        <div *ngIf="f.pin.errors.pattern">Please enter a valid pincode</div>
                    </div>
                    <div *ngIf="errors?.pin" class="backend_error">{{ errors?.pin }}</div>
                </div>

            </div>
            <!--<div class="row row_custom">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 shopreg_outlet">
                    <div *ngIf="showOutlet">
                        <label class="shoplogin_label">Choose the outlet*</label>
                        <div class="outlet_resp" *ngFor="let store of stores">
                            <div class="outlet_gallery" [ngClass]="{image_active: isActive(store)}">
                                <a (click)="ToggleCourse(store)">

                                    <div class="img-container">
                                        <img class="outlet_img"  src="{{store.shop_image}}" alt="outlet">
                                        <div class="overlay">
                                            <span class="outlet_center">{{store.shop_name | uppercase}}</span>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 shopreg_outlet">
                    <input type="hidden" value="0" formControlName="outlet" [ngClass]="{ 'is-invalid': submitted && f.outlet.errors }" />
                    <div *ngIf="submitted && f.outlet.errors && showOutlet" class="invalid-feedback">
                        <div *ngIf="f.outlet.errors.required">Please choose an outlet</div>
                    </div>
                </div>
            </div> -->
			

					
            <div class="row row_custom shopreg_botrow">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <button class="shopreg_button" [ngClass]="{'buttonDisable':formSumbit}" type="submit" (click)="Register()">SUBMIT</button>
                    </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <button class="shopreg_button" type="reset" >Cancel</button>
                    </div>
                </div>

            </div>
        </div>
    </form>
	
	<form id="otp_form" appForm *ngIf="showRegisterotp" [formGroup]="SubmitOtpForm">
	    <div class="alert alert-success mt-3">
                Registered Successfully!
        </div> 
        <div class="register_contents">
            <div class="row row_custom shopreg_toprow">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 shoplogin_main">
                    <label class="shoplogin_label" for="otp">Enter OTP*</label>
                    <input class="shoplogin_text required" type="text" id="otp" formControlName="otp" placeholder="Enter otp" [ngClass]="{ 'is-invalid': otpsubmitted && o.otp.errors }">
                    <div *ngIf="otpsubmitted && o.otp.errors" class="invalid-feedback">
                        <div *ngIf="o.otp.errors.required">OTP is required</div>
						<div *ngIf="o.otp.errors.minlength">Minimum 6 characters required</div>
						<div *ngIf="o.otp.errors.pattern">Please add a valid otp</div>
                    </div>
                    <div *ngIf="errors" class="backend_error">{{ errors }}</div>
					<input type="hidden" id="mobile" formControlName="mobile" value="" />
                   <button class="shoplogin_button" type="button" [ngClass]="{'buttonDisable':otpformSumbit}" (click)="VerifyOtp()">VERIFY OTP</button>
                </div>
            </div>
			</div>
	</form>		
</div>