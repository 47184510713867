import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { ReturnPoliceComponent } from '../return-police/return-police.component';
@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }
  getPrivacyPolicy(){
    const modalRef = this.modalService.open(PrivacyPolicyComponent,{ 
      centered: true, 
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'login_modal'
    });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
    console.log(result);
    }).catch((error) => {
    console.log(error);
    });
  }
  getReturnPolicy(){
    const modalRef = this.modalService.open(ReturnPoliceComponent,{ 
      centered: true, 
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'login_modal'
    });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
    console.log(result);
    }).catch((error) => {
    console.log(error);
    });
  }
}