<div class="modal-body">
    <div class="row row_custom">
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 shoplogin_side">
            <img class="close closedevice" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 shoplogin_main">
            <h3 class="shoplogin_head">Choose an outlet to change</h3>
        </div>
        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 shoplogin_side">
            <img class="close closedesk" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">
        </div>
    </div>
    <div class="register_contents">
        <form id="contact_form" [formGroup]="changeShopForm">
            <div class="row row_custom">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 shopreg_outlet">
                    <label class="shoplogin_label"></label>

                    <select formControlName="outlet" class="shopreg_text" id="outlet"  [ngClass]="{ 'is-invalid': submitted && f.outlet.errors }">
                        <option value="">Select Outlet</option>
                        <option *ngFor="let store of stores" [value]="store.id">{{store.seo_url}}</option>
                    </select>
                    <div *ngIf="submitted && f.outlet.errors" class="invalid-feedback">
                        <div *ngIf="f.outlet.errors.required">Outlet is required</div>
                    </div>
                    <div *ngIf="errors?.outlet" class="backend_error">{{ errors?.outlet }}</div>

                   <!-- <div class="outlet_resp" *ngFor="let store of stores">
                        <div class="outlet_gallery" [ngClass]="{image_active: isActive(store)}">
                            <a (click)="ToggleCourse(store)">
                               
                                <div class="img-container">
                                    <img class="outlet_img"  src="{{store.shop_image}}" alt="outlet">
                                    <div class="overlay">
                                        <span class="outlet_center">{{store.shop_name | uppercase}}</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                       
                    </div>	-->			
                </div>
            </div>
				
            <div class="row row_custom shopreg_botrow">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <button class="common_updatebutton" type="submit" (click)="onSubmit()">Change</button>
                </div>
            </div>
            <div *ngIf="message" class="alert alert-success mt-3">
                {{ message }}
            </div>
        </form>
    </div>

</div>