import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderService } from '../order.service';
import { CountryService} from '../country.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { OrderGiftItemsComponent} from '../modals/order-gift-items/order-gift-items.component';
@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {
  order:any =[];
  id;
  sub;
  isLoading:boolean = true;
  isShowDeliveryAddress:boolean = false;
  deliveryType:any;
  store:any =[];
  storename:string = "";
  orderStatus:string = "";
  shop_id:any;
  isToken:any=null;
  constructor(
    private OrderService: OrderService,
    private activatedRoute: ActivatedRoute,
    private CountryService: CountryService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.isLoading = true;
    this.isShowDeliveryAddress = true;
    this.sub = this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.OrderService.getOrderDetails(this.id).subscribe(
        data => {
          this.order = data;
          this.shop_id =this.order.shop_id;
          if(this.order.delivery_address_id == null){
            this.isShowDeliveryAddress = false;
          }
          if(this.order.delivery_address == null){
            this.isShowDeliveryAddress = false;
          }
          if(this.order.delivery_type == 1){
                this.deliveryType = "Home Delivery";
          }
          else{
            this.deliveryType = "Pick up from Store";
          }
          console.log(this.order)
          if(this.order.order_status == 0){
            this.orderStatus = "Pending";
          }else if(this.order.order_status == 4){
            this.orderStatus = "Cancelled";
          }

          this.isLoading = false;
      });
    });

    setTimeout(()=>{
      this.getStore();
    },2000);

    this.isToken=localStorage.getItem('auth_token');
    
  }
  getStore(){
    this.CountryService.getStoreDetailswithId(this.shop_id).subscribe(
      result => {
        this.store = result;
        console.log(this.store);
        this.storename = this.store.seo_url
      },
      error => {
        
      },() => {
      }
    );
  }
  getGiftItemModel(parent_id){
    const modalRef = this.modalService.open(OrderGiftItemsComponent, { 
                          size: 'xl',
                          backdrop: 'static',
                          keyboard: false,
                          windowClass: 'shoplogin_modal',
                          animation: true,
                          ariaDescribedBy:'test',
                        });
    modalRef.componentInstance.parent_id = parent_id; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
}
