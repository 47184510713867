import { Component, OnInit , Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef} from '@angular/core';
import { NgbActiveModal , ModalDismissReasons , NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../../cart.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-cart-gift-items',
  templateUrl: './cart-gift-items.component.html',
  styleUrls: ['./cart-gift-items.component.css']
})
export class CartGiftItemsComponent implements OnInit {
  @Input() product_id: number;
  cart:any = [];
  items:any = [];
  amount = 0;
  constructor(
  	public router: Router,
    public activeModal: NgbActiveModal,
    private cartService: CartService,
    private modalService: NgbModal
   ) { }

  ngOnInit(): void {
  	this.getContent();
  }
  getContent(){
    this.cartService.getCartGiftBoxItem(this.product_id).subscribe(
      data => {
        this.cart = data;
        this.items = this.cart.items;
      }
    ); 
  	
  }
  locationsSum(){
  	if(this.items.length >0){
	    this.amount = this.items.map(tag => tag.product_price*tag.quantity).reduce((a, b) => a + b, 0);
	}
	return this.amount;
  }
}
