import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';
import { GiftService} from '../gift.service';
import { CartService } from '../../cart.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { ErrorNotificationsComponent} from '../../modals/error-notifications/error-notifications.component';
import { GiftItemsMobileViewComponent } from '../../modals/gift-items-mobile-view/gift-items-mobile-view.component';

@Component({
  selector: 'app-giftitems',
  templateUrl: './giftitems.component.html',
  styleUrls: ['./giftitems.component.css']
})
export class GiftitemsComponent implements OnInit {
	sub;
	slug;
	giftData:any 		= [];
	giftBox:any 		= [];
	giftItems:any 		= [];
	suggestions:any 	= [];
	isLoading:boolean 	= false;
	stock:any 			= 0;
	errors:any 			= [];
	giftQuantity:number = 0;
	amount:number 		= 0;
	searchText 			= '';
	inStock:boolean		= false;
	inSuggestionItem 	= false;
	giftBoxQuanity 		= 1;
	multiGiftBox:boolean= false;
	giftboxs:any  		= [];
	count:any 			= 0;
	itemAdded:any 		= [];
	giftMsg:string 		= "";
	constructor(
		private router: Router,
    	private activatedRoute: ActivatedRoute,
		private GiftService: GiftService,
		private modalService: NgbModal,
		private confirmationDialogService: ConfirmationDialogService,
		private cartService: CartService,
	) { }

	ngOnInit(): void {
		window.scroll({ 
	      top: 0, 
	      left: 0, 
	      behavior: 'smooth' 
	    });
		this.sub = this.activatedRoute.paramMap.subscribe(params => {
			this.slug = params.get('slug');
			this.getGiftItems();
		});
	}

	getGiftItems(){

		this.isLoading=true;

		this.GiftService.getGiftItems(this.slug).subscribe(
	      data => {
	        this.giftData 	= data;
	        this.giftBox 	= this.giftData.giftbox;
	        this.giftItems 	= this.giftData.gift_items;
		    this.suggestions= this.giftData.suggested_items;
	        this.giftBoxQuanity= this.giftData.gift_box_count;
	        if(this.giftBoxQuanity > 1){
	        	this.multiGiftBox = true;
	        }
	        this.isLoading	=false;
	      }
	    );
  	}
  	locationsSum() {
	    if(this.suggestions.length >0){
	      this.amount = this.suggestions.map(tag => tag.product_price*tag.quantity).reduce((a, b) => a + b, 0);
	    }
	    return this.amount;
	}
	totalCartQuanity(){
		if(this.suggestions.length >0){
	      this.giftQuantity = this.suggestions.map(tag => tag.quantity * tag.item_qty).reduce((a, b) => a + b, 0);
	    }
	    return this.giftQuantity;
	}
  	addItemIntoGiftBox(giftboxID, giftItemID){
  		this.isLoading = true;
  		const payload = {
	      	giftbox_product_id: giftboxID,
	      	giftItem_product_id: giftItemID,
	       	quantity: 1,
	    };
	    this.GiftService.addToGiftBox(payload).subscribe(
	      result => {
	        this.getGiftItems();
	      },error => {
	        this.errors = error.error;
	        this.isLoading=false;
	        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
	                            size: 'xl',
	                            backdrop: 'static',
	                            keyboard: false,
	                            windowClass: 'cartadd_modal',
	                            animation: true,
	                            ariaDescribedBy:'test',
	                          });
	        modalRef.componentInstance.message = this.errors.error;
	        if(this.errors != "Out of Stock"){
	          //this.emptyCartBuyNow(this.id, this.quantity);
	        }else{

	        }
	      },() => {
	        
	      }
	    );
  	}
  	increamentQTY(id,quantity): void {
  		this.isLoading = true;
	    const payload = {
	      id: id,
	      quantity: quantity,
	    };
	    this.GiftService.increaseItemQty(payload).subscribe(
	      result => {
	      	this.getGiftItemsinGiftCart();
	        //this.getGiftItems();

	      },
	      error => {
	      	this.isLoading =false;
	        this.errors = error.error;
	        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
	                            size: 'xl',
	                            backdrop: 'static',
	                            keyboard: false,
	                            windowClass: 'cartadd_modal',
	                            animation: true,
	                            ariaDescribedBy:'test',
	                          });
	        modalRef.componentInstance.message = this.errors.error;
	      },() => {
	        
	      }
	    );
	}

	deleteCartItem(id): void {
		this.isLoading = true;
	    this.confirmationDialogService.confirm('', 'Are you sure you want to delete this item ?')
	    .then((confirmed) => {
	      if(confirmed === true){
	        this.GiftService.deleteGiftItem(id).subscribe(() => {
	            this.getGiftItems();
	        })
	      }
	      else{
	      	this.isLoading =false;
	      }
	    }).catch(() => {
			this.isLoading =false;
			console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
		});	 
	    
	}
	deleteGiftItem(id,giftboxid):void{
		this.isLoading = true;
	    this.confirmationDialogService.confirm('', 'Are you sure you want to delete this item ?')
	    .then((confirmed) => {
	      if(confirmed === true){
	        this.GiftService.deleteGiftItemSuggestion(id,giftboxid).subscribe(() => {
	            this.getGiftItems();
	        })
	      }
	      else{
	      	this.isLoading =false;
	      }
	    }).catch(() => {
			this.isLoading =false;
			console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
		});	
	}

	emptyGift(giftBoxId): void {
		this.isLoading = true;
	    this.confirmationDialogService.confirm('', 'Are you sure to clear all the items in your gift box?')
	    .then((confirmed) => {
	      if(confirmed === true){
	      	const payload = {
		      id: giftBoxId
		    };

	        this.GiftService.emptyGift(payload).subscribe(() => {
	           this.getGiftItems();
	           this.totalCartQuanity();
	        })
	      }else{
	      	this.isLoading =false;
	      }
	    }).catch(() => {
			this.isLoading =false;
			console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
		});	
	}

	increamentGiftBoxQTY(id, quantity){
		this.isLoading = true;
		const payload = {
	      id: id,
	      quantity: quantity,
	    };
	    this.GiftService.increamentGiftBoxQTY(payload).subscribe(
	      result => {
	        this.getGiftItems();
	        this.multiGiftBox = true;
	        this.giftboxs = result;
	        this.giftBoxQuanity = this.giftboxs.quantity;
	      },
	      error => {
	        this.errors = error.error;
	        this.isLoading = false;
	        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
	                            size: 'xl',
	                            backdrop: 'static',
	                            keyboard: false,
	                            windowClass: 'cartadd_modal',
	                            animation: true,
	                            ariaDescribedBy:'test',
	                          });
	        modalRef.componentInstance.message = this.errors.error;
	      },() => {
	        
	      }
	    );
	}
	submit(id){
		this.isLoading = true;
		const payload = {
	      id: id,
	      quantity:this.giftBoxQuanity,
	      gift_message: this.giftMsg
	    };
	    console.log(this.giftBoxQuanity*(this.locationsSum() + this.giftBox.product_price*1)   );
	    let amt=this.giftBoxQuanity*(this.locationsSum() + this.giftBox.product_price*1);
	    if( (this.giftData.gift_quanity == this.totalCartQuanity()) && amt>0 ){
	    	this.confirmationDialogService.confirm('', 'You cannot edit or add the items to the box after clicking confirm button. Are you sure want to proceed with your order?')
	    	.then((confirmed) => {
				if(confirmed === true){
					this.GiftService.addGiftBoxToCart(payload).subscribe(
						result =>{
							this.isLoading=false;
							this.getCartCount();
							this.router.navigate(['/cart']);
						},error => {
							this.isLoading=false;
							this.errors = error.error;
							const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
												size: 'xl',
												backdrop: 'static',
												keyboard: false,
												windowClass: 'cartadd_modal',
												animation: true,
												ariaDescribedBy:'test',
											});
							modalRef.componentInstance.message = this.errors.error;
					},() => {
						
					}
					);
				}else{
					this.isLoading =false;
				}
			}).catch(() => {
				this.isLoading =false;
				console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)');
			});	    
		}else{
			this.isLoading = false;
	        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
	                            size: 'xl',
	                            backdrop: 'static',
	                            keyboard: false,
	                            windowClass: 'cartadd_modal',
	                            animation: true,
	                            ariaDescribedBy:'test',
	                          });
	        modalRef.componentInstance.message = "Please fill the gift box";
		}
	}
	getCartCount(){
	    this.cartService.getCartItemCount().subscribe(
	      data => {
	        this.count = data;
	        localStorage.setItem("cart_count", JSON.stringify(this.count));
	      }
	    ); 
	}
	getGiftItemsAdded(id){
	    const modalRef = this.modalService.open(GiftItemsMobileViewComponent, { 
                          size: 'xl',
                          backdrop: 'static',
                          keyboard: false,
                          windowClass: 'shoplogin_modal',
                          animation: true,
                          ariaDescribedBy:'test',
                        });
	    modalRef.componentInstance.product_id = id; // should be the id

	    modalRef.result.then((result) => {
	      console.log(result);
	    }).catch((error) => {
	      console.log(error);
	    });
	}
	chooseItem(){
		window.scroll({ 
           top: 600, 
           behavior: 'smooth' 
    	});
	}
	getGiftItemsinGiftCart(){
		console.log(this.giftData.gift_box_id);
		this.GiftService.getGiftItemInCart(this.giftData.gift_box_id).subscribe(
	      data => {
	        this.suggestions 	= data;
	        this.isLoading	=false;
	      }
	    );
	}
}