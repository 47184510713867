import * as $ from 'jquery';
import { Component, OnInit, Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../cart.service';
import { AuthService } from '../shared/auth.service';
import { CountryService } from '../country.service';
import { OrderService } from '../order.service';
import { DeliveryAddressComponent } from 'src/app/modals/delivery-address/delivery-address.component';
import {BillingAddressComponent} from '../modals/billing-address/billing-address.component';
import { StoreDetailsComponent} from '../modals/store-details/store-details.component';
import { ErrorNotificationsComponent} from '../modals/error-notifications/error-notifications.component';

import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

import { WindowRefService } from '../window-ref.service';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-delivery-payment',
  templateUrl: './delivery-payment.component.html',
  styleUrls: ['./delivery-payment.component.css']
})
export class DeliveryPaymentComponent implements OnInit {
  carts;
  subtotal:number =0;
  grandTotal:number =0;
  countries: any = [];
  states: any = [];
  cities: any=[];
  deliveryState:any =[];
  deliveryCities:any=[];
  user:any =[];
  closeResult = '';
  amount =0;
  
  billingAddress:any =[];
  deliveryAddress:any =[];
  paymentForm: FormGroup;
  submitted = false;
  errors = null;
  disabled = false;
  isDisableFirst = true;
  isDisableSecond = true;
  isDisableDiveliveryAddress:boolean = false;
  emailDevery = "";
  displayHomeDelivery:boolean = false;
  displayPickup:boolean = false;
  displayRazorPay:boolean = true;
  displayStorePick:boolean= false;
  displayCOD:boolean = false;
  displayChooseAddress:boolean = true;
  store:any = [];
  isLoading:boolean = false;
  storename:string = "";
  paymentKey:any =[];
  displayPayOnline:boolean = false;
  isSameAddress:boolean = false;
  defaultAddress:any = [];
  deliveryCharge:any = 0.00;
  getdelivery:any = [];
  invoiceAttachment:number = 0;
  displayDeliveryPincode:boolean =null;
  displayDeliveryFee:boolean = null;
  billingHaveContactID:boolean = null;
  deliveryHaveContactID:boolean = null;
  defaultPhoneNumber:boolean=null;
  defaultDeliveryPhoneNumber:boolean=null;
  orders:any = [];
  ordersDelivery:any = [];
  emailReadonly:boolean = false;
  paymentOrder:any = [];
  billing_address_id:number=0;
  delivery_address_id:number = 0;
  country_id_billing :number = null;
  state_id_billing:number = null;
  town_id_billing:number =null;

  country_id_delivery :number = null;
  state_id_delivery:number = null;
  town_id_delivery:number =null;

  lastOrder:any = [];
  lastOrderDeliveryType:number = 0;
  lastOrderPaymentType:number = 0;

  accepted: boolean;

  pincode:any = [];

  discount:string = "";
  discountAmount:any;
  showDiscount:boolean = false;
  discountGrandtotal:number = 0;
  discountAmount1:number =0;
  pattern = /^[A-Za-z0-9 \/\s\.!@#$%^&*()~`+\-=\[\]{};':"\\|,.<>\/?]*$/;
  formSumbit:boolean = false;
  myorders:any =[];
  displayButtonBlack:boolean =false;
  payment_type_error:boolean=false;
  in_process:boolean=null;
  billing_web_city:any;
  delivery_web_city:any;
  billingCity:any;
  billingAddCity:boolean=false;
  //deliveryCity:any;
  deliveryAddCity:boolean=false;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private cartService: CartService,
    private CountryService: CountryService,
    private modalService: NgbModal,
    private winRef: WindowRefService,
    public authService: AuthService,
    public OrderService:OrderService,
    private confirmationDialogService: ConfirmationDialogService,
  ) { }

  ngOnInit(): void {
    
    this.createForm();
    this.getCart();  
    this.getCountry();
    this.getUser();
    this.getUserStoreDetails();
    this.checkPaymentKey();
    this.checkLastOrderDetails();
    this.getDefaultAddress();
    
    $(document).ready(function() {
      $('.mdb-select').materialSelect();
    });
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });

    
  }
  getUser(){
    this.authService.profileUser().subscribe(
      data => {
        this.user = data;
        this.emailDevery = this.user.email;
        if(this.emailDevery != ""){
          this.emailReadonly = true;
        }else{
          this.emailReadonly = false;
        }
        // if(this.emailDevery==null)
        // {
        //   this.displayChooseAddress = false;
        // }
        this.paymentForm.patchValue({
          email: this.emailDevery,
        });
      });
  }
  getUserStoreDetails(){
    this.CountryService.getUserStoreDetails().subscribe(
      data => {
        this.store = data;
        this.storename = this.store.seo_url;
        if(this.store.home_delivery === 1){
          this.displayHomeDelivery = true;
        }
        if(this.store.outlet === 1){
          this.displayPickup = true;
          this.displayStorePick = true;
        }
        
      });
  }
  createForm(){
    this.paymentForm = this.formBuilder.group({
      email: ["", [Validators.email]],
      uptodate: [''],
      purchare_type:['',[Validators.required]],
      billing_name:['',[Validators.required, Validators.pattern(this.pattern)]], //Validators.pattern('^[a-zA-Z ]{2,20}$')
      billing_address:[''],
      billing_phone:['', [Validators.required,  Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      country_id_billing:['',[Validators.required]],
      state_id_billing: ['',[Validators.required]],
      town_id_billing:[''],
      billing_pin:['',[Validators.required, Validators.minLength(6),Validators.pattern("^[1-9][0-9]{5}$")]],
      billing_id : [''],
      delivery_name:[''],
      delivery_phone:[''],
      delivery_address:[''],
      country_id_delivery:[''],
      state_id_delivery:[''],
      town_id_delivery:[''],
      delivery_pin:[''],
      sameaddress:[''],
      payment_type:['',[Validators.required]],
      delivery_id: [''],
      discount:[''],
      discount_value:[''],
      delivery_fee_val:[''],
      is_ship_rocket:[''],
      base_charge:[''],
      billing_web_city:[''],
      delivery_web_city:[''],
    });
  }

  getUserOrders(){
    const payload = {
      address_id: this.billing_address_id
    };
    this.OrderService.getMyOrderByAddress(payload).subscribe(
      data => {
        this.orders = data;
        if(this.orders == 0){
          this.OrderService.getMyOrderCount().subscribe(
            data => {
              this.myorders = data;
               if(this.myorders == 0){
                  this.displayChooseAddress = false;
               }
               else{
                this.displayChooseAddress = true;
               }
              //localStorage.setItem("cart_count", JSON.stringify(0));
            }
          );
          
          this.billingHaveContactID = null;
          this.defaultPhoneNumber = null;
        }else{

          this.displayChooseAddress = true;
          this.billingHaveContactID = true;
          this.defaultPhoneNumber = true;
        }
        console.log(this.billingHaveContactID);
        //localStorage.setItem("cart_count", JSON.stringify(0));
      }
    );
  }
  
  getUserDeliveryAddressOrder(){
    const payload = {
      address_id: this.delivery_address_id
    };
    this.OrderService.getMyOrderByAddress(payload).subscribe(
      data => {
        this.ordersDelivery = data;
        if(this.ordersDelivery == 0){
          this.deliveryHaveContactID = null;
          this.defaultDeliveryPhoneNumber = null;
        }else{
          this.deliveryHaveContactID= true;
          this.defaultDeliveryPhoneNumber = true;
        }
        console.log(this.billingHaveContactID);
        //localStorage.setItem("cart_count", JSON.stringify(0));
      }
    );
  }

  getCountry(){
    this.CountryService.getCountries().subscribe(
      data => this.countries = data
    );
  }

  getPincode(){
    this.CountryService.getPermittedPin().subscribe(
      data => {
        this.pincode = data;
      }
    );
  }
  checkPaymentKey(){
    this.cartService.getPaymentKey().subscribe(
      data =>{
        this.paymentKey = data;
        if(this.paymentKey.length === 0 ){
          this.displayPayOnline = false;
        }else{
          this.displayPayOnline = true;
        }
      }
    );
  }
  sameDeliveryAddress(event){
    //console.log("here");
    this.isSameAddress =true;

    this.deliveryAddCity=false;
    if ( event.target.checked ) {
      if((this.paymentForm.get('billing_address').value == null) || (this.paymentForm.get('billing_address').value == "")){
        alert('Billing address is empty');
        this.accepted = null;
        this.isSameAddress =false;
        this.deliveryHaveContactID =null;
         this.paymentForm.patchValue({
          sameaddress:''
         });
        return false;
      }
      this.paymentForm.controls['delivery_name'].disable();
      this.paymentForm.controls['delivery_address'].disable();
      this.paymentForm.controls['delivery_pin'].disable();
      this.paymentForm.controls['delivery_phone'].disable();
      this.paymentForm.controls['country_id_delivery'].disable();
      this.paymentForm.controls['state_id_delivery'].disable();
      this.paymentForm.controls['town_id_delivery'].disable();
      this.paymentForm.controls['delivery_id'].disable();
      this.paymentForm.controls['delivery_web_city'].disable();
      this.onChangeDeliveryCountry(this.paymentForm.get('country_id_billing').value);
      this.onChangeDeliveryState(this.paymentForm.get('state_id_billing').value);

      this.paymentForm.patchValue({
        delivery_id:  this.paymentForm.get('billing_id').value,
        delivery_name: this.paymentForm.get('billing_name').value,
        delivery_address:this.paymentForm.get('billing_address').value,
        delivery_pin:this.paymentForm.get('billing_pin').value,
        delivery_phone:this.paymentForm.get('billing_phone').value,
        country_id_delivery:this.paymentForm.get('country_id_billing').value,
        state_id_delivery:this.paymentForm.get('state_id_billing').value,
        town_id_delivery:this.paymentForm.get('town_id_billing').value,
        delivery_web_city:this.paymentForm.get('billing_web_city').value,
      });
      this.deliveryAddress.pin=this.paymentForm.get('billing_pin').value;
      if(this.paymentForm.get('billing_web_city').value!=''){
       // this.deliveryAddCity=true;
      }
      //this.in_process=true;
    } else{
      this.isSameAddress =false;
      this.deliveryHaveContactID =null;
      localStorage.removeItem('billing_id');
      this.paymentForm.controls['delivery_id'].enable();
      this.paymentForm.controls['delivery_name'].enable();
      this.paymentForm.controls['delivery_address'].enable();
      this.paymentForm.controls['delivery_pin'].enable();
      this.paymentForm.controls['delivery_phone'].enable();
      this.paymentForm.controls['country_id_delivery'].enable();
      this.paymentForm.controls['state_id_delivery'].enable();
      this.paymentForm.controls['town_id_delivery'].enable();
      this.paymentForm.get('delivery_name').setValidators([Validators.required, Validators.pattern(this.pattern)]); //
      this.paymentForm.get('delivery_phone').setValidators([Validators.required, Validators.pattern('[6-9]\\d{9}')]);
      this.paymentForm.get('delivery_address').setValidators([Validators.required]);
      this.paymentForm.controls['delivery_pin'].setValidators([Validators.required, Validators.minLength(6),Validators.pattern("^[1-9][0-9]{5}$")]);
      this.paymentForm.controls['country_id_delivery'].setValidators([Validators.required]);
      this.paymentForm.controls['state_id_delivery'].setValidators([Validators.required]);
      this.paymentForm.controls['delivery_web_city'].enable();

      this.onChangeDeliveryState(0);

      this.paymentForm.patchValue({
        delivery_id: '',
        delivery_name: '',
        delivery_address:'',
        delivery_pin:'',
        delivery_phone:'',
        country_id_delivery:111,
        state_id_delivery:'',
        town_id_delivery:null
      });

       if(this.paymentForm.get('purchare_type').value==1){
          this.displayDeliveryPincode= true;
          this.displayDeliveryFee= false;
          this.deliveryCharge=0;
          this.grandTotal = this.deliveryCharge + this.locationsSum();
        }

    }

    if(this.isSameAddress ==true){
      console.log('sameDeliveryAddress');
        if(this.paymentForm.get('purchare_type').value==1){
          this.in_process=true;
          this.onChangePincode();  
        }
    }

   if(this.isSameAddress ==false){
      this.defaultDeliveryPhoneNumber = null;
   }
  }
  addressModal(type){
    this.in_process=null;
    if(type === "delivery"){
      this.deliveryAddCity=false;
      const modalRef = this.modalService.open(DeliveryAddressComponent, {
                                      centered: true, 
                                      size: 'xl',
                                      backdrop: 'static',
                                      keyboard: false,
                                      windowClass: 'login_modal'
                                    });
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        this.CountryService.getAddressbyID(receivedEntry).subscribe(
          data => {
            this.deliveryAddress = data;
            this.delivery_address_id = this.deliveryAddress.id;
            this.delivery_web_city= this.deliveryAddress.web_city;
            /*if(this.deliveryAddress.contact_id){
              this.deliveryHaveContactID = true;
            }else{
              this.deliveryHaveContactID = null;
            }*/
            this.getUserDeliveryAddressOrder();
            this.onChangeDeliveryCountry(this.deliveryAddress.country_id);
            this.onChangeDeliveryState(this.deliveryAddress.state_id);
            if(this.deliveryAddress.default == 1){
              this.defaultDeliveryPhoneNumber=true;
            }else{
              this.defaultDeliveryPhoneNumber=null;
            }
            if(this.deliveryAddress.country_id == 0){
              this.country_id_delivery = null;
            }else{
              this.country_id_delivery = this.deliveryAddress.country_id;
            }

            if(this.deliveryAddress.state_id == 0){
              this.state_id_delivery = null;
            }else{
              this.state_id_delivery = this.deliveryAddress.state_id;
            }

            if(this.deliveryAddress.town_id == 0){
              this.town_id_delivery = null;
            }else{
              this.town_id_delivery = this.deliveryAddress.town_id;
            }
            this.paymentForm.controls['delivery_id'].enable();
            this.paymentForm.controls['delivery_name'].enable();
            this.paymentForm.controls['delivery_address'].enable();
            this.paymentForm.controls['delivery_pin'].enable();
            this.paymentForm.controls['delivery_phone'].enable();
            this.paymentForm.controls['country_id_delivery'].enable();
            this.paymentForm.controls['state_id_delivery'].enable();
            this.paymentForm.controls['town_id_delivery'].enable();
            this.paymentForm.controls['delivery_web_city'].enable();
            this.paymentForm.get('delivery_name').setValidators([Validators.required, Validators.pattern(this.pattern)]); //
            this.paymentForm.get('delivery_phone').setValidators([Validators.required, Validators.pattern('[6-9]\\d{9}')]);
            this.paymentForm.get('delivery_address').setValidators([Validators.required]);
            this.paymentForm.controls['delivery_pin'].setValidators([Validators.required, Validators.minLength(6),Validators.pattern("^[1-9][0-9]{5}$")]);
            this.paymentForm.controls['country_id_delivery'].setValidators([Validators.required]);
            this.paymentForm.controls['state_id_delivery'].setValidators([Validators.required]);

             if(this.paymentForm.get('purchare_type').value==1){
               // alert('addressModalnext');
                this.onChangePincode();  
             }
            this.paymentForm.patchValue({
                delivery_id : this.deliveryAddress.id,
                delivery_name: this.deliveryAddress.name,
                delivery_address:this.deliveryAddress.address,
                delivery_phone:this.deliveryAddress.phone,
                delivery_pin:this.deliveryAddress.pin,
                country_id_delivery:this.country_id_delivery,
                state_id_delivery:this.state_id_delivery,
                town_id_delivery:this.town_id_delivery,
                delivery_web_city:this.delivery_web_city
            });  

            if(this.deliveryAddress.web_city!=''){
               setTimeout(()=>{  
                  this.checkExistingCity('delivery');
               },9000);
            }

        });
      })
       
    // if( this.paymentForm.get('purchare_type').value==1){
    //     alert('addressModal');
    //     if(this.paymentForm.get('purchare_type').value==1){
    //       setTimeout(()=>{  
    //           this.onChangePincode();  
    //       },7000);
    //     }
    // }
     /* setTimeout(()=>{  

                    if( this.paymentForm.get('purchare_type').value==1){
                        console.log('addressModalSecond');
                        if(this.paymentForm.get('purchare_type').value==1){
                          this.onChangePincode();  
                        }
                    }
        },5000);*/

    }else{

      this.billingAddCity=null;
      const modalRef = this.modalService.open(BillingAddressComponent, {
                                                          centered: true, 
                                                          size: 'xl',
                                                          backdrop: 'static',
                                                          keyboard: false,
                                                          windowClass: 'login_modal'
                                                      });
      modalRef.componentInstance.passEntry.subscribe((receivedEntry) => {
        this.CountryService.getAddressbyID(receivedEntry).subscribe(
          data => {
            this.billingAddress = data;
            this.billing_address_id = this.billingAddress.id; 
            this.billing_web_city= this.billingAddress.web_city;
            if(this.billingAddress.default == 1){
              this.defaultPhoneNumber=true;
            }else{
              this.defaultPhoneNumber=null;
            }
            this.getUserOrders();
            if(this.billingAddress.contact_id != 0){
              this.billingHaveContactID = true;
            }else{
              this.billingHaveContactID = null;
            }
            console.log(this.billingHaveContactID);
            this.onChangeCountry(this.billingAddress.country_id);
            this.onChangeState(this.billingAddress.state_id);
            if(this.billingAddress.country_id == 0){
              this.country_id_billing = null;
            }else{
              this.country_id_billing = this.billingAddress.country_id;
            }

            if(this.billingAddress.state_id == 0){
              this.state_id_billing = null;
            }else{
              this.state_id_billing = this.billingAddress.state_id;
            }

            if(this.billingAddress.town_id == 0){
              this.town_id_billing = null;
            }else{
              this.town_id_billing = this.billingAddress.town_id;
            }
            this.paymentForm.patchValue({ 
              billing_id : this.billingAddress.id,
              billing_name: this.billingAddress.name,
              billing_address:this.billingAddress.address,
              billing_pin:this.billingAddress.pin,
              billing_phone:this.billingAddress.phone,
              country_id_billing:this.country_id_billing,
              state_id_billing:this.state_id_billing,
              town_id_billing:this.town_id_billing,
              billing_web_city:this.billing_web_city
            });

            this.isDisableFirst = false;
            this.isDisableSecond = false;
            if(this.paymentForm.get('purchare_type').value == 1){
              this.isSameAddress =false;
              this.deliveryHaveContactID =null;
              localStorage.removeItem('billing_id');
              this.paymentForm.controls['delivery_id'].enable();
              this.paymentForm.controls['delivery_name'].enable();
              this.paymentForm.controls['delivery_address'].enable();
              this.paymentForm.controls['delivery_pin'].enable();
              this.paymentForm.controls['delivery_phone'].enable();
              this.paymentForm.controls['country_id_delivery'].enable();
              this.paymentForm.controls['state_id_delivery'].enable();
              this.paymentForm.controls['town_id_delivery'].enable();
              this.paymentForm.get('delivery_name').setValidators([Validators.required, Validators.pattern(this.pattern)]); //
              this.paymentForm.get('delivery_phone').setValidators([Validators.required, Validators.pattern('[6-9]\\d{9}')]);
              this.paymentForm.get('delivery_address').setValidators([Validators.required]);
              this.paymentForm.controls['delivery_pin'].setValidators([Validators.required, Validators.minLength(6),Validators.pattern("^[1-9][0-9]{5}$")]);
              this.paymentForm.controls['country_id_delivery'].setValidators([Validators.required]);
              this.paymentForm.controls['state_id_delivery'].setValidators([Validators.required]);
              this.paymentForm.controls['delivery_web_city'].enable();


              this.paymentForm.patchValue({
                delivery_id: '',
                delivery_name: '',
                delivery_address:'',
                delivery_pin:'',
                delivery_phone:'',
                country_id_delivery:null,
                state_id_delivery:null,
                town_id_delivery:null,
                delivery_web_city:null
              });

              if(this.paymentForm.get('purchare_type').value==1){
                    this.displayDeliveryFee= false;
                    this.deliveryCharge=0;
                    this.deliveryAddress.pin=null;
                    this.grandTotal = this.deliveryCharge + this.locationsSum();
                    this.errors = "";  
                    this.formSumbit = false; 
              }


            } 

            if(this.billingAddress.web_city!=''){
               setTimeout(()=>{  
                  this.checkExistingCity('billing');
               },7000);
            }

        });
      });


        if(this.paymentForm.get('purchare_type').value==1){
            this.displayDeliveryFee= false;
            this.deliveryCharge=0;
            this.deliveryAddress.pin=null;
            this.grandTotal = this.deliveryCharge + this.locationsSum();
        }
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  onChangeCountry(countryId: number) {
    if(this.isSameAddress == true){
      this.isSameAddress = false;
    }
    if (countryId) {
      this.CountryService.getStates(countryId).subscribe(
        data => {
          this.states = data;
          this.cities = null;
        }
      );
    } else {
      this.states = null;
      this.cities = null;
    }
    this.paymentForm.patchValue({
      state_id_billing:null,
      town_id_billing:null
    });
  }

  onChangeState(stateId: number) {
    if(this.isSameAddress == true){
      this.isSameAddress = false;
    }
    if (stateId) {
      this.CountryService.getCities(stateId).subscribe(
        data => this.cities = data
      );
    } else {
      this.cities = null;
    }
    this.paymentForm.patchValue({
      town_id_billing:null
    });
  }

  onChangeDeliveryCountry(countryId: number) {
    if (countryId) {
      this.CountryService.getStates(countryId).subscribe(
        data => {
          this.deliveryState = data;
          this.deliveryCities = null;
        }
      );
    } else {
      this.CountryService.getStates(111).subscribe(
        data => {
          this.deliveryState = data;
          this.deliveryCities = null;
        }
      );
    }
    this.paymentForm.patchValue({
      state_id_delivery:null,
      town_id_delivery:null
    });

  }

  onChangeDeliveryState(stateId: number) {
    if (stateId) {
      this.CountryService.getCities(stateId).subscribe(
        data => this.deliveryCities = data
      );
    } else {
      this.deliveryCities = null;
    }
    this.paymentForm.patchValue({
      town_id_delivery:null
    });
  }

  locationsSum() {
    if(this.carts){
      this.amount = this.carts.map(tag => (tag.gift_box !=1)?tag.product_price*tag.quantity:tag.product_price ).reduce((a, b) => a + b, 0);
    }
    return this.amount;
  }
  getCart(): void {
    this.cartService.getCartItems().subscribe(
      data => this.carts = data
    );
  }
  get f() { return this.paymentForm.controls; }
  isFieldValid(field: string) {
    return !this.paymentForm.get(field).valid && this.paymentForm.get(field).touched;
  }
  onSubmit(){
    
    this.errors = '';
    this.submitted = true;
    this.isLoading = true;
    this.formSumbit = true;
    this.payment_type_error = false; 
    if(this.paymentForm.invalid){
      this.isLoading = false;
      this.formSumbit = false;
      this.scrollToError();
      return;
    }
    let payTyp:any;
    payTyp=$("input[name='payment_type']:checked").val();
    

    if(typeof payTyp==='undefined'){ 
        this.formSumbit = false;
        this.isLoading = false;
        this.payment_type_error = true;   
        $('#submitlink2').removeClass('buttonDisable');  
        const firstElementWithError1 = document.querySelector('#custom-panel-3-header');  
        this.scrollTo(firstElementWithError1);   
        return;
    } 
    if(this.showDiscount){
    }else{
        this.discount=null;
        console.log("after"+this.discount);  
        this.paymentForm.patchValue({
          discount:"",
          discount_value:""
        });  
    }

    this.CountryService.payment(this.paymentForm.value).subscribe(
      result => {  
        //this.inoiveAttachment();
        this.paymentOrder = result;
        if((this.paymentOrder.delivery_address_id != null) && (this.paymentOrder.delivery_address_id != this.paymentOrder.billing_address_id )) {
          this.confirmationDialogService.confirm('', 'Do you want to attach invoice with this order ?')
          .then((confirmed) => {
            if(confirmed === true){
              this.invoiceAttachment = 1;
              localStorage.setItem("invoice_attachment", '1');
            }else{
              this.isLoading = false;
              this.invoiceAttachment = 0;
              localStorage.setItem("invoice_attachment", '0');
            }
            this.isLoading = false;
            this.formSumbit = false;
            this.responseHandler(result);
          })
          .catch(
            () => {
              this.isLoading = false;
              this.formSumbit = false;
              console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
            }
          );
        }else{
          this.isLoading = false;
          this.formSumbit = false;
          this.invoiceAttachment = 0;
          localStorage.setItem("invoice_attachment", '0');
          this.responseHandler(result);
        }
       
        
      },
      error => {
        this.isLoading = false;
        this.errors = error.error;
        console.log(this.errors);
        this.submitted = false;
        this.formSumbit = false;

        
         if(this.errors.error!='' && this.errors.error=='Your cart is empty'){
            const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                            size: 'xl',
                            backdrop: 'static',
                            keyboard: false,
                            windowClass: 'cartadd_modal',
                            animation: true,
                            ariaDescribedBy:'test',
                          });
            modalRef.componentInstance.message = this.errors.error_msg; 
            modalRef.componentInstance.timeout_val = 5000; 
            this.router.navigate(['/']);

        }

      },() => {
        //this.paymentForm.reset();
      }
    );
  }

  inoiveAttachment(){
    this.confirmationDialogService.confirm('', 'Do you want to attach invoice with this order ?')
    .then((confirmed) => {
      if(confirmed === true){
        this.invoiceAttachment = 1;
        localStorage.setItem("invoice_attachment", '1');
      }else{
        this.invoiceAttachment = 0;
        localStorage.setItem("invoice_attachment", '0');
      }
      return true;
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    
  }

  responseHandler(data){
    localStorage.removeItem('billing_id');
    localStorage.removeItem('delivery_id');
    console.log(data);
    if(data.payment_type === "1"){
      this.payWithRazor(data);
    }else{
      this.payWithCOD(data);
    }
  }
  
  payWithRazor(val) {
    console.log(val);
    this.isLoading = true;
    this.formSumbit = true; 
    const options: any = {
      reference_id: val.id,
      key: this.paymentKey ,
      amount: val.amount, // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: 'Bolas E Cart', // company name or product name
      description: '',  // product description
      image: '', // company logo or product image
      order_id: val.razor_order_id, // order_id created by you in backend
      modal: {
        // We should prevent closing of the form when esc key is pressed.
        escape: false,
      },
      notes: {
        // include notes if any
      },
      theme: {
        color: '#0c238a'
      }
    };
    options.handler = ((response, error) => {
      options.response = response;
      this.thankyou(response,val);
      // call your backend api to verify payment signature & capture transaction
    });
    options.modal.ondismiss = (() => {
      // handle the case when user closes the form while transaction is in progress
      this.isLoading = false;
      this.formSumbit = false;
      console.log('Transaction cancelled.');
    });
    const rzp = new this.winRef.nativeWindow.Razorpay(options);
    rzp.open(); 
    rzp.on('payment.failed', function (response){
      console.log(response.error);
      this.paymentFailer(response.error);
    });
  }
  payWithCOD(data){
    this.isLoading = true;
    this.formSumbit = true;
    this.CountryService.payWithCOD(data).subscribe(
      result => {
        console.log(result);
        this.isLoading = false;
        this.formSumbit = false;
        this.invoiceConfirmation(result.data.id);
        this.router.navigate(['/thank-you',result.data.id]);
      },
      error => {
        this.isLoading = false;
        this.formSumbit = false;
        this.errors = error.error;
      },() => {
       // this.paymentForm.reset();
      }
    );
  }
  thankyou(response,val){
    this.isLoading = true;
    this.formSumbit = true;
    const payload = {
      payment_id: response.razorpay_payment_id,
      id: val.id,
    };
    this.CountryService.payWithRay(payload).subscribe(
      result => {
        this.isLoading = false;
        this.formSumbit = false;
        this.invoiceConfirmation(result.data.id);
        this.router.navigate(['/thank-you',result.data.id]);
      },
      error => {
        this.isLoading = false;
        this.formSumbit = false;
        this.errors = error.error;
      },() => {
        //this.paymentForm.reset();
       
      }
    );
  }
  paymentFailer(error){
    this.isLoading = false;
  }

  invoiceConfirmation(id){
    const payload = {
      attachInvoice: localStorage.getItem("invoice_attachment"),
      id: id
    };
    this.cartService.addInvoiceAttachment(payload).subscribe(
      data => {
        this.deliveryCharge = data;
      }
    );
  }

  changeType(e){
    this.formSumbit=false;
    this.in_process=null;

    //  this.discount=null;
    //  this.showDiscount=null;
    //  this.discountAmount.discount_value="";
    //  this.discountAmount.discount_amount=0
    //  console.log("beforediscount"+this.discount);   
    //   this.paymentForm.patchValue({
    //     discount:"",
    //     discount_value:""
    //   }); 
    // console.log("afterdiscount"+this.discount); 

    var target = e.target;
    if (target.checked) {
      this.isDisableFirst = false;
      if(this.paymentForm.get('billing_name').value == ""){
        this.isDisableSecond = true;
      }else{
        this.isDisableSecond = false;
      }
      if(target.value == 1) {
        this.isDisableDiveliveryAddress = true;
        this.displayDeliveryPincode = true;
        this.onChangePincode();
        
        this.OrderService.getMyLastOrder().subscribe(
          data =>{
            this.lastOrder = data;
            if(this.lastOrder.cod == 1){
              this.displayCOD = true;
            }
            else{
              this.displayCOD = false;
            }
          }
        )
        if(this.paymentForm.get('billing_address').value == null){
          this.isSameAddress = false;
          this.accepted = false;
          this.paymentForm.get('delivery_name').setValidators([Validators.required, Validators.pattern(this.pattern)]); //
          this.paymentForm.get('delivery_phone').setValidators([Validators.required, Validators.pattern('[6-9]\\d{9}')]);
          this.paymentForm.get('delivery_address').setValidators([Validators.required]);
          this.paymentForm.controls['delivery_pin'].setValidators([Validators.required]);
          this.paymentForm.controls['country_id_delivery'].setValidators([Validators.required]);
          this.paymentForm.controls['state_id_delivery'].setValidators([Validators.required]);
          return false;
        }
        this.paymentForm.get('delivery_name').setValidators([Validators.required, Validators.pattern(this.pattern)]); //
        this.paymentForm.get('delivery_phone').setValidators([Validators.required, Validators.pattern('[6-9]\\d{9}')]);
        this.paymentForm.get('delivery_address').setValidators([Validators.required]);
        this.paymentForm.controls['delivery_pin'].setValidators([Validators.required, Validators.minLength(6),Validators.pattern("^[1-9][0-9]{5}$")]);
        this.paymentForm.controls['country_id_delivery'].setValidators([Validators.required]);
        this.paymentForm.controls['state_id_delivery'].setValidators([Validators.required]);
        
      }else {
        this.deliveryAddress = [];  
        this.errors = '';
        this.deliveryCharge = 0;
        this.displayCOD = false;
        this.displayDeliveryFee= false;
        this.displayDeliveryPincode = false;
        this.grandTotal = 0;
        this.discount = this.paymentForm.get('discount').value;
        if(this.discount != ''){
          this.applyDiscount();
        }
        if(this.paymentForm.get('payment_type').value == 2){
          this.paymentForm.patchValue({
              payment_type: ''
          });
        }

        this.isDisableDiveliveryAddress = false;
        this.paymentForm.get('billing_address').clearValidators();
        this.paymentForm.get('billing_address').updateValueAndValidity();
        this.paymentForm.get('delivery_name').clearValidators();
        this.paymentForm.get('delivery_name').updateValueAndValidity();
        this.paymentForm.get('delivery_phone').clearValidators();
        this.paymentForm.get('delivery_phone').updateValueAndValidity();
        this.paymentForm.get('delivery_address').clearValidators();
        this.paymentForm.get('delivery_address').updateValueAndValidity();
        this.paymentForm.get('delivery_pin').clearValidators();
        this.paymentForm.get('delivery_pin').updateValueAndValidity();
        this.paymentForm.get('country_id_delivery').clearValidators();
        this.paymentForm.get('country_id_delivery').updateValueAndValidity();
        this.paymentForm.get('state_id_delivery').clearValidators();
        this.paymentForm.get('state_id_delivery').updateValueAndValidity();
        this.paymentForm.get('town_id_delivery').clearValidators();
        this.paymentForm.get('town_id_delivery').updateValueAndValidity();
      }
    } else {
      this.isDisableFirst = true;
      this.isDisableSecond = true;
    }
    
  }
  enableThirdEnable(e){
    this.isDisableFirst = false;
    this.isDisableSecond = false;
  }
  onKeyUp(e){
  }
  getDefaultAddress(){
    
    this.cartService.getDefaultAddress().subscribe(
      data => {
        this.defaultAddress = data;
        this.defaultPhoneNumber=true;
        /*if(this.defaultAddress.contact_id == 0){
          this.billingHaveContactID = false;
        }else{
          this.billingHaveContactID = true;
        }
        console.log(this.defaultAddress.contact_id);*/
        this.billing_address_id = this.defaultAddress.id;
        const payload = {
          address_id: this.billing_address_id
        };
        this.OrderService.getMyOrderByAddress(payload).subscribe(
          data => {
            this.orders = data;
            this.deliveryHaveContactID = null;
            
            if(this.orders == 0){
              this.displayChooseAddress = false;
              this.billingHaveContactID = null;
            }else{
              this.displayChooseAddress = true;
              this.billingHaveContactID = true;
            }
            //localStorage.setItem("cart_count", JSON.stringify(0));
          }
        );
        if(this.defaultAddress.country_id == 0){
          this.country_id_billing = null;
        }else{
          this.country_id_billing = this.defaultAddress.country_id;
        }

        if(this.defaultAddress.state_id == 0){
          this.state_id_billing = null;
        }else{
          this.state_id_billing = this.defaultAddress.state_id;
        }

        if(this.defaultAddress.town_id == 0){
          this.town_id_billing = null;
        }else{
          this.town_id_billing = this.defaultAddress.town_id;
        }

        this.onChangeCountry(this.country_id_billing);
        this.onChangeState(this.state_id_billing);

        this.CountryService.getUserStoreDetails().subscribe(
          data => {
            this.store = data;
            if(this.country_id_billing == null){
              this.onChangeCountry(this.store.country_id);
            }
            if(this.state_id_billing == null){
              this.onChangeState(this.store.state_id);
            }
            this.paymentForm.patchValue({ 
              billing_name: this.defaultAddress.name,
              billing_address:this.defaultAddress.address,
              billing_pin:this.defaultAddress.pin,
              billing_phone:this.defaultAddress.phone,
              country_id_billing:(this.country_id_billing != null)?this.country_id_billing:this.store.country_id,
              state_id_billing:(this.state_id_billing!= null)? this.state_id_billing : this.store.state_id,
              town_id_billing:this.town_id_billing,
              billing_id:this.defaultAddress.id,
              billing_web_city:this.defaultAddress.web_city,
            });
          }
        );
        
        this.isDisableFirst = false;
        this.isDisableSecond = false;

        
        this.paymentForm.controls['delivery_name'].disable();
        if(this.defaultAddress.address != '' || this.defaultAddress.address != null){
          this.isSameAddress = true;
          this.paymentForm.controls['delivery_address'].disable();
        }else{
          this.isSameAddress = false;
          this.paymentForm.controls['delivery_address'].enable();
          this.paymentForm.controls['delivery_pin'].enable();
          this.paymentForm.controls['delivery_phone'].enable();
          this.paymentForm.controls['country_id_delivery'].enable();
          this.paymentForm.controls['state_id_delivery'].enable();
          this.paymentForm.controls['town_id_delivery'].enable();
          this.paymentForm.controls['delivery_web_city'].enable();

          this.paymentForm.get('delivery_address').setValidators([Validators.required]);
            this.paymentForm.controls['delivery_pin'].setValidators([Validators.required]);
            this.paymentForm.controls['country_id_delivery'].setValidators([Validators.required]);
            this.paymentForm.controls['state_id_delivery'].setValidators([Validators.required]);
        }
        if(this.defaultAddress.address == '' || this.defaultAddress.address == null){
          this.isSameAddress = false;
          this.accepted = null;
          this.deliveryHaveContactID =null;
          this.onChangeDeliveryCountry(111);
          this.paymentForm.controls['delivery_id'].enable();
          this.paymentForm.controls['delivery_name'].enable();
          this.paymentForm.controls['delivery_address'].enable();
          this.paymentForm.controls['delivery_pin'].enable();
          this.paymentForm.controls['delivery_phone'].enable();
          this.paymentForm.controls['country_id_delivery'].enable();
          this.paymentForm.controls['state_id_delivery'].enable();
          this.paymentForm.controls['town_id_delivery'].enable();
          this.paymentForm.controls['delivery_web_city'].enable();
          this.paymentForm.patchValue({
            delivery_id: '',
            delivery_name: '',
            delivery_address:'',
            delivery_pin:'',
            delivery_phone:'',
            country_id_delivery:111,
            state_id_delivery:'',
            town_id_delivery:null,
            delivery_web_city:null
          });
          if(this.paymentForm.get('purchare_type').value == 1){
            this.paymentForm.get('delivery_name').setValidators([Validators.required, Validators.pattern(this.pattern)]); //
            this.paymentForm.get('delivery_phone').setValidators([Validators.required, Validators.pattern('[6-9]\\d{9}')]);
            this.paymentForm.get('delivery_address').setValidators([Validators.required]);
            this.paymentForm.controls['delivery_pin'].setValidators([Validators.required, Validators.minLength(6),Validators.pattern("^[1-9][0-9]{5}$")]);
            this.paymentForm.controls['country_id_delivery'].setValidators([Validators.required]);
            this.paymentForm.controls['state_id_delivery'].setValidators([Validators.required]);            
          }
          return false;
        }
        
        this.paymentForm.controls['delivery_pin'].disable();
        this.paymentForm.controls['delivery_phone'].disable();
        this.paymentForm.controls['country_id_delivery'].disable();
        this.paymentForm.controls['state_id_delivery'].disable();
        this.paymentForm.controls['town_id_delivery'].disable();
        this.paymentForm.controls['delivery_id'].disable();
        this.paymentForm.controls['delivery_web_city'].disable();
        this.getUserDeliveryAddressOrder();
        
        if(this.defaultAddress.country_id == 0){
            this.country_id_delivery = 111;
        }else{
            this.country_id_delivery = this.defaultAddress.country_id;
        }

          if(this.defaultAddress.state_id == 0){
            this.state_id_delivery = null;
          }else{
            this.state_id_delivery = this.defaultAddress.state_id;
          }

          if(this.defaultAddress.town_id == 0){
            this.town_id_delivery = null;
          }else{
            this.town_id_delivery = this.defaultAddress.town_id;
          }
          this.onChangeDeliveryCountry(this.country_id_delivery);
          this.onChangeDeliveryState(this.state_id_delivery);
          if(this.country_id_delivery == null){
            this.onChangeDeliveryCountry(this.store.country_id);
          }
          if(this.state_id_delivery == null){
            this.onChangeDeliveryState(this.store.state_id);
          }
        this.paymentForm.patchValue({
          delivery_id:  this.defaultAddress.id,
          delivery_name: this.defaultAddress.name,
          delivery_address:this.defaultAddress.address,
          delivery_pin:this.defaultAddress.pin,
          delivery_phone:this.defaultAddress.phone,
          country_id_delivery:this.country_id_delivery,
          state_id_delivery:this.state_id_delivery,
          town_id_delivery:this.town_id_delivery,
          sameaddress: 1,
          delivery_web_city:this.delivery_web_city,
        });
      }
    );
  }
  onChangePincode(){
    let pincode_new = this.deliveryAddress.pin;
     console.log( 'deliveryAddressPin ' + pincode_new)
    if(pincode_new == '' || typeof pincode_new=='undefined'){ 
      pincode_new = this.paymentForm.get('delivery_pin').value;
    }
   
    console.log('pincode_new'+pincode_new);
    this.errors = '';
    this.formSumbit=false; 
    this.cartService.getDeliveryCharge(pincode_new).subscribe(
      data => {
        this.getdelivery = data;
        if(this.getdelivery){
          if(this.getdelivery.home_delivery ==1 ){
            this.displayDeliveryFee = true;
            this.deliveryCharge = this.getdelivery.charge;
             this.paymentForm.patchValue({
                 delivery_fee_val:this.getdelivery.charge,
                 is_ship_rocket:this.getdelivery.is_ship_rocket, 
                 base_charge:this.getdelivery.base_charge,
             });
            this.grandTotal = this.deliveryCharge + this.locationsSum();
            this.discount = this.paymentForm.get('discount').value;
            if(this.discount != ''){
              this.applyDiscount();
            }
            this.getPincode();
            if(this.getdelivery.charge==0){
                this.displayDeliveryFee = false;
            }
          }else{
            this.grandTotal = this.locationsSum();
          }
        }
        else{
          this.grandTotal = 0;
          this.displayDeliveryFee = false;
        } 
        this.in_process=null;
      }, error =>{
             this.deliveryCharge=0;
             this.displayDeliveryFee = false;
             if(this.grandTotal !=0){
               this.grandTotal = this.deliveryCharge + this.locationsSum() ;
             }
             this.formSumbit=true;
             this.errors = error.error;
             this.in_process=null;
          }
    );

  }
  trim(myString) { 
    console.log(myString);
    this.paymentForm.patchValue({
      billing_phone: myString.replace(" ",''),
    });
  }
  trimDeliveryPhone(myString){
    this.paymentForm.patchValue({
      delivery_phone: myString.replace(" ",''),
    });
  }
  checkLastOrderDetails(){
    
    this.OrderService.getMyLastOrder().subscribe(
      data =>{
        this.lastOrder = data;
        this.lastOrderDeliveryType = this.lastOrder.delivery_type;
        this.lastOrderPaymentType = this.lastOrder.payment_type;
        this.paymentForm.patchValue({
          purchare_type: this.lastOrderDeliveryType,
          payment_type: this.lastOrderPaymentType,
        });
        
        if(this.lastOrderDeliveryType ==1){
          if(this.lastOrder.cod == 1){
            this.displayCOD = true;
          }
          if(this.lastOrder.home_delivery == 1){
            this.isSameAddress = true;
            this.isDisableDiveliveryAddress = true;
            this.displayDeliveryFee= true;
            this.displayDeliveryPincode =true;
          }else{
            this.displayCOD = false;
          }
         
          setTimeout(()=>{
             //alert(this.paymentForm.get('delivery_pin').value);
              this.onChangePincode();
          },500);
        } 
        else
        {
          this.displayDeliveryFee= false;
        }
        if( this.lastOrder.payment_type == 2 && this.lastOrder.cod != 1 ){
          this.paymentForm.patchValue({
            payment_type: ''
          });
        }
        if( this.lastOrder.home_delivery != 1 ){
          this.paymentForm.patchValue({
            purchare_type: '',
            payment_type: '',
          });
        }
        if( this.lastOrder.delivery_type == 2 && this.lastOrder.cod == 1 && this.lastOrder.payment_type == 2 ){
          this.paymentForm.patchValue({
            payment_type: ''
          });
        }
      }
    );
  }
  addAddress(type){
    
    this.in_process=null;
    this.displayDeliveryFee= false; 
    this.errors = "";  
    this.formSumbit = false; 
    this.deliveryCharge=0;
    this.grandTotal = this.deliveryCharge + this.locationsSum();
    this.defaultPhoneNumber=null;
    this.billingHaveContactID = null;
    this.onChangeState(0);
    this.paymentForm.patchValue({ 
      billing_name: '',
      billing_address:'',
      billing_pin:'',
      billing_phone:'',
      country_id_billing:111,
      state_id_billing:'',
      town_id_billing:null,
      billing_id:"",
      billing_web_city:'',
    });

    //rest
    this.deliveryAddress.pin=null;
    this.billingAddCity=null;
    if(this.isSameAddress == true ){ 
      this.defaultDeliveryPhoneNumber=null;
      this.isSameAddress =false;
      this.deliveryHaveContactID =null;
      localStorage.removeItem('billing_id');
      this.paymentForm.controls['delivery_id'].enable();
      this.paymentForm.controls['delivery_name'].enable();
      this.paymentForm.controls['delivery_address'].enable();
      this.paymentForm.controls['delivery_pin'].enable();
      this.paymentForm.controls['delivery_phone'].enable();
      this.paymentForm.controls['country_id_delivery'].enable();
      this.paymentForm.controls['state_id_delivery'].enable();
      this.paymentForm.controls['town_id_delivery'].enable();
      this.paymentForm.controls['delivery_web_city'].enable();
      if(this.paymentForm.get('purchare_type').value == 1){
        this.paymentForm.get('delivery_name').setValidators([Validators.required, Validators.pattern(this.pattern)]); //
        this.paymentForm.get('delivery_phone').setValidators([Validators.required, Validators.pattern('[6-9]\\d{9}')]);
        this.paymentForm.get('delivery_address').setValidators([Validators.required]);
        this.paymentForm.controls['delivery_pin'].setValidators([Validators.required, Validators.minLength(6),Validators.pattern("^[1-9][0-9]{5}$")]);
        this.paymentForm.controls['country_id_delivery'].setValidators([Validators.required]);
        this.paymentForm.controls['state_id_delivery'].setValidators([Validators.required]);
        this.paymentForm.patchValue({
          delivery_id: '',
          delivery_name: '',
          delivery_address:'',
          delivery_pin:'',
          delivery_phone:'',
          country_id_delivery:111,
          state_id_delivery:'',
          town_id_delivery:null,
          delivery_web_city:'',
        });

        //rest
        this.deliveryAddress.pin=null;
      }
    } else{
      this.paymentForm.controls['delivery_id'].enable();
      this.paymentForm.controls['delivery_name'].enable();
      this.paymentForm.controls['delivery_address'].enable();
      this.paymentForm.controls['delivery_pin'].enable();
      this.paymentForm.controls['delivery_phone'].enable();
      this.paymentForm.controls['country_id_delivery'].enable();
      this.paymentForm.controls['state_id_delivery'].enable();
      this.paymentForm.controls['town_id_delivery'].enable();
      this.paymentForm.controls['delivery_web_city'].enable();

      this.paymentForm.get('billing_address').clearValidators();
      this.paymentForm.get('billing_address').updateValueAndValidity();
      this.paymentForm.get('delivery_name').clearValidators();
      this.paymentForm.get('delivery_name').updateValueAndValidity();
      this.paymentForm.get('delivery_phone').clearValidators();
      this.paymentForm.get('delivery_phone').updateValueAndValidity();
      this.paymentForm.get('delivery_address').clearValidators();
      this.paymentForm.get('delivery_address').updateValueAndValidity();
      this.paymentForm.get('delivery_pin').clearValidators();
      this.paymentForm.get('delivery_pin').updateValueAndValidity();
      this.paymentForm.get('country_id_delivery').clearValidators();
      this.paymentForm.get('country_id_delivery').updateValueAndValidity();
      this.paymentForm.get('state_id_delivery').clearValidators();
      this.paymentForm.get('state_id_delivery').updateValueAndValidity();
      this.paymentForm.get('town_id_delivery').clearValidators();
      this.paymentForm.get('town_id_delivery').updateValueAndValidity();
    }
    if(this.paymentForm.get('purchare_type').value == 1){
      this.paymentForm.get('delivery_name').setValidators([Validators.required, Validators.pattern(this.pattern)]); //
      this.paymentForm.get('delivery_phone').setValidators([Validators.required, Validators.pattern('[6-9]\\d{9}')]);
      this.paymentForm.get('delivery_address').setValidators([Validators.required]);
      this.paymentForm.controls['delivery_pin'].setValidators([Validators.required, Validators.minLength(6),Validators.pattern("^[1-9][0-9]{5}$")]);
      this.paymentForm.controls['country_id_delivery'].setValidators([Validators.required]);
      this.paymentForm.controls['state_id_delivery'].setValidators([Validators.required]);
      this.paymentForm.patchValue({
        delivery_id: '',
        delivery_name: '',
        delivery_address:'',
        delivery_pin:'',
        delivery_phone:'',
        country_id_delivery:111,
        state_id_delivery:'',
        town_id_delivery:null
      });
    }else{
      this.paymentForm.get('billing_address').clearValidators();
      this.paymentForm.get('billing_address').updateValueAndValidity();
      this.paymentForm.get('delivery_name').clearValidators();
      this.paymentForm.get('delivery_name').updateValueAndValidity();
      this.paymentForm.get('delivery_phone').clearValidators();
      this.paymentForm.get('delivery_phone').updateValueAndValidity();
      this.paymentForm.get('delivery_address').clearValidators();
      this.paymentForm.get('delivery_address').updateValueAndValidity();
      this.paymentForm.get('delivery_pin').clearValidators();
      this.paymentForm.get('delivery_pin').updateValueAndValidity();
      this.paymentForm.get('country_id_delivery').clearValidators();
      this.paymentForm.get('country_id_delivery').updateValueAndValidity();
      this.paymentForm.get('state_id_delivery').clearValidators();
      this.paymentForm.get('state_id_delivery').updateValueAndValidity();
      this.paymentForm.get('town_id_delivery').clearValidators();
      this.paymentForm.get('town_id_delivery').updateValueAndValidity();
    }
  }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');

    this.scrollTo(firstElementWithError);
  }
  applyDiscountclass(e){
    this.displayButtonBlack = true;
    console.log(e.target.value);
    console.log(e);
    if(e.key == "Backspace" || e.key == "Delete"){
      this.paymentForm.get('discount').valueChanges.subscribe(value => {
        const payload = {
          discount: value,
          amount: this.amount 
        };
        this.OrderService.getDiscount(payload).subscribe(
          data => { 
            //
          }, error =>{
            this.showDiscount = false;
            if(this.grandTotal !=0){
              this.grandTotal = this.deliveryCharge + this.locationsSum() ;
            }
            this.discountGrandtotal  =0 ;
            this.paymentForm.patchValue({
              discount_value:""
            });
          }
        );
      });
    }
  }
  applyDiscount(){
    this.errors = "";
    this.showDiscount = false;
    this.discount = this.paymentForm.get('discount').value;

    const payload = {
      discount: this.discount,
      amount: this.amount 
    };
    this.OrderService.getDiscount(payload).subscribe(
      data => {
        this.discountAmount  = data;
        this.discountAmount1 =  this.discountAmount.discount_amount;
        this.showDiscount = true;
        let total = this.locationsSum() + this.deliveryCharge;
        if(total != 0){
          this.grandTotal = total - this.discountAmount.discount_amount;
        }else{
           this.discountGrandtotal = this.discountAmount.discount_amount;
        }

        // if(this.grandTotal != 0){
        //   this.grandTotal = this.grandTotal-this.discountAmount.discount_amount;
        // }else{
        //    this.discountGrandtotal = this.discountAmount.discount_amount;
        // }

        this.paymentForm.patchValue({
          discount:this.discount,
          discount_value:this.discountAmount.discount_amount
        });
      },
      error => {
        console.log("before"+this.discount); 
        if(this.discount){
          this.errors = error.error;
        }  
        this.discount=null;
        console.log("after"+this.discount); 
        //alert(this.discount);

        this.paymentForm.patchValue({
          discount:"",
          discount_value:""
        }); 
       // alert(this.paymentForm.get('discount').value);
 
        this.submitted = false;
        this.formSumbit = false;
        this.showDiscount = false;
        this.discountGrandtotal  =0 ;
        let total = this.locationsSum() + this.deliveryCharge;
        if(this.grandTotal != 0){
          this.grandTotal = total;
        }
      },() => {
          
       } 
    );
     //this.OrderService.getMyLastOrder().subscribe(
  }

  showStoreInfo(store:any){  
    const modalRef = this.modalService.open(StoreDetailsComponent, { 
                          size: 'md',
                          backdrop: 'static',
                          keyboard: false,
                          windowClass: 'storedetails_modal',
                          animation: true,
                          ariaDescribedBy:'test',
                        });
    modalRef.componentInstance.store = store; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });

  }  

  checkDeliveryFee(){
    if(this.paymentForm.get('purchare_type').value==1){

      if(this.paymentForm.get('delivery_pin').value == '' || typeof this.paymentForm.get('delivery_pin').value=='undefined'){ 
        return false;
      }

        console.log('checkDeliveryFee'+this.paymentForm.get('delivery_pin').value);
        this.deliveryAddress.pin=this.paymentForm.get('delivery_pin').value;
        this.onChangePincode();  
    }
  }


  onKeypressUpDeliveryPin(e){
      //console.log(e.target.value);
      if((e.target.value.length ) >=6){
           console.log(e.target.value.length);
          this.checkDeliveryFee();
      }
  }

  resetSubmit(){
        this.formSumbit = false; 
        this.payment_type_error = false; 
        $('#submitlink2').removeClass('buttonDisable');  
  }

  // Only AlphaNumeric
  keyPressAlpha(event,setType:any) { 

    let chkCity=false;
 
    if((event.target.value.length ) >=4){
        //chkCity=this.checkExistingCity(setType); 
    }

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  checkExistingCity(setType:any){  

       let val='';
       if(setType=='billing'){
           val=this.paymentForm.get('billing_web_city').value; 
       }
       if(setType=='delivery'){
           val=this.paymentForm.get('delivery_web_city').value; 
       }  
       //alert(val);
       //console.log("val"+val);
       console.log("setType"+setType);
       let arr=this.cities;  
       let obj = arr.find((o, i) => {
        
          if (o.town === val) {  
              console.log('match');
              if(setType=='billing'){
                this.paymentForm.patchValue({
                  town_id_billing: o.id,
                });
                 if(val==''){
                   this.paymentForm.controls['billing_web_city'].setValue('');
                   this.billing_web_city='';  
                 }
                 this.billingAddCity=false;
              } 
              if(setType=='delivery'){
                  this.paymentForm.patchValue({
                    town_id_delivery: o.id,
                  });
                  if(val==''){
                    this.paymentForm.controls['delivery_web_city'].setValue('');
                    this.delivery_web_city=''; 
                  }
              }  
                
              return true; // stop searching
          }
       }); 
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }
  addOtherCityBilling($event){
    this.paymentForm.controls['billing_web_city'].setValue('');
    var selectedVal=$event.target.options[$event.target.options.selectedIndex].text;
    this.billing_web_city=''; 
    if(selectedVal=='Other'){
      this.billingAddCity=true;
    }else{ 
      this.billingAddCity=false;
    }
  }
  addOtherCityDelivery(e){
    this.paymentForm.controls['delivery_web_city'].setValue('');
    var selectedVal=e.target.options[e.target.options.selectedIndex].text;
    this.delivery_web_city=''; 
    if(selectedVal=='Other'){ //if(this.deliveryCity==''){
      this.deliveryAddCity=true;
    }else{ 
      this.deliveryAddCity=false;
    }
  }

}
