import * as $ from 'jquery';
declare var $: any;
import { Component, OnInit, Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef } from '@angular/core';
import { AuthService } from '../../shared/auth.service';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { GiftService} from '../gift.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';
import { ErrorNotificationsComponent} from '../../modals/error-notifications/error-notifications.component';
import { LoginComponent } from 'src/app/modals/login/login.component';
import { AuthStateService } from '../../shared/auth-state.service';
@Component({
  selector: 'app-giftbox',
  templateUrl: './giftbox.component.html',
  styleUrls: ['./giftbox.component.css']
})
export class GiftboxComponent implements OnInit {
	giftbox:any = [];
	isLoading:boolean =false;
	errors:any 			= [];
	isSignedIn: boolean;
	constructor(
		private formBuilder: FormBuilder,
	    public router: Router,
	    private GiftService: GiftService,
	    public authService: AuthService,
	    private confirmationDialogService: ConfirmationDialogService,
	    private modalService: NgbModal,
		private auth: AuthStateService,
	) { }

	ngOnInit(): void {
		window.scroll({ 
	      top: 0, 
	      left: 0, 
	      behavior: 'smooth' 
	    });
		this.getGiftBox();
		this.auth.userAuthState.subscribe(val => {
			this.isSignedIn = val;
		});
	}

	getGiftBox(){
		this.isLoading = true;
		this.GiftService.getAllGiftBox().subscribe(
	      data => {
	      	this.isLoading = false;
	      	this.giftbox = data;
	      }
	    );
	}
	addSuggestedItemsTocart(id,slug){
		if(!this.isSignedIn ){ 
			this.openLoginModal(id, slug); 
			return;
		  }
		this.isLoading = true;
		const payload = {
	      id: id
	    };
		this.GiftService.addItemsToGiftCart(payload).subscribe(
	      data => {
	      	this.isLoading = false;
	      	this.router.navigate(['/gift/', slug]);
	      },error => {
	    		//this.router.navigate(['/gift/', slug]);
	    		this.isLoading = false;
	    		this.errors = error.error;
		        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
		                            size: 'xl',
		                            backdrop: 'static',
		                            keyboard: false,
		                            windowClass: 'cartadd_modal',
		                            animation: true,
		                            ariaDescribedBy:'test',
		                          });
		        modalRef.componentInstance.message = this.errors.error;
	      },() => {
	        
	      }
	    );
		
	}
	openLoginModal(id, slug) {
		//history.pushState(null, null, '');
		const modalRef = this.modalService.open(LoginComponent,{ 
												centered: true, 
												size: 'xl',
												backdrop: 'static',
												keyboard: false,
												windowClass: 'login_modal'
											  });
		modalRef.componentInstance.id = 1; // should be the id
	
		modalRef.result.then((result) => {
		  console.log(result);
		  this.auth.userAuthState.subscribe(val => {
			this.isSignedIn = val;
		  });
		  this.addSuggestedItemsTocart(id, slug);
		  
		}).catch((error) => {
		  console.log(error);
		});
	  }
}
