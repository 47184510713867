import * as $ from 'jquery';
declare var $: any;
import { Component, OnInit, Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CountryService} from '../country.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
@Component({
  selector: 'app-myaddress',
  templateUrl: './myaddress.component.html',
  styleUrls: ['./myaddress.component.css']
})
export class MyaddressComponent implements OnInit {
  user: any = [];
  stores: any = [];
  countries: any = [];
  states: any = [];
  cities: any=[];
  myAddressFrom: FormGroup;
  submitted = false;
  errors:any = null;
  myaddress: any = [];
  dfltaddress:any=[];
  isLoading:boolean = false;
  selected :any;
  success:boolean = false;
  store:any =[];
  storename:string = "";
  isDisabled:boolean =false;
  defaulsAddressButton:boolean =false;
  pattern = /^[A-Za-z0-9 \/\s\.!@#$%^&*()~`+\-=\[\]{};':"\\|,.<>\/?]*$/;
  billingCity:any;
  billingAddCity:boolean=false;
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private CountryService: CountryService,
    public authService: AuthService,
    private confirmationDialogService: ConfirmationDialogService
  ) { }

  ngOnInit(): void {
    setTimeout(()=>$('.error-class').fadeOut('fast'),1000);
    this.createForm();
    this.getDefaultAddress();
    this.getMyAddressUser();
    this.getStore();
    this.CountryService.getCountries().subscribe(
      data => this.countries = data
    );
    this.CountryService.getStates(111).subscribe(
      data => this.states = data
    );
    // this.CountryService.getAllTown().subscribe(
    //   data => this.cities = data
    // );
	   
  }
  createForm(){
    this.myAddressFrom = this.formBuilder.group({
      name: ["", [Validators.required, Validators.pattern(this.pattern)]],
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      address:['', [Validators.required]],
      country_id: [111],
      pin:['', [Validators.required, Validators.minLength(6),Validators.pattern("^[1-9][0-9]{5}$")]],
      state_id:[''],
      town_id:[''],
      default:[''],
      address_type:[''],
      id:[''],
      web_city:['']
    });
  }
  onChangeCountry(countryId: number) {
    if (countryId) {
      this.CountryService.getStates(countryId).subscribe(
        data => {
          this.states = data;
          this.cities = null;
        }
      );
    } else {
      this.states = null;
      this.cities = null;
    }
  }

  onChangeState(stateId: number) {
    if (stateId) {
      this.CountryService.getCities(stateId).subscribe(
        data => this.cities = data
      );
    } else {
      this.cities = null;
    }
  }

  getMyAddressUser(){
    this.CountryService.myAddress().subscribe(
      data => this.myaddress = data
    );
  }
  getDefaultAddress(){
    //this.isLoading = true;
    this.errors = null;
    this.CountryService.myDefaultAddress().subscribe(
      data => {
        this.dfltaddress = data;
        if(this.dfltaddress.default ==1 ){
          this.isDisabled = true;
        }else{
          this.isDisabled = false;
        }
        console.log(this.isDisabled);
        this.onChangeState(this.dfltaddress.state_id);
        this.myAddressFrom.patchValue({
            name: this.dfltaddress.name,
            phone: this.dfltaddress.phone,
            address:this.dfltaddress.address,
            pin:this.dfltaddress.pin,
            country_id:this.dfltaddress.country_id,
            state_id:this.dfltaddress.state_id,
            town_id:this.dfltaddress.town_id,
            default:this.dfltaddress.default,
            id:this.dfltaddress.id,
            address_type:this.dfltaddress.address_type,
            web_city:this.dfltaddress.web_city,
        });
        if(this.dfltaddress.contact_id == 0){
          this.defaulsAddressButton = null;
        }else{
          this.defaulsAddressButton = true;
        }
        this.isLoading = false;
        this.selected = this.dfltaddress.address_type;
        console.log(this.dfltaddress.address_type);
      });

  }
  setAddressType(type){
    this.selected = type;
    this.myAddressFrom.patchValue({
      address_type:type
    });
  }
  
  isActive(item) {
  
    if(this.selected==item)
	{
	return this.selected;
	}
  }

  get f() { return this.myAddressFrom.controls; }
  isFieldValid(field: string) {
    return !this.myAddressFrom.get(field).valid && this.myAddressFrom.get(field).touched;
  }
  onSubmit() {
    this.errors ="";
    this.submitted = true;
    
   
    if(this.myAddressFrom.invalid){
      this.scrollToError();
      return;
    }
    
    this.CountryService.updateAddress(this.myAddressFrom.value).subscribe(
      result => {
        console.log(result)
      },
      error => {
        this.errors = error.error;
        window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
      },() => {
	    this.success = true;
        setTimeout(()=>this.reloadComponent(),5000);
      }
    );
    
  }
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
  onReset(){
    this.myAddressFrom.reset();
  }
  editAddress(id){
    this.isLoading = true;
    this.errors = null;
    this.CountryService.getAddressbyID(id).subscribe(
      data => {
        this.dfltaddress = data;
        this.onChangeCountry(this.dfltaddress.country_id);
        this.onChangeState(this.dfltaddress.state_id);
        this.myAddressFrom.patchValue({
            name: this.dfltaddress.name,
            phone: this.dfltaddress.phone,
            address:this.dfltaddress.address,
            pin:this.dfltaddress.pin,
            country_id:this.dfltaddress.country_id,
            state_id:this.dfltaddress.state_id,
            town_id:this.dfltaddress.town_id,
            default:this.dfltaddress.default,
            id:this.dfltaddress.id,
            address_type:this.dfltaddress.address_type,
            web_city:this.dfltaddress.web_city,
        });
        if(this.dfltaddress.web_city!=''){
          this.billingAddCity=true;
        }
        if(this.dfltaddress.default == 1){
          this.defaulsAddressButton = true;
        }else{
          this.defaulsAddressButton = null;
        }
        this.isLoading=false;
		    this.selected = this.dfltaddress.address_type;
		    window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
      });
  }
  deleteAddress(id){
    this.errors = null;
    this.confirmationDialogService.confirm('', 'Are you sure you want to delete this address ?')
    .then((confirmed) => {
      if(confirmed === true){
        this.CountryService.deleteAddressbyID(id).subscribe(
          result => {
            this.store = result;
            console.log(this.store);
            this.storename = this.store.seo_url;
            this.reloadComponent();
          },
          error => {
            this.errors = error.error;
            window.scroll({ 
              top: 0, 
              left: 0, 
              behavior: 'smooth' 
            });
          },() => {
          }
        )
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    
  }
  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      result => {
        this.store = result;
        console.log(this.store);
        this.storename = this.store.shop_name
      },
      error => {
        
      },() => {
      }
    );
  }
  trim(myString) { 
    console.log(myString);
    this.myAddressFrom.patchValue({
      phone: myString.replace(" ",''),
    });
    
  }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    console.log("aa:", firstElementWithError);

    this.scrollTo(firstElementWithError);
  }

  // Only AlphaNumeric
  keyPressAlpha(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }
  addOtherCityBilling($event){
    this.myAddressFrom.controls['web_city'].setValue('');  
    var selectedVal=$event.target.options[$event.target.options.selectedIndex].text;
    if(selectedVal=='Other'){
      this.billingAddCity=true;
    }else{ 
      this.billingAddCity=false;
    }

     let text =this.billingCity
     let position = text.search("web_city"); 
    if(position>-1){
      let seltext = $event.target.options[$event.target.options.selectedIndex].text;
      this.myAddressFrom.controls['web_city'].setValue(seltext);
    }else{
      this.myAddressFrom.controls['web_city'].setValue(''); 
    } 
  }


  checkExistingCity(){  
    
       let val=this.myAddressFrom.get('web_city').value; 
       let arr=this.cities; 
       let obj = arr.find((o, i) => {
        
          if (o.town === val) { 
                 console.log('match');
             
                  this.myAddressFrom.patchValue({
                    town_id: o.id,
                  });
                 this.myAddressFrom.controls['web_city'].setValue('');
                 this.billingCity=''; 
                 this.billingAddCity=false;
            
              return true; // stop searching
          }
       }); 
  }
}
