import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../cart.service';
import { Router } from '@angular/router';
import { identifierName } from '@angular/compiler';
import { CountryService} from '../country.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ErrorNotificationsComponent} from '../modals/error-notifications/error-notifications.component';
import { CartGiftItemsComponent} from '../modals/cart-gift-items/cart-gift-items.component';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  carts:any = [];
  cartDetails;
  subtotal:number =0;
  grandTotal:number =0;
  amount = 0;
  totalItem:any = 0
  count:any =0;
  store:any =[];
  storename:string = "";
  isLoading = false;
  isDisableButton:boolean = false;
  errors:any = "";
  session_id:string ="";
  process_error:any= "";
  inStock:boolean = true;
  stock:any =0;
  gftMsg:any;
  giftStockCheck:number = 0;
  constructor(
    public router: Router,
    private cartService: CartService,
    private confirmationDialogService: ConfirmationDialogService,
    private CountryService:CountryService,
	  private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.getCart();  
    this.getStore();
    this.cartService.getCount().subscribe(count => {
      this.totalItem = count
       console.log(count)
      }
      );
  }
  locationsSum() {
    if(this.carts){
      this.amount = this.carts.map(tag => (tag.gift_box !=1)?tag.product_price*tag.quantity:tag.product_price ).reduce((a, b) => a + b, 0);
    }
    return this.amount;
  }
  getCart(): void {
    this.cartService.getCartItems().subscribe(
      result => {
        this.carts = result
        this.stock  = this.carts.stock;
        if(this.stock === 0){
          this.inStock = false;
        }
      },
      error => {
        this.isDisableButton = true;
        this.router.navigate(['/']);
      },() => {
      }
    );
  }
  
  increamentQTY(id,quantity): void {
    const payload = {
      id: id,
      quantity: quantity,
    };
    this.cartService.increaseQty(payload).subscribe(
      result => {
        this.getCart();
        this.getCartCount();
      },
      error => {
        this.errors = error.error;
        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                            size: 'xl',
                            backdrop: 'static',
                            keyboard: false,
                            windowClass: 'cartadd_modal',
                            animation: true,
                            ariaDescribedBy:'test',
                          });
        modalRef.componentInstance.message = this.errors.error;
      },() => {
        
      }
    );
  }
  


  deleteCartItem(id): void {
    this.confirmationDialogService.confirm('', 'Are you sure you want to delete this item ?')
    .then((confirmed) => {
      if(confirmed === true){
        this.cartService.deleteCartItem(id).subscribe(() => {
              this.getCart();
              this.getCartCount();
              //this.reloadComponent();
        })
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    
  }
  
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
  emptyCart(): void {
    this.confirmationDialogService.confirm('', 'Are you sure to clear all the items in your cart?')
    .then((confirmed) => {
      if(confirmed === true){
        this.cartService.emptyCart().subscribe(() => {
          this.getCart();
          this.getCartCount();
          this.router.navigate(['/']);
        })
      }
    })
    .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
    
  }
  getCartCount(){
    this.cartService.getCartItemCount().subscribe(
      data => {
        this.count = data;
        localStorage.setItem("cart_count", JSON.stringify(this.count));
      }
    ); 
  }
  
  processPay(){
    this.process_error ="";
    this.session_id=sessionStorage.getItem("session_id");
    this.cartService.getCartItems().subscribe(
      result => {
        this.carts = result;
       
        for (let cart of this.carts) {
          if(cart.stock == 0 ){
            this.process_error = cart.product_name+" is out of stock";
            const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                          size: 'xl',
                          backdrop: 'static',
                          keyboard: false,
                          windowClass: 'cartadd_modal',
                          animation: true,
                          ariaDescribedBy:'test',
                        });
            modalRef.componentInstance.message = this.process_error;
            this.getCart();
            return;
          }else if(cart.quantity > cart.stock){
            this.process_error = 'Only '+cart.stock+' "'+cart.product_name+'" left, please update your quantity and proceed';
            const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                          size: 'xl',
                          backdrop: 'static',
                          keyboard: false,
                          windowClass: 'cartadd_modal',
                          animation: true,
                          ariaDescribedBy:'test',
                        });
            modalRef.componentInstance.message = this.process_error;
            this.getCart();
            return;
          }
          else if(cart.gift_box === 1){
            //this.gftMsg =this.getGiftItemStock(cart.id);
            const payload = {
              id: cart.id
            };
            this.cartService.getGIftItemStock(payload).subscribe(
              result => {
                this.giftStockCheck=1;
                this.router.navigate(['/order-confirm']);
              },
              error => {
                this.process_error = 'Giftbox or giftitem out of stock.';
                const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                              size: 'xl',
                              backdrop: 'static',
                              keyboard: false,
                              windowClass: 'cartadd_modal',
                              animation: true,
                              ariaDescribedBy:'test',
                            });
                modalRef.componentInstance.message = this.process_error;
                this.giftStockCheck=0;
                return;
              }
            );
            //this.getCart();
          }
        }
        if(this.locationsSum() > 25000){
            this.process_error = 'Order amount cannot exceed 25000 INR';
            const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                          size: 'xl',
                          backdrop: 'static',
                          keyboard: false,
                          windowClass: 'cartadd_modal',
                          animation: true,
                          ariaDescribedBy:'test',
                        });
            modalRef.componentInstance.message = this.process_error;
            return false;
        }
        this.router.navigate(['/order-confirm']);
      },
      error => {
        this.process_error = error.error;
      },() => {
      }
    );
  }
  getGiftItemStock(id){
    const payload = {
      id: id
    };
    this.cartService.getGIftItemStock(payload).subscribe(
      result => {
        this.giftStockCheck=1;
        return "1";
      },
      error => {
        this.process_error = 'Giftbox or giftitem out of stock.';
        const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                      size: 'xl',
                      backdrop: 'static',
                      keyboard: false,
                      windowClass: 'cartadd_modal',
                      animation: true,
                      ariaDescribedBy:'test',
                    });
        modalRef.componentInstance.message = this.process_error;
        this.giftStockCheck=0;
        return "0";
      }
    );
  }
  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      result => {
        this.store = result;
        console.log(this.store);
        this.storename = this.store.seo_url
      },
      error => {
        
      },() => {
      }
    );
  }
  getGiftItemModel(id){
    const modalRef = this.modalService.open(CartGiftItemsComponent, { 
                          size: 'xl',
                          backdrop: 'static',
                          keyboard: false,
                          windowClass: 'shoplogin_modal',
                          animation: true,
                          ariaDescribedBy:'test',
                        });
    modalRef.componentInstance.product_id = id; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
}
