<nav class="navbar navbar-expand-md navbar-dark fixed-top" id="banner">
    <div class="container head_contain" id="nav_container">
        <a class="navbar-brand" id="logo" href="">
            <img src="/assets/images/logo-glow-new.png" alt="Logo">
        </a>
        <!-- Toggler/collapsibe Button -->
        <button class="navbar-toggler first-button" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            <div class="animated-icon1"><span></span><span></span><span></span></div>
        </button>


        <div class="header_social">
            <ul class="nav  navbar-right">
                <li></li>  
                
                <li class="nav-item"><a class="nav-link shopadmin shopadmin"   (click)="openSearcOutletModal(true)" title="Search outlets"><img src="/assets/images/searchoutlet.png" class="mx-auto" alt="delete" class="search-outletclass"></a> </li> 

                <li class="nav-item"><a class="nav-link shopadmin" [routerLink]="['/myaccount']" *ngIf="isSignedIn"> Hi {{getUserName() }}</a></li>
                <li class="nav-item"><a class="nav-link shopadmin" [routerLink]="['/myaccount']" *ngIf="isSignedIn">My Account</a> </li>
                <li class="nav-item ">
                    <a class="nav-link shopadmin"[routerLink]="['/']">
                        Shop
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link shopadmin contact_link" href="{{mainSiteUrl}}contact-us">
                        Contact
                    </a>

                </li>

                <li class="nav-item"><a class="nav-link shopadmin contact_link" *ngIf="!isSignedIn" (click)="openLoginModal()">Log In</a> </li>
                <li class="nav-item"><a class="nav-link shopadmin contact_link" *ngIf="isSignedIn" (click)="signOut()">Log Out</a> </li>

                
                <li class="mobile_search">
                    <a    (click)="openSearcOutletModal(true)"  title="Search outlets"><img src="/assets/images/searchoutlet.png" class="search-outletclass"></a>
                </li>
                <li class="shopcart" [ngClass]="{'buttonDisable':isDisableButton}">
                    <a [routerLink]="['/cart']"><img src="/assets/images/cart.png" alt="cart" class="">
                        <span class="shop_cart shop_cart1">{{ getCartCount() }}</span> 
                    </a>
                </li>
                <li class="mobile_search">
                    <a  (click)="searchModal()" class="mobile-searchicon" ><img src="/assets/images/shopsearch.png"></a>
                </li>
                <li class="social-mobile-hide">
                    <a href="https://www.instagram.com/bolas.india/" target="_blank"><img src="/assets/images/insta.png" alt="instagram" class=""></a>&nbsp;</li>
                <li class="social-mobile-hide">
                    <a href="https://www.facebook.com/bolasindia" target="_blank"><img src="/assets/images/facebookh.png" alt="facebook" class=""></a>&nbsp;</li>
                <li class="social-mobile-hide">
                    <a href="https://twitter.com/Bolasindia" target="_blank"><img src="/assets/images/twitter.png" alt="twitter" class=""></a>&nbsp;</li>
                <li class="social-mobile-hide">
                    <a href="https://www.linkedin.com/company/bolas2005/" target="_blank"><img src="/assets/images/linkedin.png" alt="Linked In" class=""></a>
                </li>
            </ul>
        </div>

        <div class="collapse navbar-collapse" id="collapsibleNavbar">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item shopadmin_link" *ngIf="isSignedIn">
                    <span class="shop_hello">Hello {{getUserName() }}</span>
                </li>
                <li class="nav-item shopadmin_link" *ngIf="isSignedIn">
                    <a data-toggle="collapse" data-target=".navbar-collapse" class="nav-link" [routerLink]="['/myaccount']">
                        <span class="">My Account</span>
                    </a>
                </li>
                <li class="nav-item shopadmin_link">
                    <a class="nav-link" data-toggle="collapse" data-target=".navbar-collapse" [routerLink]="['/']">
                        <span class="">Shop</span>
                    </a>
                </li>
                <li class="nav-item contact_link shopadmin_link">
                    <a class="nav-link contact_link" href="{{mainSiteUrl}}contact-us">
                        <span class="">Contact</span>
                    </a>

                </li>

                
                <li class="nav-item shopadmin_link">
                    <a class="nav-link" href="#">
                        <span data-toggle="collapse" data-target=".navbar-collapse" class="" *ngIf="isSignedIn" (click)="signOut()">Logout</span>
                        <span data-toggle="collapse" data-target=".navbar-collapse" class="" *ngIf="!isSignedIn" (click)="openLoginModal()">Log In</span>
                    </a>
                </li>
                <!-- Dropdown -->

            </ul>
        </div>

    </div>
</nav>
