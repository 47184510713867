import { Component, OnInit } from '@angular/core';
import { CountryService} from '../../country.service';
@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.css']
})
export class SubHeaderComponent implements OnInit {
  store:any = [];
  appShopId:any;

  storename:string = "";
  address:string = "";
  pincode = "";
  storeTiming:string = "";

  shopStatus:string = "CLOSED";
  shopStatusColor:string = "red";
  shopOpeningTiming:number = 0;
  shopCloseTiming:number = 0;
  shopphone:string ="";
  now: number = Date.now();
  shopOpClseStatus:boolean = false;
  constructor(
    private CountryService: CountryService,
  ) { }

  ngOnInit(): void {
    /*this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId && this.appShopId!='undefined'){
        this.getStore(); 
    }else{ 
        this.getStoreWitoutToken();
    } */
    this.getStore();
    //setTimeout(() => { this.ngOnInit(); }, 1000 * 10);
  }
  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      result => {
        this.store = result;
        this.storename = this.store.seo_url;
        if(this.store.address != "NULL" ){
          this.address = this.store.address;
        }
        this.pincode = this.store.pin;
        this.storeTiming = this.store.shop_timing;
        this.shopOpeningTiming = this.store.opening_time;
        this.shopCloseTiming = this.store.closing_time;
        this.shopphone = this.store.phone;
        this.shopStatus = this.store.shop_status;
        if(this.shopStatus =="OPEN"){
          this.shopStatusColor = "green";
        }else{
          this.shopStatusColor = "red";
        }
        if(this.store.opening_time != "00:00"){
          this.shopOpClseStatus =true;
        }
      },
      error => {
        
      },() => {
      }
    );
  }

  getStoreWitoutToken(){
    this.CountryService.getStoreDetailswithoutToken().subscribe(
      result => {
        this.store = result;
        console.log(this.store);
        this.storename = this.store.seo_url;
        this.address = this.store.address;
        this.pincode = this.store.pin;
        this.storeTiming = this.store.shop_timing;
        this.shopOpeningTiming = this.store.opening_time;
        this.shopCloseTiming = this.store.closing_time;
        if((this.store.openingstrtotime*1000 >= this.now) || (this.now <= this.store.closingstrtotime*1000)){
          this.shopStatus = "OPEN";
          this.shopStatusColor = "green";
        }
      },
      error => {
       // this.chooseOutlet();
        //setTimeout(() => { this.chooseOutlet() }, 1000 );
      },() => {
      }
    );
  } 

}
