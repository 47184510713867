import { Component, OnInit , Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef} from '@angular/core';
import { NgbActiveModal , ModalDismissReasons , NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CountryService } from '../../country.service';

@Component({
  selector: 'app-choose-outlets',
  templateUrl: './choose-outlets.component.html',
  styleUrls: ['./choose-outlets.component.css']
})
export class ChooseOutletsComponent implements OnInit {
stores: any = [];
  selected :any;
  store:any;
  changeShopForm: FormGroup;
  submitted = false;
  errors = null;
  message =null;
  constructor(
  	public router: Router,
    public activeModal: NgbActiveModal,
    private CountryService: CountryService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
	this.changeShopForm = this.formBuilder.group({
	      outlet:['', [Validators.required] ],
	});
	this.CountryService.getStores().subscribe(
	        data => this.stores = data
	    );
  }
  ToggleCourse(e){
    this.selected = e;
    this.changeShopForm.controls['outlet'].patchValue(e.id);
  }
  isActive(item) {
    this.store = item.id;
    return this.selected === item;
  }
  get f() { return this.changeShopForm.controls; }
  isFieldValid(field: string) {
    return !this.changeShopForm.get(field).valid && this.changeShopForm.get(field).touched;
  }
  onSubmit() {
    this.errors = '';
    this.submitted = true;
    if(this.changeShopForm.invalid){
      return;
    }
    this.CountryService.changeOutlet(this.changeShopForm.value).subscribe(
      result => {
        console.log(result)
        this.message = result.message;
        
      },
      error => {
        this.errors = error.error;
      },
      () => {
        setTimeout(()=>{
          this.changeShopForm.reset();
          this.activeModal.close('Modal Closed');
          this.modalService.dismissAll()
          this.reloadComponent();
        },5000);
      }
    );
  }
  reloadComponent() {
    let currentUrl = '/';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
}
