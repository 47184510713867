import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {
	transform(items: any[], searchText: string): any[] {
	    if(!items) return [];

	    if(!searchText) return items;

	    return this.searchItems(items, searchText.toLowerCase());
	}

   	private searchItems(items :any[], searchText): any[] {
     	let results = [];
  		items?.forEach(it => {
        if (it.product_name.toLowerCase().includes(searchText)) {
            results.push(it);
        } else {
          	let searchResults =  this.searchItems(it.items_containers, searchText);
          	if (searchResults.length > 0) {
              	results.push({
                	product_name: it.product_name,
                	items_containers: searchResults
              	});
          	}
    	}
  		});
      return results;
   	}

}
