import { Component, OnInit, Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CountryService} from '../country.service';

@Component({
  selector: 'app-new-address',
  templateUrl: './new-address.component.html',
  styleUrls: ['./new-address.component.css']
})
export class NewAddressComponent implements OnInit {
  user: any = [];
  stores: any = [];
  countries: any = [];
  states: any = [];
  cities: any=[];
  newAddressFrom: FormGroup;
  submitted = false;
  errors = null;
  selected :any;
  success:boolean = false;
  store:any =[];
  storename:string = "";
  isLoading:boolean = false;
  billingCity:any;
  billingAddCity:boolean=false;
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    private CountryService: CountryService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getStore();
    this.CountryService.getCountries().subscribe(
      data => {
        this.countries = data;
        this.onChangeCountry(111);
      }
    );
	this.selected = 1;
  }
  createForm(){
    this.newAddressFrom = this.formBuilder.group({
      name: ["", [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]{2,20}$')]],
      phone: ['', [Validators.required,  Validators.pattern('[6-9]\\d{9}')]],
      address:['', [Validators.required]],
      country_id: [111],
      pin:['', [Validators.required, Validators.minLength(6),Validators.pattern("^[1-9][0-9]{5}$")]],
      state_id:[''],
      town_id:[''],
      address_type:'1',
      default:[''],
      web_city:[''],
    });
  }
  onChangeCountry(countryId: number) {
    if (countryId) {
      this.CountryService.getStates(countryId).subscribe(
        data => {
          this.states = data;
          this.cities = null;
        }
      );
    } else {
      this.CountryService.getStates(111).subscribe(
        data => {
          this.states = data;
          this.cities = null;
        }
      );
    }
  }

  onChangeState(stateId: number) {
    if (stateId) {
      this.CountryService.getCities(stateId).subscribe(
        data => this.cities = data
      );
    } else {
      this.cities = null;
    }
  }

  get f() { return this.newAddressFrom.controls; }
  isFieldValid(field: string) {
    return !this.newAddressFrom.get(field).valid && this.newAddressFrom.get(field).touched;
  }
  onSubmit() {
    this.submitted = true;
    this.isLoading = true;
    if(this.newAddressFrom.invalid){
      this.scrollToError();
      return;
    }
    
    this.CountryService.addAddress(this.newAddressFrom.value).subscribe(
      result => {
        console.log(result);
        this.success = true;
        this.isLoading = false;
      },
      error => {
        this.errors = error.error;
        this.isLoading = false;
      },() => {
			  this.success = true;
        setTimeout(()=>this.reloadComponent(),5000);
		    /*this.newAddressFrom.reset();
	        
			this.createForm();
			this.CountryService.getCountries().subscribe(
			  data => this.countries = data
			);
			this.selected = 1;
            this.errors = null;
			this.submitted = false;*/
			//setTimeout(()=>this.reloadComponent(),5000);
      }
    );
    
  }
  setAddressType(type){
    this.selected = type;
    this.newAddressFrom.patchValue({
      address_type:type
    });
  }
  
  isActive(item) {
    //this.newAddressFrom.controls['address_type'].setValue(item);
    return this.selected === item;
  }
  
  
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
		this.success = true;
    }
  onReset(){
    this.newAddressFrom.reset();
	
  }
  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      result => {
        this.store = result;
        console.log(this.store);
        this.storename = this.store.seo_url
      },
      error => {
        
      },() => {
      }
    );
  }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    console.log("aa:", firstElementWithError);

    this.scrollTo(firstElementWithError);
  }

  // Only AlphaNumeric
  keyPressAlpha(event) {

    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  onPaste(e) {
    e.preventDefault();
    return false;
  }
  addOtherCityBilling($event){
    this.newAddressFrom.controls['web_city'].setValue(''); 
    var selectedVal=$event.target.options[$event.target.options.selectedIndex].text;
    if(selectedVal=='Other'){
      this.billingAddCity=true;
    }else{ 
      this.billingAddCity=false;
    }

     let text =this.billingCity
     let position = text.search("web_city"); 
    if(position>-1){
      let seltext = $event.target.options[$event.target.options.selectedIndex].text;
      this.newAddressFrom.controls['web_city'].setValue(seltext);
    }else{
      this.newAddressFrom.controls['web_city'].setValue(''); 
    } 
  }


  checkExistingCity(){  
    
       let val=this.newAddressFrom.get('web_city').value; 
       let arr=this.cities; 
       let obj = arr.find((o, i) => {
        
          if (o.town === val) { 
                 console.log('match');
             
                  this.newAddressFrom.patchValue({
                    town_id: o.id,
                  });
                 this.newAddressFrom.controls['web_city'].setValue('');
                 this.billingCity=''; 
                 this.billingAddCity=false;
            
              return true; // stop searching
          }
       }); 
  }
  

}
