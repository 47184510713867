<app-sub-header></app-sub-header>
 
<div class="containercart_outer">

    <div class="container_myorder">

        <div class="row row_custom">

            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cart_topdummy">

            </div>

        </div>

        <div class="myorder_inner">

            <div class="row row_custom">

                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

                </div>

                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col accountheadbg myorderhead">

                    <h3 class="accountheadtext">MY ORDERS</h3>

                </div>

                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

                </div>

            </div>



            <div class="row row_custom myorder_row">

                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">

                    <!--<div class="myorder_filter">
						<form [formGroup]="filterOrderForm">
                        <select (change)="FilterOrders()" formControlName="myfilter" class="myorder_filterlist" id="myfilter" name="myfilter">

                            <option value="">ALL ORDERS</option>

                            <option value="1">THIS MONTH</option>

                            <option value="2">LAST 6 MONTHS</option>

                            <option value="3">THIS YEAR</option>

                        </select>
						</form>

                    </div> -->

                </div>

                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col">
				 <form [formGroup]="searchOrderForm">
                    <div class="input-group myorder_input">

                        <input type="text" class="form-control order_search" formControlName="search_text" placeholder="Search Orders" id="search_text" name="search_text">

                        <div class="input-group-append">

                            <button (click)="SearchOrders()" class="btn btn-sale" type="button"><span class="icon ico_search"><img
                                        src="/assets/images/shopsearch.png" /></span></button>

                        </div>

                    </div>
				 </form>
                </div>

            </div>



            <div class="row row_custom myorder_row">

                <div *ngIf="isShownOrders" class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col">

                    <div class="myorder_item" *ngFor="let order of orders">

                        <div class="myorder_itemhead">

                            <div class="row row_custom">

                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

                                    <p class="myorder_top">Order placed: {{order.order_date}}</p>

                                </div>

                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 process_col">

                                    <p class="myorder_top">Total: Rs. {{order.amount - order.discount_amount | number : '1.2-2'}}</p>



                                </div>

                                <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 process_col">

                                    <p class="myorder_top myorder_topright"><span class="myorder_red"><a class="myorder_red" [routerLink]="['/order-details', order.id]">Order
                                            Details</a></span>&nbsp;&nbsp;|&nbsp;&nbsp;Order # {{order.id}}</p>
                                    <p class="myorder_top myorder_topright" *ngIf="order.razpay_order_status">Payment Status:  <span class="myorder_red" style="text-transform: uppercase;">{{order.razpay_order_status}}</span></p>
                                </div>

                            </div>

                        </div>

                        <div class="myorder_itembody">

                            <div class="myorder_itembody">

                                <div class="row row_custom order_bodyrow" *ngFor="let item of order.order_items ; let i=index ">

                                    <div class="col-md-1 col-lg-1 col-sm-12 col-xs-12 process_col">

                                        <div class="item_thumbnail mx-auto">

                                            <img src="{{item.product_images}}" class="img_cartthumb img-fluid img-thumbnail" alt="cart">

                                            <span class="checkout_qty">{{item.quantity}}</span>

                                        </div>

                                    </div>

                                    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 process_col">

                                        <p class="myorder_top myorder_topadjust">
                                            {{item.product_name}} 
                                            <a *ngIf="item.gift_item==1" (click)="getGiftItemModel(item.item_id)" >&nbsp;<img src="/assets/images/information-button.png"></a>
                                        </p>

                                    </div>

                                    <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 process_col">

                                        <p class="myorder_top">Rs. {{item.amount}}</p>

                                    </div>

                                    <!--<div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 myorders_buttoncol" *ngIf="i===0">


                                        <button type="submit" class="myorders_button myorders_topbut buttrackorder">TRACK ORDER</button>
                                        <button type="submit" class="myorders_button myorders_botbut">REORDER</button>
                                    </div>-->

                                </div>



                            </div>

                        </div>

                    </div>

                </div>
				
				 <div *ngIf="!isShownOrders" class="col-md-12 col-lg-12 col-sm-12 col-xs-12 process_col">

                    <div class="myorder_item" >

                        <div class="myorder_itemhead">

                            <div class="row row_custom">

                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">
								&nbsp;
                                </div>

                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 process_col">
								&nbsp;
                                </div>

                                <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 process_col">
								&nbsp;
                                </div>

                            </div>

                        </div>

                        <div class="myorder_itembody">

                            <div class="myorder_itembody">

                                <div class="row row_custom order_bodyrow">

                                    <div class="col-md-1 col-lg-1 col-sm-12 col-xs-12 process_col">

                                    </div>

                                    <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 process_col">


                                    </div>

                                    <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 process_col">

                                        <p class="myorder_top">No Orders Found!</p>

                                    </div>


                                </div>



                            </div>

                        </div>

                    </div>

                </div>
				
				
				
				
				
                <div class="ordercondown">

                </div>

            </div>

        </div>

    </div>
<app-footer></app-footer>

    <app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>