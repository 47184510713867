import * as $ from 'jquery';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CountryService } from '../country.service';
import { AuthStateService } from '../shared/auth-state.service';

@Component({
  selector: 'app-viewmap',
  templateUrl: './viewmap.component.html',
  styleUrls: ['./viewmap.component.css']
})
export class ViewmapComponent implements OnInit {

  lat = 12.8842699;
  lng = 74.8487253;

  mapType = 'roadmap';
  postcode:any;
  sub;
  coordinates:any;
  zoom:number;
  pincode:any; 
  mapFrm: FormGroup; 
  menu:any;
  allOutlets:any;
  nearbycoordinates:any;
  sethovered: boolean = false;
  selectedItem = null;
  innerWidth: any;
  previous_info_window = null;
  nodata: boolean = false;
  isHovered: boolean = false;
  minusHeader:any;
  isOpenInfoWindow: boolean = false; 
  submitted: boolean = false;
  formSumbit: boolean = false; 
  errors: boolean = false;
  constructor(
    public router: Router,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private CountryService: CountryService,
    private auth: AuthStateService,) { }  

    ngOnInit(): void {
        window.scroll({ 
          top: 0, 
          left: 0, 
          behavior: 'smooth' 
        });
        this.innerWidth = window.innerWidth;
        this.zoom=8;
        this.pincode=='all';
         this.sub = this.activatedRoute.paramMap.subscribe(params => { 
              this.pincode = params.get('pincode');  
              //console.log('pin'+this.pincode)
        }); 
       //  alert( this.pincode);  


        this.mapFrm = this.formBuilder.group({
          pinsearch_text: ['', [Validators.required, Validators.pattern("^[1-9][0-9]{5}$")]], 
        }); 
        
    }
  
    ngAfterViewInit():void{ 
  
     if(this.pincode=='all' || this.pincode==''){ 

        this.zoom=6;
        this.lat =16.169233792496478;
        this.lng =75.66037853435611;
        this.CountryService.getGooglemapAllOutlets(this.pincode).subscribe(
          data => {  
              this.coordinates=data;  
              this.allOutlets=data;  
              //console.log(data);               
          });           
          this.scrollToMap();
      }else{  
        this.mapFrm.patchValue({
            pinsearch_text:  this.pincode,
        });
        this.loadGoogleMap();
      } 
    }

    scrollToMap(){ 

        if(this.innerWidth<768){     
           this.minusHeader="105";
        }else{
          this.minusHeader="85";
        } 
        setTimeout( ()=>{             
          var topOfElement = $('#agm-map-inner').offset().top - this.minusHeader;
          window.scrollTo({ top: topOfElement, behavior: "smooth" });    
        }, 1000);

    }

    trim(myString) { 
      //console.log(myString);
      this.mapFrm.patchValue({
        pinsearch_text: myString.replace(" ",''),
      }); 
      return myString.replace(" ",'-');  
      return myString; 
    }
  
  
     get f() { return this.mapFrm.controls; }
  
     loadGoogleMap(){
     this.previous_info_window = null;
  
     this.pincode = this.mapFrm.get('pinsearch_text').value; 
        this.errors=false;
        this.submitted = true;
        this.formSumbit = true;
        if(this.mapFrm.invalid){
          this.formSumbit = false;
          this.errors=true; 
          return;
        } 

     this.CountryService.getGooglemapNearestLocation(this.pincode).subscribe(
      data => {  
          this.coordinates=data; 
          this.allOutlets=data;
          this.zoom=12;   
          if(this.coordinates===null){
                this.nodata=true;
          }else if(this.coordinates.length==0){
                this.nodata=true;
          }else{ 
                this.nodata=false;
                this.lat=this.coordinates[0].center_latitude;
                this.lng=this.coordinates[0].center_longitude;  

               /* if(this.innerWidth<768){
                  this.scrollTo();
                }else{      
                  this.scrollToMap();
                }*/

                this.scrollToMap();
          } 

      });   
  
     }
  
     loadGoogleMapOnClickOfInfo(pin,marker){  
       this.selectedItem = marker;
      // this.zoom=10;  
 
        this.zoom=13;  
        this.lat=marker.latitude;
        this.lng=marker.longitude; 

       /* this.CountryService.getlatlon(pin).subscribe(
        data => {  
            this.nearbycoordinates=data;   
            if(this.nearbycoordinates.length>0){
              this.zoom=13;
              this.lat=this.nearbycoordinates[0].latitude;
              this.lng=this.nearbycoordinates[0].longitude;  
            }else{
              this.zoom=13;  
              this.lat=marker.latitude;
              this.lng=marker.longitude; 
            }  

            if(this.innerWidth<768){
              this.scrollTo();
            }
           
        });   */



        if(this.innerWidth<768){
          this.scrollTo();
        }else{ 
          this.scrollToMap();
        }
     }
     scrollTo() {
      document.querySelector('#agm-map-inner').scrollIntoView({ behavior: 'smooth', block: 'center' }); //, block: 'center'
     }
     
     onHover(){      
      //menu.hovered = !menu.hovered;
      this.isHovered=true;
     }
     onOut(){
      this.isHovered=false;
     }

     keyPressAllowNumbers(evt){  
       
        var inp = String.fromCharCode(evt.keyCode);

        // Only ASCII character in that range allowed

        var ASCIICode = (evt.which) ? evt.which : evt.keyCode;
         console.log(ASCIICode);
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
            return false;
        return true;

        if (/[0-9]/.test(inp)) {
          return true;
        } else {
          evt.preventDefault();
          return false;
        } 
     }

    onMouseOver(infoWindow, $event: MouseEvent) {
        infoWindow.open();
    }

    onMouseOut(infoWindow, $event: MouseEvent) {
        infoWindow.close();
    }

    reloadMap(){  
       this.router.navigateByUrl('/viewmap', { skipLocationChange: true }).then(() => this.router.navigate(['/viewmap/' + 'all' ]));
    } 

    /*close_window(evt){
    if (this.previous_info_window != null ) {
      this.previous_info_window.close()
      }    
    }*/    
    select_marker(infoWindow,marker,i){      
      if (this.previous_info_window == null)
        this.previous_info_window = infoWindow;
      else  if (this.previous_info_window != null){      
        this.previous_info_window.close();
      }
      this.previous_info_window = infoWindow;
      this.selectedItem = marker;

      if(this.innerWidth>768){  
        /* var container = $('.store-locate-outer'); var scrollTo = $("#Id-"+i);
        var position = scrollTo.offset().top - container.offset().top + container.scrollTop();
        container.animate({ scrollTop: position });*/
        document.querySelector("#Id-"+i).scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
    
    /*isInfoWindowOpen(id){
      
      console.log("p: "+this.previous_info_window+" ID: "+id);
      if(this.previous_info_window == id){
        console.log("previous");
      }
      return this.previous_info_window == id; 
    }*/
}