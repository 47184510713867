import { Component, OnInit } from '@angular/core';
import { CountryService} from '../../country.service';
@Component({
  selector: 'app-device-category',
  templateUrl: './device-category.component.html',
  styleUrls: ['./device-category.component.css']
})
export class DeviceCategoryComponent implements OnInit {
  categories: any = [];
  constructor(
    private CountryService: CountryService,
  ) { }

  ngOnInit(): void {
    this.getCategory();
  }
  getCategory(){
    this.CountryService.getCategory().subscribe(
      data => this.categories = data
    );
  }

}
