import * as $ from 'jquery';
declare var $: any;
import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from 'src/app/modals/login/login.component';
import { SearchModalComponent } from 'src/app/modals/search-modal/search-modal.component';
import { ResetPasswordComponent } from 'src/app/modals/reset-password/reset-password.component';
import { DirectLoginModalComponent } from 'src/app/direct-login/login/login.component';
import { TokenService } from '../../shared/token.service';
import { AuthStateService } from '../../shared/auth-state.service';
import { CartItem, CartService } from '../../cart.service';
import { AuthService } from '../../shared/auth.service';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot,ActivatedRoute ,NavigationEnd } from '@angular/router';
import { ErrorNotificationsComponent} from 'src/app/modals/error-notifications/error-notifications.component';
import { SearchoutletComponent } from 'src/app/modals/searchoutlet/searchoutlet.component';
import { environment } from '../../../environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  
  public showContainer: boolean;
  user: any = [];
  isSignedIn: boolean;
  name:string ="";
  cartCount: number = 0;
  cartItem: Array<CartItem> = [];
  countCart:any =0;
  isDisableButton:boolean = false;
  count:any =0;
  shop:any =0;
  url:string ="";
  sub;
  resetToken  :string = '';
  error:any = '';
  shopDetails:any = [];
  shopName:any ='';
  store:any = [];
  isOutletSelected: boolean;
  myShopId:any = [];
  mainSiteUrl = environment.MAIN_SITE_URL;
  checkUrl:any =[];

  
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private modalService: NgbModal,
    private auth: AuthStateService,
    public authService: AuthService,
    public token: TokenService,
    private cartService: CartService,
    public router: Router,
	  private activatedRoute: ActivatedRoute
  ) { }


  openLoginModal() {
    //history.pushState(null, null, '');
    const modalRef = this.modalService.open(LoginComponent,{ 
                                            centered: true, 
                                            size: 'xl',
                                            backdrop: 'static',
                                            keyboard: false,
                                            windowClass: 'login_modal'
                                          });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    }); 
    return false;
  }
  
   openDirectLinkLoginModal() {
    //history.pushState(null, null, 'shop');
    const modalRef = this.modalService.open(DirectLoginModalComponent,{ 
                                            centered: true, 
                                            size: 'xl',
                                            backdrop: 'static',
                                            keyboard: false,
                                            windowClass: 'login_modal'
                                          });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  openResetPasswordModal(){
    const modalRef = this.modalService.open(ResetPasswordComponent,{ 
                                            centered: true, 
                                            size: 'xl',
                                            backdrop: 'static',
                                            keyboard: false,
                                            windowClass: 'login_modal'
                                          });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
  ngAfterViewInit(): void {
	
  }
  authCheck(){
    this.authService.userAuthValideToken().subscribe(
      result => {
        localStorage.setItem('estore', '');
        
      },
      error => {

        this.activatedRoute.queryParams.subscribe(params => {
            this.resetToken = params['token'];
            if(this.resetToken){
              localStorage.setItem('estore', '');
              this.openResetPasswordModal();
            }else{
              console.log( this.router.url);
              if(this.router.url == '/'){
                 localStorage.setItem('estore', '');
              }
              if(localStorage.getItem('estore') === null || localStorage.getItem('estore') === "" || localStorage.getItem('estore') === "estore")
              {
                localStorage.setItem('estore', '');
                this.myShopId=localStorage.getItem('appShopId'); 
                if(this.myShopId>0  && this.myShopId!='undefined'){
                  //Do nothing
                }else{ 
                  console.log(this.checkUrl); 
                  var string = this.checkUrl;
                  var substring = "order-details"; 
                  console.log(string.indexOf(substring) !== -1); // true
                  if(string.indexOf(substring) !== -1){
                    //Do nothing
                  }else{
                    this.openSearcOutletModal();  
                  }
                }
              }
              else{                  
                  var string = this.checkUrl;
                  var substring = "order-details";                   
                  if(string.indexOf(substring) !== -1){ }else{
                    this.getShopDetails();
                  }                  
                //this.getShopDetails();

                /*console.log(this.shopName);return;
                if(this.shopName != ""){
                  this.openDirectLinkLoginModal();
                }else{
                  this.router.navigate(['/']);
                  this.openLoginModal();
                }*/
              }
            }
        });
        this.auth.setAuthState(false);
        this.token.removeToken();
      },() => {
      }
    );
  }
  getShopDetails(){
    let payload = {
      shop: localStorage.getItem('estore') 
    };
    this.cartService.getShopDetailsSeoUrl(payload).subscribe(
      result => {

        this.shopDetails = result;   
        if(this.shopDetails){
          this.shopName = this.shopDetails.shop_name;
          //this.openDirectLinkLoginModal(); 
          if(this.shopDetails.id>0){
            localStorage.setItem('appShopId', this.shopDetails.id);
            sessionStorage.setItem("appShopId", this.shopDetails.id);  
            this.router.navigate(['/']);
          }else{
            this.openSearcOutletModal(); 
          }
        }else{
          localStorage.setItem('estore', '');
          const modalRef = this.modalService.open(ErrorNotificationsComponent, { 
                            size: 'xl',
                            backdrop: 'static',
                            keyboard: false,
                            windowClass: 'cartadd_modal loginError',
                            animation: true,
                            ariaDescribedBy:'test',
                          });
          modalRef.componentInstance.message = "Page you are trying to access does not exist";
          setTimeout(()=>{                           // <<<---using ()=> syntax
              //this.activeModal.close('Modal Closed');
              this.router.navigate(['/']);
              this.openSearcOutletModal();
          }, 2000);
        }
        console.log(this.shopName); 
      },
      error => {
        this.error = error;
      }
    )
  }
  async ngOnInit() {

    this.router.events.subscribe(value => {
      if(value instanceof NavigationEnd)    {
        console.log(this.router.url.toString());
        this.checkUrl=this.router.url.toString();
      }
    });
    //$('dropdown-toggle11').dropdown();
    //$('.navbar-nav>li>a').on('click', function(){ $('.navbar-collapse').collapse('hide'); });
    this.authCheck();

    this.auth.userAuthState.subscribe(val => {
      this.isSignedIn = val;
    });
    if(this.isSignedIn){
      this.cartItem = await this.cartService.getCart();
      this.cartCount =this.cartItem.length;
      
      this.cartService.prodCountCountChange.subscribe(
        newProdCount =>{
          console.log(newProdCount);
          this.cartCount = newProdCount
        }
      );
      localStorage.setItem("cart_count", JSON.stringify(this.cartCount));
      this.getUser();
      this.setCartCount();
      
    }
  }
  
  getUser(){
    this.authService.profileUser().subscribe(
      data => {
        this.user = data;
        localStorage.setItem("username", JSON.stringify(this.user.name));
      }
    );
  }
  getUserName(){
    if(localStorage.getItem("username") != null){
      this.name = localStorage.getItem("username");
      this.name = this.name.replace(/['"]+/g, '');
    }
    return this.name;
  }
  setCartCount(){
    this.cartService.getCartItemCount().subscribe(
      data => {
        this.count = data;
        localStorage.setItem("cart_count", JSON.stringify(this.count));
      }
    ); 
  }
  getCartCount(){
    this.isDisableButton = true;
    if(this.isSignedIn){
      this.countCart = localStorage.getItem("cart_count");
      if(this.countCart != 0){
        this.isDisableButton = false;
      }
      return this.countCart;
    }else{
      return '0';
    }
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 86 ||     
      document.documentElement.scrollTop > 86 ) {
      document.getElementById('banner').classList.add('shrink');
      document.getElementById('logo').classList.add('shrink_logo');
      document.getElementById('nav_container').classList.add('shrink_container');
      document.getElementById('collapsibleNavbar').classList.add('shrink_menu');
    }else{
      document.getElementById('banner').classList.remove('shrink');
      document.getElementById('logo').classList.remove('shrink_logo');
      document.getElementById('nav_container').classList.remove('shrink_container');
      document.getElementById('collapsibleNavbar').classList.remove('shrink_menu');
    }
  }
  signOut() {
    this.auth.setAuthState(false);
    this.token.removeToken();
    localStorage.removeItem('cart_count');
	  localStorage.removeItem('username');
    this.authService.stopRefreshTokenTimer();
	  localStorage.setItem('estore', '');
    
    //this.openLoginModal();
    // localStorage.setItem("cart_count", '0');
     //this.getCartCount();
    this.reloadComponent();  
  }
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/']);
        window.history.forward();
    }
  ngOnDestroy() {
    this.cartService.prodCountCountChange.unsubscribe();
  }
  searchModal(){
    const modalRef = this.modalService.open(SearchModalComponent,{ 
                                            centered: true, 
                                            size: 'xl',
                                            backdrop: 'static',
                                            keyboard: false,
                                            windowClass: 'search_modal'
                                          });
    modalRef.componentInstance.id = 1; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  openSearcOutletModal(is_close=false) {
    //history.pushState(null, null, '');
    const modalRef = this.modalService.open(SearchoutletComponent,{ 
                                            centered: true, 
                                            size: 'sm',
                                            backdrop: 'static',
                                            keyboard: false,
                                            windowClass: 'search_outlet_modal'
                                          });
    modalRef.componentInstance.id = 1; // should be the id
    modalRef.componentInstance.is_close = is_close; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }

  
}
