import { Component, OnInit , Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef} from '@angular/core';
import { NgbActiveModal , ModalDismissReasons , NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CountryService } from '../../country.service';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-shop-register',
  templateUrl: './shop-register.component.html',
  styleUrls: ['./shop-register.component.css']
})
export class ShopRegisterComponent implements OnInit {
  stores: any = [];
  selected :any;
  store:any;
  registerShopForm: FormGroup;
  submitted = false;
  errors = null;
  constructor(
    public router: Router,
    public activeModal: NgbActiveModal,
    private CountryService: CountryService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.registerShopForm = this.formBuilder.group({
      outlet:['', [Validators.required] ],
    });
    this.CountryService.getStores().subscribe(
      data => this.stores = data
    );
  }
  ToggleCourse(e){
    this.selected = e;
    this.registerShopForm.controls['outlet'].patchValue(e.id);
  }
  isActive(item) {
    this.store = item.id;
    return this.selected === item;
  }
  get f() { return this.registerShopForm.controls; }
  isFieldValid(field: string) {
    return !this.registerShopForm.get(field).valid && this.registerShopForm.get(field).touched;
  }
  onSubmit() {
    this.errors = '';
    this.submitted = true;
    if(this.registerShopForm.invalid){
      this.scrollToError();
      return;
    }
    this.CountryService.registerShop(this.registerShopForm.value).subscribe(
      result => {
        console.log(result)
      },
      error => {
        this.errors = error.error;
      },
      () => {
        this.registerShopForm.reset();
        this.activeModal.close('Modal Closed');
        this.modalService.dismissAll()
        this.reloadComponent();
      }
    );
  }
  reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    console.log("aa:", firstElementWithError);

    this.scrollTo(firstElementWithError);
  }
}
