<div class="modal-body">
 <!--  <div class="row row_custom">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 process_col">
            <img class="close closedevice" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
            <img class="close closedesk" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">
        </div>
    </div> --> 
    <div class="row row_custom">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 process_col">
            <p class="cartadded_text"><span><img class="carttick" src="/assets/images/tick.png" style="width:7%!important;"></span>&nbsp;&nbsp;Product successfully added to the cart <img class="close closeIcon" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png"> </p>
        </div>
    </div>
</div>