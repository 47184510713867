import { Component, OnInit, Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef, HostListener, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router , ActivatedRoute, ParamMap} from '@angular/router';
import { AuthService } from './../../shared/auth.service';
import { TokenService } from '../../shared/token.service';
import { AuthStateService } from '../../shared/auth-state.service';
import { ConfirmationDialogService } from '../../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class DirectLoginModalComponent implements OnInit {
  public showContainer: boolean;
  
  @Input()id: number;
  closeResult: string;
  loginForm: FormGroup;
  submitted:boolean = false;
  formSumbit:boolean = false;
  errors = null;
  result = null;
  otp:string;
  mobile:string;
  isShownButton:boolean = false;
  isShown: boolean = true ;
  isShownSendOtp: boolean = true ;

  isShownOr:boolean = false;
  isShownPasswordDiv:boolean = false;
  isShownMobileDiv:boolean = false;
  isShownPassword:boolean = false;
  showMobileDiv:boolean =false;
  isShownForgotPassword:boolean = false;
  
  establishments:any = [];
  outlets:any = [];
  establishment_id:any =0;
  user:any = [];
  nameT:string ="";
  showResendOtp:boolean = false;
  timeLeft: number = 60;
  interval;
  pattern = /^[A-Za-z0-9 \/\s\.!@#$%^&*()~`+\-=\[\]{};':"\\|,.<>\/?]*$/;
  constructor(
    public router: Router,
	  private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private token: TokenService,
    private authState: AuthStateService,
	private confirmationDialogService: ConfirmationDialogService,
  ) { }

  ngOnInit(): void {
    this.buildForm();
    this.showMobileDiv = true;
  }
  buildForm() {
    this.loginForm = this.formBuilder.group({
      mobile: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      otp :['',[Validators.required,Validators.minLength(6),Validators.pattern("^[0-9]*$")]],
      outlet: [localStorage.getItem('estore')],
	    name :['',[Validators.required,Validators.pattern(this.pattern)]],
      smstype: [''],
      password: [''],
      email: ['']
    });
  }

  sendOtp(type){
    this.token.removeToken();
    sessionStorage.removeItem('cart_count');
    sessionStorage.removeItem('session_id');
    sessionStorage.clear();
    this.errors = '';
    this.submitted = true;
    this.formSumbit = true;
    this.loginForm.removeControl('otp');
    this.loginForm.patchValue({ 
      smstype : type,
    });
    //this.loginForm.removeControl('outlet');
    if(this.loginForm.invalid){
      this.formSumbit = false;
      return;
    }
    this.authService.sendDirectOtp(this.loginForm.value).subscribe(
      result => {
        this.formSumbit = false;
        this.responseHandler(result);
        this.result = result;
      },
      error => {
        this.errors = error.error;
        this.formSumbit = false;
      },() => {
        this.formSumbit = false;
        this.submitted = false;
        this.isShown = ! this.isShown;
        this.startTimer();
        this.loginForm.addControl('otp', new FormControl('', [Validators.required,Validators.minLength(6),Validators.pattern("^[0-9]*$")]));
      }
    );
  }
  get f() { return this.loginForm.controls; }

  reloadComponent() {
      let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
	    console.log(currentUrl);
      this.router.navigate(['/']);
    }

  loginWithShop(){
    this.token.removeToken();
    sessionStorage.removeItem('cart_count');
    sessionStorage.removeItem('session_id');
    sessionStorage.clear();
    this.errors = '';
    this.submitted = true;
    this.formSumbit = true;
	  this.loginForm.controls['outlet'].setValue(localStorage.getItem('estore'));
    if(this.loginForm.invalid){
      this.formSumbit = false;
      return;
    }
	
	//Check for the existing user 
	  this.authService.checkExistingUser(this.loginForm.value).subscribe(
      result => {
        this.responseHandler(result);
        
  			if(result.changeoutlet==1)
  			{
            this.authService.shopLogin(this.loginForm.value).subscribe(
              result => {
                this.responseHandler(result);
                this.formSumbit = false;
                this.authService.profileUser().subscribe(
                  data => {
                  this.user = data;
                  this.authService.startRefreshTokenTimer()
                  localStorage.setItem("username", JSON.stringify(this.user.name));
                  localStorage.setItem('shop', '');
                });
                this.reloadComponent();		  
              },
              error => {
                this.formSumbit = false;
                this.errors = error.error;
                return false;
              },() => {
                this.formSumbit = false;
                this.authState.setAuthState(true);
                this.loginForm.reset();
                this.activeModal.close('Modal Closed');
                //this.reloadComponent();
              }
            )
          //})
          //.catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
			  }
        else
        {
          this.authService.shopLogin(this.loginForm.value).subscribe(
            result => {
            this.responseHandler(result);
            this.formSumbit = false;
            this.authService.profileUser().subscribe(
              data => {
              this.user = data;
              this.authService.startRefreshTokenTimer()
              localStorage.setItem("username", JSON.stringify(this.user.name));
              localStorage.setItem('estore', '');
              }
            );
            this.reloadComponent();
              
            },
            error => {
            this.formSumbit = false;
            this.errors = error.error;
            return false;
            },() => {
              this.formSumbit = false;
              this.authState.setAuthState(true);
              this.loginForm.reset();
              this.activeModal.close('Modal Closed');
              //this.reloadComponent();
            }
          )
			  }
			
			
	
	
      },
      error => {
        this.formSumbit = false;
        this.errors = error.error;
        return false;
      },() => {
	 
      }
    );
	

	
  }
  // convenience getter for easy access to form fields
  
  isFieldValid(field: string) {
    return !this.loginForm.get(field).valid && this.loginForm.get(field).touched;
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  // Handle response
  responseHandler(data){
    this.token.handleData(data.token);
  }
  getNameByMobile(e){
    if((e.target.value.length ) >= 10){
      this.authService.getNameByMobile(e.target.value).subscribe(
        data => {
          this.user = data;
          this.nameT = this.user.name;
          this.isShownPassword = false;
          this.isShownOr = false;
          this.loginForm.patchValue({
            name: this.nameT,
          });
        }
      );
    }
  }
  resendOtp(){
    this.authService.sendDirectOtp(this.loginForm.value).subscribe(
      result => {
        this.formSumbit = false;
        this.responseHandler(result);
        this.result = result;
      },
      error => {
        this.errors = error.error;
        this.formSumbit = false;
      },() => {
        this.formSumbit = false;
        this.showResendOtp =false;
        this.startTimer();
        this.loginForm.addControl('otp', new FormControl('', [Validators.required,Validators.minLength(6),Validators.pattern("^[0-9]*$")]));
      }
    );
  }
  trim(myString) { 
    console.log(myString);
    this.loginForm.patchValue({
      mobile: myString.replace(" ",''),
    });
    return myString.replace(" ",'-'); 
    
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.showResendOtp = true;
    },5000)
  }
  pauseTimer() {
    clearInterval(this.interval);
  }

  usePassword(){
    this.isShown = true;
    this.isShownSendOtp = false;
    this.isShownOr = ! this.isShownOr;
    this.isShownPassword = ! this.isShownPassword;
    this.isShownPasswordDiv = !this.isShownPasswordDiv;
    this.isShownMobileDiv = ! this.isShownMobileDiv;
    this.showMobileDiv = false;
    this.loginForm.removeControl('otp');
    this.isShownForgotPassword = true;
    //this.loginForm.removeControl('mobile');
    this.loginForm.controls['password'].setValidators([Validators.required,Validators.minLength(6),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&].{5,}')]);
    this.loginForm.controls['email'].setValidators([Validators.required]);
    
    this.loginForm.patchValue({
        email: this.loginForm.get('mobile').value
      });
  }

  loginUsingPassword(){
    this.errors = '';
    this.submitted = true;
    this.formSumbit = true;
    if(this.loginForm.invalid){
      this.formSumbit = false;
      return;
    }
    this.authService.passwordLogin(this.loginForm.value).subscribe(
      result => {
        this.responseHandler(result);
        this.formSumbit = false;
        this.authService.profileUser().subscribe(
          data => {
            this.user = data;
            localStorage.setItem("username", JSON.stringify(this.user.name));
            this.reloadComponent();
          }
        );
        
      },
      error => {
        this.errors = error.error;
        this.formSumbit = false;
        return false;
      },() => {
        this.authState.setAuthState(true);
        this.loginForm.reset();
        this.activeModal.close('Modal Closed');
        //this.reloadComponent();
      }
    );
  }
}
