<app-sub-header></app-sub-header>
<div class="container_shop process_desk">

    <!--<img [src]="store_image" id="proimgstore" class="mx-auto d-block img_shopstore" alt="product" data-src="/assets/images/store.png" data-hover="/assets/images/store_ho.png">-->
    <!-- <img [src]="eshop_image" id="proimgstore2" class="mx-auto d-block img_shopstore2" alt="product" data-src="/assets/images/eshop.png" data-hover="/assets/images/eshop_ho.png"> -->


    <div class="side_filter">
        <app-brand-filter (selectedFilters)="onChangeFilters($event)"></app-brand-filter>
    </div>







    <div class="store_section">

        <ul class="nav nav-tabs" id="shop-tabs" role="tablist">

			 <li class="nav-item" id="backarrow">

                <a class="nav-link" (click)='goBack()'><img class="" src="/assets/images/arrow12.png" alt="logo"></a>

            </li>
			
		    <li class="nav-item" id="prodtab">

                <a class="nav-link" data-toggle="tab" (click)="goToShopCategory()" href="#productlist"><img class="noshop_logo" src="/assets/images/products_hover0.png" alt="logo"><img class="acshop_logo" src="/assets/images/products0.png" alt="logo">&nbsp;Products&nbsp;</a>

            </li>
			
            <li class="nav-item" id="listbrandtab">

                <a class="nav-link active" data-toggle="tab" (click)="goToShopBrand()" ><img class="acshop_logo" src="/assets/images/brands0.png" alt="logo"><img class="noshop_logo" src="/assets/images/brands_hover0.png" alt="logo">&nbsp;Brands&nbsp;</a>

            </li>

           

            <li class="nav-item" id="searchtab">

                <app-search-product-header></app-search-product-header>
  
            </li>

        </ul>



        <!-- Tab panes -->

        <div class="salelist_contentinner">

            <div class="tab-content list_content">



                <div id="brandlist" class="tab-pane active">



                    <div class="row row_custom itemlist_row" *ngIf="!showNoProducts">

                        <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12 listitem_col" *ngFor="let product of products  ">
                            <!--| filter:searchCategory:'category_id'-->

                            <div class="listitem_inner">

                                <a [routerLink]="['/product-details', product.seo_url]"><img src="{{product.product_images}}" class="mx-auto d-block img-fluid img-thumbnail" alt="product"></a>

                                <p class="listitem_desc">{{product.product_name}} {{product.category_name}}</p>

                                <p class="listitem_cost">Rs. {{product.product_price}}</p>
                                <p class="mrp-price"><del>Rs. {{ product.cskd_mrp}}</del></p>

                                <!-- <p class="listitem_bcost"><s>Rs.1,050.00</s></p> -->

                                <button (click)="buyNow(product.id, 1)" type="submit" class="probuy">BUY NOW</button>
                                <button (click)="addItemToCart(product.id, 1)" type="submit" class="proadd">ADD TO CART</button>
                            </div>

                        </div>





                    </div>

                    
                    <div class="row row_custom itemlist_row" *ngIf="showNoProducts">
                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 listitem_col">
                            <div class="listitem_inner product_text text-center">
                                No results found
                            </div>
                        </div>
                    </div>

                </div>

                <div id="productlist" class="tab-pane fade">
                    <!-- <app-category></app-category> -->
                </div>

            </div>

        </div>

    </div>
</div>



<div class="container_shop process_device"> 

    <!-- <ul class="nav nav-tabs" id="shop-tabs" role="tablist">

        <li class="nav-item" id="searchtab1">

            <app-search-product-header></app-search-product-header>

        </li>

        <li class="nav-item meshoptab" id="mobtab1" (click)="changeShopType('shop')">



            <img class="img-fluid mx-auto d-block imgm_shopstore" [src]="store_image_device" alt="logo" data-src="/assets/images/shopinstore.png" data-hover="/assets/images/shopinstore_ho.png">



        </li>

       

    </ul> -->

    <div class="store_section">

        <ul class="nav nav-tabs" id="shop-tabs2" role="tablist">
            <li class="nav-item" id="prodtab1">

                <a class="nav-link" data-toggle="tab" [routerLink]="['/']" href="#productlist"><img class="acshop_logo" src="/assets/images/products0.png" alt="logo"><img class="noshop_logo" src="/assets/images/products_hover0.png" alt="logo">&nbsp;Products</a>

            </li>

            <li class="nav-item" id="brandtab1">

                <a class="nav-link active" data-toggle="tab" [routerLink]="['/brands']"><img class="acshop_logo" src="/assets/images/brands0.png" alt="logo"><img class="noshop_logo" src="/assets/images/brands_hover0.png" alt="logo">&nbsp;Brands</a>

            </li>

            

        </ul>





        <!-- Tab panes -->

        <div class="tab-content sale_content">

            <div id="brand1" class="tab-pane active">

                <div class="row row_shop1 shop_items">

                    <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 shop_itemscol" *ngFor="let product of products ; let i=index ">

                        <div class="listitem_inner">

                            <a class="categorylink" [routerLink]="['/product-details', product.seo_url]"><img src="{{product.product_images}}" class="mx-auto d-block img-fluid img-thumbnail" alt="product"></a>

                            <p class="listitem_desc">{{product.product_name}} {{product.category_name}}</p>

                            <p class="listitem_cost">Rs. {{product.product_price}}</p>
                            <p class="mrp-price"><del>Rs. {{ product.cskd_mrp}}</del></p>

                            <!-- <p class="listitem_bcost"><s>Rs.1,050.00</s></p> -->

                            <a (click)="buyNow(product.id, 1)"><img src="/assets/images/buynow.png" class="mx-auto itemaction" alt="buy"></a>

                            <a (click)="addItemToCart(product.id, 1)" id="{{product.description}}"><img src="/assets/images/addtocart.png" class="mx-auto itemaction" alt="add"></a>

                        </div>

                    </div>
                </div>

            </div>

            <div id="productlist" class="tab-pane fade product_device"><br>
                <!-- <app-device-category></app-device-category> -->
            </div>



        </div>



    </div>

</div>

<app-footer></app-footer>

<app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>