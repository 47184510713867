import { Component, OnInit } from '@angular/core';
import { CountryService} from '../../country.service';
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  categories: any = [];
  isLoading: boolean = false;
  constructor(
    private CountryService: CountryService,
  ) { }

  ngOnInit(): void {
    this.isLoading=true;
    this.getCategory();
  }
  getCategory(){
    
    this.CountryService.getCategory().subscribe(
      data => {
        this.categories = data;
        this.isLoading=false;
      }
    );
  }
}
