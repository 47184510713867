<app-sub-header></app-sub-header>
<div class="containercart_outer">

    <div class="container_cart">

        <div class="row row_custom">

            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cart_topdummy">

            </div>

        </div>

        <div class="row row_custom">

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col" *ngIf="isToken">
                <a class="order-back" [routerLink]="['/myorders']">
                    <img _ngcontent-wnb-c123="" src="/assets/images/arrow121.PNG" alt="logo">
                </a>

            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col accountheadbg addresshead">

                <h3 class="accountheadtext">ORDER SUMMARY</h3>

            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

            </div>

        </div>



        <div class="row row_custom">

            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cordercon_upcol">

                <h3 class="ordercon_tophead">Order Details</h3>

                <p class="ordercon_topdet">Ordered on {{order.order_date}} | Order # {{order.id}} </p>

            </div>

             <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cordercon_upcol" *ngIf="order.razpay_order_status">

                <h3 class="ordercon_tophead">Order Payment Status</h3>

                <p class="ordercon_topdet" style="text-transform: uppercase;color: #fbb600;">{{order.razpay_order_status}} </p>

            </div>

            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cordercon_upcol" *ngIf="order.order_status == 4">

                <h3 class="ordercon_tophead">Order Status</h3>

                <p class="ordercon_topdet" style="text-transform: uppercase;color: #fbb600;">Cancelled</p>

            </div>
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cordercon_upcol">

                <h3 class="ordercon_tophead" *ngIf="order.shopDetail">Shop: {{order.shopDetail.seo_url}}</h3>

                <p class="ordercon_topdet"  *ngIf="order.shopDetail"><STRONG>Address:</STRONG>  {{order.shopDetail.address}}, <span *ngIf="order.shopDetail.ccm_city_name">{{order.shopDetail.ccm_city_name}},</span><span *ngIf="order.shopDetail.csm_state_name"> {{order.shopDetail.csm_state_name}},</span><span *ngIf="order.shopDetail.ccm_city_name"> {{order.shopDetail.ccm_city_name}},</span><span *ngIf="order.shopDetail.pin !=0"> {{order.shopDetail.pin}}</span>

                    <a href="{{store.google_link}}" target="_blank" title="Go To Map" *ngIf="store.google_link"><svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="40px" height="25px" viewBox="0 0 425.963 425.963" style="enable-background:new 0 0 425.963 425.963;" xml:space="preserve" fill="#fbb600">
                                    <g>
                                        <path d="M213.285,0h-0.608C139.114,0,79.268,59.826,79.268,133.361c0,48.202,21.952,111.817,65.246,189.081
                                            c32.098,57.281,64.646,101.152,64.972,101.588c0.906,1.217,2.334,1.934,3.847,1.934c0.043,0,0.087,0,0.13-0.002
                                            c1.561-0.043,3.002-0.842,3.868-2.143c0.321-0.486,32.637-49.287,64.517-108.976c43.03-80.563,64.848-141.624,64.848-181.482
                                            C346.693,59.825,286.846,0,213.285,0z M274.865,136.62c0,34.124-27.761,61.884-61.885,61.884
                                            c-34.123,0-61.884-27.761-61.884-61.884s27.761-61.884,61.884-61.884C247.104,74.736,274.865,102.497,274.865,136.62z"/>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                    <g>
                                    </g>
                                </svg></a>

                </p>
                <p class="ordercon_topdet" *ngIf="store.phone"><STRONG>Contact: </STRONG>{{store.phone}}</p>
                <p class="ordercon_topdet" *ngIf="store.opening_time !='00:00'"><STRONG>Open & Close Timing: {{store.opening_time}} - {{store.closing_time}}</STRONG> </p> 
            </div>

        </div>

        <div class="ordercon_botouter">

            <div class="row row_custom ordercon_detrow">

                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                    <h4 class="ordercon_dethead">Billing Address</h4>
                    <p class="ordercon_dettext" *ngIf="order.billing_address">
                        {{order.billing_address.name}}<br> {{order.billing_address.address}}
                        <span *ngIf="order.billing_address.ccm_city_name"><br> {{order.billing_address.ccm_city_name}}</span>

                        <br> {{order.billing_address.csm_state_name}}                       
                        <br> {{order.billing_address.ccm_country_name}}
                        <span *ngIf="order.billing_address.web_city"><br>{{ order.billing_address.web_city }}</span>
                        <br> {{order.billing_address.pin}}
                    </p>
                </div>

                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12" *ngIf="isShowDeliveryAddress">

                    <h4 class="ordercon_dethead">Delivery Address</h4>

                    <p class="ordercon_dettext" *ngIf="order.delivery_address">

                        {{order.delivery_address.name}}<br> {{order.delivery_address.address}}
                        <span *ngIf="order.delivery_address.ccm_city_name"><br> {{order.delivery_address.ccm_city_name}}</span>
                        <br> {{order.delivery_address.csm_state_name}}
                        <br> {{order.delivery_address.ccm_country_name}}
                        <span *ngIf="order.delivery_address.web_city"><br>{{ order.delivery_address.web_city }}</span>
                        <br> {{order.delivery_address.pin}}
                    </p>

                </div>
                
                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">

                    <h4 class="ordercon_dethead">Payment Method</h4>

                    <p class="ordercon_dettext" *ngIf="order">

                        {{order.method}}

                    </p>

                    <h4 class="ordercon_dethead">Delivery</h4>
                    <p class="ordercon_dettext">

                        {{deliveryType}}

                    </p>
                    <!--<h4 class="ordercon_dethead">Order Status</h4>
                    <p class="ordercon_dettext">

                        {{orderStatus}}

                    </p> -->
                </div>

                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12">

                    <h4 class="ordercon_dethead">Order Summary</h4>

                    <div class="ordercon_bill">

                        <div class="row row_custom">

                            <div class=" col-md-7 col-lg-7 col-sm-12 col-xs-12 process_col">

                                <p class="ordercon_dettext2">Item(s) Subtotal</p>

                            </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 process_col">

                                <p class="ordercon_dettext2">Rs. {{ order.subtotal | number : '1.2-2'}}</p>

                            </div>

                        </div>

                        <div class="row row_custom" *ngIf="order.discount_amount != 0">

                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 process_col">

                                <p class="ordercon_dettext2">Discount</p>

                            </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 process_col">

                                <p class="ordercon_dettext2">-Rs. {{order.discount_amount | number : '1.2-2'}}</p>

                            </div>

                        </div>


                        <div class="row row_custom" *ngIf="order.delivery_charge != 0">

                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 process_col">

                                <p class="ordercon_dettext2">Delivery Fee</p>

                            </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 process_col">

                                <p class="ordercon_dettext2">Rs. {{order.delivery_charge | number : '1.2-2'}}</p>

                            </div>

                        </div>
    

                        <div class="row row_custom">

                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 process_col ">

                                <p class="ordercon_dettext2">Grand Total</p>

                            </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 process_col">

                                <p class="ordercon_dettext2">Rs. {{order.amount-order.discount_amount| number : '1.2-2'}}</p>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>





        <div class="ordercon_botouter">

            <div class="row row_custom ordercon_detrow2">

                <div class="col-md-4 col-lg-4 col-sm-12 col-xs-12">

                    <h4 class="ordercon_dethead">Item Details</h4>

                </div>

            </div>

            <div class="row row_custom ">

                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">

                    <div class="row row_custom ordercon_detrow3" *ngFor="let item of order.order_items">

                        <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12 process_col">

                            <div class="itemthumb_outer">

                                <div class="item_thumbnail2">

                                    <img src="{{item.product_images}}" class="img_cartthumb img-fluid img-thumbnail" alt="cart">

                                    <span class="checkout_qty">{{item.quantity}}</span>

                                </div>

                                <p class="itemthumbdet">{{item.product_name}} <a *ngIf="item.gift_item==1" (click)="getGiftItemModel(item.item_id)" >&nbsp;<img src="/assets/images/information-button.png"></a></p>

                            </div>

                        </div>

                        <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12 process_col">

                            <p class="itemthumbdet">Rs. {{item.amount}}</p>

                        </div>

                    </div>

                </div>

                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">



                </div>

            </div>



        </div>
        
        <div class="ordercondown">

        </div>

    </div>

</div>
<app-footer></app-footer>

<app-loader *ngIf="isLoading">{{ isLoading }}</app-loader>