import * as $ from 'jquery';
import {  Component, OnInit, Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef, HostListener, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { DOCUMENT } from '@angular/common';
import { NgbActiveModal, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {RegistraionComponent} from 'src/app/modals/registraion/registraion.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import {ChooseOutletsComponent} from 'src/app/modals/choose-outlets/choose-outlets.component';
import { Router } from '@angular/router';
import { AuthService } from './../../shared/auth.service';
import { TokenService } from '../../shared/token.service';
import { AuthStateService } from '../../shared/auth-state.service';
import { CountryService } from '../../country.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public showContainer: boolean;

  @Input()id: number;
  closeResult: string;
  loginForm: FormGroup;
  submitted:boolean = false;
  formSumbit:boolean = false;
  errors = null;
  result = null;
  otp:string;
  mobile:string;
  isShownButton:boolean = false;
  isShown: boolean = false ;
  isShownPassword:boolean = true;
  isShownSendOtp:boolean = true;
  isShownOr:boolean = true;
  isShownPasswordDiv:boolean = false;
  isShownMobileDiv:boolean = false;
  user:any = [];
  showResendOtp:boolean = false;  
  setmaintenance:any = [];
  timeLeft: number = 60;
  interval;
  isShowPincode:boolean = false;
  isShowPincodeor:boolean = false;
  pincode: number = 0;
  shops:any = [];
  message:any ="";
  isShownForgotPassword:boolean = false;
  nameT:string ="";
  pattern = /^[A-Za-z0-9 \/\s\.!@#$%^&*()~`+\-=\[\]{};':"\\|,.<>\/?]*$/;
  isPasswordError:boolean = false;
  isEmailError:boolean = false; 
  constructor(
    public router: Router,
    @Inject(DOCUMENT) private document: Document,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private token: TokenService,
    private authState: AuthStateService,
    private CountryService: CountryService
  ) { }

  ngOnInit(): void {
    this.buildForm();
    $(".is-invalid").focus();
  }
  buildForm() {
    this.loginForm = this.formBuilder.group({
      mobile: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      otp :[null],
      password:[''],
      email:[''],
      smstype: [''],
      name :['',[Validators.required,Validators.pattern(this.pattern)]],
      pin: [''],
      shop_id: ['']
    });
  }
  VerifyNumber(){  
   localStorage.removeItem('user_mobile');
   localStorage.setItem("user_mobile", JSON.stringify(this.loginForm.value.mobile));

   /*if(this.loginForm.value.mobile!='9880898693'){ 
     this.CountryService.checkMaintenance().subscribe(
      data => { 
        this.setmaintenance=data;
        if(this.setmaintenance.message=='maintenance'){ 
            this.activeModal.close('Modal Closed'); 
            this.reloadComponent();
            this.formSumbit = false;
            return;
        }  
      }); 
    }*/


    this.errors = '';
    this.submitted = true;
    this.formSumbit = true;
    this.loginForm.removeControl('password');
    this.loginForm.removeControl('email');
   // this.loginForm.controls['otp'].clearValidators();
    if(this.loginForm.invalid){
      this.formSumbit = false;
      return;
    } 

    this.loginForm.value.pin=localStorage.getItem('appPincode');
    this.loginForm.value.shop_id=localStorage.getItem('appShopId');
    
    this.authService.VerifyNumberAndRegister(this.loginForm.value).subscribe(
      result => {
        this.responseHandler(result);
        this.formSumbit = false;
      },
      error => {
        this.errors = error.error;
        this.formSumbit = false;
      },() => {
        this.isShownButton = ! this.isShownButton;
        this.isShownPassword = ! this.isShownPassword;
        this.isShownSendOtp = ! this.isShownSendOtp;
        this.isShownOr = ! this.isShownOr;
        this.isShownForgotPassword = true;
      }
    );
  }
  searchShopPincode(){
    let payload = {
      pincode: this.loginForm.get('pincode').value,
    };
    this.authService.searchPincode(payload).subscribe(
      data => {
        this.shops = data;
        console.log(this.shops);
        this.isShowPincodeor = !this.isShowPincodeor;
        this.isShownButton = ! this.isShownButton;
        this.isShownPassword = false;
        this.isShownSendOtp = false;
        this.isShownOr = false;
        this.isShown = false;
        this.isShownPasswordDiv = false;
        this.isShownMobileDiv = true;
        //this.isShownForgotPassword = true;
      },
      error => {

      },() => {

    });
  }
  sendOtp(type){  
    this.isShownPassword = true;
    this.isShownOr = true;
    this.errors = '';
    this.submitted = true;
    this.formSumbit = true;
    this.loginForm.removeControl('password');
    this.loginForm.removeControl('email');
    //this.loginForm.controls['otp'].clearValidators();
    this.loginForm.patchValue({ 
      smstype : type,
    });
    if(this.loginForm.invalid){
      this.formSumbit = false;
      return;
    }

    this.authService.sendOtpAndRegister(this.loginForm.value).subscribe(
      result => {
        this.responseHandler(result);
        this.result = result;
        this.formSumbit = false;
      },
      error => {
        this.errors = error.error;
        this.formSumbit = false;
      },() => {
        this.isShown = ! this.isShown;
        this.isShownSendOtp = ! this.isShownSendOtp;
        this.submitted = false;
        this.loginForm.removeControl('password');
        this.loginForm.removeControl('email');
        this.loginForm.controls['otp'].setValidators([Validators.required]);
        this.loginForm.value.pin=localStorage.getItem('appPincode');
        this.loginForm.value.shop_id=localStorage.getItem('appShopId');
        this.startTimer();
      }
    );
  }

  get f() { return this.loginForm.controls; }
  VerifyOtp(){
    this.loginForm.value.pin=localStorage.getItem('appPincode');
    this.loginForm.value.shop_id=localStorage.getItem('appShopId');
    this.errors = '';
    this.submitted = true;
    this.formSumbit = true;
    if(this.loginForm.invalid){
      this.formSumbit = false;
      return;
    }
    this.authService.signin(this.loginForm.value).subscribe(
      result => {
        this.responseHandler(result);
        this.formSumbit = false;
        this.authService.profileUser().subscribe(
          data => {
            this.user = data;
            console.log(this.user.shop_id);
            this.authService.startRefreshTokenTimer()
            localStorage.setItem("username", JSON.stringify(this.user.name));
            localStorage.setItem("appShopId", JSON.stringify(this.user.shop_id));
            //this.reloadComponent();
            
          }
        );

        
      },
      error => {
        this.errors = error.error;
        this.formSumbit= false;
        return false;
      },() => {
        this.authState.setAuthState(true);
        this.loginForm.reset();
        this.activeModal.close('Modal Closed');
        //this.reloadComponent();
      }
    );
  }
  reloadComponent() {
      //let currentUrl = this.router.url;
      let currentUrl = "/";
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    }

  usePassword(){
    this.isShown = false;
    this.isShownSendOtp = false;
    this.formSumbit= false;
    this.isShownOr = ! this.isShownOr;
    this.isShownPassword = ! this.isShownPassword;
    this.isShownPasswordDiv = !this.isShownPasswordDiv;
    this.isShownMobileDiv = ! this.isShownMobileDiv;
    this.isShownForgotPassword = true;
	  // this.loginForm.removeControl('otp');
    this.loginForm.controls['otp'].clearValidators();
    this.loginForm.removeControl('name');
    this.loginForm.addControl('password', new FormControl('', [Validators.required,Validators.minLength(6),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&].{5,}')]));
    this.loginForm.addControl('email', new FormControl(this.loginForm.get('mobile').value, Validators.required));
    this.loginForm.value.pin=localStorage.getItem('appPincode');
    this.loginForm.value.shop_id=localStorage.getItem('appShopId'); 
    this.loginForm.controls['email'].setValue(this.loginForm.get('mobile').value);
    console.log(this.loginForm.value.email);
    this.loginForm.removeControl('mobile');
  }
  loginUsingPassword(){
    this.loginForm.value.pin=localStorage.getItem('appPincode');
    this.loginForm.value.shop_id=localStorage.getItem('appShopId');
    this.errors = '';
    this.submitted = true;
    this.formSumbit = true;
    this.loginForm.value.otp='';
    this.isEmailError=false; 
    this.isPasswordError=false; 

    console.log(this.f.password.errors);
    console.log(this.loginForm);

    if(this.loginForm.invalid){
      console.log(this.loginForm);
      //alert(this.loginForm.invalid);
      this.formSumbit = false;
      return;
    }

    if(this.loginForm.value.email==''){
      this.isEmailError=true; 
    }

    if(this.loginForm.value.password==''){
      this.isPasswordError=true; 
    }

    if(this.loginForm.value.email=='' || this.loginForm.value.password=='' || this.f.password.errors!=null){
      this.formSumbit = false;
      return;
    }
    this.authService.passwordLogin(this.loginForm.value).subscribe(
      result => {
        this.responseHandler(result);
        this.formSumbit = false;
        this.authService.profileUser().subscribe(
          data => {
            this.user = data;
            localStorage.setItem("username", JSON.stringify(this.user.name));
            localStorage.setItem("appShopId", JSON.stringify(this.user.shop_id));
            //this.reloadComponent();
          }
        );
        
      },
      error => {
        this.errors = error.error;
        this.formSumbit = false;
        return false;
      },() => {
        this.authState.setAuthState(true);
        this.loginForm.reset();
        this.activeModal.close('Modal Closed');
        //this.reloadComponent();
      }
    );
  }
  openRegisterModal(){
    const modalRef = this.modalService.open(RegistraionComponent, { 
                                                centered: true, 
                                                size: 'xl',
                                                backdrop: 'static',
                                                keyboard: false,
                                                windowClass: 'register_modal' 
                                          });
    modalRef.componentInstance.id = 2; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
  openForgetPassword(){
    const modalRef = this.modalService.open(ForgotPasswordComponent, { 
      centered: true, 
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'login_modal' 
    });
    modalRef.componentInstance.id = 2; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
  // convenience getter for easy access to form fields
  
  isFieldValid(field: string) {
    return !this.loginForm.get(field).valid && this.loginForm.get(field).touched;
  }
  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

  // Handle response
  responseHandler(data){
    this.token.handleData(data.token);
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.showResendOtp = true;
    },5000)
  }
  pauseTimer() {
    clearInterval(this.interval);
  }
  resendOtp(){
    this.authService.sendOtp(this.loginForm.value).subscribe(
      result => {
        this.formSumbit = false;
        this.responseHandler(result);
        this.result = result;
      },
      error => {
        this.errors = error.error;
        this.formSumbit = false;
      },() => {
        this.formSumbit = false;
        this.showResendOtp =false;
        this.startTimer();
        this.loginForm.controls['otp'].setValidators([Validators.required,,Validators.minLength(6),Validators.pattern("^[0-9]*$")]);
        //this.loginForm.addControl('otp', new FormControl('', [Validators.required,Validators.minLength(6),Validators.pattern("^[0-9]*$")]));
      }
    );
  }
  trim(myString) { 
    console.log(myString);
    this.loginForm.patchValue({
      mobile: myString.replace(" ",''),
    });
    return myString.replace(" ",'-'); 
    
  }


  getNameByMobile(e){
    if((e.target.value.length ) >= 10){
      this.authService.getNameByMobile(e.target.value).subscribe(
        data => { 
          console.log(data); 
          if(data){
            this.user  = data;
            this.nameT = this.user.name; 
            console.log(this.user.name); 
            this.isShownPassword = true;
            this.isShownOr = true;
            this.loginForm.patchValue({
              name: this.nameT,
            });
          }else{
            this.user  = data;
            this.nameT = '';
            this.isShownPassword = false;
            this.isShownOr = false; 
          }
        }
      );
    }
  }
}
