<app-sub-header></app-sub-header>
<div class="containercart_outer" id="containercart_outer"> 
	<div class="container-fluid"><!-- container_cart -->   
		
		<div class="row no-gutters">

			<div class="col-md-12 col-lg-4 col-sm-12 col-xs-12">

				<!--<div class="locate-pan-outer">
					<div (mouseover)="onHover()" (mouseout)="onOut()" 				
						[ngClass]="{'locate-outer-active': isHovered, 'locate-outer': !isHovered }">
						<a href="/kedinje" title="Click PAN India delivery"><div class="locate-inner">
							<div class="pan-pin"></div>
							<div class="pan-msg">PAN <div class="right-arrow"></div>
							<span>India delivery</span></div>
						</div></a> 
					</div>
				</div>-->

				<div class="store-locate-header">
					<span><img src="/assets/images/store-location.png" alt="image"></span>
					<h4 class="store-title">Locate Stores Near Me</h4>
					<!--<span class="locator-hr"></span>-->  
					<!--<a href="/kedinje" class="btn btn-click-here right-align">PAN India delivery</a>-->
				</div>
				<form appForm [formGroup]="mapFrm">
					<div class="outlet-pincode-section">
						<div class="input-group outlet-outer">
							<input type="text" class="form-control pin_search" placeholder="Enter PIN Code" id="pinsearch_text" name="pinsearch_text" formControlName="pinsearch_text" maxlength="6"  [ngClass]="{ 'is-invalid': submitted && f.pinsearch_text.errors }"  > 
							<div class="input-group-append"><button class="btn btn-pin" type="submit" (click)="loadGoogleMap()">GO</button></div>

							<div class="input-group-append"><button class="btn btn-pin"  (click)="reloadMap()"  style="margin-left:10px;">ALL</button></div>

						</div>
						 
					<div *ngIf="errors"  class="errMsg"> 
						<div  >Please enter valid Pincode</div> 
					</div> 
					</div> 
				    <div class="notfoundMsg store-locate-outer" *ngIf="nodata" > 
							<span >Nearest outlets are not found for the entered pincode.</span><br/>
							<div class="panClick">Click here for </div> 

									<a href="/kedinje" title="Click PAN India delivery">
										<img src="/assets/images/pan.svg" alt="image" style="height:auto;width:50%;">
									</a>
				    </div> 

					<div class="store-locate-outer"> 					

						<!--<div class="infoMsg"> 
								<span >Start shopping by clicking on Shop Now</span>
						</div>-->
						<div class="outlet-outer">
								
							<div class="outlet-inner" *ngFor="let marker of allOutlets; let i = index" 
							[ngClass]="marker==selectedItem  ? 'outlet-inner active':'outlet-inner '"  id="{{'Id-'+i}}">
								<!--(mouseover)="hoverClass(marker)" (mouseout)="hoverClass(marker)" [ngClass]="marker.hovered ? 'outlet-inner active':'outlet-inner '"-->
									<div class="outlet-name" >{{marker.name}}</div>
									<div class="outlet-items">
										<span class="outlet-location"></span>
										<span class="outlet-address">{{marker.vicinity}}</span>
									</div>
									
										<div class="outlet-items"  *ngIf="marker.phone" > 
												<a  href="tel:+91{{marker.phone}}"  *ngIf="marker.phone"><span class="outlet-mob"></span></a>
												<span class="outlet-contact"><a  class="outlet-contact" href="tel:+91{{marker.phone}}"  *ngIf="marker.phone">{{marker.phone}}  </a></span>
										</div>		
															   
									<div class="item-bottom outlet-items">
										<button class="btn btn-click-here btn-viewmap" (click)="loadGoogleMapOnClickOfInfo(marker.pin,marker)">View On Map</button> 
										<button class="btn btn-click-here"  *ngIf="marker.outlet_map_link"><a href="{{marker.outlet_map_link}}" target="_blank" class="shop_now">Go To Map</a></button> 
										<button class="btn btn-click-here"> <a href="/{{marker.seo_url}}" class="shop_now">Shop Now</a></button> 
									</div>
							</div>
							
						</div>
					</div>
				</form>

			</div>

			<div class="col-md-12 col-lg-8 col-sm-12 col-xs-12 agm-map-inner" id="agm-map-inner">
				<agm-map  [latitude]="lat" [longitude]="lng"  [mapTypeId]="mapType" [zoom]="zoom" ><!-- (mapClick)="close_window($event)" -->
					<agm-marker (markerClick)=select_marker(infoWindow,marker,i) *ngFor="let marker of coordinates; let i = index" 
					[iconUrl]="icon" 
					[latitude]="marker.latitude"
					[longitude]="marker.longitude"
					[markerClickable]="true">
						<agm-info-window [isOpen]="marker==selectedItem  ? true:false" #infoWindow> 
							<div class="agm-map-container">
								<div class="agm-marker-name"> {{marker.name}}  </div>
								<div class="agm-map-items">
									<span class="agm-map-location"></span>
									<span class="agm-map-address">{{marker.vicinity}}</span>
								</div>
								<div class="agm-map-items">
									<span class="agm-map-mob"></span>
									<a href="tel:+91{{marker.phone}}"  *ngIf="marker.phone"><span class="agm-map-contact">{{marker.phone}}</span></a> 
								</div>
								<div class="agm-map-items">  
									 <button class="btn btn-click-here"> <a href="/{{marker.seo_url}}" class="shop_now">Shop Now</a></button>  
								</div>
							</div>
						</agm-info-window>
					</agm-marker>

				</agm-map>
			</div>

		</div>

	</div>
</div>
<app-footer></app-footer>  
