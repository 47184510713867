import { Component, OnInit } from '@angular/core';
import { OrderService } from '../order.service';
import { CountryService} from '../country.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { OrderGiftItemsComponent} from '../modals/order-gift-items/order-gift-items.component';
@Component({
  selector: 'app-myorders',
  templateUrl: './myorders.component.html',
  styleUrls: ['./myorders.component.css']
})
export class MyordersComponent implements OnInit {
  orders:any =[];
  isLoading = false;
  searchOrderForm: FormGroup;
  filterOrderForm: FormGroup;
  isShownOrders:boolean = true;
  store:any =[];
  storename:string = "";
  constructor(
    private OrderService: OrderService,
	  private formBuilder: FormBuilder,
    private CountryService: CountryService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
    });
    this.isLoading = true;
    this.buildForm();
    this.getStore();
    this.buildfilterForm();
    this.OrderService.getMyOrder().subscribe(
      data => {
        this.orders = data;
        this.isLoading = false;
        //localStorage.setItem("cart_count", JSON.stringify(0));
      }
    );
  }
  
  buildForm() {
    this.searchOrderForm = this.formBuilder.group({
      search_text:['']
    });
  }
  
  buildfilterForm() {
    this.filterOrderForm = this.formBuilder.group({
      myfilter:['']
    });
  }
  
  
  SearchOrders()
  {
  this.isLoading = true;
  this.isShownOrders = true;
  this.filterOrderForm.controls['myfilter'].setValue("");
  if(this.searchOrderForm.get('search_text').value!="")
  {
    this.OrderService.searchMyOrder(this.searchOrderForm.value).subscribe(
      data => {
        this.orders = data; 
        this.isLoading = false;
        //localStorage.setItem("cart_count", JSON.stringify(0));
      },  
      error => {
	    this.isLoading = false;
        this.isShownOrders = false;
      },() => {
        this.isShownOrders = true;
      });
	  
	}
	else
	{
	 this.OrderService.getMyOrder().subscribe(
      data => {
        this.orders = data;
        this.isLoading = false;
        //localStorage.setItem("cart_count", JSON.stringify(0));
      }
    );
	}
	
  }
  
  FilterOrders()
  {
   this.isLoading = true;
   this.isShownOrders = true;
   this.searchOrderForm.reset();
    this.OrderService.filterMyOrder(this.filterOrderForm.value).subscribe(
      data => {
        this.orders = data; 
        this.isLoading = false;
        //localStorage.setItem("cart_count", JSON.stringify(0));
      }
    );

  }
  getStore(){
    this.CountryService.getStoreDetails().subscribe(
      result => {
        this.store = result;
        console.log(this.store);
        this.storename = this.store.seo_url
      },
      error => {
        
      },() => {
      }
    );
  }
  getGiftItemModel(parent_id){
    const modalRef = this.modalService.open(OrderGiftItemsComponent, { 
                          size: 'xl',
                          backdrop: 'static',
                          keyboard: false,
                          windowClass: 'shoplogin_modal',
                          animation: true,
                          ariaDescribedBy:'test',
                        });
    modalRef.componentInstance.parent_id = parent_id; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
}
