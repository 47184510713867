import { Component, OnInit, Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef, HostListener, Inject} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/auth.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  closeResult: string;
  forgotPasswordForm: FormGroup;
  submitted = false;
  errors = null;
  result = null;
  constructor(
    public router: Router,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.formBuilder.group({
      email:['', [Validators.email]],
	  phone: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    });
  }
  
  get f() { return this.forgotPasswordForm.controls; }
  isFieldValid(field: string) {
    return !this.forgotPasswordForm.get(field).valid && this.forgotPasswordForm.get(field).touched;
  }
  forPassword(){
    this.errors ="";
    this.submitted = true;
    if(this.forgotPasswordForm.invalid){
      return;
    }

    this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe(
      result => {
        this.result = result;
        
        setTimeout(()=>{                           
          this.activeModal.close('Modal Closed');
        }, 3000);
      },
      error => {
        this.errors = error.error;
      },() => {
        //this.activeModal.close('Modal Closed');
      }
    );
  }
  trim(myString) { 
    console.log(myString);
    this.forgotPasswordForm.patchValue({
      phone: myString.replace(" ",''),
    });
    
  }
}
