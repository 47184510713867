<div class="modal-body">

    <div class="row row_custom">

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 process_col">

            <img class="close closedevice" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">

        </div>



        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 process_col">

            <img class="close closedesk" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">

        </div>

    </div>

    <div class="row row_custom address_poprow">

        <div class="col-lg-4 col-md-4 col-sm-12 col-xs-12 address_popup" *ngFor="let address of billingAddress">

            <a (click)="addBillingAddressToStore(address.id)">

                <h3 class="address_popuphead">{{address.type}}</h3>

                <p class="address_popupdet">

                    {{address.name}}<br> {{address.phone}}
                    <br> {{address.address}} 
                    <br> <span *ngIf="address.town">{{address.town}},</span> <span *ngIf="address.web_city">{{address.web_city}} ,</span>{{address.state}}, {{address.country_name}} - {{address.pin}}

                </p>

            </a>

        </div>

    </div>