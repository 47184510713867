<div class="prod_layer">

    <div class="row row_shop2 shop_items">

        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 shop_procol">

        </div>

        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 shop_procol">

        </div>

        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 shop_procol" *ngFor="let category of categories">

            <div class="shop_prod">

                <a [routerLink]="['/product/category',category.category_name]"><img src="{{category.category_image}}" class="img-fluid img-thumbnail category-image" alt="product">

                    <h3 class="shopprod_head">{{category.category_name}}</h3>
                    <!-- <p class="shopprod_det">Lorem ipsum dolor sit amet, etur adipiscing</p> -->
                </a>

            </div>
        </div>

    </div>

</div>