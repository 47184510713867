import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { TokenService } from './shared/token.service';
import { AuthStateService } from './shared/auth-state.service'; 
import { CountryService } from './country.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Shop';
  isSignedIn: boolean; 
  setmaintenance:any = [];
  maintenance_token:any = [];
  isMaintenance: boolean = false;  
  isOutletSelected: boolean; 
  constructor(
    public breakpointObserver: BreakpointObserver,
    private auth: AuthStateService,
    public router: Router,
    public token: TokenService,
    private CountryService: CountryService,
  ) { }

  isLoading = false;
  ngOnInit() {    
   
    /*setInterval(() => {
      this.checkMaintenence(); 
    }, 1000);  */

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe( (state: BreakpointState) => {
      if (state.breakpoints[Breakpoints.XSmall]) {
           console.log( 'Matches XSmall viewport');
      }
      if (state.breakpoints[Breakpoints.Small]) {
           console.log( 'Matches Small viewport');
      }
      if (state.breakpoints[Breakpoints.Medium]) {
           console.log( 'Matches Medium  viewport');
      }
      if (state.breakpoints[Breakpoints.Large]) {

          console.log( 'Matches Large viewport');
      }
      if (state.breakpoints[Breakpoints.XLarge]) {

         console.log( 'Matches XLarge viewport');   
      }
    });
  }


  checkMaintenence(){
   // alert();
    this.CountryService.checkMaintenance().subscribe(
      data => {
 
         this.maintenance_token = localStorage.getItem('user_mobile');   
         if (this.maintenance_token.match('9880898693') === null) { 
            //alert('noequal'+ this.maintenance_token);
            this.setmaintenance=data;
            if(this.setmaintenance.message=='maintenance'){
                this.isMaintenance=true;
            }else{ 
                this.isMaintenance=false;
            } 
        } else{
            //alert('equal'+ this.maintenance_token);
            this.isMaintenance=false; 
        }

      });
  }

}
