import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { of , Observable, Subject} from 'rxjs';
import { map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GiftService {
  count = 0;
  appShopId:any;
  simpleObservable = new Subject();
  simpleObservable$ = this.simpleObservable.asObservable();
  constructor(
  	private http: HttpClient
  ) { }
  apiBaseUrl = environment.APP_URL;

  getAllGiftBox(){
    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId   && this.appShopId!='undefined'){
        this.appShopId='0';
    }else{
        this.appShopId=localStorage.getItem('appShopId'); 
    } 

  	return this.http.get(`${this.apiBaseUrl}get-giftbox/${this.appShopId}`);
  }
  getGiftItems(slug: string) {
  	return this.http.get(`${this.apiBaseUrl}gifts/${slug}`).pipe(
      catchError(this.handleError)
    );
  }
  increaseItemQty(payload) {
    this.count+=1;
    this.simpleObservable.next(this.count)
    return this.http.post(`${this.apiBaseUrl}update-gift-cart`,payload);
  }
  increamentGiftBoxQTY(payload) {
    return this.http.post(`${this.apiBaseUrl}updated-giftbox`,payload);
  }
  deleteGiftItem(id){
    if (this.count > 0) { this.count-=1 };
    this.simpleObservable.next(this.count)
    return this.http.delete(`${this.apiBaseUrl}delete-gift-item/${id}`);
  }
  deleteGiftItemSuggestion(productid,giftboxid){
    if (this.count > 0) { this.count-=1 };
    this.simpleObservable.next(this.count)
    return this.http.delete(`${this.apiBaseUrl}delete-gift-item-suggestion/${productid}/${giftboxid}`);
  }
  emptyGift(payload) {
    return this.http.post(`${this.apiBaseUrl}ignore-item`, payload);
  }
  addToGiftBox(payload) {
  	return this.http.post(`${this.apiBaseUrl}add-to-giftbox`, payload);
  }
  addItemsToGiftCart(payload){
  	return this.http.post(`${this.apiBaseUrl}add-all-items-giftbox`, payload);
  }
  addGiftBoxToCart(payload){
    return this.http.post(`${this.apiBaseUrl}add-giftbox-cart`,payload);
  }
  getGiftCartAddedItems(id){
    return this.http.get(`${this.apiBaseUrl}get_gift_items/${id}`);
  }
  getGiftItemInCart(id){
    return this.http.get(`${this.apiBaseUrl}get-gift-item-cart/${id}`);
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }
}