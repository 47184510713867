<div class="modal-body">

    <div class="row row_custom">

        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 shoplogin_side">
            <img class="close closedevice" (click)="activeModal.dismiss('Close clicked')" data-dismiss="modal" aria-label="Close" src="/assets/images/close.png">
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 shoplogin_main">

            <h3 class="shoplogin_head">Forgot Password</h3>

            <form [formGroup]="forgotPasswordForm">

                <div class="">
                    <div>
                        <label class="shoplogin_label" for="uname">Email:</label>

                        <input class="shoplogin_text" type="text" placeholder="Email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.pattern">Please enter valid number</div>

                        </div>
                        <div *ngIf="errors?.email">
                            {{ errors?.email }}
                        </div>
                    </div>
					
					<div>
                        <label class="shoplogin_label" for="uname">Mobile*:</label>

                        <input class="shoplogin_text" type="text" placeholder="Mobile" name="phone" formControlName="phone" (keyup)="trim($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
                        <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                            <div *ngIf="f.phone.errors.required">Mobile number is required</div>
                            <div *ngIf="f.phone.errors.pattern">Please enter valid number</div>

                        </div>
                        <div *ngIf="errors?.phone">
                            {{ errors?.phone }}
                        </div>
                    </div>
					
                    <button class="shoplogin_button" type="submit" (click)="forPassword()">Submit</button>


                    <div *ngIf="errors?.error" class="alert alert-danger mt-3">
                        {{ errors?.error }}
                    </div>
                    <div *ngIf="result" class="alert alert-success mt-3">
                        {{ result }}
                    </div>

                </div>

            </form>

        </div>

        <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12 shoplogin_side">

            <img class="close closedesk" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">

        </div>

    </div>

</div>