import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { LoaderComponent } from './loader/loader.component';
import { AuthInterceptor } from './shared/auth.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LightboxModule } from 'ngx-lightbox';
import { AgmCoreModule } from '@agm/core';

import { HeaderComponent } from './template/header/header.component';
import { FooterComponent } from './template/footer/footer.component';
import { ShopComponent } from './shop/shop.component';
import { LoginComponent } from './modals/login/login.component';
import { RegistraionComponent } from './modals/registraion/registraion.component';
import { ShopRegisterComponent } from './modals/shop-register/shop-register.component';
import { ForgotPasswordComponent } from './modals/forgot-password/forgot-password.component';
import { DeliveryAddressComponent } from './modals/delivery-address/delivery-address.component';
import { BillingAddressComponent } from './modals/billing-address/billing-address.component';
import { CategoryComponent } from './shop/category/category.component';
import { WindowService} from './common/window/window.service';


import { CartComponent } from './cart/cart.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { MyaddressComponent } from './myaddress/myaddress.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { DeliveryPaymentComponent } from './delivery-payment/delivery-payment.component';
import { ChangePasswordComponent } from './myaccount/change-password/change-password.component';
import { NewAddressComponent } from './new-address/new-address.component';
import { NotificationsComponent } from './modals/notifications/notifications.component';
import { ErrorNotificationsComponent } from './modals/error-notifications/error-notifications.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { MyordersComponent } from './myorders/myorders.component';
import { ChangeOutletComponent } from './change-outlet/change-outlet.component';
import { BrandListComponent } from './products/brand-list/brand-list.component';
import { CategoryListComponent } from './products/category-list/category-list.component';
import { CategoryProductComponent } from './product-details/category-product/category-product.component';
import { BrandProductComponent } from './product-details/brand-product/brand-product.component';
import { CategoryFilterComponent } from './products/category-list/category-filter/category-filter.component';
import { BrandFilterComponent } from './products/brand-list/brand-filter/brand-filter.component';
import { ProductDetailComponent } from './product-details/product-detail.component';
import { DeviceCategoryComponent } from './shop/device-category/device-category.component';
import { SearchProductHeaderComponent } from './shop/search-product-header/search-product-header.component';
import { SearchProductComponent } from './search-product/search-product.component';
import { DirectLoginComponent } from './direct-login/direct-login.component';
import { DirectLoginModalComponent } from './direct-login/login/login.component';
import { ShopProductComponent } from './shop-product/shop-product.component';
import { PrivacyPolicyComponent } from './modals/privacy-policy/privacy-policy.component';
import { ReturnPoliceComponent } from './modals/return-police/return-police.component';
import { TermsConditionsComponent } from './modals/terms-conditions/terms-conditions.component';
import { ResetPasswordComponent } from './modals/reset-password/reset-password.component';
import { SearchModalComponent } from './modals/search-modal/search-modal.component';
import { ChooseOutletsComponent } from './modals/choose-outlets/choose-outlets.component';
import { SetPasswordComponent } from './myaccount/set-password/set-password.component';
import { SubHeaderComponent } from './template/sub-header/sub-header.component';
import { GiftboxComponent } from './gift/giftbox/giftbox.component';
import { GiftitemsComponent } from './gift/giftitems/giftitems.component';
import { SearchPipe } from './search.pipe';
import { CartGiftItemsComponent } from './modals/cart-gift-items/cart-gift-items.component';
import { GiftItemsMobileViewComponent } from './modals/gift-items-mobile-view/gift-items-mobile-view.component';
import { OrderGiftItemsComponent } from './modals/order-gift-items/order-gift-items.component';
import { SearchoutletComponent } from './modals/searchoutlet/searchoutlet.component';
import { StoreDetailsComponent } from './modals/store-details/store-details.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AutoFocusDirective } from './auto-focus.directive';
import { MyAgmComponent } from './my-agm/my-agm.component';
import { ViewmapComponent } from './viewmap/viewmap.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
	LoginComponent,
    FooterComponent,
    ShopComponent,
    RegistraionComponent,
    ShopRegisterComponent,
    ForgotPasswordComponent,
    DeliveryAddressComponent,
    BillingAddressComponent,
    LoaderComponent,
    CategoryComponent,
    CartComponent,
    MyaccountComponent,
    MyaddressComponent,
    PaymentSuccessComponent,
    DeliveryPaymentComponent,
    ChangePasswordComponent,
    NewAddressComponent,
    NotificationsComponent,
	ErrorNotificationsComponent,
    ConfirmationDialogComponent,
    OrderDetailsComponent,
    MyordersComponent,
    ChangeOutletComponent,
    BrandListComponent,
    CategoryListComponent,
    CategoryProductComponent,
    BrandProductComponent,
    CategoryFilterComponent,
    BrandFilterComponent,
    ProductDetailComponent,
    DeviceCategoryComponent,
	  SearchProductHeaderComponent,
	  SearchProductComponent,
	  DirectLoginComponent,
	  DirectLoginModalComponent,
	  ShopProductComponent,
	  PrivacyPolicyComponent,
	  ReturnPoliceComponent,
	  TermsConditionsComponent,
	  ResetPasswordComponent,
	  SearchModalComponent,
	  ChooseOutletsComponent,
	  SetPasswordComponent,
	  SubHeaderComponent,
	  GiftboxComponent,
	  GiftitemsComponent,
	  SearchPipe,
	  CartGiftItemsComponent,
	  GiftItemsMobileViewComponent,
	  OrderGiftItemsComponent,
	  SearchoutletComponent,
	  StoreDetailsComponent,
	  PagenotfoundComponent,
	  AutoFocusDirective,
	  MyAgmComponent,
	  ViewmapComponent,
	  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    LayoutModule,
    LightboxModule  ,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBQp-AnSOYTnHnp8gW6zCD4HQqMph0x9rk',
      libraries: ['places']
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
      
    },
    WindowService,
    NgbActiveModal,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
