import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TokenService } from '../shared/token.service';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from './../../environments/environment';

export class User {
  name: String;
  email: String;
  password: String;
  password_confirmation: String;
  phone:String;
  address:String;
  address1:String;
  address2: String;
  country_id:number;
  pin:number;
  state_id:number;
  town_id:number;
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  constructor(
    private http: HttpClient,
    public token: TokenService
  ) { 
    this.userSubject = new BehaviorSubject<User>(null);
    this.user = this.userSubject.asObservable();
  }
  //apiBaseUrl = 'https://bolas.co.in/admin/api/';
  //apiBaseUrl = 'https://bsk.bispltest.in/admin/api/';
  apiBaseUrl = environment.APP_URL;
  
  // User registration
  public get userValue(): User {
    return this.userSubject.value;
  }
  register(user: User): Observable<any> {
    return this.http.post(`${this.apiBaseUrl}register`, user);
  }

  // Login
  signin(user: User): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}otp-login`, user);
  }
  passwordLogin(user: User): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}otp-login`, user);
  }
  // Access user profile
  profileUser(): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}user-profile`);
  }
  sendOtp(user: User): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}sendOtp`, user);
  }
  VerifyNumber(user: User): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}verify-number`, user);
  }
  sendDirectOtp(user: User): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}send-direct-otp`, user);
  }
  shopLogin(user: User): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}shop-direct-login`, user);
  }
  checkExistingUser(user: User): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}check-existing-user`, user);
  }
  changeUserOutlet(payload)  {
    return this.http.post(`${this.apiBaseUrl}change-outlet-slug`,payload);
  }
  RefreshToken(): Observable<any> {
    return this.http.get<any>(`${this.apiBaseUrl}refresh`);
  }
 
  //refresh token
  refreshToken(): Observable<any> {
    return this.http.get<any>(`${this.apiBaseUrl}refresh`)
            .pipe(map((user) => {
                this.userSubject.next(user);
                this.startRefreshTokenTimer();
                return user;
            }));
  }
  private refreshTokenTimeout;

    public startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const accessToken = this.token.getToken();
        const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
        console.log(jwtToken);
        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        console.log(timeout);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    public stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
  forgotPassword(user: User): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}forgot-password`, user);
  }
  resetPassword(user: User): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}reset-password`, user);
  }
  logout(): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}logout`, {});
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
  }
  userAuthValideToken(){
    return this.http.get(`${this.apiBaseUrl}shop-token-valid`);
  }
  getNameByMobile(phone): Observable<any> {
    return this.http.get(`${this.apiBaseUrl}get-user-name/${phone}`);
  }
  searchPincode(payload){
    return this.http.post(`${this.apiBaseUrl}search-pincode`,payload);
  } 
  VerifyNumberAndRegister(user: User): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}verify-number-and-register`, user);
  }
  getoutlets(){
    return this.http.get(`${this.apiBaseUrl}get-all-outlets`);
  }
  getoutletDetails(id:any){
    return this.http.get(`${this.apiBaseUrl}get-all-outlets-details/${id}`);
  }
  sendOtpAndRegister(user: User): Observable<any> {
    return this.http.post<any>(`${this.apiBaseUrl}sendOtpAndRegister`, user);
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }
}
