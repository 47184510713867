<div class="process_desk">
    
    <div class="row row_custom product_device" id="footer">

        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 foot_leftdiv">

            <nav class="navbar navbar-default footer_nav"   style="width: 160%!important;z-index:999">

                <div class="container-fluid nav_footer_container">

                    <!--<p class="navbar-text">
                        <a href="mailto:mail@bolas.co.in?Subject=Career Opportunities" class="navbar-link">Careers</a>
                    </p>-->

                    <p class="navbar-text ">
                        <a href="{{mainSiteUrl}}privacyPolicy" target="_blank" class="navbar-link contact_link">Privacy Policy</a>
                    </p>
    				 <p class="navbar-text ">
                        <a href="{{mainSiteUrl}}terms-and-conditions" target="_blank"  class="navbar-link contact_link">T&C</a>
                    </p>
                     <p class="navbar-text ">
                        <a href="{{mainSiteUrl}}return-policy" target="_blank"  class="navbar-link contact_link">Return Policy</a>
                    </p> 
                    <p class="navbar-text ">
                       <a href="{{mainSiteUrl}}shipping-policy" target="_blank"  class="navbar-link contact_link">Shipping Policy</a>
                   </p> 

                </div>

            </nav>

        </div>

        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 foot_middiv"></div>

        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 foot_rightdiv">

            <p class="footer_right">&#169; Copyrights 2020<a href="" class="footer_link"> Bolas Group </a> All Rights Reserved</p>

        </div>

    </div>
</div>
<div class="process_device bg-back">
     <div class=" ">


        <div class="container">
            <div class="row ">
                <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 shop_locator" *ngIf="pincode">
                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="50px" height="50px" viewBox="0 0 425.963 425.963" style="enable-background:new 0 0 425.963 425.963;" xml:space="preserve" fill="#fbb600">
                        <g>
                            <path d="M213.285,0h-0.608C139.114,0,79.268,59.826,79.268,133.361c0,48.202,21.952,111.817,65.246,189.081
                                c32.098,57.281,64.646,101.152,64.972,101.588c0.906,1.217,2.334,1.934,3.847,1.934c0.043,0,0.087,0,0.13-0.002
                                c1.561-0.043,3.002-0.842,3.868-2.143c0.321-0.486,32.637-49.287,64.517-108.976c43.03-80.563,64.848-141.624,64.848-181.482
                                C346.693,59.825,286.846,0,213.285,0z M274.865,136.62c0,34.124-27.761,61.884-61.885,61.884
                                c-34.123,0-61.884-27.761-61.884-61.884s27.761-61.884,61.884-61.884C247.104,74.736,274.865,102.497,274.865,136.62z"/>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                        <g>
                        </g>
                    </svg>
                </div>

                <div class="col-md-9 col-lg-9 col-sm-9 col-xs-9 shop_info">
                    <p class=" ">
                        
                        <span *ngIf="shopOpClseStatus">
                            <span class="store-open"  style="color: {{shopStatusColor}}" >{{shopStatus}}</span> <br/>
                            <span class="store-address" >{{shopOpeningTiming}} - {{shopCloseTiming}}</span> <br/>
                        </span>
                        <span class="store-name"> {{storename}} </span> <br/>
                        
                        <span class="store-address" *ngIf="address">{{address}}</span><span *ngIf="pincode">, {{pincode}}</span> <br *ngIf="address"/>
                        <a href="tel:+91{{shopphone}}" class="store-address text-white" *ngIf="shopphone">+91 {{shopphone}} </a>
                    </p>
                </div>
            </div>
        </div>
    </div> 
    <div class=" ">
        <div class="raw ">
            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 footer-line-left navbar-text">
                <img style="width: 100%;" src="/assets/images/ico/divider.PNG">
            </div>
            <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6 footer-line-middle navbar-text">
                <ul class="nav">
                    <li>
                        <a href="https://www.instagram.com/bolas.india/" target="_blank"><img src="/assets/images/insta.png" alt="instagram" class=""></a>&nbsp;</li>
                    <li>
                        <a href="https://www.facebook.com/bolasindia" target="_blank"><img src="/assets/images/facebookh.png" alt="facebook" class=""></a>&nbsp;</li>
                    <li>
                        <a href="https://twitter.com/Bolasindia" target="_blank"><img src="/assets/images/twitter.png" alt="twitter" class=""></a>&nbsp;</li>
                    <li>
                        <a href="https://www.linkedin.com/company/bolas2005/" target="_blank"><img src="/assets/images/linkedin.png" alt="Linked In" class=""></a>
                    </li>
                </ul>
            </div>
            <div class="footer-line-right navbar-text">
                <img style="width: 100%;"  src="/assets/images/ico/divider.PNG">
            </div>
            
        </div>
        <nav class="footer_nav"   style="z-index:99999">

           <div class="container">
                <div class="row footer-devide-links">
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6 footer-left-device">
                        <p class="raw ">
                            <a href="{{mainSiteUrl}}privacyPolicy" target="_blank" class="navbar-link contact_link">Privacy Policy</a>
                        </p>
                        <p class="raw  ">
                            <a href="{{mainSiteUrl}}terms-and-conditions" target="_blank"  class="navbar-link contact_link"> T&C</a>
                        </p>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6 col-xs-6  footer-rigth-device">
                        
                        <p class=" ">
                            <a href="{{mainSiteUrl}}return-policy" target="_blank"  class="navbar-link contact_link">Return Policy</a>
                        </p> 
                        <p class="">
                            <a href="{{mainSiteUrl}}shipping-policy" target="_blank"  class="navbar-link contact_link">Shipping Policy</a>
                        </p> 
                    </div>
                </div>
            </div>

        </nav>
        <div class="row row_shopcontact">

            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 shop_contactcol">
                <p class="center_text ">
                    <a href="tel:+919449868000"><img src="/assets/images/shopmcall.png" alt="phone" class="img-thumbnail"><br> +91-9449868000
                    </a>
                </p>
            </div>

            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 shop_contactcol">
                <p class="center_text">
                    <a href="tel:+918258281944"><img src="/assets/images/shopmland.png" alt="phone" class="img-thumbnail"><br> 91-8258 281944</a>
                </p>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 shop_contactcol">
                <p class="center_text">
                    <a href="mailto:wecare@bolas.co.in?Subject=Contact Inquiries">
                        <img src="/assets/images/shopmemail.png" alt="phone" class="img-thumbnail"><br> wecare@bolas.co.in
                    </a>
                </p>
            </div>
            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3 shop_contactcol">
                <p class="center_text"><img src="/assets/images/shopmlocation.png" alt="phone" class="img-thumbnail"><br> Karkala,<br> Mangalore
                </p>

            </div>


        </div>
        <br/>
        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 foot_middiv"></div>

        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 foot_rightdiv">

            <p class="footer_right">&#169; Copyrights 2020<a href="" class="footer_link"> Bolas Group </a> All Rights Reserved</p>

        </div>

    </div>
</div>