<div class="modal-body">

    <div class="row row_custom">

        <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12 shoplogin_side">
            <img class="close closedevice" (click)="activeModal.dismiss('Close clicked')" data-dismiss="modal" aria-label="Close" src="/assets/images/close.png">
        </div>

        <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12 policy_body">

            <h3 class="policy_head">Privacy Policy</h3>

            <p>
                We at <a href="https://www.bolas.co.in">www.bolas.co.in</a> (Bolas Agro Private Limited) are committed to respect the privacy of all visitors to our website. Our privacy policy has been put in place recognizing the importance of protecting any information collected about our visitors, while using our website. This document is designed to tell you about the way in which we collect and use information from our site so that you can make an informed choice of using this site.
             </p>
			 <p>
             Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.
		   </p>

            <h3 class="policy_head">Changes to This Privacy Policy</h3>

            <p>
             This Privacy Policy is effective from 19-01-2022 and will remain in effect except with respect to any changes to its provisions in the future, which will be in effect immediately after being posted on this page. We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgement of the modifications and your consent to abide and be bound by the modified Privacy Policy.
			</p>

            <h3 class="policy_head">Collection and Use of Personal Information:</h3>

            <p>
               If you use our website, we may collect information about your IP address and the browser you're using. We might look at what site you came from, duration of time spent on our website. We might also collect information about the type of mobile device you are using, or the version of the operating system your computer or device is running. We may collect your Name, Date of Birth, and Contact Information including e-mail Address, Demographic information such as Post code, preferences and interests and information relevant to customer surveys and/or offers.
			</p>
            <p>
            <u> We collect information directly from you.</u> We collect information directly from you when you register or fill out any forms on our website and enter information about yourself. We also collect information if you post a comment on our website or ask us a question through phone or email.
			</p>
            <p>
              <u>We collect information from you passively.</u> We use tracking tools like Google Analytics for collecting information about your usage of our website.
		   </p>
			<p>
              <u>We get information about you from third parties.</u> For example, if you use an integrated social media feature on our websites. The third-party social media site will give us certain information about you. This could include your name and email address.
			</p>


            <h3 class="policy_head">Device information</h3>
            <ul>
            <li>
             Examples of Personal Information collected: version of web browser, IP address, time zone, cookie information, what sites or products you view, search terms, and how you interact with the Site.
		   </li>
            <li>
            Purpose of collection: to load the Site accurately for you, and to perform analytics on Site usage to optimize our Site.
			</li>
            <li>
            Source of collection: Collected automatically when you access our Site using cookies, log files, web beacons, tags, or pixels.
		   </li>
             </ul>
            <h3 class="policy_head">Order information</h3>
            <ul>
            <li>
             Examples of Personal Information collected: name, billing address, shipping address, payment information (including credit/Debit card numbers or any other payment details) email address, and phone number.
			</li>
            <li>
            Purpose of collection: to provide products or services to you to fulfil our contract, to process your payment information, arrange for shipping, and provide you with invoices and/or order confirmations, communicate with you, screen our orders for potential risk or fraud, and when in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.
			</li>
            <li>
             Source of collection: collected from you.
            </li>
			</ul>
            <h3 class="policy_head">What we do with your information</h3>
            <p>
             We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
			</p>
            <ul>
                <li>
                  We may use the information to improve our products and services. We may use the information to customize the website according to your interests. We use this information to process your purchase order.
			   </li>
                <li>
                 We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the e-mail address which you have provided.
			   </li>
                <li>
                 From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax, mail  or whatsapp.
			   </li>
                <li>
                    Internal record keeping.
                </li>
            </ul>
            <h3 class="policy_head">Security</h3>

            <p>
            We are committed to ensuring that your information is secure. In order to prevent unauthorized access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
		  </p>

            <h3 class="policy_head">How we use cookies</h3>
            <p>
            A cookie is a small file which asks permission to be placed on your computer's hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets us know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.
			</p>
            <p>
            We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system
			</p>
            <p>
            Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
			</p>
            <p>
            You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
			</p>
            <p>
            The length of time that a cookie remains on your computer or mobile device depends on whether it is a “persistent” or “session” cookie. Session cookies last until you stop browsing and persistent cookies last until they expire or are deleted. Most of the cookies we use are persistent and will expire between 30 minutes and two years from the date they are downloaded to your device.
			</p>
            <p>
            You can control and manage cookies in various ways. Please keep in mind that removing or blocking cookies can negatively impact your user experience and parts of our website may no longer be fully accessible.
			</p>

            <h3 class="policy_head">Links to other websites</h3>
            <p>
           Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
		  </p>
		    <p>
                If you believe that any information we are holding on you is incorrect or incomplete, please email us <a href="mailto:mail@bolas.co.in">mail@bolas.co.in</a>. We will promptly correct any information found to be incorrect.
            </p>
           <p>
		   We Implement security measures designed to protect your information from unauthorized access. We protect your information from potential security breaches by implementing certain technological security measures. However these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such measures.
		   </p>
            

            <h3 class="policy_head">Disclaimer</h3>
            <p>
             The images and content in this website are the exclusive Intellectual property of <a href="https://www.bolas.co.in">www.bolas.co.in</a> (Bolas Agro Private Limited). All rights to the images and content are reserved and no person may download, duplicate, reproduce, edit, publish, make available or disseminate through whatever means this website or any content contained in whole or in part.
		   </p>

            <h3 class="policy_head">Contact Us</h3>
            <p>
               If you have any questions about this Privacy Policy, please contact us at <a href="mailto:mail@bolas.co.in">mail@bolas.co.in</a>
            </p>
        </div>
        <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12 shoplogin_side">

            <img class="close closedesk" (click)="activeModal.dismiss('Close clicked')" src="/assets/images/close.png">

        </div>
    </div>
</div>