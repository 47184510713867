import * as $ from 'jquery';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { CountryService} from '../../../country.service';
import { ProductService } from '../../../product-details/product.service';
import { forkJoin } from 'rxjs';

export class PriceFilter {
  _id: string;
  slug: string;
  title: string;
  max: number;
  min: number;

  constructor(obj) {
    this._id = obj._id;
    this.slug = obj.slug;
    this.title = obj.title;
    this.max = obj.metadata.max;
    this.min = obj.metadata.min;
  }
}
export class Category {
  _id: string;
  category_name: string;

  constructor(obj) {
    this._id = obj._id;
    this.category_name = obj.metadata.category_name;
  }
}
export class WeightFilter {
  _id: string;
  name: string;
  value: string;

  constructor(obj) {
    this._id = obj._id;
    this.name = obj.metadata.name;
    this.value = obj.metadata.value;
  }
}

@Component({
  selector: 'app-brand-filter',
  templateUrl: './brand-filter.component.html',
  styleUrls: ['./brand-filter.component.css']
})
export class BrandFilterComponent implements OnInit {
  weightFilters:any = [];
  priceFilters:any =[];
  categories:any = [];
  @Output() selectedFilters = new EventEmitter<string>();
  isactive:boolean = false;
  selectedWeight:any = [];
  selectedPrice:any =[];
  selectedCategory:any=[];
  public activeElement:any = []; 
  activeIds = ['panel-1', 'panel-2', 'panel-3']; 
  constructor(
    private formBuilder: FormBuilder,
    private CountryService: CountryService,
    private ProductService:ProductService,
  ) { }

  public categoryList: Map<string, boolean> = new Map<string, boolean>();;
  public WeightList: Map<string, boolean> = new Map<string, boolean>();
  public priceList: Map<string, boolean> = new Map<string, boolean>();

  ngOnInit(): void {
    $(document).ready(function(){
      var fil=$(".container_shop").height();
      $(".side_filter").height(fil);
     });
    $(window).on('resize', function(){
      var fil=$(".container_shop").height();
     $(".side_filter").height(fil);
    });
    forkJoin( this.ProductService.getWeight(), this.ProductService.getPrice(), this.ProductService.getCategory()).subscribe(
      ([weightFilters, priceFilters, categories]) => {
        // categories
        categories.forEach(cat => {
           this.categoryList.set(cat, false);
        });

        // prices
        priceFilters.forEach(pf => this.priceList.set(pf, false));

        weightFilters.forEach(pf => this.WeightList.set(pf, false));

        this.updateSelectedFilters();
      }
    );
    

  }

  updateSelectedFilters() {
    // categories

    const catInSelection: string[] = this.setCategoryFilterSelection(this.categoryList);
    this.selectedCategory = this.setCategoryFilterSelection(this.categoryList);
    // colors

    const colorInSelection: string[] = this.setweightFiltersSelection(this.WeightList);
    this.selectedWeight = this.setweightFiltersSelection(this.WeightList);
    // price
    const pricesInSelection: string[]  = this.setPriceFilterSelection(this.priceList);
    this.selectedPrice = this.setPriceFilterSelection(this.priceList);
    // query
    let jsonObj = {};
    if (catInSelection.length > 0) {
      jsonObj['categories'] = {
        $in: catInSelection
      };
    }
    if (colorInSelection.length > 0) {
      jsonObj['weight'] = { $in: colorInSelection };
    }

    if (pricesInSelection.length > 0) {
      jsonObj['price'] = { $in: pricesInSelection };
    }

    const query = encodeURIComponent(JSON.stringify(jsonObj));
    this.selectedFilters.emit(query);
  }
  filterCategory(entry: { key: string; value: boolean }) {
    this.categoryList.set(entry.key, !entry.value);
    this.activeElement = entry.key;
    this.updateSelectedFilters();
  }

  filterWeight(entry: { key: string; value: boolean }) {
    this.WeightList.set(entry.key, !entry.value);
    this.updateSelectedFilters();
  }

  filterPrice(entry: { key: string; value: boolean }) {
    this.priceList.set(entry.key, !entry.value);
    this.updateSelectedFilters();
  }
  setCategoryFilterSelection(collection: Map<string, boolean>): string[] {
    const inList = [];
    collection.forEach((value: boolean, key: string) => {
      if (value === true) {
        inList.push(key);
      }
    });
    return inList;
  }

  setweightFiltersSelection(collection: Map<string, boolean>): string[] {
    const inList = [];
    collection.forEach((value: boolean, key: string) => {
      if (value === true) {
        inList.push(key);
      }
    });
    return inList;
  }

  setPriceFilterSelection(collection: Map<string, boolean>): string[] {
    const inList = [];
    collection.forEach((value: boolean, key: string) => {
      if (value === true) {
        inList.push(key);
      }
    });
    return inList;
  }
 
}
