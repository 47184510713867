<app-sub-header></app-sub-header>
 


<div class="container_details ">





    <div class="store_section">

        <div class="process_desk">

            <ul class="nav nav-tabs" id="shop-tabs" role="tablist">

                <li class="nav-item" id="backarrow">

                    <a class="nav-link" (click)='goBack()'><img class="" src="/assets/images/arrow12.png" alt="logo"></a>
    
                </li>
                
                <li class="nav-item" id="prodtab">

                    <a class="nav-link active" data-toggle="tab"  (click)="goToShopCategory()"><img class="noshop_logo" src="/assets/images/products_hover0.png" alt="logo"><img class="acshop_logo" src="/assets/images/products0.png" alt="logo">&nbsp;Products&nbsp;</a>

                </li>
				
				<li class="nav-item" id="listbrandtab">

                    <a class="nav-link " data-toggle="tab" (click)="goToShopBrand()"   href="#brand"><img class="acshop_logo" src="/assets/images/brands0.png" alt="logo"><img class="noshop_logo" src="/assets/images/brands_hover0.png" alt="logo">&nbsp;Brands&nbsp;</a>

                </li>

                <li class="nav-item" id="searchtab">

                <app-search-product-header></app-search-product-header>
  
               </li>

            </ul>

        </div>
        
        <div class="process_device">

            <!-- <ul class="nav nav-tabs" id="shop-tabs" role="tablist">

                <li class="nav-item" id="searchtab1">

                    <div class="input-group">

                        <input type="text" class="form-control sale_search1" placeholder="Search Products" id="search_text1" name="search_text1">

                        <div class="input-group-append">

                            <button class="btn btn-sale" type="submit"><i class="fa fa-search"></i></button>

                        </div>

                    </div>

                </li>



            </ul> -->

            <ul class="nav nav-tabs" id="shop-tabs2" role="tablist">

                <li class="nav-item" id="prodtab1">

                    <a class="nav-link active" data-toggle="tab" [routerLink]="['/']"><img class="acshop_logo" src="/assets/images/products-hover.png" alt="logo"><img class="noshop_logo" src="/assets/images/products.png" alt="logo">&nbsp;Products</a>

                </li>
                <li class="nav-item" id="brandtab">

                    <a class="nav-link" data-toggle="tab" [routerLink]="['/brands']"><img class="acshop_logo" src="/assets/images/brands0.png" alt="logo"><img class="noshop_logo" src="/assets/images/brands0.png" alt="logo">&nbsp;Brands</a>

                </li>

                

            </ul>

        </div>

        <!-- Tab panes -->

        <div class="details_contentinner">


            <div class="tab-content sale_content">



                <div id="brand" class="tab-pane" [ngClass]="{'active':isProductActive}">



                    <div class="row row_custom">

                        <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12 itempop_left">

                            <div class="itempop_slidediv">

                                <ul class="itempop_thumbnails process_desk">

                                    <li *ngFor="let photo of photos  | keyvalue">
                                        <img class="itempopsmall" [src]="photo.value.product_image" (click)="updateMainImage($event, photo.key)" />
                                    </li>

                                </ul>
                                <div class="itempop_big">

                                    <img id="itempopimg" class="mx-auto d-block itempop_bigimg" [src]="imagePath" (click)="open(0)" />

                                </div>
                                <ul class="itempop_thumbnails process_device">
                                    <li *ngFor="let photo of photos  | keyvalue"><img class="itempopsmall" [src]="photo.value.product_image" (click)="updateMainImage($event, photo.key)" /></li>
                                </ul>
                            </div>

                        </div>

                        <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12 itempop_right">

                            <h3 class="itempop_head">{{product.product_name}} </h3>

                            <!-- <p class="itempop_weight">Net Weight: {{product.product_weight}}g</p> -->

                            <p class="itempop_mrp">Price: Rs. {{price}} <span class="mrp-price"><del>Rs. {{ product.cskd_mrp}}</del></span></p>

                            <div *ngIf="inStock" class="itempop_qtydiv">

                                <p class="cart_button"><span class="cartadd1"><a class="activebutton"
                                            [ngClass]="{inactiveButton: iqnty == 1} "
                                            (click)="increamentQTY(-1)">-</a></span>{{ iqnty }}<span class="cartadd2"><a
                                            class="activebutton" (click)="increamentQTY(1)">+</a></span></p>

                            </div>

                           <p class="itempop_stock" *ngIf="inStock">In Stock <small class="text_red" *ngIf="inStock && product.stock <= 10">({{product.stock}} Left)</small></p>
                           <p class="itempop_stock text_red" *ngIf="!inStock">Out of Stock</p>

                            <div class="itempopbuttons" *ngIf="inStock">

                                <button type="submit" class="itempop_buy" (click)="buyNow()">BUY NOW</button>

                                <button type="submit" class="itempop_cart" (click)="addItemToCart()">ADD TO
                                    CART</button>

                            </div>
							<p [innerHTML]="product.description"></p>

                        </div>
                        <div class="add_image_response" [innerHTML]="product.aplus_description"></div>
                    </div>
                </div>

                <div id="categoryList" class="tab-pane fade">
                    <!-- <app-category></app-category> -->
                </div>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>