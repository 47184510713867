import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, shareReplay } from 'rxjs/operators';
import { environment } from './../../environments/environment';
export class Category {
  _id: string;
  category_name: string;

  constructor(obj) {
    this._id = obj._id;
    this.category_name = obj.category_name;
  }
}
export class Brand {
  _id: string;
  brand_name: string;

  constructor(obj) {
    this._id = obj._id;
    this.brand_name = obj.brand_name;
  }
}
export class PriceFilter {
  _id: string;
  slug: string;
  title: string;
  max: number;
  min: number;
 

  constructor(obj) {
    this._id = obj._id;
    this.slug = obj.slug;
    this.title = obj.title;
    this.max = obj.metadata.max;
    this.min = obj.metadata.min;
  }
}
export class Product {
  _id: string;
  title: string;
  price: string;
  categories: Category[];
  brands: Brand[];
  image: string;
  weight: string;

  constructor(obj) {
    this._id = obj._id;
    this.title = obj.title;
    this.price = obj.metadata.price;
    this.image = obj.metadata.image.url;
    this.weight = obj.metadata.weight;
    this.categories = [];
    this.brands = [];

    if (obj.metadata && obj.metadata.categories) {
      obj.metadata.categories.map(category => this.categories.push(new Category(category)));
    }
    if (obj.metadata && obj.metadata.brands) {
      obj.metadata.brands.map(brand => this.brands.push(new Brand(brand)));
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  appShopId:any;
 

  constructor(private http: HttpClient) { }
  public sharedProduct = {};
  //apiBaseUrl = 'https://bolas.co.in/admin/api/';
  //apiBaseUrl    = "https://bsk.bispltest.in/admin/api/";
  apiBaseUrl = environment.APP_URL;
  private products$ = new Map<string, Observable<Product[]>>();

  private categories$: Observable<Category[]>;

  private brands$: Observable<Brand[]>;

  getBrandProducts(brandName: string) {
    return this.http.get(`${this.apiBaseUrl}store/products/brand/${brandName}`).pipe(
      catchError(this.handleError)
    );
  }
  getCategoryProducts(categoryName: string) {
    return this.http.get(`${this.apiBaseUrl}store/products/category/${categoryName}`).pipe(
      catchError(this.handleError)
    );
  }
  getProductById(id: number){
    return this.http.get(`${this.apiBaseUrl}store-products/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  getProductBySlug(slug: string){
    return this.http.get(`${this.apiBaseUrl}store-product/${slug}`).pipe(
      catchError(this.handleError)
    );
  }
  searchProduct(payload) {
    //Handle without token
    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId   && this.appShopId!='undefined'){
        this.appShopId='0';
    }else{
        this.appShopId=localStorage.getItem('appShopId'); 
    }
    return this.http.post(`${this.apiBaseUrl}search-product/${this.appShopId}`, payload);
  }
  getPrice(){
    return this.http.get<any[]>(`${this.apiBaseUrl}store/get-price`).pipe(
      catchError(this.handleError)
    );
  }
  getWeight(){
    return this.http.get<any[]>(`${this.apiBaseUrl}store/get-weight`).pipe(
      catchError(this.handleError)
    );
  }
  getCategory(){
    return this.http.get<any[]>(`${this.apiBaseUrl}store/get-category`).pipe(
      catchError(this.handleError)
    );
  }
  getBrand() {


    //Handle without token
    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId   && this.appShopId!='undefined'){
        this.appShopId='0';
    }else{
        this.appShopId=localStorage.getItem('appShopId'); 
    } 

    return this.http.get<any[]>(`${this.apiBaseUrl}get-brand/${this.appShopId}`).pipe(
      catchError(this.handleError)
    );
  }
  getProductsBrandByQuery(id:string, query?: string): Observable<Product[]> {
    if (!this.products$.get(query)) {
      const querystring = query ? '?query=' + query : '';
      return this.http.get<any>(`${this.apiBaseUrl}store/products/brand/${id}${querystring}`).pipe(
        catchError(this.handleError)
      );
    }
    //return this.products$.get(query);
  }
  getProductsCategoryByQuery(id:number, query?: string): Observable<Product[]> {
    if (!this.products$.get(query)) {
      const querystring = query ? '?query=' + query : '';
      return this.http.get<any>(`${this.apiBaseUrl}store/products/category/${id}${querystring}`).pipe(
        catchError(this.handleError)
      );
    }
    // if (!this.products$.get(query)) {
    //   const querystring = query ? '?query=' + query : '';

    //   const response = this.http.get<any[]>(this.apiBaseUrl + 'store/products/category/'+id + querystring).pipe(
    //     tap(_ => console.log('fetched products')),
    //     map(_ => {
    //       if (_['objects']) {
    //         return _['objects'].map(element => new Product(element));
    //       }
    //     }),
    //     shareReplay(1),
    //     catchError(this.handleError)
    //   );
    //   this.products$.set(query, response);
    // }
    // return this.products$.get(query);
  }
  getImages(id) {
    return this.http.get<any>(`${this.apiBaseUrl}store/products_image/${id}`).pipe(
      catchError(this.handleError)
    );
  }


  getCategoryProductsWithoutToken(categoryName: string,appShopId: string) { 
    return this.http.get(`${this.apiBaseUrl}store/products-info/category/${categoryName}/${appShopId}`).pipe(
      catchError(this.handleError)
    );
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }
  private handleErrors<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      return of(result as T);
    };
  }
}
