<app-sub-header></app-sub-header>
 

<div class="containercart_outer">

    <div class="container_cart">

        <div class="row row_custom">

            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 cart_topdummy">

            </div>

        </div>



        <div class="row row_custom">

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 process_col accountheadbg carthead">

                <h3 class="accountheadtext">MY ACCOUNT</h3>

            </div>

            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 process_col">

            </div>

        </div>



        <div class="row row_custom">

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 myacc_leftcol">

                <div class="myacc_namesec">
                    
                    <div  class="myacc_thumbnail">

                        <img [src]="imageSrc"  class="myacc_thumbimg" alt="cart" style="width: 99px;height: 99px; border-radius: 50%; object-fit: cover; "><!-- width: 100%;height:auto; border-radius: 42px; -->

                        <a *ngIf="!imgUploaded" (click)="editImage()"><span class="myacc_thumbedit" ><img src="/assets/images/edit-white.png" class="" alt="cart"></span></a>
                    </div>
                    
                    <h4 ng-if="!editNameMode" class="myacc_name">{{user?.name}}
                        <!-- <a (click)="editName()"><span class="myacc_nemeedit" ><img src="assets/images/edit-black.png" class="" alt="cart"></span></a> -->
                    </h4>

                    

                </div>
                

                <form id="myacc_form" [formGroup]="profileForm">
                    <div *ngIf="editImageMode"  class="">
                        <input type="file" name="file"  (change)="handleFileInput($event)">
                    </div>
                    <div *ngIf="errors?.error" class="alert alert-danger mt-3">
                        {{ errors?.error }}
                    </div>
                    <label class="myacc_label" for="email">Email:</label>

                    <input class="myacc_text" type="email" placeholder="Enter Email" [value]="user.email" name="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.pattern">Please enter a valid email</div>
                    </div>
                    <div *ngIf="errors?.email" class="backend_error">{{ errors?.email }}</div>

                    <label class="myacc_label" for="mobile">Mobile:</label>

                    <input class="myacc_text" type="text" placeholder="Enter Mobile Number" name="mobile" [value]="user.phone" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" readonly>
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">Mobile number is required</div>
                        <div *ngIf="f.phone.errors.pattern">Please enter a valid mobile number</div>
                    </div>
                    <div *ngIf="errors?.phone" class="backend_existerror invalid-feedback">{{ errors?.phone }}</div>

                    <label *ngIf="showStoreDiv" class="myacc_label" for="store">Store:</label>
                    <div *ngIf="showStoreDiv" class="outlet_gallery"> 
                     <input class="myacc_text" type="text" placeholder="Enter store" name="store" [value]="user.seo_url" formControlName="store" readonly>

                       
                    </div>

                    <div class="myacc_buttonsec">
                        <button class="common_updatebutton" type="submit" (click)="onSubmit()">UPDATE</button>

                        <!-- <button class="common_cancelbutton" (click)="onReset()">CANCEL</button> -->

                    </div>

                </form>
                <div *ngIf="message" class="alert alert-success mt-3">
                    {{ message }}
                </div>


            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">

                <h3 class="accsetting_head">Account Settings</h3>

                <a [routerLink]="['/myaddress']"><button class="accsetting_button">MY ADDRESSES</button></a>

                <a  [routerLink]="['/new-address']"><button class="accsetting_button">ADD A NEW ADDRESSES</button></a>
                
              <!-- user.password--> 
                <a *ngIf="hasPassword" [routerLink]="['/change-password']"><button class="accsetting_button">CHANGE PASSWORD</button></a>

                <a *ngIf="!hasPassword" [routerLink]="['/set-password']"><button class="accsetting_button">SET PASSWORD</button></a>

                <a *ngIf="displayNewAddress" [routerLink]="['/myorders']"><button class="accsetting_button">MY ORDERS</button></a>

                <a    (click)="openSearcOutletModal(true)"  *ngIf="showStoreDiv"><button class="accsetting_button">CHANGE OUTLET</button></a>

            </div>

        </div>



    </div>

</div>
<app-footer></app-footer>