<app-sub-header></app-sub-header>
<div class="containercart_outer"> 
	<div class="container_cart" >  
		<div style="padding-bottom:50px;"> 
		   <form appForm [formGroup]="mapFrm">
			<input type="text" class="form-control pin_search" placeholder="Search Pin" id="pinsearch_text" name="pinsearch_text" formControlName="pinsearch_text" (keyup)="trim($event.target.value)"  > 
			 <button class="btn btn-pinbot" type="submit" (click)="loadGoogleMap()" >OK</button>

			 <div>
			 	<div *ngFor="let marker of allOutlets;">
			 		<div style="font-weight: bold;" (click)="loadGoogleMapOnClickOfInfo(marker.pin,marker)">{{marker.name}}</div>
			 		<div>{{marker.vicinity}}</div>
			 		<div>{{marker.phone}}</div> 
			 	</div>
			 </div>
		  </form>
		</div>

		 <!-- <agm-map [latitude]="lat" [longitude]="lng"  [mapTypeId]="mapType" [zoom]="zoom" >
		        <agm-marker *ngFor="let marker of coordinates;" 
		        [iconUrl]="icon" 
		        [latitude]="marker.latitude"
		        [longitude]="marker.longitude"
		        [markerClickable]="true"
		        (markerClick)="markerClicked($event)"
                	(mouseOver)="onMouseOver(infoWindow, $event)" 
                	(mouseOut)="onMouseOut(infoWindow, $event)">
	        		<agm-info-window #infoWindow>
	        			<div><a href="/{{marker.seo_url}}">{{marker.name}}</a></div>
	        			<div>{{marker.vicinity}}</div>
	        		</agm-info-window>
		    	</agm-marker>

    		 </agm-map> -->  
	</div>
</div>
<app-footer></app-footer>  
