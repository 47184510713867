import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  apiBaseUrl = environment.APP_URL;
  loginBaseUrl = environment.APP_URL_LOGIN;
  registerBaseUrl = environment.APP_URL_REGISTER;
  private issuer = {  
    login:        this.loginBaseUrl,
    register:     this.registerBaseUrl 
  }

  constructor() { }

  handleData(token){
    localStorage.setItem('auth_token', token);
    sessionStorage.setItem("token", token);
  }

  getToken(){
    return localStorage.getItem('auth_token');
  }

  // Verify the token
  isValidToken(){
     const token = this.getToken();
     if(token){
       const payload = this.payload(token);
       if(payload){
         return Object.values(this.issuer).indexOf(payload.iss) > -1 ? true : false;
       }
     } else {
        return false;
     }
  }

  payload(token) {
    if(token != "undefined"){
      const jwtPayload = token.split('.')[1];
      if(jwtPayload !=''){
        return JSON.parse(atob(jwtPayload));
      }
    }
  }

  // User state based on valid token
  isLoggedIn() {
    return this.isValidToken();
  }

  // Remove token
  removeToken(){
    localStorage.removeItem('auth_token');
  }
}
