import { Component, OnInit, Output, EventEmitter, Input,  AfterViewInit,ViewChild,ElementRef} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal , ModalDismissReasons , NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ChooseOutletsComponent} from 'src/app/modals/choose-outlets/choose-outlets.component';
import { MustMatch } from '../../_helpers/must-match.validator';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { CountryService } from '../../country.service';
import { AuthService } from './../../shared/auth.service';
import { TokenService } from '../../shared/token.service';
import { AuthStateService } from '../../shared/auth-state.service';
@Component({
  selector: 'app-registration',
  templateUrl: './registraion.component.html',
  styleUrls: ['./registraion.component.css']
})

export class RegistraionComponent implements OnInit {
  @Input()id: number;
  closeResult: string;
  registerForm: FormGroup;
  SubmitOtpForm: FormGroup;
  submitted:boolean = false;
  otpsubmitted:boolean = false;
  formSumbit:boolean = false;
  otpformSumbit:boolean = false;
  countries: any = [];
  states: any = [];
  cities: any=[];
  errors = null;
  stores: any = [];
  image_active:boolean = false;
  selected :any;
  store:any;
  showOutlet:boolean =true;
  showRegisterotp:boolean =false;
  phonevalue: string;
  user:any = [];
  pattern = /^[A-Za-z \/\s\.!@#$%^&*()~`+\-=\[\]{};':"\\|,.<>\/?]*$/;
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private CountryService: CountryService,
    public authService: AuthService,
    public router: Router,
    private modalService: NgbModal,
    private token: TokenService,
    private authState: AuthStateService
  ) { 
    
  }
  closeModal() {
    this.activeModal.close('Modal Closed');
  }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
        name: ['', [Validators.required, Validators.pattern(this.pattern)] ],
        email: ["", [  Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
        password: ['',[Validators.minLength(6),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&].{5,}')] ],
        password_confirmation: [''],
        phone: ['', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
        address:[''],
        address1:[''],
        address2: [''],
        country_id: [111],
        pin:['',[Validators.minLength(6),Validators.pattern("^[1-9][0-9]{5}$")]],
        state_id:[''],
        town_id:[''],
        //outlet:['',[Validators.required]]
    },{
      validator: MustMatch('password', 'password_confirmation')
    });
	
	 this.SubmitOtpForm = this.formBuilder.group({
        otp :['',[Validators.required,Validators.minLength(6),Validators.pattern("^[0-9]*$")]],
		    mobile : ['']
    });
	
      this.CountryService.getCountries().subscribe(
        data => this.countries = data
      );

      this.CountryService.getStores().subscribe(
        data => this.stores = data
      );
      this.onChangeCountry(111);
  }
  _keyPress(event: any) {
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
        event.preventDefault();

    }
  }
  onChangeCountry(countryId: number) {
    if (countryId) {
      this.CountryService.getStates(countryId).subscribe(
        data => {
          this.states = data;
          this.cities = null;
        }
      );
    } else {
      this.states = null;
      this.cities = null;
    }
  }

  onChangeState(stateId: number) {
    if (stateId) {
      this.CountryService.getCities(stateId).subscribe(
        data => this.cities = data
      );
    } else {
      this.cities = null;
    }
  }


  ToggleCourse(e){
    this.selected = e; 
    this.registerForm.controls['outlet'].patchValue(e.id);
  }
  isActive(item) {
    this.store = item.id;
    return this.selected === item;
  }

  skipOutletValidation(){
    this.showOutlet = false;
    this.registerForm.get('outlet').clearValidators();
    //this.registerForm.removeControl('outlet');
	this.registerForm.get('outlet').updateValueAndValidity();
  }
  
  showOutletValidation()
  {
   this.showOutlet = true;
    this.registerForm.controls['outlet'].setValidators([Validators.required]);
    //this.registerForm.removeControl('outlet');
	this.registerForm.get('outlet').updateValueAndValidity();
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  get o() { return this.SubmitOtpForm.controls; }
  isFieldValid(field: string) {
    return !this.registerForm.get(field).valid && this.registerForm.get(field).touched;
  }
  Register() {
    this.errors = '';
    this.submitted = true;
    this.formSumbit = true;
	this.showRegisterotp = false;
    if(this.registerForm.invalid){
      this.formSumbit = false;
      this.scrollToError();
      return;
    }
		
	this.phonevalue = this.registerForm.get('phone').value;	
    this.authService.register(this.registerForm.value).subscribe(
      result => {
        this.responseHandler(result);
        this.formSumbit = false;
      },
      error => {
        this.errors = error.error;
        this.formSumbit = false;
      },
     () => {
	     
		this.showRegisterotp = true;
		this.registerForm.reset();
        /*this.authState.setAuthState(true);
        this.activeModal.close('Modal Closed');
        this.modalService.dismissAll()
        this.reloadComponent();*/
      }
    );
	

  }
  
    VerifyOtp(){
    this.errors = '';
    this.otpsubmitted = true;
    this.otpformSumbit = true;
	//console.log(this.phonevalue);
	this.SubmitOtpForm.controls['mobile'].setValue(this.phonevalue);
    if(this.SubmitOtpForm.invalid){
      this.otpformSumbit = false;
      return;
    }
    this.authService.signin(this.SubmitOtpForm.value).subscribe(
      result => {
        this.responseHandler(result);
        this.otpformSumbit = false;
        this.authService.profileUser().subscribe(
          data => {
            this.user = data;
            this.authService.startRefreshTokenTimer()
            localStorage.setItem("username", JSON.stringify(this.user.name));
          }
        );
        this.chooseOutlet();
        //this.reloadComponent();
      },
      error => {
        this.errors = error.error.error;
        console.log(this.errors);
        this.otpformSumbit= false;
        return false;
      },() => {
        this.authState.setAuthState(true);
        this.SubmitOtpForm.reset();
        this.activeModal.close('Modal Closed');
		    this.modalService.dismissAll();
        //this.reloadComponent();
        this.chooseOutlet();
      }
    );
  }
  
  chooseOutlet(){
    const modalRef = this.modalService.open(ChooseOutletsComponent, { 
                                                centered: true, 
                                                size: 'xl',
                                                backdrop: 'static',
                                                keyboard: false,
                                                windowClass: 'register_modal' 
                                          });
    modalRef.componentInstance.id = 2; // should be the id

    modalRef.result.then((result) => {
      console.log(result);
    }).catch((error) => {
      console.log(error);
    });
  }
  
  /*reloadComponent() {
    let currentUrl = this.router.url;
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([currentUrl]);
    }*/
	
	reloadComponent() {
      //let currentUrl = this.router.url;
      let currentUrl = "/";
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
    }
	
    responseHandler(data){
      this.token.handleData(data.token);
    }
    trim(myString) {
      console.log(myString);
      this.registerForm.patchValue({
        phone: myString.replace(" ",''),
      });
      
    }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    console.log("aa:", firstElementWithError);

    this.scrollTo(firstElementWithError);
  }
}