import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
import { ShopComponent } from './shop/shop.component';
import { CartComponent } from './cart/cart.component';
import { MyaccountComponent } from './myaccount/myaccount.component';
import { MyaddressComponent } from './myaddress/myaddress.component';
import { NewAddressComponent } from './new-address/new-address.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { DeliveryPaymentComponent } from './delivery-payment/delivery-payment.component';
import { ChangePasswordComponent } from './myaccount/change-password/change-password.component';
import { SetPasswordComponent } from './myaccount/set-password/set-password.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { MyordersComponent } from './myorders/myorders.component';
import { BrandListComponent } from './products/brand-list/brand-list.component';
import { CategoryListComponent} from './products/category-list/category-list.component';
import { ProductDetailComponent } from './product-details/product-detail.component'
import { SearchProductComponent } from './search-product/search-product.component';
import { DirectLoginComponent } from './direct-login/direct-login.component';
import { DirectLoginModalComponent } from './direct-login/login/login.component';
import { ShopProductComponent } from './shop-product/shop-product.component';
import { CategoryProductComponent } from './product-details/category-product/category-product.component';
import { GiftboxComponent } from './gift/giftbox/giftbox.component';
import { GiftitemsComponent } from './gift/giftitems/giftitems.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { MyAgmComponent } from './my-agm/my-agm.component';
import { ViewmapComponent } from './viewmap/viewmap.component';

const routes: Routes = [
  { path: '', component: ShopComponent },
  { path: '',   redirectTo: '', pathMatch: 'full' },
  
  { path: 'product/brand/:slug', component: BrandListComponent },
  { path: 'product/category/:slug', component: CategoryListComponent },
  { path: 'product-details/:slug', component:ProductDetailComponent},
  { path: 'product/product-details/:slug', component:CategoryProductComponent},
  { path: 'cart', component: CartComponent },
  { path: 'myaccount', component: MyaccountComponent },
  { path: 'myaddress', component: MyaddressComponent },
  { path: 'new-address', component: NewAddressComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'set-password', component: SetPasswordComponent },
  { path: 'thank-you/:id', component:PaymentSuccessComponent},
  { path: 'order-confirm', component:DeliveryPaymentComponent},
  { path: 'order-details/:id', component:OrderDetailsComponent},
  { path: 'myorders', component:MyordersComponent},
  { path: 'product-search', component:SearchProductComponent},
  { path: 'gift', component:GiftboxComponent },
  { path: 'gift/:slug', component:GiftitemsComponent },
  
  { path: 'brands', component:ShopProductComponent },
  { path: 'viewmap/:pincode', component:ViewmapComponent },
  { path: ':shop', component:DirectLoginComponent },

  { path: '**', pathMatch: 'full', component: PagenotfoundComponent },
];

@NgModule({
   imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
