import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-brand-product',
  templateUrl: './brand-product.component.html',
  styleUrls: ['./brand-product.component.css']
})
export class BrandProductComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
