import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { Observable, of } from 'rxjs';
import { catchError, map, tap, shareReplay } from 'rxjs/operators';
import { environment } from './../../environments/environment';
export class Category {
  _id: string;
  category_name: string;

  constructor(obj) {
    this._id = obj._id;
    this.category_name = obj.category_name;
  }
}
export class Brand {
  _id: string;
  brand_name: string;

  constructor(obj) {
    this._id = obj._id;
    this.brand_name = obj.brand_name;
  }
}
export class PriceFilter {
  _id: string;
  slug: string;
  title: string;
  max: number;
  min: number;
 

  constructor(obj) {
    this._id = obj._id;
    this.slug = obj.slug;
    this.title = obj.title;
    this.max = obj.metadata.max;
    this.min = obj.metadata.min;
  }
}
export class Product {
  _id: string;
  title: string;
  price: string;
  categories: Category[];
  brands: Brand[];
  image: string;
  weight: string;

  constructor(obj) {
    this._id = obj._id;
    this.title = obj.title;
    this.price = obj.metadata.price;
    this.image = obj.metadata.image.url;
    this.weight = obj.metadata.weight;
    this.categories = [];
    this.brands = [];

    if (obj.metadata && obj.metadata.categories) {
      obj.metadata.categories.map(category => this.categories.push(new Category(category)));
    }
    if (obj.metadata && obj.metadata.brands) {
      obj.metadata.brands.map(brand => this.brands.push(new Brand(brand)));
    }
  }
}

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  appShopId:any;

  constructor(private http: HttpClient) { }

  //apiBaseUrl = 'https://bolas.co.in/admin/api/';
  //apiBaseUrl = 'https://bsk.bispltest.in/admin/api/';
  apiBaseUrl = environment.APP_URL;
  private products$ = new Map<string, Observable<Product[]>>();

  private categories$: Observable<Category[]>;

  private brands$: Observable<Brand[]>;


  getBrandProducts(brandId: number, query?: string) {


    //Handle without token
    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId   && this.appShopId!='undefined'){
        this.appShopId='0';
    }else{
        this.appShopId=localStorage.getItem('appShopId'); 
    }

    return this.http.get(`${this.apiBaseUrl}brand/products/${brandId}/${this.appShopId}`).pipe(
      catchError(this.handleError)
    );
  }
  getCategoryProducts(categoryId: number, query?: string) {
    return this.http.get(`${this.apiBaseUrl}category/products/${categoryId}`).pipe(
      catchError(this.handleError)
    );
  }
   getCategoryName(id: number){
    return this.http.get(`${this.apiBaseUrl}category/${id}`).pipe(
      catchError(this.handleError)
    );
  }
  getProductById(id: number){
    //Handle without token
    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId   && this.appShopId!='undefined'){
        this.appShopId='0';
    }else{
        this.appShopId=localStorage.getItem('appShopId'); 
    }

    return this.http.get(`${this.apiBaseUrl}product/${id}/${this.appShopId}`).pipe(
      catchError(this.handleError)
    );
  }
 
   
  /*getCategory(): Observable<Category[]> {
  
    if (!this.categories$) {
      this.categories$ = this.http.get<Category[]>(`${this.apiBaseUrl}get-all-category`).pipe(
        tap(_ => console.log('fetched categories')),
        map(_ => {
          console.log( _['objects']);
          return _['objects'].map(element => new Category(element));
        }
        ),
        shareReplay(1),
        catchError(this.handleErrors('getCategory', []))
      );
    }
    return this.categories$;
  }*/
  
  getPrice(){
    return this.http.get<any[]>(`${this.apiBaseUrl}store/get-price`).pipe(
      catchError(this.handleError)
    );
  }
  getWeight(){
    return this.http.get<any[]>(`${this.apiBaseUrl}store/get-weight`).pipe(
      catchError(this.handleError)
    );
  }
  getCategory(){ 

    //Handle without token
    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId   && this.appShopId!='undefined'){
        this.appShopId='0';
    }else{
        this.appShopId=localStorage.getItem('appShopId'); 
    } 
    return this.http.get<any[]>(`${this.apiBaseUrl}get-all-category/${this.appShopId}`).pipe(
      catchError(this.handleError)
    );
  }
  
  
  getBrand(): Observable<Brand[]> {


    if (!this.brands$) {
      this.brands$ = this.http.get<Brand[]>(`${this.apiBaseUrl}get-brands`).pipe(
        tap(_ => console.log('fetched brands')),
        map(_ => {
          console.log( _['objects']);
          return _['objects'].map(element => new Brand(element));
        }
        ),
        shareReplay(1),
        catchError(this.handleErrors('getBrand', []))
      );
    }
    return this.brands$;
  }
  getBrandFilter() {

    //Handle without token
    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId   && this.appShopId!='undefined'){
        this.appShopId='0';
    }else{
        this.appShopId=localStorage.getItem('appShopId'); 
    }

    return this.http.get<any[]>(`${this.apiBaseUrl}get-brands/${this.appShopId}`).pipe(
      catchError(this.handleError)
    );
  }
  getCaegoryProductsByQuery(id :number,query?: string): Observable<Product[]> {
    if (!this.products$.get(query)) {


    //Handle without token
    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId   && this.appShopId!='undefined'){
        this.appShopId='0';
    }else{
        this.appShopId=localStorage.getItem('appShopId'); 
    }


      const querystring = query ? '?query=' + query : '';
      return this.http.get<any>(`${this.apiBaseUrl}category/products/${id}/${this.appShopId}${querystring}`).pipe(
        catchError(this.handleError)
      );
    }
  
  }

  getProductsBrandByQuery(id:number, query?: string): Observable<Product[]> {

    //Handle without token
    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId   && this.appShopId!='undefined'){
        this.appShopId='0';
    }else{
        this.appShopId=localStorage.getItem('appShopId'); 
    }
	
	  if (!this.products$.get(query)) {
      const querystring = query ? '?query=' + query : '';
      return this.http.get<Product[]>(`${this.apiBaseUrl}brand/products/${id}/${this.appShopId}${querystring}`).pipe(
        catchError(this.handleError)
      );
    }
	
  }
  
  
  getProductStock(id: number){


    //Handle without token
    this.appShopId=localStorage.getItem('auth_token');
    if(this.appShopId   && this.appShopId!='undefined'){
        this.appShopId='0';
    }else{
        this.appShopId=localStorage.getItem('appShopId'); 
    }

    return this.http.get(`${this.apiBaseUrl}get-stock/${id}/${this.appShopId}`).pipe(
      catchError(this.handleError)
    );
  }


  getCategoryProductsWithoutToken(categoryName: string,appShopId: string) { 
    return this.http.get(`${this.apiBaseUrl}store/products-info/category/${categoryName}/${appShopId}`).pipe(
      catchError(this.handleError)
    );
  }
  
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }
  private handleErrors<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      return of(result as T);
    };
  }
}

